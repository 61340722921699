<app-toolbar></app-toolbar>
<div class="setting__main_container">
  <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
    <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
  </span>
  <div class="setting__heading_container">
    <h3>{{ 'salary_break_up_table' | translate }}</h3>
  </div>
  <ag-grid-angular style="width: 100%; height: 30rem;" class="ag-theme-alpine" [rowData]="salaryBreakupData"
    [columnDefs]="columnDefs" [pagination]="true" [paginationPageSize]="pageSize" [defaultColDef]="defaultColDef"
    [rowSelection]="'single'" (gridReady)="onGridReady($event)" [pagination]="true"
    [paginationPageSize]="12"></ag-grid-angular>
</div>