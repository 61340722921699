import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { RolesService } from 'src/app/services/roles/roles.service';

@Component({
  selector: 'app-role-information',
  templateUrl: './role-information.component.html',
  styleUrls: ['./role-information.component.scss']
})
export class RoleInformationComponent implements OnInit {
  @Input() id: any;
  roleList: any[] = [];
  selectedRoleList: any[] = [];
  columnDefs: any[];
  defaultColDef: any;
  isDeleteAction = false;
  constructor(private toastrService: ToastrService, private roleService: RolesService, private renderer: Renderer2) {
    this.columnDefs = [
      { headerName: 'ID', field: 'id', sortable: true, filter: true },
      { headerName: 'Role Name', field: 'roleName', sortable: true, filter: true },
      { headerName: 'Role Description', field: 'roleDescription', sortable: true, filter: true },
      {
        headerName: 'Action',
        cellRenderer: this.actionCellRenderer.bind(this)
      }
    ];

    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true
    };
  }

  ngOnInit() {
    this.getAllRoleList();
    this.getAssignRole(this.id);
  }

  getAssignRole(employeeId: any) {
    this.roleService.getRoleByEmployeeId(sessionStorage.getItem(AppConstant.COMPANY_ID), employeeId).subscribe(
      (data: any) => {
        if (data.status === 200 && data.data !== null) {
          this.selectedRoleList = data.data;
        }
      }
    );
  }

  saveRole() {
    const dto = {
      companyId: Number(sessionStorage.getItem(AppConstant.COMPANY_ID)),
      employeeId: this.id,
      adminId: sessionStorage.getItem(AppConstant.ADMIN_ID),
      roles: this.selectedRoleList.map((role: any) => role.roleName)
    };

    this.roleService.saveAllRoleByEmployeeId(dto).subscribe(
      (data: any) => {
        this.toastrService.clear();
        if (this.isDeleteAction) {
          this.toastrService.success('Role deleted successfully');
        } else {
          this.toastrService.success(data.message);
        }
        this.isDeleteAction = false;
      },
      (error: any) => {
        this.isDeleteAction = false;
      }
    );
  }










  getAllRoleList() {
    this.roleService.getAllRole(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.roleList = data.data;
        }
      }
    );
  }

  selectRole(event: any) {
    const selectedRole = this.roleList.find(role => role.id === event.value);
    if (selectedRole && !this.selectedRoleList.some(role => role.id === selectedRole.id)) {
      this.selectedRoleList.push(selectedRole);
      this.updateGridData();
      this.saveRole();
    }
  }

  removeSelectedRoleList(params: any) {
    const index = this.selectedRoleList.findIndex(role => role.id === params.data.id);
    if (index >= 0) {
      this.selectedRoleList.splice(index, 1);
      this.updateGridData();
      this.saveRole();
    }
  }

  isRoleSelected(roleId: any): boolean {
    return this.selectedRoleList.some(role => role.id === roleId);
  }

  updateGridData() {
    this.selectedRoleList = [...this.selectedRoleList];
  }

  // actionCellRenderer(params: any) {
  //   const cell = document.createElement('div');
  //   const createButton = (icon: string, color: string, clickHandler: () => void) => {
  //     const button = this.renderer.createElement('button');
  //     const iconElement = this.renderer.createElement('mat-icon');
  //     this.renderer.listen(button, 'click', clickHandler);
  //     this.renderer.setAttribute(iconElement, 'role', 'img');
  //     this.renderer.setStyle(iconElement, 'font-size', 'large');
  //     this.renderer.addClass(iconElement, 'material-icons');
  //     this.renderer.setStyle(iconElement, 'color', color);
  //     if (icon === 'delete') {
  //       this.renderer.setStyle(iconElement, 'margin-right', '15px');
  //     }
  //     this.renderer.appendChild(iconElement, this.renderer.createText(icon));
  //     this.renderer.appendChild(button, iconElement);
  //     this.renderer.setStyle(button, 'border', 'none');
  //     this.renderer.setStyle(button, 'background-color', 'transparent');
  //     this.renderer.appendChild(cell, button);
  //   };
  //   const deleteIconClickHandler = () => {
  //     if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
  //       this.selectedRoleList = this.selectedRoleList.filter((item: any) => item.id !== params.data.id);
  //       this.updateGridData();
  //       this.saveRole();
  //     }
  //   };
  //   createButton('delete', 'red', deleteIconClickHandler);
  //   return cell;
  // }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const deleteIconClickHandler = () => {
      if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
        this.isDeleteAction = true; // Set the flag to true for delete action
        this.selectedRoleList = this.selectedRoleList.filter((item: any) => item.id !== params.data.id);
        this.updateGridData();
        this.saveRole(); // Call saveRole with the delete action flag
      }
    };

    createButton('delete', 'red', deleteIconClickHandler);
    return cell;
  }
}
