import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  isExpanded: boolean = true;
  isEmployeeAvailable: boolean = false;
  isEmployee: boolean = false;
  badgeValue = 0;
  showNotifications: boolean = false;
  notifications: any[] = [];
  companyLogo: any;
  companyName: any;

  @ViewChild('notificationsSidenav') notificationsSidenav!: MatSidenav;
  isMenuItemSelected: boolean = false;

  constructor(public dialog: MatDialog, private companyProfileService: CompanyProfileService, public employeeService: EmployeeDetailsService, private toastrService: ToastrService, private router: Router, private translate: TranslateService) {

  }

  ngOnInit() {
    this.initRole();
    this.getCompanyLogo();

  }

  initRole() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.EMPLOYEE_ROLE) {
      this.isEmployee = true;
      this.getEmployeeNotification();
    } else {
      this.isEmployee = false;
      this.getAdminNotification();
    }
  }

  getCompanyLogo() {
    this.companyProfileService.getCompanyData(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.companyLogo = 'data:image/png;base64,' + data.data.logo;
        this.companyName = data.data.companyName
      }
    )
  }


  getAdminNotification() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.EMPLOYEE_ROLE) {
      return;
    }
    this.employeeService.getAdminNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.ADMIN_ID)).subscribe(
      (res: any[]) => {
        this.notifications = res;
        this.badgeValue = this.notifications.length;
      });
  }

  getEmployeeNotification() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.HR_ROLE) {
      return;
    }
    this.employeeService.getEmployeeNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (res: any[]) => {
        this.notifications = res;
        this.badgeValue = this.notifications.length;
      });
  }

  iwexeSupport() {
    this.router.navigate([AppConstant.ADMIN+AppConstant.SEPERATOR+AppConstant.SUPPORT]);
  }

  iwexeHelp(){
      const pdfPath = 'assets/images/landing-page/help/help-document.pdf';
      window.open(pdfPath, '_blank');
  }

  toggleNotifications() {
    this.showNotifications = this.notifications.length > 0;
    this.notificationsSidenav.toggle();
  }

  closeNotification(notification: any) {
    const index = this.notifications.indexOf(notification);
    if (notification.id === null) {
      this.notifications.splice(index, 1);
      if (this.badgeValue > 0) {
        this.badgeValue--;
      }
      if (this.badgeValue <= 0) {
        this.showNotifications = false;
        this.notificationsSidenav.toggle();
      }
    }
    else if (index !== -1) {
      this.employeeService.deleteNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), notification.id).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.notifications.splice(index, 1);
            if (this.badgeValue > 0) {
              this.badgeValue--;
            }
            if (this.badgeValue <= 0) {
              this.showNotifications = false;
              this.notificationsSidenav.toggle();
            }
          }
          else {
          }
        });
    }
  }

  closeAllNotification() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.EMPLOYEE_ROLE) {
      this.employeeService.deleteAllEmployeeNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.notifications = [];
            this.badgeValue = 0;
            this.showNotifications = false;
            this.notificationsSidenav.toggle();
          }
        });
    }
    else if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.HR_ROLE) {
      this.employeeService.deleteAllAdminNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.ADMIN_ID)).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.notifications = [];
            this.badgeValue = 0;
            this.showNotifications = false;
            this.notificationsSidenav.toggle();
          }
        },
        (error: any) => {
        });
    }
  }
  onMenuItemSelected() {
    this.isMenuItemSelected = true;
    this.router.navigate([AppConstant.ADMIN+ AppConstant.SEPERATOR+AppConstant.VIEW_COMPANY_PROFILE]);
  }

  logout() {
    this.isMenuItemSelected = true;
    sessionStorage.clear();
    this.router.navigate([AppConstant.LANDING_PAGE]);
  }
}
