
<app-toolbar></app-toolbar>

<div class="page-heading">
  <mat-icon matTooltip="{{'previous_page' | translate}}" (click)="goToBack()" svgIcon="arrow-back-double"
    class="rewind"></mat-icon>
  <h3>{{'view_asset_timeline'|translate}}</h3>
</div>
<mat-divider></mat-divider>

<div class="main-container">
  <div class="assetSections">
    <mat-tab-group class="assetTabs">

      <mat-tab label="{{'asset_details'|translate}}">
        <div class="asset-detail-row">
          <div>
            <form [formGroup]="assetsDetails">
              <mat-form-field appearance="outline" class="selector">
                <mat-label>{{'asset'|translate}}</mat-label>
                <mat-select formControlName="assetType">
                  <div *ngFor="let asset of assets">
                    <mat-option [value]="asset">{{asset}}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </form>
          </div>
          <div>
            <button (click)="addassets()" matTooltip="{{'add_asset'|translate}}">{{'add_asset'|translate}}</button>
          </div>
        </div>

        <div class="assetDetailTable">
          <ag-grid-angular class="ag-theme-alpine" style="height: 455px; width: 100%;" [columnDefs]="addAssetcolumnDefs"
            [rowData]="addAssetrowData" [tooltipShowDelay]="tooltipShowDelay" [tooltipHideDelay]="tooltipHideDelay"
            [floatingFiltersHeight]="40" [sideBar]="true" [pagination]="pagination" (gridReady)="onGridReady($event)"
            [paginationPageSize]="paginationPageSize" [paginationPageSizeSelector]="paginationPageSizeSelector">
          </ag-grid-angular>
        </div>

      </mat-tab>

      <mat-tab label="{{'asset_allocation'|translate}}">
        <div class="asset-detail-row">
          <div>
            <form [formGroup]="assetsAllocation">
              <mat-form-field appearance="outline" class="selector">
                <mat-label>{{'asset'|translate}}</mat-label>
                <mat-select formControlName="assetAllocationType">
                  <div *ngFor="let asset of assets">
                    <mat-option [value]="asset">{{asset}}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </form>
          </div>
          <div>
            <button (click)="assignAsset()"
              matTooltip="{{'assign_asset'|translate}}">{{'assign_asset'|translate}}</button>
          </div>
        </div>

        <div class="assetsTable">
          <ag-grid-angular class="ag-theme-alpine" style="height:455px; width: 100%;" [columnDefs]="assetAllocationColumnDefs"
            [rowData]="assetAllocationrowData" [tooltipShowDelay]="tooltipShowDelay" [floatingFiltersHeight]="40"
            [sideBar]="true" [pagination]="pagination" [paginationPageSize]="paginationPageSize"
            [paginationPageSizeSelector]="paginationPageSizeSelector" [enableSorting]="true" [enableFilter]="true">
          </ag-grid-angular>
        </div>
      </mat-tab>

      <mat-tab label="{{'asset_history'|translate}}">
        <div class="assetHistory">
          <form [formGroup]="assetsHistory">
            <mat-form-field appearance="outline" class="selector">
              <mat-label>{{'asset'|translate}}</mat-label>
              <mat-select formControlName="assetHistoryType">
                <div *ngFor="let asset of assets">
                  <mat-option [value]="asset">{{asset}}</mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </form>
        </div>

        <div class="assetsHistoryTable">
          <ag-grid-angular class="ag-theme-alpine" style="height:455px; width: 100%;" [columnDefs]="historyColumnDefs"
            [rowData]="historyrowData" [tooltipShowDelay]="tooltipShowDelay" [floatingFiltersHeight]="40"
            [sideBar]="true" [pagination]="pagination" [paginationPageSize]="paginationPageSize"
            [paginationPageSizeSelector]="paginationPageSizeSelector" [enableSorting]="true" [enableFilter]="true">
          </ag-grid-angular>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>
