import { Component, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { SendOtpService } from 'src/app/services/send-otp/send-otp.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],

})
export class ForgotPasswordComponent {
  
  forgotPasswordForm!: FormGroup;
  showOtpVerification: boolean = false;
  otp: string | undefined;
  isOtpSending: boolean = false;
  formSubmitted: boolean = false;
  loginLink = AppConstant.LANDING_PAGE;
  invalidOtp: boolean = false;
  isCountdownComplete: boolean = false;
  isResendClicked: boolean = false;
  remainingTime: number = 300;
  displayTime: string = '5:00';
  countdownSubscription: Subscription | undefined;
  showSpinner: boolean = false;

  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    inputStyles: {
      width: "30px",
      height: "30px",
    },
  };

  constructor(private ngZone: NgZone, private router: Router, private fb: FormBuilder, private sendOtpService: SendOtpService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.initForgotPasswordForm();
  }

  initForgotPasswordForm() {
    this.forgotPasswordForm = this.fb.group({
      'email': ['', [Validators.required, ValidationUtil.emailValidation()]],
      'otp': ['']
    });
  }

  onOtpChange(otp: any) {
    this.otp = otp;
    if (otp.length === 6) {
      this.changePassword();
    }
  }

  otpVerification() {
    this.formSubmitted = true;
    this.invalidOtp = false;
    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }
    const forgotPasswordRequestBody = {
      email: this.forgotPasswordForm.get('email')?.value,
    };
    sessionStorage.setItem('email', this.forgotPasswordForm.value.email);
    this.isOtpSending = true;
    this.sendOtpService.sendOtp(forgotPasswordRequestBody).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.toastrService.clear();
          this.toastrService.success(res.message);
          this.showOtpVerification = true;
          this.startCountdown();
        } else {
          this.toastrService.clear();
          this.toastrService.error(res.message);
        }
        this.ngZone.run(() => {
          this.isOtpSending = false;
        });
      }
    );
  }

  resendOtp() {
    if (!this.isOtpSending) {
      this.isResendClicked = true;
      this.isOtpSending = true;
      this.isCountdownComplete = false;
      this.remainingTime = 300;
      if (this.countdownSubscription) {
        this.countdownSubscription.unsubscribe();
      }
      this.showSpinner = true;
      const forgotPasswordRequestBody = {
        email: this.forgotPasswordForm.get('email')?.value,
      };
      this.sendOtpService.sendOtp(forgotPasswordRequestBody).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.isOtpSending = false;
            this.startCountdown();
            this.toastrService.success(AppConstant.OTP_SEND_SUCCESSFULLY);
          } else {
            this.isOtpSending = false;
            this.toastrService.clear();
            this.toastrService.error(AppConstant.RESEND_OTP_ERROR);
          }
          this.showSpinner = false;
        }
      );
      this.resetOtpInput();
    }
  }

  startCountdown() {
    this.countdownSubscription = interval(1000).subscribe(() => {
      this.remainingTime--;
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      if (this.remainingTime === 0) {
        this.countdownComplete();
        this.countdownSubscription?.unsubscribe();
      }
      this.displayTime = `${minutes}m ${seconds}s`;
    });
  }

  countdownComplete() {
    this.isCountdownComplete = true;
    this.resetOtpInput();
  }



  changePassword() {
    this.otpValidation(this.otp);
  }

  otpValidation(otp: any) {
    const forgotPasswordRequestBody = {
      email: this.forgotPasswordForm?.get('email')?.value,
      otp: this.otp
    };
    if (otp !== "" && otp?.length === 6) {
      this.sendOtpService.otpVarification(forgotPasswordRequestBody).subscribe((res: any) => {
        if (res.status === 200) {
          this.router.navigate([`/${AppConstant.CHANGE_PASSWORD}`]);
          this.invalidOtp = false;
        } else {
          this.toastrService.clear();
          this.toastrService.error(res.message);
          this.invalidOtp = true;
        }
        this.resetOtpInput();
      });
      return true;
    }
    return false;
  }

  resetOtpInput() {
    this.ngOtpInput.setValue('');
  }
  
  }