import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AccountType, BankDetail } from 'src/app/model/bankDetails.model';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-employee-bank-details',
  templateUrl: './employee-bank-details.component.html',
  styleUrls: ['./employee-bank-details.component.scss']
})

export class EmployeeBankDetailsComponent {

  @Input() createBankForm!: FormGroup;
  isLoading: boolean = false;
  hidePassword: boolean = true;
  verifiedIFSC: boolean = false;
  ifscCode: any;
  errorMessage: any;
  accountType: AccountType[] = [
    { accountType: AppConstant.SALARY, selectedAccount: AppConstant.SALARY },
    { accountType: AppConstant.CURRENT, selectedAccount: AppConstant.CURRENT },
    { accountType: AppConstant.SAVING, selectedAccount: AppConstant.SAVING }
  ];

  constructor(private router: Router, private stepper: MatStepper, private toaster: ToastrService, private employeeDetailsService: EmployeeDetailsService, private fb: FormBuilder, private EmployeeDetailsService: EmployeeDetailsService) { }

  ngOnInit() {
    this.initBankDetails()
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  initBankDetails() {
    this.createBankForm = this.fb.group({
      'bankAccountNo': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(18)]],
      'ifscCode': ['', Validators.required],
      'branch': ['', Validators.required],
      'accountType': ['', Validators.required],
      'bankName': ['', Validators.required],
      'companyId': [''],
      'confirmBankAccountNo': ['', Validators.required],
      "ibanCode": [''],
      'accountHolderName': ['']
    });
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumbers(event);
  }

  validateCharacter(event: any) {
    return ValidationUtil.validateCharacter(event);
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  addBankDetails() {
    if (this.verifiedIFSC) {
      const accountNumber = this.createBankForm.value.bankAccountNo;
      const confirmAccountNumber = this.createBankForm.value.confirmBankAccountNo;
      if (accountNumber !== confirmAccountNumber) {
        this.createBankForm.get('confirmBankAccountNo')?.setErrors({ 'mismatch': true });
      } else {
        this.createBankForm.get('confirmBankAccountNo')?.setErrors(null);
        let createBankDetails: BankDetail = {
          bankAccountNo: accountNumber,
          ifscCode: this.createBankForm.value.ifscCode,
          branch: this.createBankForm.value.branch,
          accountType: this.createBankForm.value.accountType,
          bankName: this.createBankForm.value.bankName,
          companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
          confirmBankAccountNo: confirmAccountNumber,
          ibanCode: this.createBankForm.value.ibanCode,
          accountHolderName: this.createBankForm.value.accountHolderName,
          id: sessionStorage.getItem(AppConstant.BASIC_DETAILS_ID)
        };
        this.employeeDetailsService.employeeBankDetails(createBankDetails).subscribe((data: any) => {
          if (data.status === 200) {
            this.stepper.next();
            this.toaster.success(data.message);
          }
          else {
            this.toaster.error(data.message);
          }
        }
        );
      }
    } else {
      this.createBankForm.controls['ifscCode'].setErrors({ 'invalidIFSC': true });
    }
  }

  validateAccountNumberMatch() {
    const accountNumber = this.createBankForm.value.bankAccountNo;
    const confirmAccountNumber = this.createBankForm.value.confirmBankAccountNo;
    if (accountNumber !== confirmAccountNumber) {
      this.createBankForm.get('confirmBankAccountNo')?.setErrors({ 'mismatch': true });
    } else {
      this.createBankForm.get('confirmBankAccountNo')?.setErrors(null);
    }
  }

  isBankDetailsFormValid(): boolean {
    return this.createBankForm.valid && this.createBankForm.get('bankAccountNo')?.value.length === 11;
  }

  verifyIFSC() {
    sessionStorage.setItem(AppConstant.IFSC_CODE, this.createBankForm.value.ifscCode)
    this.isLoading = true;
    this.errorMessage = '';
    this.EmployeeDetailsService.verifyIFSC(this.createBankForm.value.ifscCode).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.verifiedIFSC = true;
          this.isLoading = false;
          this.createBankForm.controls['ifscCode'].setErrors(null);
          const data = JSON.parse(response.data);
          const bankName = data.BANK;
          const branchName = data.BRANCH;
          this.createBankForm.patchValue({
            bankName: bankName,
            branch: branchName
          });
        } else {
          this.isLoading = false;
          this.verifiedIFSC = false;
          this.createBankForm.controls['ifscCode'].setErrors({ 'invalidIFSC': true });
        }
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  selectedOption(event: any) {
    this.createBankForm.patchValue({
      bankName: event.value
    })
  }

  resetIFSCFieldCheck() {
    this.verifiedIFSC = false;
  }

  backToHome() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES]);
  }
}

