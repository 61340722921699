<app-toolbar></app-toolbar>
<div class="page-heading">
  <mat-icon matTooltip="{{'back_to_dashboard' | translate}}" routerLink="../asset-management"
    svgIcon="arrow-back-double" class="rewind"></mat-icon>
  <h3>{{'supplier'|translate}}</h3>
</div>
<div class="button-div">
  <button (click)="addSupplier()">{{'add_supplier'|translate}}</button>
</div>
<div class="main-container">
  <div>
    <ag-grid-angular class="ag-theme-alpine" style="height:27rem" [columnDefs]="supplierscolumnDefs"
      [rowData]="suppliersrowData" [tooltipShowDelay]="tooltipShowDelay" [tooltipHideDelay]="tooltipHideDelay"
      [floatingFiltersHeight]="40" [sideBar]="true" [pagination]="pagination" [paginationPageSize]="paginationPageSize"
      [paginationPageSizeSelector]="paginationPageSizeSelector">
    </ag-grid-angular>
  </div>
</div>