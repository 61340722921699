
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginService } from '../login/loginService';
import { AppConstant } from 'src/app/constants/app.constants';
import { SessionService } from '../session-service/session.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard {
  constructor(private loginService: LoginService, private sessionService: SessionService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.sessionService.getTimeRemainingForTokenExpiry() == 0) {
      return this.router.parseUrl(AppConstant.LANDING_PAGE);
    }

    const userRole = this.loginService.getUserRole();
    userRole === AppConstant.HR_ROLE || userRole === AppConstant.EMPLOYEE_ROLE
    return true;
  }
}
