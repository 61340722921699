<app-toolbar></app-toolbar>
<div class="page-heading">
    <mat-icon matTooltip="{{'previous_page' | translate}}" (click)="goBack()" svgIcon="arrow-back-double"
        class="rewind"></mat-icon>
    <h3>{{'add_supplier'|translate}}</h3>
</div>
<mat-divider></mat-divider>
<div class="addSupplier">
    <div class="form-container">
        <form [formGroup]="supplierForm">
            <div class="form-field-gap1">
                <div class="gst-question">{{'do-you-have-gst-number' | translate}}</div><br>
                <mat-slide-toggle [checked]="hasGSTNumber" (change)="changeDisable()"></mat-slide-toggle>
                <mat-form-field id="gst-input" appearance="outline" class="full-width">
                    <mat-label>{{ 'gst_number' | translate }}</mat-label>
                    <div class="input-container">
                        <input matInput type="text" class="input" (ngModelChange)="gstNumberUpdated($event)"
                            (keypress)="alphanumberOnly($event)" formControlName="gstNumber"
                            oninput="this.value = this.value.toUpperCase()" maxlength="15" />
                        <a *ngIf="!verifiedGST && !isLoadingForVerifyGST" (click)="verifyGST()" class="verify"
                            [ngClass]="supplierForm.controls['gstNumber'].value.length == 15 ? 'verify-blue' : 'verify-gray'">{{'verify'
                            | translate}}</a>
                        <mat-icon *ngIf="verifiedGST" class="success-icon" color="primary">done</mat-icon>
                    </div>
                    <mat-error
                        *ngIf="supplierForm.controls['gstNumber'].hasError('required')">{{'please-provide-valid-gst' |
                        translate}}</mat-error>
                    <mat-error
                        *ngIf="supplierForm.controls['gstNumber'].hasError('invalidGST')">{{'please-provide-valid-gst'
                        | translate}}</mat-error>
                    <app-spinner *ngIf="isLoadingForVerifyGST" class="spinner"></app-spinner>
                </mat-form-field>
            </div>

            <div class="form">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'supplier_name'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" formControlName="supplierName" required="*"
                        (keypress)="validateCharacterNumberAndSpace($event)">
                    <mat-error *ngIf="supplierForm.controls['supplierName'].hasError('required')">
                        {{'supplier_name_is_required'|translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'business_name' | translate}}</mat-label>
                    <input class="input-field" matInput type="text" formControlName="businessName"
                           (keypress)="validateSpecialCharacters($event)" maxlength="25">
                    <mat-error *ngIf="supplierForm.get('businessName')?.hasError('required')">
                        {{'business_name_is_required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="supplierForm.get('businessName')?.hasError('maxlength')">
                        {{'business_name_max_length' | translate}}
                    </mat-error>
                </mat-form-field>
                
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'business_type'|translate}}</mat-label>
                    <mat-select formControlName="businessType">
                        <mat-option>{{'none'|translate}}</mat-option>
                        <div *ngFor="let business of businessTypes">
                            <mat-option [value]="business">{{business}}</mat-option>
                        </div>
                    </mat-select>
                    <mat-error *ngIf="supplierForm.controls['businessType'].hasError('required')">
                        {{'business_type_is_required' |translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'asset_type' | translate }}</mat-label>
                    <mat-select #select multiple formControlName="assetTypes" class="select-mat-input">
                      <div class="select-all">
                        <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" 
                                      (change)="toggleAllAssetTypes()">
                          {{ 'select_all' | translate }}
                        </mat-checkbox>
                      </div>
                  
                      <mat-option *ngFor="let asset of allAssetTypeList" [value]="asset">
                        {{ asset }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="supplierForm.controls['assetTypes'].hasError('required')">
                      {{ 'asset_type_is_required' | translate }}
                    </mat-error>
                  </mat-form-field>
                  
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'contact_number'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" (keypress)="numberOnly($event)" maxlength="10"
                        formControlName="contactNumber" prequired="*">
                    <mat-error *ngIf="supplierForm.controls['contactNumber'].hasError('required')">
                        {{'contact_is_required'|translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'email'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" formControlName="email" required="*">
                    <mat-error *ngIf="supplierForm.controls['email'].hasError('required')">
                        {{'email_is_required' |translate}}
                    </mat-error>
                    <mat-error *ngIf="supplierForm.controls['email'].hasError('email')">
                        {{'invalid_email' |translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'supplier_address'|translate}} </mat-label>
                    <input class="Input-field" matInput type="text" formControlName="supplierAddress" required="*"
                        (keypress)="addressVerification($event)">
                    <mat-error *ngIf="supplierForm.controls['supplierAddress'].hasError('required')">
                        {{'supplier_address_is_required'|translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'supplier_address_2'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" formControlName="supplierAddress2"
                        (keypress)="addressVerification($event)">
                </mat-form-field>
            </div>
            <div class="form">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label class="formLabel">{{'country' | translate}}</mat-label>
                    <mat-select matInput formControlName="country" class="select_mat_input" required="true"
                        (selectionChange)="getState($event.value)">
                        <mat-option *ngFor="let country of countryList;" [value]="country">{{country}}</mat-option>
                    </mat-select>
                    <mat-error>{{'country_is_required' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label class="formLabel">{{ 'state' | translate }}</mat-label>
                    <mat-select matInput formControlName="state" class="select-mat-input" required="true"
                        (selectionChange)="getCity($event.value)">
                        <mat-option *ngFor="let state of stateList;" [value]="state">
                            {{ state }}
                        </mat-option>
                    </mat-select>
                    <mat-error>{{ 'state_is_required' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label class="formLabel">{{ 'city' | translate }}</mat-label>
                    <mat-select matInput formControlName="city" class="select-mat-input"
                        (selectionChange)="selectedCityName($event.value)">
                        <mat-option *ngFor="let city of cityList;" [value]="city">{{ city }}</mat-option>
                    </mat-select>
                    <mat-error>{{ 'city_is_required' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'pincode' | translate }}</mat-label>
                    <input matInput type="text" class="input" (keypress)="validateNumber($event)"
                        formControlName="pinCode" minlength="6" maxlength="6" />

                    <mat-error *ngIf="supplierForm.get('pinCode')?.hasError('required')">
                        {{ 'pincode_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="supplierForm.get('pinCode')?.hasError('minlength')">
                        {{ 'pincode_length' | translate }}
                    </mat-error>
                </mat-form-field>

            </div>
            <div class="form">

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'ifsc_code' | translate }}</mat-label>
                    <input matInput type="text" class="ifscVerify" formControlName="ifscCode" [(ngModel)]="ifscCode"
                        (keypress)="alphanumericAllowed($event)" maxlength="11" (ngModelChange)="resetIFSCFieldCheck()"
                        appUppercase required />
                    <mat-error *ngIf="supplierForm.get('ifscCode')?.hasError('required')">
                        {{ 'ifsc_code_is_required' | translate }}
                    </mat-error>
                    <a *ngIf="!verifiedIFSC && !isLoadingForVerifyIFSC" (click)="verifyIFSC()" class="verify"
                        [ngClass]="supplierForm.controls['ifscCode'].value?.length === 11 && supplierForm.get('ifscCode')?.valid ? 'verify-blue' : 'verify-gray'">{{'verify'
                        | translate}}</a>
                    <mat-icon *ngIf="verifiedIFSC" class="success-icon" color="primary">done</mat-icon>
                    <p class="error-message" *ngIf="errorMessage">{{ "errorMessage" }}</p>
                    <app-spinner *ngIf="isLoadingForVerifyIFSC" class="spinner"></app-spinner>
                    <mat-error
                        *ngIf="supplierForm.get('ifscCode')?.hasError('invalidIFSC')">{{'please-provide-valid-ifsc'
                        | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'bank_name' | translate}}</mat-label>
                    <input matInput class="input" formControlName="bankName" required
                        (keypress)="validateCharacterNumberAndSpace($event)" />
                    <mat-error *ngIf="supplierForm.get('bankName')?.hasError('required')">
                        {{ 'bank_name_is_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'branch_name' | translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="branch" required
                        (keypress)="validateCharacterNumberAndSpace($event)" />
                    <mat-error *ngIf="supplierForm.controls['branch'].hasError('required')">
                        {{ 'branch_name_is_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="supplierForm.controls['branch'].hasError('invalidInput')">
                        {{ 'invalid_name_input' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label class="formLabel"> {{'account_type'| translate}}</mat-label>
                    <mat-select class="select-mat-input" formControlName="accountType" required>
                        <mat-option *ngFor="let accountType of accountType" [value]="accountType.accountType">
                            {{accountType.selectedAccount}}
                        </mat-option>
                    </mat-select>
                    <mat-error>{{ 'account_type_is_required' | translate }}</mat-error>
                </mat-form-field>
            </div>

            <div class="form">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'account_number' | translate}}</mat-label>
                    <input class="input" id="password-field1" type="password" (keypress)="validateNumber($event)"
                        [maxLength]="15" [type]="hidePassword ? 'password' : 'text'" formControlName="bankAccountNo"
                        appBlockCopyPaste matInput />
                    <mat-error *ngIf="!supplierForm.controls['bankAccountNo'].valid">
                        {{'account_number_required'| translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'confirm_account_number' | translate}}</mat-label>
                    <input class="input" id="password-field" (input)="validateAccountNumberMatch()" [maxLength]="15"
                        (keypress)="validateNumber($event)" formControlName="confirmBankAccountNo" matInput
                        appBlockCopyPaste required />
                    <mat-error *ngIf="supplierForm.hasError('mismatch', 'confirmBankAccountNo')">
                        {{'account_do_not_match'|translate}}
                    </mat-error>
                    <mat-error *ngIf="supplierForm.get('confirmBankAccountNo')?.hasError('required')">
                        {{'confirm_account_number_required'| translate}}</mat-error>
                </mat-form-field>
            </div>

            <div class="buttons">
                <button class="cancel-btn" (click)="goBack()">{{'cancel'|translate}}</button>
                <button (click)="submit()">{{'save'|translate}}</button>
            </div>
        </form>
    </div>
</div>

<app-loader></app-loader>