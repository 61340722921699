import { Component } from '@angular/core';

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.scss']
})
export class AllProductsComponent {
  openInterviewPortal() {
    window.open('http://autorecruite.com/home', '_blank');
  }
}
