export const environment = {
  production: false,
  //localhost
    // apiUrl: "http://localhost:8080",
    maintenanceMode: false,
  //dev
  apiUrl: "http://98.70.13.207:8081",

  //AES Cipher Keys
  passPhrase: "dhty#@$24536Csdghyj>/@xdg!sfdgfdty456"
  
}