import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmitterVisitorContext } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class HelpDeskService {

  constructor(private http: HttpClient) { }

  getAllProjectList(comapnyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_PROJECT_LIST + comapnyId).pipe((
      map((res: any) => {
        return res;
      })
    ))
  }

  getserviceList(comapnyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SERVICE_LIST + AppConstant.SEPERATOR + comapnyId).pipe((
      map((res: any) => {
        return res;
      })
    ))
  }

  getCategoryList(companyId: any, serviceCode: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SERVICE_CATEGORY + companyId + AppConstant.SEPERATOR + serviceCode).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getSubCategory(comapnyId: any, categoryCode: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SERVICE_SUBCATEGORY + comapnyId + AppConstant.SEPERATOR + categoryCode).pipe(
      map((res: any) => {
        return res;
      }))
  }

  getAllIssues(type: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_ISSUES + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID) + AppConstant.SEPERATOR + type).pipe(
      map((res: any) => {
        return res;
      }))
  }


  getAllSupportEmployeeList(comapnyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GTE_SUPPORT_EMPLOYEE_LIST + comapnyId).pipe(
      map((res: any) => {
        return res;
      })
    )

  }

  getRole() {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ROLE + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).pipe(
      map((res: any) => {
        return res;
      }))
  }

  requestInformation(requestDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.REQUESTiNFORMATION, requestDto).pipe((
      map((res: any) => {
        return res;
      })
    ))

  }

  createIncident(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });
    return this.http.post(environment.apiUrl + API_END_POINTS.CREATE_INCIDENT, formData, { headers }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }


  getAllTickets() {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_TICKETS_BY_EMPLOYEE_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).pipe(
      map((res: any) => {
        return res;
      }))
  }

  viewRequestBsedOnEmployeeId(requestId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_REQUEST_BY_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID) + AppConstant.SEPERATOR + requestId).pipe(
      map((res: any) => {
        return res;
      }))
  }

  updateStatus(dto: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.ASSIGN_TO, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

}
