<mat-sidenav-container>

    <mat-sidenav #sidenav mode="over" position="end">
        <label>{{'iWExe' | translate}}</label>
        <hr />
        <nav class="nav-link" aria-label="nav links">
            <a routerLink="/login" routerLinkActive="active">{{'signin' | translate}}</a>
            <a routerLink="/registration" routerLinkActive="active">{{'signup' | translate}}</a>
        </nav>
    </mat-sidenav>

    <mat-sidenav-content>
        <!-- header and banner section -->
        <section id="banner">
            <!-- header section -->
            <header class="header">
                <a routerLink="/" class="logo-heading">
                    <img src="../../../../../assets/images/landing-page/logo/logo.svg" alt="" />
                    <label>{{'iWExe' | translate}}</label>
                </a>

                <!-- SignIn and SignUp buttons -->
                <div class="auth-buttons">
                    <button class="sign-in" (click)="login()">{{'signin' | translate}}</button>
                    <button class="sign-up" (click)="registration()">{{'signup' | translate}}</button>
                </div>

                <!-- mobile sidenav -->
                <mat-icon class="mobile-sidenav" (click)="sidenav.toggle()">menu</mat-icon>
            </header>
            <!-- header section end -->

            <!-- banner section -->
            <div class="container">
                <div class="banner-info">
                    <div class="heading">{{ 'elevate_your_hr_experience' | translate}}</div>
                    <div class="sub-heading"> {{ 'where_efficiency_meets_innovation' | translate}}</div>

                    <ul>
                        <li>
                            <span class="material-symbols-outlined">task_alt</span><span>{{'cutomized_group_payroll' |
                                translate}}</span>
                        </li>
                        <li>
                            <span
                                class="material-symbols-outlined">task_alt</span><span>{{'resource_onboarding_to_offboarding'
                                | translate}}</span>
                        </li>
                        <li>
                            <span class="material-symbols-outlined">task_alt</span><span>{{'integrated_banking_support'
                                | translate}}</span>
                        </li>
                        <li>
                            <span class="material-symbols-outlined">task_alt</span><span>{{'comprehensive_reporting' |
                                translate}}</span>
                        </li>
                    </ul>
                </div>
                <app-book-demo></app-book-demo>
            </div>
            <!-- banner section end -->
        </section>
        <!-- header and banner section end -->

        <!-- solutions section -->
        <section id="solutions">
            <app-solutions></app-solutions>
        </section>
        <!-- solution section end -->

        <!-- features section -->
        <section id="features">
            <app-features></app-features>
        </section>
        <!-- features section end -->

        <!-- master team section -->
        <section id="team">
            <app-master-team></app-master-team>
        </section>
        <!-- master team section end -->

        <!-- what's  upcomming section -->
        <section id="comingup">
            <app-upcomings></app-upcomings>
        </section>
        <!-- what's  upcomming section end -->

        <!-- footer section -->
        <section id="comingup">
            <app-footer></app-footer>
        </section>
        <!-- footer section end -->

    </mat-sidenav-content>
</mat-sidenav-container>