<app-toolbar></app-toolbar>
<div class="header">
    <div class="back-button">
        <mat-icon svgIcon="arrow-back-double" class="rewind" (click)="goToBack()"></mat-icon>
        <h3>{{'add_training' | translate}}</h3>
    </div>
</div>
<mat-divider></mat-divider>

<div class="container">
    <form [formGroup]="addTrainingForm" class="form-container">
        <div class="inputFlex">
            <mat-form-field class="inputAlign" appearance="outline">
                <mat-label>{{'training_name' | translate}}</mat-label>
                <input matInput type="text" formControlName="trainingName" (keypress)="alphanumeric($event)"
                    maxlength="60" />
                <mat-error *ngIf="addTrainingForm.controls['trainingName'].errors?.['required']">
                    {{'training_name_is_required' | translate}}
                </mat-error>
                <mat-error *ngIf="addTrainingForm.controls['trainingName'].errors?.['invalidCharacters']">
                    {{'training_name_contains_invalid_characters' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field-grid">
            <mat-form-field appearance="outline">
                <mat-label>{{'training_type'|translate}}</mat-label>
                <mat-select formControlName="trainingType">
                    <mat-option *ngFor="let asset of assets" [value]="asset">{{asset}}</mat-option>
                </mat-select>
                <mat-error *ngIf="addTrainingForm.controls['trainingType'].errors?.['required']">
                    {{'training_type_is_required'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'start_date'|translate}}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="startDate" [min]="minDate"
                    [max]="maxStartDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="addTrainingForm.controls['startDate'].errors?.['required']">
                    {{'start_date_is_required'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'end_date'|translate}}</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="endDate"
                    [min]="addTrainingForm.controls['startDate'].value || minDate" [max]="maxEndDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="addTrainingForm.controls['endDate'].errors?.['required']">
                    {{'end_date_is_required'|translate}}
                </mat-error>
                <mat-error *ngIf="addTrainingForm.controls['endDate'].errors?.['min']">
                    {{'end_date_cannot_be_before_start_date'|translate}}
                </mat-error>
                <mat-error *ngIf="addTrainingForm.controls['endDate'].errors?.['max']">
                    {{'end_date_cannot_be_after_end_of_year'|translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="textareaAlign" appearance="outline">
                <mat-label>{{'training_short_des'|translate}}</mat-label>
                <textarea matInput type="text" formControlName="shortDescription" maxlength="250"
                    (keypress)="alphanumeric($event)"></textarea>
                <mat-error *ngIf="addTrainingForm.controls['shortDescription'].errors?.['required']">
                    {{'short_description_is_required'|translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="inputFlex">
            <mat-form-field class="textareaAlign" appearance="outline">
                <mat-label>{{'training_long_desc'|translate}}</mat-label>
                <textarea matInput type="text" formControlName="longDescription" (keypress)="alphanumeric($event)"
                    maxlength="500"></textarea>
                <mat-error *ngIf="addTrainingForm.controls['longDescription'].errors?.['required']">
                    {{"long_description"|translate}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="attachment-container">
            <div class="file-upload">
                <button class="selectFileContainer" (click)="fileInput.click()">
                    <span *ngIf="selectedFiles; else newFile">{{selectedFiles.item(0)?.name}}</span>
                    <ng-template #newFile>
                        {{'choose_pdf' | translate}}
                    </ng-template>
                    <input hidden (change)="selectFile($event)" #fileInput type="file"
                        accept=".pdf,.mp4,.mov,.avi,.mkv,.wmv,.flv">
                </button>

                <button (click)="thumbnailInput.click()" class="thumbnail">
                    <span *ngIf="selectedThumbnail; else newThumbnail">{{selectedThumbnail.item(0)?.name}}</span>
                    <ng-template #newThumbnail>
                        {{'choose_thumbnail' | translate}}
                    </ng-template>
                    <input hidden (change)="selectThumbnail($event)" #thumbnailInput type="file" accept=".jpg,.svg">
                </button>
            </div>
            <mat-error *ngIf="fileNameError" class="jpg-file-size">{{'file_name_too_long' | translate}}</mat-error>
            <mat-error *ngIf="thumbnailNameError" class="thubnail-file-size">{{'file_name_too_long' |
                translate}}</mat-error>
        </div>
        <div class="saveCancleButton">
            <button class="cancel-btn" type="reset" (click)="goToBack()">{{'cancel'|translate}}</button>
            <button type="submit" (click)="addCourse()" class="saveButton">{{'save'|translate}}</button>
        </div>
    </form>
</div>
<app-loader></app-loader>