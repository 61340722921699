
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppConstant } from 'src/app/constants/app.constants';
import { AddEmployeeInformationData } from 'src/app/model/employeeDetails';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { Router } from '@angular/router';

@Component({
    selector: 'app-assign-manager',
    templateUrl: './assign-manager.component.html',
    styleUrls: ['./assign-manager.component.scss']
})
export class AssignManagerComponent implements OnInit {
    selectFormControl = new FormControl();
    managerList: AddEmployeeInformationData[] = [];
    isAvailable: boolean = false;
    employeeList: AddEmployeeInformationData[] = [];
    employeeListName: string[] = [];
    filteredEmployeeIssues!: string[];
    filteredEmployeeList: string[] = [];
    dropDownEmployeeResults!: Observable<string[]>;
    managerListName: string[] = [];
    filteredIssues!: string[];
    filteredManagerList: string[] = [];
    dropDownResults!: Observable<string[]>;
    searchControl = new FormControl();
    selectedManager: any = null;
    selectedEmployee: any = null;
    searchEmployeeControl = new FormControl();
    showOptions: boolean = false;
    showEmployeeOptions: boolean = false;
    isEmployeeAvailable: boolean = false;
    showConfirmationDialog: boolean = false;
    companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    priviousManagerId: any;
    newManagerID: any;
    employeeId: any;
    manager: any;
    employee: any;
    chnageManager: any;
    constructor(private router:Router, public employeeService: EmployeeDetailsService, private toastrService: ToastrService) { }

    ngOnInit() {
        this.getManagerList();
        this.getEmployeeList();
    }

    toggleOptions() {
        this.showOptions = !this.showOptions;
    }

    hideAssignManagerPopup() {
        this.selectedManager = null;
        this.selectedEmployee = null;
        this.searchEmployeeControl.reset();
        this.searchControl.reset();
        this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.DEPARTMENT]);
    }

    isManagerAvailable() {
        if (this.managerList.length == 0) {
            this.isAvailable = true;
        }
    }

    validateCharacterNumberAndSpace(event: any) {
        return ValidationUtil.characterNumberAndSpaceAllowed(event);
    }

    getEmployeeList() {
        this.employeeService.getAllActiveEmployee(1, AppConstant.COMPANYNAME + sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
            (res: any) => {
                const filteredEmployees = res.filter((employee: any) => employee.employeeBasicDetail.designationName !== 'CEO');
                this.employeeList = filteredEmployees.map((employee: any) => employee.employeeBasicDetail);
                this.employeeListName = filteredEmployees.map((employee: any) => `${employee.employeeBasicDetail.firstName} ${employee.employeeBasicDetail.lastName}`);
                this.filteredEmployeeIssues = [...this.employeeListName];
                this.filteredEmployeeList = [...this.employeeListName];
            });
    }

    getManagerList() {
        this.employeeService.getManagerList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
            (res: any) => {
                this.managerList = res;
                this.managerListName = res.map((manager: any) => `${manager.firstName} ${manager.lastName}`);
                this.filteredIssues = [...this.managerListName];
                this.filteredManagerList = [...this.managerListName];
            });
    }

    onSearchChange(event: Event) {
        const query = (event.target as HTMLInputElement).value;
        this.filteredManagerList = this.managerListName.filter(manager =>
            manager.toLowerCase().includes(query.toLowerCase())
        );
        this.showOptions = this.filteredManagerList.length > 0;
    }

    onEmployeeSearchChange(event: Event) {
        const query = (event.target as HTMLInputElement).value;
        this.filteredEmployeeList = this.employeeListName.filter(employee =>
            employee.toLowerCase().includes(query.toLowerCase())
        );
        this.showEmployeeOptions = this.filteredEmployeeList.length > 0;
    }

    onOptionClick(group: string) {
        this.selectedManager = group;
        this.searchControl.setValue(group);
        this.showOptions = false;
    }

    onOptionEmployeeClick(group: string) {
        this.selectedEmployee = group;
        this.searchEmployeeControl.setValue(group);
        this.showEmployeeOptions = false;
    }

    isEmployeeFound() {
        if (this.employeeList.length == 0)
            this.isEmployeeAvailable = true;
    }

    toggleEmployeeOptions() {
        this.showEmployeeOptions = !this.showEmployeeOptions;
    }

    assignManagerApi() {
        if (this.selectedManager !== null && this.selectedEmployee !== null) {
            const [managerFirstName, managerLastName] = this.selectedManager.split(' ');
            const [employeeFirstName, employeeLastName] = this.selectedEmployee.split(' ');

            this.manager = this.managerList.find(m => m.firstName === managerFirstName && m.lastName === managerLastName);
            this.employee = this.employeeList.find(e => e.firstName === employeeFirstName && e.lastName === employeeLastName);

            if (!this.manager || !this.employee) {
                this.toastrService.error(AppConstant.PLEASE_PROVIDE_VALID_DETAILS);
                return;
            }

            const dto = {
                companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
                managerId: this.manager.id,
                employeeId: this.employee.id
            };

            this.employeeService.assignManagerApi(dto).subscribe(
                (data: any) => {
                    if (data.status == 200) {
                        this.toastrService.success(data.message);
                    } else {
                        if (data.data == null) {
                            this.toastrService.error(data.message);
                        }
                    }
                    this.chnageManager = data.data.previousManagerId;
                    if (data.status === 200) {
                        this.newManagerID = this.manager.id;
                        this.employeeId = this.employee.id;
                        this.hideAssignManagerPopup();
                        this.toastrService.success(data.message);
                    } else if (data.status === 404 && data.message === "The manager is already assigned to employee.") {
                        this.showConfirmationDialog = true;
                    } else {
                        this.toastrService.error(data.message);
                    }
                },
                (error: any) => {
                    this.toastrService.error(AppConstant.PLEASE_PROVIDE_VALID_DETAILS);
                }
            );
        } else {
            this.toastrService.error(AppConstant.PLEASE_PROVIDE_VALID_DETAILS);
        }
    }
    managerNotChange() {
        this.showConfirmationDialog = false;
    }

    confirmChange() {
        const dto = {
            companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
            previousManagerId: this.chnageManager,
            employeeId: this.employee.id,
            newManagerId: this.manager.id,

        }
        this.employeeService.changeManager(dto).subscribe((data: any) => {
            if (data.status == 200) {
                this.toastrService.success(data.message);
                this.showConfirmationDialog = false;
            }

        })
    }

}
