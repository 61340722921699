<app-toolbar></app-toolbar>
<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goBack()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{'create_incident'|translate}}</h3>
    </div>
    <mat-divider></mat-divider>
</div>

<div class="main-container">

    <div class="container">
        <div class="incidents">
            <mat-radio-group class="radio-button" formControlName="serviceRequestType" aria-label="Select an option">
                <mat-radio-button value="true"
                    class="radio-button">{{'technical_incident'|translate}}</mat-radio-button>
                <mat-radio-button value="false" class="radio-button">{{'nontechnical'|translate}}</mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-divider class="divider"></mat-divider>
        <div class="heading">
            <h3 class="heading"><strong>{{'requestor_information'|translate}}</strong></h3>
        </div>
        <div class="Information">
            <form [formGroup]="requestorsInfo">
                <div class="requestorForm">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'reported_for'|translate}}</mat-label>
                        <mat-select formControlName="requestFor" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search [placeholderLabel]="'Search'" [formControl]="personSearchFilter"
                                    noEntriesFoundLabel="" [searching]="searching"
                                    [preventHomeEndKeyPropagation]="true"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of filteredServerSide | async"
                                [value]="employee.employeeId">
                                {{employee.employeeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'mobile_number' | translate }}</mat-label>
                        <input matInput id="contactNumber" (keypress)="validateNumber($event)"
                            formControlName="contactNo" minlength="10" maxlength="10" required />
                        <mat-error *ngIf="requestorsInfo.controls['contactNo'].hasError('required')">
                            {{ 'mobile_number_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="requestorsInfo.controls['contactNo'].hasError('invalidMobileNumber')">
                            {{ 'invalid_mobile_number' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="requestorForm">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'location'|translate}}</mat-label>
                        <input class="Input-field" matInput type="text" formControlName="location" required="*">
                        <mat-error
                            *ngIf="requestorsInfo.controls['location'].hasError('required')">{{'location_is_required'|translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'project'|translate}}</mat-label>
                        <mat-select formControlName="projectId">
                            <div *ngFor="let projectName of projectList">
                                <mat-option [value]="projectName.id">{{projectName.projectName}}</mat-option>
                            </div>
                        </mat-select>
                        <mat-error
                            *ngIf="requestorsInfo.controls['projectId'].hasError('required')">{{'project_is_required'|translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="categoryheading">
                    <h3 class="heading"><strong>{{'please_select_category'|translate}}</strong></h3>
                </div>
                <div class="requestorForm">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'service' | translate }}</mat-label>
                        <mat-select formControlName="serviceId"
                            (selectionChange)="onServiceSelectionChange($event.value)">
                            <mat-option *ngFor="let service of serviceList" [value]="service.id">
                                {{ service.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="requestorsInfo.get('serviceId')?.hasError('required')">
                            {{ 'service_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" *ngIf="isServiceNotSelected">
                        <mat-label>{{'category'|translate}}</mat-label>
                        <mat-select formControlName="serviceCategoryId"
                            (selectionChange)="onServiceCategoryChange($event.value)">
                            <mat-option *ngFor="let category of categoryList"
                                [value]="category.id">{{category.name}}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="requestorsInfo.get('serviceCategoryId')?.hasError('required')">{{'category_is_required'|translate}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="requestorForm" *ngIf="isCategoryNotSelected">
                    <mat-form-field class="full-width" [disabled]="selectedCategoryId == null" appearance="outline">
                        <mat-label>{{'sub_category'|translate}}</mat-label>
                        <mat-select formControlName="subCategoryId">
                            <div *ngFor="let subCategory of subCategoriesList">
                                <mat-option [value]="subCategory.id">{{subCategory.name}}</mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="categoryheading">
                    <h3 class="heading"><strong>{{'your_issue'|translate}}</strong></h3>
                </div>
                <div class="requestorForm">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'short_description'|translate}}</mat-label>
                        <textarea matInput formControlName="shortDescription" maxlength="150" required="*"></textarea>
                        <mat-error
                            *ngIf="requestorsInfo.get('shortDescription')?.hasError('required')">{{'short_description_is_required'|translate}}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'detail_description'|translate}}</mat-label>
                        <textarea matInput formControlName="detailedDescription" maxlength="300"></textarea>
                        <mat-error
                            *ngIf="requestorsInfo.get('detailedDescription')?.value?.length > 300">{{'detail_description_is_required'|translate}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="attachedFile">
                    <p>{{'attached_file'|translate}}
                        <input type="file" id="attachment" formControlName="attachment" #fileInput style="display:none"
                            (change)="onFileChange($event)" accept=".xlsx, .pdf" multiple />
                        <button mat-raised-button style="color: black ; background-color: white; width: auto;"
                            (click)="fileInput.click()">
                            {{'Select files'}}
                        </button>
                        <mat-error *ngIf="requestorsInfo.get('attachment')?.hasError('fileTypeInvalid')">
                            {{'unsupported file type'}}
                        </mat-error>
                        <mat-error *ngIf="requestorsInfo.get('attachment')?.hasError('fileSizeExceeded')">
                            {{'unsupported file size'}}
                        </mat-error>
                        <mat-error *ngIf="requestorsInfo.get('attachment')?.hasError('fileNameInvalid')">
                            {{'file name too long'}}
                        </mat-error>
                    </p>
                </div>
                <mat-chip-list *ngIf="selectedFiles.length > 0">
                    <mat-chip *ngFor="let file of selectedFiles; let i = index" [selectable]="false" [removable]="true"
                        (removed)="removeFile(i)">
                        <span class="filename">{{ file.name }}<span *ngIf="i < selectedFiles.length - 1">,
                            </span></span>
                    </mat-chip>
                </mat-chip-list>

                <div class="button-container">
                    <button class="cancel-btn" (click)="reset()" type="reset">{{'cancel'|translate}}</button>
                    <button class="submit-btn" (click)="submit()">{{'submit'|translate}}</button>
                </div>
                <app-loader></app-loader>
            </form>
        </div>
    </div>

</div>