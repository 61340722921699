<mat-toolbar color="primary">
  <!-- <button (click)="isExpanded = !isExpanded" *ngIf="!isEmployee">
    <mat-icon>menu</mat-icon>
  </button> -->
  <!-- <button>
    <mat-icon *ngIf="showHomeIcon" matTooltip="Home" (click)="onHomeIconClick()">home</mat-icon>
  </button> -->
  <img class="logo" [src]="companyLogo" alt="">
  <h1>{{ companyName | translate}}</h1>

  <span class="profile-menu"></span>

  <button (click)="iwexeSupport()" *ngIf="!isEmployee">
    <mat-icon (click)="iwexeSupport()" matTooltip="IWEXE Support">support</mat-icon>
  </button>

  <button (click)="iwexeHelp()" *ngIf="!isEmployee">
    <mat-icon (click)="iwexeHelp()" matTooltip="Help">help</mat-icon>
  </button>
  

  <button (click)="toggleNotifications()" matTooltip="Notifications">
    <div class="notification-icons" *ngIf="isEmployee">
      <mat-icon>notifications_none</mat-icon>
    </div>
    <div *ngIf="!isEmployee">
      <mat-icon>notifications_none</mat-icon>
    </div>
    <span class="employee-badge" *ngIf="badgeValue > 0 && isEmployee">{{ badgeValue }}</span>
    <span class="custom-badge" *ngIf="badgeValue > 0 && !isEmployee">{{ badgeValue }}</span>
  </button>

  <button [matMenuTriggerFor]="menu" matTooltip="Profile">
    <span class="material-icons">account_circle</span>
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onMenuItemSelected()" routerLink="company-profile" *ngIf="!isEmployee">
      <mat-icon>business</mat-icon>
      <span class="profile-menu">{{ 'company_profile' | translate }}</span>
    </button>
    <button mat-menu-item routerLink="/home" (click)="logout()">
      <mat-icon class="logoutIcon">logout</mat-icon>
      <span class="profile-menu">{{ 'logout' | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #notificationsSidenav class="notification-menu" position="end" mode="over" [opened]="showNotifications"
    autosize>
    <div class="notification-menu-content">
      <ng-container *ngIf="showNotifications; else noNotificationsMessage">
        <div class="notification-list">
          <div *ngFor="let notification of notifications" class="notification">
            <mat-icon>email</mat-icon>
            <div class="notification-message">{{ notification.message }}</div>
            <span class="close-button" (click)="closeNotification(notification)">
              <mat-icon>cancel</mat-icon>
            </span>
          </div>
        </div>
        <span class="closeAll" (click)="closeAllNotification()">Clear All</span>
      </ng-container>
      <ng-template #noNotificationsMessage>
        <div class="no-notifications-message">
          No notifications available
        </div>
      </ng-template>
    </div>
  </mat-sidenav>
</mat-sidenav-container>