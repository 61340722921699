<mat-toolbar color="primary">
    <button (click)="isExpanded = !isExpanded">
        <mat-icon class="menu-icon">menu</mat-icon>
    </button>
    <h1>{{'iWExe' | translate}}</h1>
</mat-toolbar>
<mat-sidenav-container class="container" autosize>
    <mat-sidenav #sidenav class="sidenav" mode="side" opened="true">
        <mat-nav-list>
            <mat-list-item *ngFor="let otherItem of menuList" (click)="navigate(otherItem.name)" class="nested">
                <mat-icon mat-list-icon class="icon">{{ otherItem.icon }}</mat-icon>
                <span *ngIf="isExpanded" class="full-width" mat-list-icon>{{ otherItem.name | translate}}</span>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <router-outlet></router-outlet>
</mat-sidenav-container>