<mat-toolbar color="primary">
    <img class="logo" [src]="companyLogo" alt="">
    <h1>{{ companyName | translate}}</h1>
</mat-toolbar>

<mat-sidenav-container class="container" autosize>
    <mat-sidenav mode="side" opened="true" class="mat-sidenav">

        <mat-nav-list *ngIf="!hrRole || isManager">

            <mat-list-item [routerLinkActive]="['is-active']" (click)="home()">
                <mat-icon class="icon" (click)="home()">home</mat-icon>
                <span class="full-width" (click)="home()">{{'home'| translate}}</span>
            </mat-list-item>

            <mat-list-item routerLink="employee-list" [routerLinkActive]="['is-active']">
                <mat-icon class="icon" routerLink="employee-list">person</mat-icon>
                <span class="full-width" routerLink="employee-list">{{'assign-employees'| translate}}</span>
            </mat-list-item>

            <mat-list-item (click)="approveAttendanceButton()" [routerLinkActive]="['is-active']">
                <mat-icon class="icon">calendar_month</mat-icon>
                <span class="full-width">{{'attendance'|
                    translate}}</span>
            </mat-list-item>

            <mat-list-item (click)="leave()" [routerLinkActive]="['is-active']">
                <mat-icon (click)="leave()" class="icon"> history</mat-icon>
                <span (click)="leave()" class="full-width">{{'leave'|translate}}</span>
            </mat-list-item>

            <mat-list-item (click)="projectApproval()" [routerLinkActive]="['is-active']">
                <mat-icon (click)="projectApproval()" class="icon">approval</mat-icon>
                <span (click)="projectApproval()" class="full-width">{{'project_approval'| translate}}</span>
            </mat-list-item>

            <mat-list-item [routerLinkActive]="['is-active']" routerLink="employee-resignations">
                <mat-icon class="icon" routerLink="employee-resignations"> exit_to_app</mat-icon>
                <span class="full-width" routerLink="employee-resignations">{{'resignations'|
                    translate}}</span>
            </mat-list-item>

            <mat-list-item (click)="training()" [routerLinkActive]="['is-active']">
                <mat-icon (click)="training()" class="icon">laptop</mat-icon>
                <span (click)="training()" class="full-width">{{'assign_training'| translate}}</span>
            </mat-list-item>

            <mat-list-item [routerLinkActive]="['is-active']" (click)="appraisalProcess()" (click)="appraisal()">
                <mat-icon class="icon" (click)="appraisalProcess()" (click)="appraisal()">star</mat-icon>
                <span (click)="appraisal()" class="full-width" (click)="appraisalProcess()">{{'appraisal_process'|
                    translate}}</span>
            </mat-list-item>
        </mat-nav-list>

        <mat-nav-list *ngIf="hrRole && !isManager">

            <mat-list-item [routerLinkActive]="['is-active']" (click)="home()">
                <mat-icon class="icon" (click)="home()">home</mat-icon>
                <span class="full-width" (click)="home()">{{'home'| translate}}</span>
            </mat-list-item>

            <mat-list-item [routerLinkActive]="['is-active']" (click)="leaveDetails()">
                <mat-icon (click)="leaveDetails()" class="icon"> history</mat-icon>
                <span (click)="leaveDetails()" class="full-width">{{'leave'|
                    translate}}</span>
            </mat-list-item>

            <mat-list-item [routerLinkActive]="['is-active']" routerLink="employee-resignations">
                <mat-icon class="icon" routerLink="employee-resignations"> exit_to_app</mat-icon>
                <span class="full-width" routerLink="employee-resignations">{{'resignations'|
                    translate}}</span>
            </mat-list-item>

            <mat-list-item [routerLinkActive]="['is-active']"(click)="viewAttendance()">
                <mat-icon class="icon" (click)="viewAttendance()">calendar_month</mat-icon>
                <span class="full-width" (click)="viewAttendance()">{{'attendance'|
                    translate}}</span>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <router-outlet></router-outlet>
</mat-sidenav-container>