<mat-dialog-content>
    <div class="heading">
        <h3>{{ 'leave_details' | translate }}</h3>
        <div class="button">
            <button (click)="onClose()"> <mat-icon>cancel</mat-icon></button>
        </div>
    </div>
    <div class="leave-details-grid">
        <div class="grid-item"><strong>{{ 'employee_name' | translate }}:</strong>
            {{ leaveData.employeeBasicDetailsModel.firstName}} {{ leaveData.employeeBasicDetailsModel.lastName }}
        </div>
        <div class="grid-item"><strong>{{ 'leave_type' | translate }}:</strong> {{ leaveData.leaveType }}</div>
        <div class="grid-item"><strong>{{ 'start_date' | translate }}:</strong> {{ leaveData.startDate | date }}</div>
        <div class="grid-item"><strong>{{ 'end_date' | translate }}:</strong> {{ leaveData.endDate | date }}</div>
        <div class="grid-item"><strong>{{ 'status' | translate }}:</strong> {{ leaveData.leaveStatus }}</div>
        <div class="grid-item"><strong>{{ 'duration' | translate }}:</strong> {{ leaveData.noOfDays }}</div>
        <div><strong>{{ 'leave_reason' | translate }}:</strong> {{ leaveData.leaveReason }}</div>
    </div>
</mat-dialog-content>
<div *ngIf="leaveData.leaveStatus.toLowerCase() === 'pending'" class="button-div">
    <button class="cancel-btn" (click)="onReject()">{{ 'reject' | translate }}</button>
    <button (click)="onApprove()">{{ 'approve' | translate }}</button>
</div>