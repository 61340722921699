import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayRunService } from 'src/app/services/pay-run/pay-run.service';
import { PayNowList } from 'src/app/model/payrun/payrun.model';
import { AppConstant } from 'src/app/constants/app.constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bank-advice-dialog',
  templateUrl: './bank-advice-dialog.component.html',
  styleUrls: ['./bank-advice-dialog.component.scss']
})
export class BankAdviceDialogComponent {
  bankAdviceForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<BankAdviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private payrunService: PayRunService,
    private toastrService: ToastrService
  ) {
    this.bankAdviceForm = this.fb.group({
      format: ['Standard Format', [Validators.required]],
      downloadAs: ['XLS (Microsoft Excel 1997-2004 Compatible)', [Validators.required]]
    });
  }

  onDownload(): void {
    const dto: PayNowList = JSON.parse(sessionStorage.getItem("payRunList") || "{}");
    const payload = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      bankName: this.bankAdviceForm.value.format,
      fileType: this.bankAdviceForm.value.downloadAs,
      dto: dto.employeeList
    }
    this.payrunService.onDownload(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          const content = data.data.body;
          const byteCharacters = atob(content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const contentDisposition = data.data.headers['Content-Disposition'];
          let fileName = 'defaultFileName.xlsx';
          if (contentDisposition) {
            const matches = /filename="([^"]*)"/.exec(contentDisposition[0]);
            if (matches != null && matches[1]) {
              fileName = matches[1];
            }
          }
          const fileURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.dialogRef.close('download');
        }else{
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    )
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
