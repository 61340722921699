<app-navbar></app-navbar>

<div class="center_div top" id="change_password">
    <mat-card class="container">
        <mat-card-content>
            <div class="header">
                <h2>{{'forgot_password'|translate}}</h2>
            </div>
            <form [formGroup]="changePasswordForm" id="changePasswordForm">

                <mat-form-field appearance="outline" id="password" class="full-width">
                    <mat-label>{{'new_password'|translate}}</mat-label>
                    <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" required>
                    <mat-error *ngIf="changePasswordForm.controls['password'].hasError('required')">
                        {{'password_is_required'|translate}}
                    </mat-error>
                    <mat-error *ngIf="changePasswordForm.controls['password'].hasError('pattern')">
                        {{'strong_password_required'|translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" id="password" class="full-width">
                    <mat-label> {{'confirm_new_password'|translate}}</mat-label>
                    <input matInput formControlName="confirmPassword" [type]="hidePassword ? 'password' : 'text'"
                        [minlength]="8" required>
                    <mat-error *ngIf="!changePasswordForm.controls['confirmPassword'].valid">
                        {{'check_newpassword_and_confirmpassword'|translate}}
                    </mat-error>
                </mat-form-field>

                <button class="visibility button-left" mat-icon-button (click)="hidePassword = !hidePassword"
                    [attr.aria-label]="'Toggle Password Visibility'" [attr.aria-pressed]="!hidePassword">
                    <i class="material-icons">{{ hidePassword ? 'visibility_off' : 'visibility' }}</i>
                    <span class="visibility-text">{{(hidePassword ? 'show' : 'hide') | translate}}</span>
                </button>
            </form>

            <button (click)="setPassword()" class="button-right">{{'submit'|translate}}</button>
        </mat-card-content>
    </mat-card>
</div>