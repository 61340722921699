<div class="setting__main_container">
  <div class="setting__heading_container">
    <h3>{{'salary-structure' | translate}}</h3>
    <div class="setting__header_button_container">
      <button (click)="togglePopup()"> {{'add-component' | translate}} </button>
    </div>
  </div>
</div>

<div class="main-container">
  <mat-tab-group>
    <mat-tab label="{{'earning'|translate}}">
      <table mat-table class="mat-table" [dataSource]="earningsData">
        <ng-container matColumnDef="earning_type">
          <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'earning_type' | translate }}</th>
          <td mat-cell *matCellDef="let earning" [ngClass]="{'bold-text': earning.type === 'Total'}">{{ earning.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="calculation_type">
          <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'calculation_type' | translate }}</th>
          <td mat-cell *matCellDef="let earning" [ngClass]="{'bold-text': earning.type === 'Total'}">
            <ng-container *ngIf="!earning.editing; else editField">{{ earning.value }}</ng-container>
            <div class="percentSymbol" *ngIf="earning.amountType === 'percentage'"> % </div>
            <ng-template #editField>
              <input #editInput [(ngModel)]="earning.updatedAmount" [formControl]="earningFormControl"
                pattern="[0-9]*" />
              <div *ngIf="earningFormControl.invalid && (earningFormControl.dirty || earningFormControl.touched)">
                <mat-error *ngIf="earningFormControl.errors?.['required']">{{ 'required' | translate }}</mat-error>
                <mat-error *ngIf="earningFormControl.errors?.['pattern']">{{ 'only_digit_allowed' | translate
                  }}</mat-error>
              </div>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'status' | translate }}</th>
          <td mat-cell *matCellDef="let earning">
            <ng-container *ngIf="earning.type !== 'Total'">
              <button [class]="'status-button ' + (earning.status === 'active' ? 'active' : 'inactive')"
                (click)="toggleStatus(earning, 'earning')">
                <div *ngIf="earning.status === 'active'">{{'active'|translate}}</div>
                <div *ngIf="!(earning.status === 'active')">{{'inactive'|translate}}</div>
              </button>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'action' | translate }}</th>
          <td mat-cell *matCellDef="let earning">
            <ng-container *ngIf="earning.type !== 'Total'">
              <ng-container *ngIf="!earning.editing; else editButton">
                <mat-icon class="icon_edit_button" (click)="editEarning(earning)" matTooltip="Edit">edit</mat-icon>
                <mat-icon class="icon_delete_button" (click)="deleteEarning(earning)"
                  matTooltip="Delete">delete</mat-icon>
              </ng-container>
              <ng-template #editButton>
                <mat-icon class="icon_update_button" (click)="saveEarning(earning)" matTooltip="Save">save</mat-icon>
                <mat-icon class="icon_discard_button" (click)="cancelEdit(earning)" matTooltip="Cancel">undo</mat-icon>
              </ng-template>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['earning_type', 'calculation_type', 'status', 'action']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['earning_type', 'calculation_type', 'status', 'action']"></tr>
      </table>
      <mat-divider class="mat-divider"></mat-divider>
    </mat-tab>

    <mat-tab label="{{'deduction'|translate}}">
      <table mat-table class="mat-table" [dataSource]="deductionData">
        <ng-container matColumnDef="deduction_type">
          <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'deduction_type' | translate }}</th>
          <td mat-cell *matCellDef="let deduction">{{ deduction.type }}</td>
        </ng-container>
        <ng-container matColumnDef="deduction_calculation_type">
          <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'deduction_calculation_type' | translate }}
          </th>
          <td mat-cell *matCellDef="let deduction">
            <ng-container *ngIf="!deduction.editing; else editField">{{ deduction.value }}</ng-container>
            <div class="percentSymbol" *ngIf="deduction.amountType === 'percentage'"> % </div>
            <ng-template #editField>
              <input type="text" #editInput [(ngModel)]="deduction.updatedAmount" />
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'status' | translate }}</th>
          <td mat-cell *matCellDef="let deduction">
            <button [class]="'status-button ' + (deduction.status === 'active' ? 'active' : 'inactive')"
              (click)="toggleStatus(deduction, 'deduction')">
              <div *ngIf="deduction.status === 'active'">{{'active'|translate}}</div>
              <div *ngIf="!(deduction.status === 'active')">{{'inactive'|translate}}</div>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'action' | translate }}</th>
          <td mat-cell *matCellDef="let deduction">
            <ng-container *ngIf="deduction.type !== 'Professional Tax'">
              <ng-container *ngIf="!deduction.editing; else editButton">
                <mat-icon class="icon_edit_button" (click)="editDeduction(deduction)" matTooltip="Edit">edit</mat-icon>
                <mat-icon class="icon_delete_button" (click)="deleteDeduction(deduction)"
                  matTooltip="Delete">delete</mat-icon>
              </ng-container>
              <ng-template #editButton>
                <mat-icon class="icon_update_button" (click)="saveDeduction(deduction)"
                  matTooltip="Edit">save</mat-icon>
                <mat-icon class="icon_discard_button" (click)="cancelEdit(deduction)"
                  matTooltip="Cancel">undo</mat-icon>
              </ng-template>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['deduction_type', 'deduction_calculation_type', 'status', 'action']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['deduction_type', 'deduction_calculation_type', 'status', 'action']">
        </tr>
      </table>
      <mat-divider class="mat-divider"></mat-divider>
    </mat-tab>
    <mat-tab label="{{'reimbursement'|translate}}">
      <ng-container *ngIf="reimbursementData && reimbursementData.length > 0; else noData">
        <table mat-table class="matTable" [dataSource]="reimbursementData">
          <ng-container matColumnDef="reimbursement_type">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'reimbursement_type' | translate }}</th>
            <td mat-cell *matCellDef="let reimbursement">{{ reimbursement.type }}</td>
          </ng-container>
          <ng-container matColumnDef="calculation_type">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'calculation_type' | translate }}</th>
            <td mat-cell *matCellDef="let reimbursement">
              <ng-container *ngIf="!reimbursement.editing; else editField">{{ reimbursement.value }}</ng-container>
              <div class="percentSymbol" *ngIf="reimbursement.amountType === 'percentage'"> % </div>
              <ng-template #editField>
                <input type="text" #editInput [(ngModel)]="reimbursement.updatedAmount" />
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'status' | translate }}</th>
            <td mat-cell *matCellDef="let reimbursement">
              <button [class]="'status-button ' + (reimbursement.status === 'active' ? 'active' : 'inactive')"
                (click)="toggleStatus(reimbursement, 'reimbursement')">
                <div *ngIf="reimbursement.status === 'active'">{{'active'|translate}}</div>
                <div *ngIf="!(reimbursement.status === 'active')">{{'inactive'|translate}}</div>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>{{ 'action' | translate }}</th>
            <td mat-cell *matCellDef="let reimbursement">
              <ng-container *ngIf="!reimbursement.editing; else editButton">
                <mat-icon class="icon_edit_button" (click)="editReimbursement(reimbursement)"
                  matTooltip="Edit">edit</mat-icon>
                <mat-icon class="icon_delete_button" (click)="deleteReimbursement(reimbursement)"
                  matTooltip="Delete">delete</mat-icon>
              </ng-container>
              <ng-template #editButton>
                <mat-icon class="icon_update_button" (click)="saveReimbursement(reimbursement)"
                  matTooltip="Edit">save</mat-icon>
                <mat-icon class="icon_discard_button" (click)="cancelEdit(reimbursement)"
                  matTooltip="Cancel">undo</mat-icon>
              </ng-template>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['reimbursement_type', 'calculation_type', 'status', 'action']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['reimbursement_type', 'calculation_type', 'status', 'action']">
          </tr>
        </table>
        <mat-divider class="mat-divider"></mat-divider>
      </ng-container>
      <ng-template #noData>
        <div class="matTable no-data-container">
          <p>{{'no_data_found'|translate}}</p>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="overlay" *ngIf="showPopup">
  <div class="add-component-popup">
    <div class="container">
      <div class="add-header">
        <h3>{{ 'add_component' | translate }}</h3>
      </div>
      <form [formGroup]="componentForm" class="form">
        <div>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'component_name' | translate }}</mat-label>
            <input matInput formControlName="key" class="form-field" type="text"
              (keypress)="validateCharacterNumberAndSpace($event)" required>
            <mat-error *ngIf="componentForm.get('key')?.hasError('required') && componentForm.get('key')?.touched">
              {{ 'component_name_require' | translate }}
            </mat-error>
            <mat-error *ngIf="componentForm.get('key')?.hasError('invalidInput')">
              {{ 'invalid_name_input' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'component_type' | translate }}</mat-label>
            <mat-select formControlName="collectionType" class="inputField">
              <mat-option value="earning">{{ 'earning' | translate }}</mat-option>
              <mat-option value="deduction">{{ 'deduction' | translate }}</mat-option>
              <mat-option value="reimbursement">{{ 'reimbursement' | translate }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="componentForm.get('collectionType')?.invalid && componentForm.get('collectionType')?.touched">
              {{ 'component_type_is_require' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'type'|translate}}</mat-label>
            <mat-select formControlName="type">
              <mat-option [value]="'amount'">{{'Amount'|translate}}</mat-option>
              <mat-option [value]="'percentage'">{{'Percentage'|translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="componentForm.get('type')?.hasError('required')">
              {{'type_is_required'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'amount' | translate }}</mat-label>
            <input matInput formControlName="value" class="form-field" type="text" pattern="[0-9]*"
              (keypress)="onlyNumberKey($event)">
            <mat-error
              *ngIf="componentForm.get('value')?.invalid && componentForm.get('value')?.touched && !componentForm.get('value')?.hasError('onlyDigits') && !componentForm.get('value')?.hasError('invalidPercentage')">
              {{ 'amount_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="componentForm.get('value')?.hasError('onlyDigits')">
              {{ 'only_digits_allowed' | translate }}
            </mat-error>
            <mat-error *ngIf="componentForm.get('value')?.hasError('invalidPercentage')">
              {{ 'percentage_range_error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="footerbuttonDiv">
          <button (click)="togglePopup()" class="cancel-btn">{{ 'cancel' | translate }}</button>
          <button (click)="addComponents()" [disabled]="saveButtonStatus">{{ 'save' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>