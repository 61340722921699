import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { Messages } from 'src/app/constants/messages.constant';
import { supportModule } from 'src/app/model/support-module/support.module';
import { supportservice } from 'src/app/services/support-service/support-service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})

export class SupportComponent {
  supportForm!: FormGroup;
  showAddPopup: boolean = false;
  createRequest: boolean = false;
  gridOptions: GridOptions = {};
  columnDefs: any[];
  rowData: any[] = [];
  priorityOptions: string[] = Messages.PRIORITY_LIST
  PageList: string[] = Messages.PAGE_NAME_LIST;
  selectedTabIndex = 0;

  constructor(private formBuilder: FormBuilder, private supportService: supportservice, private router: Router, private toastrService: ToastrService) {
    this.supportFormValidation();
    this.columnDefs = [
      {
        headerName: 'Request ID', field: 'requestId', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true
      },

      { headerName: 'Name', field: 'name', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
      { headerName: 'Email', field: 'email', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
      { headerName: 'Short Description', field: 'shortDescription', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
      { headerName: 'Priority', field: 'priority', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
      { headerName: 'Page Name', field: 'pageName', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
      { headerName: 'Description', field: 'description', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true }
    ];
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  ngOnInit() {
    this.supportFormValidation();
    this.getAllSupportRequestList();
  }

  supportFormValidation() {
    this.supportForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      pageName: ['', Validators.required],
      priority: ['', Validators.required],
      shortDescription: ['', Validators.required],
      description: ['', [Validators.required, Validators.minLength(50), Validators.maxLength(300)]]
    });
  }

  createSupportRequest() {
    this.createRequest = true;
  }

  viewRequest() {
    this.createRequest = false;
  }

  getAllSupportRequestList() {
    this.supportService.getAllSupportRequestList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (res: any) => {
        this.rowData = res.data.map((request: any) => ({
          requestId: '00R' + request.id,
          name: request.name,
          email: request.email,
          shortDescription: request.shortDescription,
          description: request.description,
          priority: request.priority,
          pageName: request.pageName
        }));
      }
    )
  }

  onSubmit() {
    if (this.supportForm.valid) {
      const supportRequest: supportModule = {
        "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID),
        "name": this.supportForm.value.name,
        "number": this.supportForm.value.mobileNumber,
        "adminId": sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
        "description": this.supportForm.value.description,
        "shortDescription": this.supportForm.value.shortDescription,
        "priority": this.supportForm.value.priority,
        "pageName": this.supportForm.value.pageName
      };
      this.supportService.sendSupportRequest(supportRequest).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.getAllSupportRequestList();
            this.toastrService.clear();
            this.toastrService.success(res.message);
            this.createRequest = false;
            this.selectedTabIndex = 0;
            this.supportForm.reset();
            //remove mat errors from reset form
            Object.keys(this.supportForm.controls).map((field) => {
              const control = this.supportForm.get(field);
              if (control instanceof FormControl) {
                control.setErrors(null);
              }  
            });

          } else {
            this.toastrService.clear();
            this.toastrService.error(res.message);
          }
        },
      );
    }
  }

  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event);
  }
  goBack(){
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }
}

