import { Component, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { LeaveData } from 'src/app/model/leave-application/leave-history.model';
import { LeaveService } from 'src/app/services/leave-application/employee-side/leave-history/leave.service';
import { LeaveDetailsPopupComponent } from 'src/app/shared/leave-details-popup/leave-details-popup.component';
@Component({
  selector: 'app-employee-leave-details',
  templateUrl: './employee-leave-details.component.html',
  styleUrls: ['./employee-leave-details.component.scss']
})

export class EmployeeLeaveDetailsComponent {
  currentUserId = sessionStorage.getItem('role');
  leaveHistoryColDef: any;
  public leaveHistoryData!: LeaveData[];
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  public leaveHistory!: LeaveData[];
  leaveId: any;
  empId: any;
  leaveData: any;
  public balanceLeave!: LeaveData[];
  public employeeUnderManagerHistory!: LeaveData[];
  isManager!: boolean;
  leaveHistoryTableCol = [
    { field: "id", width: 130, unSortIcon: true },
    { field: "name", width: 150, unSortIcon: true },
    { field: "leaveType", width: 170, unSortIcon: true },
    { field: "startDate", width: 150, unSortIcon: true },
    { field: "endDate", width: 150, unSortIcon: true },
    { field: "noOfDays", width: 130, unSortIcon: true },
    { field: "leaveReason", width: 300, unSortIcon: true },
    { field: "leaveStatus", width: 150, unSortIcon: true },
    { field: "Action", cellRenderer: this.actionCellRenderer.bind(this), width: 345 }
  ];

  constructor(private dialog: MatDialog, private renderer: Renderer2, private toasterService: ToastrService, private leaveService: LeaveService, private router: Router) { }

  ngOnInit() {
    if (sessionStorage.getItem(AppConstant.MANAGER_SIDENAV) === AppConstant.EMPLOYEE_ROLE) {
      this.isManager = true;
    }
    this.getApplyLeaveHistory();
    this.getManagerUnderEmployee();
    this.leaveHistoryColDef = {
      sortable: true,
      resizable: true,
    };
    this.companyId = sessionStorage.getItem(AppConstant.COMPANY_ID)
  }

  goToDashboard() {
    (sessionStorage.getItem('login') === 'admin') 
    ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]) 
    : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

  getApplyLeaveHistory() {
    this.leaveService.getAllEmployeeLeaveHistory(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      this.leaveHistoryData = data.map((row: { id: { toString: () => string; }; startDate: string; endDate: string; }) => ({
        ...row,
        id: "LR" + row.id.toString().padStart(3, "0"),
        startDate: this.formatDate(row.startDate),
        endDate: this.formatDate(row.endDate),
      }));

      this.leaveHistoryData.sort((a, b) => {
        const idA = parseInt(a.id.substr(2));
        const idB = parseInt(b.id.substr(2));
        return idB - idA;
      });

      this.leaveHistory = this.leaveHistoryData;
    });
  }

  getManagerUnderEmployee() {
    if (this.isManager) {
      this.leaveService.getEmployeeUnderManagerLeaveData(sessionStorage.getItem(AppConstant.COMPANY_ID), (sessionStorage.getItem(AppConstant.EMPLOYEE_ID))).subscribe((data: any) => {
        this.leaveHistoryData = data.map((row: { id: { toString: () => string; }; startDate: string; endDate: string; }) => ({
          ...row,
          id: "LR" + row.id.toString().padStart(3, "0"),
          startDate: this.formatDate(row.startDate),
          endDate: this.formatDate(row.endDate),
        }));
        this.employeeUnderManagerHistory = this.leaveHistoryData;
      });
    }
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');

    const createButton = (icon: string, colorClass: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      this.renderer.addClass(button, 'mat-icon-button');
      this.renderer.addClass(button, 'ag-grid-custom-button');
      this.renderer.addClass(button, colorClass);
      this.renderer.listen(button, 'click', clickHandler);
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.appendChild(cell, button);
    };

    const viewLeaveDetails = () => {
      const leaveData: LeaveData = params.data;
      const dialogRef = this.dialog.open(LeaveDetailsPopupComponent, {
        disableClose: true,
        width: '50%',
        data: leaveData,
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result?.action === AppConstant.APPROVE) {
          this.approveRequest(params);
        } else if (result?.action === AppConstant.REJECTED) {
          this.rejectRequest(params);
        }
      });
    }

    createButton('visibility', 'green', viewLeaveDetails);
    return cell;
  }

  approveRequest(params: any) {
    const companyId = parseInt(sessionStorage.getItem(AppConstant.COMPANY_ID) || '0', 10);
    const empId = params.data.employeeBasicDetailsModel.id;
    const leaveId = params.data.id.replace(/\D/g, '');
    const leaveStatus = AppConstant.APPROVE;
    const payload = {
      companyId,
      employeeId: empId,
      leaveId,
      leaveStatus
    };
    this.leaveService.updateLeaveStatus(payload).subscribe(
      (leaveDetails) => {
        if (leaveDetails.status == 200) {
          this.toasterService.clear();
          this.toasterService.success(leaveDetails.message);
        }
        this.getApplyLeaveHistory();
        this.getManagerUnderEmployee();
      });
  }

  rejectRequest(params: any) {
    const companyId = parseInt(sessionStorage.getItem(AppConstant.COMPANY_ID) || '0', 10);
    const empId = params.data.employeeBasicDetailsModel.id;
    const leaveId = params.data.id.replace(/\D/g, '');
    const leaveStatus = AppConstant.REJECTED;
    const payload = {
      companyId,
      employeeId: empId,
      leaveId,
      leaveStatus
    };
    this.leaveService.updateLeaveStatus(payload).subscribe(
      (leaveDetails) => {
        if (leaveDetails.status == 200) {
          this.toasterService.clear();
          this.toasterService.success(leaveDetails.message);
        }
        this.getApplyLeaveHistory();
        this.getManagerUnderEmployee();
      });
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

}
