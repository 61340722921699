<div class="requestAssets">
    <mat-card class="supplierCard">
        <h3>{{'request_for_asset'|translate}}</h3>
        <mat-divider></mat-divider>
        <mat-card-content>
            <form [formGroup]="requestAssetForm">
                <div class="form">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'supplier_name' |translate}}</mat-label>
                        <mat-select required="*" formControlName="supplierName">
                            <mat-option>{{'none'|translate}}</mat-option>
                            <div *ngFor="let name of supplierDataList">
                                <mat-option [value]="name.id">{{name.supplierName}}</mat-option>
                            </div>
                        </mat-select>
                        <mat-error *ngIf="requestAssetForm.get('supplierName')?.hasError('required')">
                            {{'supplier_name_is_required'|translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{'asset_type'|translate}}</mat-label>
                        <mat-select formControlName="assetType" required>
                            <mat-option *ngFor="let asset of allAssetTypeList" [value]="asset.assetType">
                                {{ asset.assetType }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="requestAssetForm.get('assetType')?.hasError('required')">
                            {{'asset_type_is_required'|translate}}
                        </mat-error>
                    </mat-form-field>

                </div>
                <div class="form">

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{'model_name'|translate}}</mat-label>
                        <mat-select required="*" formControlName="modelName">
                            <mat-option>{{'none'|translate}}</mat-option>
                            <div *ngFor="let name of modelName">
                                <mat-option [value]="name">{{name}}</mat-option>
                            </div>
                        </mat-select>
                        <mat-error *ngIf="requestAssetForm.get('modelName')?.hasError('required')">
                            {{'model_name_is_required'|translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'quantity'|translate}}</mat-label>
                        <input matInput type="number" [min]="1" (keypress)="numberOnly($event)" maxlength="10"
                            formControlName="quantity" required="*">
                        <mat-error
                            *ngIf="requestAssetForm.get('quantity')?.hasError('required') && !requestAssetForm.get('quantity')?.hasError('quantityInRange') && requestAssetForm.get('quantity')?.touched">
                            {{'quantity_is_required'|translate}}
                        </mat-error>
                        <mat-error
                            *ngIf="requestAssetForm.get('quantity')?.hasError('quantityInRange') && requestAssetForm.get('quantity')?.value < 0">
                            {{'quantity_must_be_greater_than_0'|translate}}
                        </mat-error>
                        <mat-error
                            *ngIf="requestAssetForm.get('quantity')?.hasError('quantityInRange') && requestAssetForm.get('quantity')?.value > 1000000000">
                            {{'quantity_must_be_less_than_1000000000'|translate}}
                        </mat-error>
                    </mat-form-field>

                </div>
                <div class="form">

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'contact_number' |translate}}</mat-label>
                        <input class="Input-field" matInput type="text" formControlName="contactNumber"
                            (keypress)="numberOnly($event)" maxlength="10" required="*">
                        <mat-error *ngIf="requestAssetForm.controls['contactNumber'].hasError('required')">
                            {{'contact_is_required'|translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="!isLaptop" class="full-width" appearance="outline">
                        <mat-label>{{'specification'|translate}}</mat-label>
                        <input class="Input-field" matInput type="text" formControlName="specification"
                            (keypress)="validateNumberAndSpaceAndHyphenAllowed($event)">
                        <mat-error *ngIf="requestAssetForm.controls['specification'].hasError('required')">
                            {{'specification_is_required'|translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="isLaptop" class="full-width" appearance="outline">
                        <mat-label>{{'ram'|translate}}</mat-label>
                        <mat-select formControlName="ram">
                            <mat-option>{{'none'|translate}}</mat-option>
                            <div *ngFor="let ram of ramList">
                                <mat-option [value]="ram">{{ram}}</mat-option>
                            </div>
                        </mat-select>
                        <mat-error *ngIf="requestAssetForm.get('ram')?.hasError('required')">
                            {{'ram_is_required'|translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form" *ngIf="isLaptop">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'ssd'|translate}}</mat-label>
                        <mat-select formControlName="ssd">
                            <mat-option>{{'none'|translate}}</mat-option>
                            <div *ngFor="let ssd of ssdList">
                                <mat-option [value]="ssd">{{ssd}}</mat-option>
                            </div>
                        </mat-select>
                        <mat-error *ngIf="requestAssetForm.get('ssd')?.hasError('required')">
                            {{'ssd_is_required'|translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'processor'|translate}}</mat-label>
                        <mat-select formControlName="processor">
                            <mat-option>{{'none'|translate}}</mat-option>
                            <div *ngFor="let processor of processorList">
                                <mat-option [value]="processor">{{processor}}</mat-option>
                            </div>
                        </mat-select>
                        <mat-error *ngIf="requestAssetForm.get('processor')?.hasError('required')">
                            {{'processor_is_required'|translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="buttons">
                    <button class="cancel-btn" (click)="goBack()">{{'cancel'|translate}}</button>
                    <button class="button" (click)="requestAsset()">{{'save'|translate}}</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
<app-loader></app-loader>