import { query } from '@angular/animations';
import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { HelpDeskService } from 'src/app/services/help-desk/help-desk.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-resolution',
  templateUrl: './resolution.component.html',
  styleUrls: ['./resolution.component.scss']
})
export class ResolutionComponent {
  viewAllTickets: any;
  viewAllTicketsColDef: any;
  viewAllTicketsTableDef: any;
  type!: any;

  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);

  constructor(private helpDeskService: HelpDeskService, private renderer: Renderer2, private router: Router) {
    this.viewAllTicketsTableDef = [
      { field: "number", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      {
        headerName: 'Open Date', field: 'openDate', floatingFilter: true, sortable: true, flex: 1,
        cellRenderer: ValidationUtil.dateFormatter
      },
      { field: "requesterFor", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "priority", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "status", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "contactNumber", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "action", cellRenderer: this.actionCellRenderer.bind(this), }
    ];
  }

  ngOnInit() {
    this.type = 'all'
    this.viewAllTicketsColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.getAllTickets(this.type);
    this.getSupportEmployeeList();
  }
  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  getSupportEmployeeList(){
    this.helpDeskService.getAllSupportEmployeeList(this.companyId).subscribe(
      (data: any) => {
        if (data.status === 200) {
        }
      }
    )
  }

  goToBack() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.HELPDESK])
  }

  getAllTickets(type: any) {
    this.helpDeskService.getAllIssues(type).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.viewAllTickets = data.data;
        }
      }
    )
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const viewIconClickHandler = () => {
      const numericId = parseInt(params.data.number.replace(/\D/g, ''), 10);
      this.viewRequestDetails(numericId);
    };
    createButton('visibility', 'green', viewIconClickHandler);
    return cell;
  }

  viewRequestDetails(id: any): void {
    this.router.navigate([`${AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_REQUEST_DETAILS}/${id}`], {
      queryParams: { type: "resolution" }
    });
  }

}
