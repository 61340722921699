<app-toolbar></app-toolbar>
<div class="main-container">
   <div class="tittle-div">
      <span matTooltip="{{ 'back_to_dashboard' | translate }}" (click)="goToBack()">
         <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
      </span>
      <div class="heading">
         <h3>{{ 'view_training_details' | translate }}</h3>
      </div>
   </div>
   <mat-divider></mat-divider>

   <div *ngIf="isLoading" class="loader-container">
      <mat-spinner></mat-spinner>
   </div>

   <div class="ppt-frame">
      <object *ngIf="showPdf" [data]="fileUrl" width="100%" height="500px" (load)="onContentLoaded()"></object>
      <video width="100%" height="500px" controls controlslist="nodownload" *ngIf="fileUrl != undefined && !showPdf"
         (loadedmetadata)="onContentLoaded()">
         <source [src]="fileUrl" [type]="trainingDetails?.file?.fileType">
      </video>
   </div>

   <div class="training">
      <mat-card class="training-details">
         <mat-card-content>
            <mat-tab-group class="tabs">
               <mat-tab label="Overview">
                  <div class="training-detail-card">
                     <div>
                        <span class="training-title">{{ trainingDetails?.trainingName }}</span>
                     </div>
                     <div class="short-desc">
                        <span>{{ trainingDetails?.trainingShortDescription }}</span>
                     </div>
                     <div class="long-desc">
                        <span>{{ trainingDetails?.trainingLongDescription }}</span>
                     </div>
                     <div>
                        <div class="star-rating-container star-rating-animation" fxLayout="row"
                           fxLayoutAlign="start center">
                           <div *ngFor="let star of stars" [ngClass]="star.class">
                              <mat-icon>{{ star.icon }}</mat-icon>
                           </div>
                        </div>
                        <span class="rating">{{ 'average_rating' | translate }}: {{ trainingDetails?.avgRatting
                           }}</span>
                        <span class="total-views">{{ 'total_reviews' | translate }} ({{ trainingDetails?.totalView
                           }})</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12">
                        <form [formGroup]="ratingForm">
                           <div class="rating-component">
                              <mat-label>{{ 'rating' | translate }}</mat-label>
                              <app-rating [rating]="0" (ratingUpdated)="onRatingChange($event)"></app-rating>
                           </div>
                           <div class="comment-box-button" *ngIf="showComments">
                              <mat-form-field class="full-width" appearance="outline">
                                 <mat-label>{{ 'comment' | translate }}</mat-label>
                                 <textarea matInput formControlName="comment"></textarea>
                              </mat-form-field>
                              <div class="button-div">
                                 <button (click)="cancel()" class="cancel-btn" type="reset">{{ 'cancel' | translate
                                    }}</button>
                                 <button (click)="onSubmit()" type="submit">{{ 'submit' | translate }}</button>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </mat-tab>
               <mat-tab label="Review">

                  <div class="comments-container">
                     <div *ngFor="let comment of displayComments">
                        <div class="reviewer-name">
                           <div class="avatar-circle" matTooltip="{{ comment.firstName + ' ' + comment.lastName }}">
                              <span class="initials">
                                 {{ getInitials(comment.firstName, comment.lastName) }}
                              </span>
                           </div>
                           <span><b>{{ comment.firstName }} {{ comment.lastName }}</b></span>
                        </div>

                        <div class="row">
                           <div class="col-sm-12">
                              <div class="rating-heading">
                                 <span class="rating">Rating:</span>
                                 <ul class="list-inline rating-list" style="display: inline-block">
                                    <li *ngFor="let star of [1, 2, 3, 4, 5]"
                                       [ngClass]="{ 'selected': star <= comment.ratting }">
                                       ★
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>

                        <div class="comment-content">

                           <p class="clearfix">
                              Comment - {{ comment.comments }}
                           </p>
                        </div>
                     </div>

                     <div class="button">
                        <button class="review-button" (click)="showMoreComments()">
                           <b>See more Reviews</b>
                        </button>
                     </div>
                  </div>

               </mat-tab>
            </mat-tab-group>
         </mat-card-content>
         <app-loader></app-loader>
      </mat-card>
   </div>
</div>