import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { API_END_POINTS } from '../config/api-endpoint.config';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private readonly http: HttpClient) { }

  addBranch(branchData: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_BRANCH, branchData).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  switchBranch(branchId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.SWITCH_BRANCH + branchId).pipe(
      map((switchBranchRes: any) => {
        return switchBranchRes;
      })
    )
  }

  updateBranch(branchId: number, branchData: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_BRANCH + branchId, branchData).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getBranchById(branchId: number): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_BRANCH_DETAILS + branchId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  deleteBranch(branchId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_BRANCH + branchId).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAllBranch(comapnyId: number): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_BRANCH + comapnyId).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

}
