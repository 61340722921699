import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { Messages } from 'src/app/constants/messages.constant';
import { CommentData } from 'src/app/model/task-management/task-management/task-management.module';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { TaskManagementService } from 'src/app/services/task-management/task-management.service';

interface Issue {
  id: any;
  title: string;
  status: string;
  description: string;
  storyPointEstimate: number;
  assignee: string;
}

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent {
  initials: string | any;
  showComments: boolean = false;
  isEditMode: boolean = false;
  dashboardId: any;
  taskDetails: any;
  taskId: any;
  commentsForm!: FormGroup;
  comments: any;
  commentToDelete: any;
  showDeleteDialog: boolean = false;
  commentId: any;
  createCommentRequest!: CommentData;
  employeeId: any = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  attachments: any[] = [];
  taskStatus = Messages.TASK_STATUS;
  sprintId !: number;
  taskName!: string;
  showClosePopup: boolean = false;
  showUpdateWindow: boolean = false;

  constructor(@Optional() private dialogRef: MatDialogRef<TaskComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private route: ActivatedRoute, private projectService: ProjectManagementService, private toastrService: ToastrService, private taskService: TaskManagementService, private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.dashboardId && this.data.taskId) {
      this.dashboardId = this.data.dashboardId;
      this.taskId = this.data.taskId;
      this.showClosePopup = true;
      this.getTaskDetails();

    } else {
      this.route.queryParams.subscribe(params => {
        this.dashboardId = params['dashboardId'];
        this.taskId = params['taskId'];
        this.sprintId = params['sprintId'];
        this.showUpdateWindow = true;
        this.getTaskDetails();
      });
    }

    this.allComments();
    const email = sessionStorage.getItem(AppConstant.LOGIN_EMAIL);
    if (email) {
      this.initials = this.getInitials(email);
    }
    this.taskService.isEditMode$.subscribe((isEditMode: boolean) => {
      this.isEditMode = isEditMode;
    });
    this.initComments();
  }

  getRelativeTime(dateString: string): string {
    return moment(dateString).fromNow();
  }

  handleTaskUpdated(event: any) {
    if (event) {
      this.isEditMode = !this.isEditMode;
      this.getTaskDetails();
    }
  }

  openDeleteDialog(comment: any) {
    this.commentToDelete = comment;
    this.showDeleteDialog = true;
  }

  editTaskDetails() {
    this.isEditMode = true;
  }

  initComments() {
    this.commentsForm = this.fb.group({
      comment: ['']
    });
  }

  saveComment() {
    if (this.commentsForm.invalid) {
      this.commentsForm.markAllAsTouched();
      return;
    }
    this.createCommentRequest = {
      "comment": this.commentsForm.value.comment,
      "employeeId": this.employeeId,
      "taskId": this.taskId
    };
    this.taskService.addCommnet(this.createCommentRequest).subscribe((addComment: any) => {
      if (addComment.status == 200) {
        this.commentsForm.reset();
        this.allComments();
        this.toastrService.clear();
        this.toastrService.success(addComment.message);
      } else {
        this.toastrService.clear();
        this.toastrService.error(addComment.message);
      }
    })
  }

  getCamelCase(value: string): string {
    return value.replace(/([A-Z])/g, ' $1').replace(/^./, (str: any) => str.toUpperCase());
  }

  getInitials(email: string): string {
    const parts = email.split('@')[0].split('.');
    return parts.map(part => part.charAt(0).toUpperCase()).join('');
  }

  showCommets() {
    this.showComments = true;
  }

  hideComments() {
    this.showComments = false;
  }

  getTaskDetails() {
    sessionStorage.setItem(AppConstant.TASK_ID, this.taskId);
    this.taskService.getTaskDetails(this.dashboardId, this.taskId).subscribe((taskDetails: any) => {
      this.taskDetails = taskDetails.data;
      this.decodeAttachments(this.taskDetails.attachments);
    })
  }

  decodeAttachments(attachments: any[]) {
    this.attachments = attachments.map(attachment => {
      return {
        fileName: attachment.fileName,
        fileContent: `data:application/octet-stream;base64,${attachment.fileContent}`
      };
    });
  }

  downloadFile(fileContent: string, fileName: string) {
    const link = document.createElement('a');
    link.href = fileContent;
    link.download = fileName;
    link.click();
  }

  allComments() {
    this.taskService.viewAllComments(this.taskId).subscribe((commentsDetails: any) => {
      this.comments = commentsDetails.data.reverse();
    });
  }

  closePopup() {
    this.dialogRef.close();
  }

  confirmDelete(): void {
    if (this.commentToDelete) {
      this.taskService.deleteComment(this.taskId, this.commentToDelete.commentId).subscribe((deleteComment: any) => {
        if (deleteComment.status === 200) {
          this.showDeleteDialog = false;
          this.toastrService.clear();
          this.toastrService.success(deleteComment.message);
          this.comments = this.comments.filter((c: any) => c.commentId !== this.commentToDelete.commentId);
        } else {
          this.toastrService.clear();
          this.toastrService.error(deleteComment.message);
        }
      });
    }
  }

  cancelDelete() {
    this.showDeleteDialog = false;
  }

  viewDocument(attachment: any): void {
    const fileContent = attachment.fileContent;
    const fileName = attachment.fileName;

    const byteCharacters = atob(fileContent.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: this.getFileMimeType(fileName) });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL, '_blank');
  }

  getFileMimeType(fileName: string): string {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    switch (fileExtension) {
      case 'pdf': return 'application/pdf';
      case 'doc': return 'application/msword';
      case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'xls': return 'application/vnd.ms-excel';
      case 'xlsx': return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'txt': return 'text/plain';
      case 'jpg':
      case 'jpeg': return 'image/jpeg';
      case 'png': return 'image/png';
      default: return 'application/octet-stream';
    }
  }


}
