import { Component, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
@Component({
  selector: 'app-add-bulk-holiday',
  templateUrl: './add-bulk-holiday.component.html',
  styleUrls: ['./add-bulk-holiday.component.scss']
})
export class AddBulkHolidayComponent {
  @Input() value: number | undefined;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
  truncateProgress(progress: number): number {
    return Math.floor(progress);
  }
}
