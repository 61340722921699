import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
@Component({
  selector: 'app-add-asset-popup',
  templateUrl: './add-asset-popup.component.html',
  styleUrls: ['./add-asset-popup.component.scss']
})
export class AddAssetPopupComponent implements OnInit {
  assetForm !: FormGroup;
  assetTypeForm!: FormGroup;
  isLaptop: Boolean = false;
  supplierList: any[] = [];
  allAssetTypeList: any[] = [];
  assets: string[] = ['Laptop', 'Mouse', 'Keyboard', 'Monitor'];
  ramList: string[] = ['2', '4', '8', '16', '32', '64', '128', '256', '512'];
  processorList: string[] = ['i3', 'i5', 'i7', 'i9', 'Ryzen 3', 'Ryzen 5', 'Ryzen 7', 'Ryzen 9', 'Threadripper'];
  modelName: string[] = ['HP', 'Dell', 'Lenovo', 'Acer', 'Apple', 'Asus',];
  ssdList: string[] = ['128GB', '256GB', '512GB', '1TB', '2TB', '4TB'];
  isClientAsset: boolean = false;
  newAssetType: string | undefined;
  allProjects: any[] = [];

  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  @ViewChild('addAssetTypeDialogTemplate') addAssetTypeDialogTemplate: TemplateRef<any> | any;

  constructor(private projectService: ProjectManagementService, private formBuilder: FormBuilder, private matDialog: MatDialog, public dialog: MatDialog, private addAssetService: AssetManagementDashboardService, private toasterService: ToastrService,) { }

  ngOnInit(): void {
    this.getAllAssetType();
    this.getSupplierList();
    this.getAllProject();
    this.assetForm = this.formBuilder.group({
      assetType: ["", [Validators.required]],
      modelName: ["", [Validators.required]],
      modelNo: ["", [Validators.required]],
      ram: ["",],
      ssd: ["",],
      processor: ["",],
      specification: ["",],
      isClientAsset: [false],
      clientName: [''],
      assetsQuantity: [''],
      projectName: [''],
      collectedBy: [''],
    });

    this.assetForm.get('assetType')?.valueChanges.subscribe
      ((value: any) => {
        if (value === 'Laptop') {
          this.isLaptop = true;
        }
        else {
          this.isLaptop = false;
        }
      })

    this.assetTypeForm = this.formBuilder.group({
      assetType: ['', Validators.required],
    });
  }

  getSupplierList() {
    this.addAssetService.getSupplierList().subscribe(
      (data: any) => {
        if (data.status) {
          this.supplierList = data.data;
        }
      }
    );
  }

  getAllProject() {
    this.projectService.getAllProject().subscribe((data: any) => {
      if (data.status === 200) {
        this.allProjects = data.data; 
      }    })
  }

  getAllAssetType() {
    this.addAssetService.getActiveAssetTypeList(this.companyId).subscribe((data: any) => {
      if (data.status == 200) {
        this.allAssetTypeList = data.data;
      }
    })
  }
  closeDialog() {
    this.matDialog.closeAll();
  }

  save() {
    if (this.assetForm.valid) {
      const dto = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        assetType: this.assetForm.value.assetType,
        modelName: this.assetForm.value.modelName,
        modelNo: this.assetForm.value.modelNo,
        assetsQuantity: this.assetForm.value.assetsQuantity,
        projectName: this.assetForm.value.projectName,
        collectedBy: this.assetForm.value.collectedBy,
        clientName: this.assetForm.value.clientName,

      };
      this.addAssetService.assetForm(dto).subscribe(
        (data: any) => {
          if (data.status == 200) {
            this.closeDialog();
            this.getSupplierList();
            this.toasterService.clear();
            this.toasterService.success(data.message);
          }
          else {
            this.toasterService.clear()
            this.toasterService.error(data.message);
          }
        }
      );
    }
  }

  validateNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event);
  }

  onClientAssetChecked(isChecked: boolean) {
    this.isClientAsset = isChecked;
    if (isChecked) {
      this.assetForm.get('clientName')?.setValidators([Validators.required]);
    } else {
      this.assetForm.get('clientName')?.clearValidators();
      this.assetForm.get('clientName')?.reset();
    }
    this.assetForm.get('clientName')?.updateValueAndValidity();
  }

  openAddAssetTypeDialog(): void {
    this.dialog.open(this.addAssetTypeDialogTemplate);
  }

  saveAssetType(): void {
    if (this.assetTypeForm.valid) {
      const assetTypeDto = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        assetType: this.assetTypeForm.get('assetType')?.value,
      };

      this.allAssetTypeList.push({ assetType: assetTypeDto.assetType });

      this.addAssetService.addAssettype(assetTypeDto).subscribe(
        (data: any) => {
          this.toasterService.success("Asset Type added")
        },

      );

      this.assetTypeForm.reset();
      this.dialog.closeAll();
    }
  }

}
