
<app-toolbar></app-toolbar>
<div class="main-container">
    <div class="header">
        <div class="header-left">
            <mat-icon class="back-arrow-icon" matTooltip="{{'back' | translate}}" (click)="goToBack()"
                svgIcon="arrow-back-double"></mat-icon>
            <div width="24" height="24" svgIcon="arrow-back-double"></div>
            <h3 class="headerName">{{'event_management' | translate}}</h3>
        </div>
        <div class="header-right">
            <button (click)="addEvent()">{{'add_event' | translate}}</button>
        </div>
    </div>
    <div class="employeeEventDetail">
      <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
      [rowData]="eventData" [animateRows]="true" [columnDefs]="eventColumnDef" [defaultColDef]="defaultColDef"
      [enableSorting]="true" [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
    </div>
</div>