<app-toolbar></app-toolbar>
<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goBack()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{'create_request'|translate}}</h3>
    </div>
    <mat-divider></mat-divider>
</div>

<div class="main-container">
    <div class="container">
        <form name="requestorInformationForm" class="form-content" [formGroup]="requestorInformationForm">
            <div class="form-field-gap1">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'name'|translate}}</mat-label>
                    <mat-select formControlName="requestFor" #singleSelect>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'Search'" [formControl]="personSearchFilter"
                                noEntriesFoundLabel="" [searching]="searching"
                                [preventHomeEndKeyPropagation]="true"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let employee of filteredServerSide | async" [value]="employee.employeeId">
                            {{employee.employeeName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="requestorInformationForm.controls['requestFor'].hasError('required')">
                        {{ 'employee_name_is_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="requestorInformationForm.controls['requestFor'].hasError('invalidInput')">
                        {{ 'invalid_name_input' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'mobile_number' | translate }}</mat-label>
                    <input matInput id="contactNo" (keypress)="validateNumber($event)" formControlName="contactNo"
                        minlength="10" maxlength="10" required />
                    <mat-error *ngIf="requestorInformationForm.controls['contactNo'].hasError('required')">
                        {{ 'mobile_number_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="requestorInformationForm.controls['contactNo'].hasError('invalidMobileNumber')">
                        {{ 'invalid_mobile_number' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-field-gap1">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'project'|translate}}</mat-label>
                    <mat-select formControlName="projectId">
                        <div *ngFor="let projectName of projectList">
                            <mat-option [value]="projectName.id">{{projectName.projectName}}</mat-option>
                        </div>
                    </mat-select>
                    <mat-error *ngIf="requestorInformationForm.controls['projectId'].hasError('invalidInput')">
                        {{ 'invalid_name_input' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'location' | translate }}</mat-label>
                    <input matInput id="location" formControlName="location" maxlength="30" required />
                    <mat-error *ngIf="requestorInformationForm.controls['location'].hasError('required')">
                        {{ 'location_is_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-field-gap1">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'business_service'|translate}}</mat-label>
                    <mat-select formControlName="businessService">
                        <div *ngFor="let businessService of businessService">
                            <mat-option [value]="businessService">{{businessService}}</mat-option>
                        </div>
                    </mat-select>
                    <mat-error *ngIf="requestorInformationForm.controls['businessService']?.hasError('required')">
                        {{ 'business_service_is_required' | translate }}
                    </mat-error>

                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'request_type' | translate }}</mat-label>
                    <mat-select formControlName="requestType">
                        <div *ngFor="let requestType of requestType">
                            <mat-option [value]="requestType">{{requestType}}</mat-option>
                        </div>
                    </mat-select>
                    <mat-error *ngIf="requestorInformationForm.controls['requestType'].hasError('required')">
                        {{ 'request_type_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'description' | translate }}</mat-label>
                    <input matInput id="requestDescription" formControlName="detailedDescription" maxlength="500"
                        required />
                    <mat-error *ngIf="requestorInformationForm.controls['detailedDescription'].hasError('required')">
                        {{ 'request_description_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <button class="button generate" (click)="submit()">{{'submit'|translate}}</button>
            <button class="cancel-btn cancel" type="reset">{{'cancel'|translate}}</button>
            <app-loader></app-loader>
        </form>
    </div>  
</div>