<app-toolbar *ngIf="!hideToolbar"></app-toolbar>

<div class="title">
    <mat-icon svgIcon="arrow-back-double" class="rewind-icon" (click)="goToDashboard()"></mat-icon>
    <h3>{{ 'performance_apprisal' | translate }}</h3>
</div>
<mat-divider class="divider"></mat-divider>

<div>
    <mat-card class="mat-card">
        <mat-card-content>
            <div>
                <mat-form-field appearance="outline" class="Review-Input">
                    <mat-label>{{ currentYear }} {{ 'review_period' | translate }}</mat-label>
                    <mat-select (selectionChange)="onYearSelected($event)">
                        <mat-option *ngFor="let year of reviewPeriods" [value]="year">
                            {{ year }} {{ 'review_period' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="expansion-panel">
    <form>
        <mat-accordion>
        </mat-accordion>
        <div class="expansion">
            <mat-accordion>
                <mat-expansion-panel [expanded]="isPanelOpen">
                    <mat-expansion-panel-header (click)="isPanelOpen = !isPanelOpen">
                        <mat-panel-title>
                            <h3 class="heading">{{ 'evoluation_topic' | translate }}</h3>
                            <div *ngIf="isPanelOpen && !managerViewEmpAppraisal" class="performance-matrix"
                                (click)="downloadGoalsExcel()">{{ 'performace_matrix' | translate }}</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-divider class="evalution-divider"></mat-divider>
                    <a class="goal-plan">{{ 'performance_goal_plan' | translate }}</a>
                    <button class="button" *ngIf="showPerformanceGoal && !managerViewEmpAppraisal"
                        (click)="addGoals()">{{ 'add_goal' | translate }}</button>
                    <button class="button" *ngIf="!showPerformanceGoal || managerViewEmpAppraisal"
                        (click)="viewGoals()">{{ 'view' | translate }}</button>
                    <mat-divider class="divider"></mat-divider>
                    <div class="Behavioral">
                        <a class="goal-plan">{{ 'behavioral_competency' | translate }}</a>
                        <button class="button" *ngIf="showBehavioralGoals && !managerViewEmpAppraisal"
                            (click)="addBehavioralGoals()">{{ 'add_goal' | translate }}</button>
                        <button class="button" *ngIf="!showBehavioralGoals || managerViewEmpAppraisal"
                            (click)="viewBbhavioralGoals()">{{ 'view' | translate }}</button>
                    </div>
                    <div class="Behavioral-competency"></div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </form>
</div>