<app-toolbar></app-toolbar>
<div class="container">
    <div class="header">
        <div class="back-button">
            <mat-icon class="back-arrow-icon" (click)="goToBack()" svgIcon="arrow-back-double"></mat-icon>
            <h3><span class="final-settlement">{{'final_settlement' | translate}}{{employeeName}} {{lastName}} ({{'emp'
                    |
                    translate}}{{employeeId}})</span>
                {{'poi_base'|translate}}</h3>
        </div>
    </div>
    <div class="content">
        <span class="period"><b>{{ 'period' | translate }} {{ lastWorkingMonthYear }}</b></span><br><br>
        <div class="payroll-wrapper">
            <div class="payroll-container">
                <div>
                    <div>
                        <span class="cost-amount">{{'rupiee' | translate}}{{ fnfData.payrollCost | number: '2.2-2'
                            }}</span><br>
                        <span>{{'payroll_cost' | translate}}</span>
                    </div>
                </div>
                <div class="cost-container">
                    <div class="cost-content">
                        <span class="cost-amount right-align">{{'rupiee' |translate}}{{fnfData.netPay | number:
                            '2.2-2'}}</span><br>
                        <span class="right-align">{{'employee_net_pay_amount' | translate}}</span>
                    </div>
                </div>
            </div>
            <mat-card class="pay-day-card">
                <span class="pay-day">{{'pay_day'|translate}}</span><br>
                <span class="month"><b>{{fnfData.payDay}}</b></span>
                <div>
                    <mat-divider class="divider"></mat-divider>
                    <div class="lwd">{{'LWD'|translate}}{{fnfData.lastWorkingDate}}</div>
                </div>
            </mat-card>
        </div>
        <div class="payroll-summary">
            <div class="payroll-summary">
                <h3>{{'employee_pay_summary' |translate}}</h3>
                <div class="tax-section">
                    <span>{{'paid_days'|translate}}{{fnfData.paidDay}}</span>
                    <span class="summary-amount">{{'payment_mode'|translate}} : {{fnfData.paymentMode}}</span>
                </div>
                <mat-divider class="summary-divider"></mat-divider>
                <div class="earning"><b>{{ 'earnings' | translate }}</b></div>

                <div *ngFor="let data of sortedEarningList(fnfData.earningList)">
                    <div class="deduction-section">
                        <span>{{ convertLabel(data.name) }}</span>
                        <span class="summary-amount">{{ 'rupiee' | translate }}{{ data.amount | number: '2.2-2'
                            }}</span>
                    </div>
                </div>
                <mat-divider class="summary-divider"></mat-divider>

                <div class="earning"><b>{{'fnf_deductions' | translate}}</b></div>
                <div *ngFor="let data of sortedEarningList(fnfData.deductionList)">
                    <div class="deduction-section">
                        <span>{{convertLabel(data.name)}}</span>
                        <span class="summary-amount">{{'rupiee' | translate}}{{data.amount| number: '2.2-2'}}</span>
                    </div>
                </div>
                <mat-divider class="summary-divider"></mat-divider>
                <div class="earning"><b>{{'reimbursement' | translate}}</b></div>
                <div *ngFor="let data of sortedEarningList(fnfData.reimbursementList)">
                    <div class="deduction-section">
                        <span>{{convertLabel(data.name)}}</span>
                        <span class="summary-amount">{{'rupiee' | translate}}{{data.amount| number: '2.2-2'}}</span>
                    </div>
                </div>
                <mat-divider class="summary-divider"></mat-divider>
                <div class="deduction-section">
                    <span><b class="net-pay">{{'net_pay_summary' | translate}}</b> {{'net-summary'|translate}}</span>
                    <span class="summary-amount">{{'rupiee' | translate}}{{fnfData.netPay | number: '2.2-2'}}</span>
                </div>
            </div>
        </div>
    </div>
</div>