<app-toolbar *ngIf="!isHideNavbar"></app-toolbar>
<div class="employee-container">
    <div class="employee-header">
        <mat-icon class="back-arrow" svgIcon="arrow-back-double" (click)="goToBack()"></mat-icon>
        <h3 class="employee-name">{{activeEmployeeData?.employeeBasicDetail?.firstName }}
            {{activeEmployeeData?.employeeBasicDetail?.middleName
            }} {{activeEmployeeData?.employeeBasicDetail?.lastName }}
            <span *ngIf="isExit" class="exited">{{'exited'|translate}}</span>
        </h3>
        <div *ngIf="!isExit && !isIncompleteEmployee&& !isHideNavbar">
            <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-icon"
                matTooltip="{{'exit_process' | translate}}" matTooltipPosition="right">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu" class="menu">
                <span *ngIf="!employeeIsExit" (click)="initiateExitProcess()" class="exit-flow">
                    {{ 'initiate_exit_process' | translate }}
                </span>

                <span class="revert-flow" (click)="revertExitPopup()" *ngIf="employeeIsExit">
                    {{ 'revert_exit_process' | translate }}
                </span>
                <mat-divider *ngIf="employeeIsExit"></mat-divider>
                <span class="revert-flow" (click)="processFnf()" *ngIf="employeeIsExit">
                    {{ 'fnf_process' | translate }}
                </span>
            </mat-menu>

        </div>

        <div *ngIf="isExit">
            <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-icon"
                matTooltip="{{'exit_history' | translate}}" matTooltipPosition="right">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu" class="menu">
                <span class="exit-flow" (click)="openExitPopup()">{{'exit_history' | translate}}</span><br>
            </mat-menu>
        </div>

    </div>
    <div id="pdfContainer"></div>
    <div *ngIf="pdfViewer">
        <mat-divider></mat-divider>
        <mat-tab-group>
            <!-- overview tab start -->
            <mat-tab label="{{'overview'|translate}}">
                <div class="toggle-edit-icon" *ngIf="!isExit">
                    <mat-icon id="employeeBasicDetailsIcon" (click)="cancelChanges()" *ngIf="isEditMode"
                        [matTooltip]="'Cancel'">
                        <ng-container>undo</ng-container>
                    </mat-icon>

                    <mat-icon id="employeeBasicDetailsIcon" (click)="saveEmployeeDetails()"
                        [matTooltip]="!isEditMode ? 'Edit' : 'Save'">
                        <ng-container *ngIf="!isEditMode">edit</ng-container>
                        <ng-container class="save-button" *ngIf="isEditMode">save</ng-container>
                    </mat-icon>
                </div>
                <div class="card-container">
                    <form [formGroup]="basicDetails">
                        <div class="mat-cards">

                            <!-- basic information start -->
                            <mat-card class="basic-card">
                                <div class="employee-image">
                                    <img class="avatar mat-elevation-z8" *ngIf="employeeImage" [src]="employeeImage"
                                        alt="Employee avatar" />
                                </div>
                                <div class="employee-profile">
                                    {{activeEmployeeData?.employeeBasicDetail?.firstName }}
                                    {{activeEmployeeData?.employeeBasicDetail?.middleName }}
                                    {{activeEmployeeData?.employeeBasicDetail?.lastName }}
                                    <mat-card-subtitle> {{activeEmployeeData?.employeeBasicDetail?.designationName}}
                                        (EMP00{{
                                        activeEmployeeData?.employeeBasicDetail?.id }})</mat-card-subtitle>
                                    <span *ngIf="isExit" class="last-working">{{'last_worked_on'|translate}}
                                        {{fnfData.lastWorkingDate}}</span>
                                </div>
                                <mat-divider class="divider"></mat-divider>

                                <div class="employee-basic-details-icon">
                                    <mat-card-subtitle class="basic-information">{{'basic_information' |
                                        translate}}</mat-card-subtitle><br>
                                </div>

                                <div class="employee-info" [ngClass]="isEditMode ? 'readonly_field_bottom': ''">
                                    <div class="heading-icon">
                                        <mat-icon class="icon">email</mat-icon>
                                        <h4 class="data-key">{{ 'work_email' | translate }} : </h4>
                                    </div>
                                    <ng-container>
                                        <span class="data-value">{{ activeEmployeeData?.employeeBasicDetail?.workEmail
                                            }}</span>
                                    </ng-container>
                                </div>

                                <div *ngIf="isEditMode">
                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">assignment_ind</mat-icon>
                                            <h4 class="data-key">{{ 'first_name' | translate }} : </h4>
                                        </div>
                                        <ng-container>
                                            <div class="input-field">
                                                <mat-form-field class="full-width" class="first-name"
                                                    appearance="outline">
                                                    <mat-label class="formLabel">{{'first_name'|translate}}</mat-label>
                                                    <input matInput type="text" class="input"
                                                        (keypress)="validateCharacter($event)"
                                                        formControlName="firstName" required />
                                                    <mat-error
                                                        *ngIf="basicDetails.controls['firstName'].hasError('required')">
                                                        {{ 'first_name_is_required' | translate }}
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="basicDetails.controls['firstName'].hasError('invalidInput')">
                                                        {{ 'invalid_name_input' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">account_circle</mat-icon>
                                            <h4 class="data-key">{{ 'middle_name' | translate }} : </h4>
                                        </div>
                                        <ng-container>
                                            <div class="input-field">
                                                <mat-form-field class="full-width" class="middle-name"
                                                    appearance="outline">
                                                    <mat-label class="formLabel">{{'middle_name'|translate}}</mat-label>
                                                    <input matInput type="text" class="input"
                                                        formControlName="middleName"
                                                        (keypress)="validateCharacter($event)" />
                                                    <mat-error
                                                        *ngIf="basicDetails.controls['middleName'].hasError('required')">
                                                        {{ 'middle_name_is_required' | translate }}
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="basicDetails.controls['middleName'].hasError('invalidInput')">
                                                        {{ 'invalid_name_input' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">account_box</mat-icon>
                                            <h4 class="data-key">{{ 'last_name' | translate }} : </h4>
                                        </div>
                                        <ng-container>
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label class="formLabel">{{'last_name'|translate}}</mat-label>
                                                    <input matInput type="text" class="input" formControlName="lastName"
                                                        (keypress)="validateCharacter($event)" required />
                                                    <mat-error
                                                        *ngIf="basicDetails.controls['lastName'].hasError('required')">
                                                        {{ 'last_name_is_required' | translate }}
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="basicDetails.controls['lastName'].hasError('invalidInput')">
                                                        {{ 'invalid_name_input' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <mat-icon class="icon">person_outline</mat-icon>
                                        <h4 class="data-key">{{ 'gender' | translate }} : </h4>
                                    </div>
                                    <ng-container *ngIf="!isEditMode">
                                        <span class="data-value">{{ activeEmployeeData?.employeeBasicDetail?.gender
                                            }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="isEditMode">
                                        <div class="input-field">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{ 'gender' | translate }}</mat-label>
                                                <mat-select formControlName="gender">
                                                    <mat-option value="Male">{{'male' | translate}}</mat-option>
                                                    <mat-option value="Female">{{'female' | translate}}</mat-option>
                                                    <mat-option value="Other">{{'other' | translate}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <mat-icon class="icon">today</mat-icon>
                                        <h4 class="data-key">{{'date_of_joining'|translate}} : </h4>
                                    </div>
                                    <ng-container *ngIf="!isEditMode">
                                        <span class="data-value">{{
                                            formatDateForDisplay(activeEmployeeData?.employeeBasicDetail?.doj) }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="isEditMode">
                                        <div class="input-field">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{ 'date_of_joining' | translate }}</mat-label>
                                                <input matInput formControlName="dateOfJoining" [matDatepicker]="picker"
                                                    [min]="dojMinLength" [max]="futureSixMonths"
                                                    (keypress)="$event.preventDefault()" required>
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-error>{{ 'date_of_joining_is_required' | translate }}</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <mat-icon class="icon">domain</mat-icon>
                                        <h4 class="data-key">{{'department'|translate}} : </h4>
                                    </div>
                                    <ng-container *ngIf="!isEditMode">
                                        <span
                                            class="data-value">{{activeEmployeeData?.employeeBasicDetail?.departmentName}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="isEditMode">
                                        <div class="input-field">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{'department'|translate}}</mat-label>
                                                <mat-select formControlName="department">
                                                    <mat-option *ngFor="let department of departmentList"
                                                        [value]="department.name">
                                                        {{ department.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <mat-icon class="icon">work</mat-icon>
                                        <h4 class="data-key">{{'designation'|translate}} : </h4>
                                    </div>
                                    <ng-container *ngIf="!isEditMode">
                                        <span
                                            class="data-value">{{activeEmployeeData?.employeeBasicDetail?.designationName}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="isEditMode">
                                        <div class="input-field">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{'designation'|translate}}</mat-label>
                                                <mat-select formControlName="designation">
                                                    <mat-option *ngFor="let designation of designationList"
                                                        [value]="designation.name">
                                                        {{ designation.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <mat-icon class="icon">perm_identity</mat-icon>
                                        <h4 class="data-key">{{'employee_type'|translate}} : </h4>
                                    </div>
                                    <ng-container *ngIf="!isEditMode">
                                        <span class="data-value">{{
                                            activeEmployeeData?.employeeBasicDetail?.employeeType | translate
                                            }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="isEditMode">
                                        <div class="input-field">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label class="formLabel">{{'employee_type'|translate}}</mat-label>
                                                <mat-select formControlName="employeeType">
                                                    <div *ngFor="let employeeType of employeeTypes">
                                                        <mat-option [value]="employeeType">{{employeeType |
                                                            translate}}</mat-option>
                                                    </div>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="employee-info" *ngIf="!hideManagerField && !isEditMode">
                                    <div class="heading-icon">
                                        <mat-icon class="icon">supervisor_account</mat-icon>
                                        <h4 class="data-key">{{'manager'|translate}} : </h4>
                                    </div>
                                    <ng-container *ngIf="!isEditMode">
                                        <span
                                            class="data-value">{{activeEmployeeData?.employeeBasicDetail?.managerName}}</span>
                                    </ng-container>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <mat-icon class="icon">attach_money</mat-icon>
                                        <h4 class="data-key">{{'payrun_group'|translate}} : </h4>
                                    </div>
                                    <ng-container *ngIf="!isEditMode">
                                        <span
                                            class="data-value">{{activeEmployeeData?.employeeBasicDetail?.payRunGroupName}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="isEditMode">
                                        <!-- <div class="input-field">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{'payrun_group'|translate}}</mat-label>
                                                <mat-select formControlName="payRunGroup">
                                                    <mat-option *ngFor="let payRunGroup of payRunGroupList"
                                                        [value]="payRunGroup.id">
                                                        {{ payRunGroup.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->

                                        <div class="input-field">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{'payrun_group' | translate}}</mat-label>
                                                <mat-select formControlName="payRunGroup">
                                                    <mat-option *ngFor="let payRunGroup of activeRunGroupList"
                                                        [value]="payRunGroup.id">
                                                        {{ payRunGroup.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </ng-container>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <mat-icon class="icon">location_on</mat-icon>
                                        <h4 class="data-key">{{'work_location'|translate}} : </h4>
                                    </div>
                                    <ng-container *ngIf="!isEditMode">
                                        <span
                                            class="data-value">{{activeEmployeeData?.employeeBasicDetail?.workLocation}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="isEditMode">
                                        <div class="input-field">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label class="formLabel">{{'work_location'|translate}}</mat-label>
                                                <input matInput type="text" class="input" formControlName="address"
                                                    required (keypress)="addressVerification($event)"
                                                    [maxlength]="15" />
                                                <mat-error>
                                                    {{'work_location_is_required'|translate}}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key toggle-key">{{ 'portal_access_toggle' | translate }}</h4>
                                    </div>
                                    <div class="data-value">
                                        <mat-slide-toggle
                                            [checked]="activeEmployeeData?.employeeBasicDetail?.portalAccess"
                                            [disabled]="!isEditMode" (click)="portalAccessToggle()"
                                            class="toggle-value"></mat-slide-toggle>
                                    </div>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key toggle-key">{{'professional_tax' |translate}}</h4>
                                    </div>
                                    <div class="data-value">
                                        <mat-slide-toggle class="toggle-value" [disabled]="!isEditMode"
                                            (click)="pTPopupToggle()"
                                            [checked]="activeEmployeeData?.employeeSalaryDetail?.ptactive"></mat-slide-toggle>
                                    </div>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key toggle-key">{{ 'employee_pf' | translate }}</h4>
                                    </div>
                                    <div class="data-value">
                                        <mat-slide-toggle [checked]="pfToggle" (click)="pfPopupToggle()"
                                            [disabled]="!isEditMode" class="toggle-value"></mat-slide-toggle>
                                    </div>
                                </div>

                                <!-- show pf and uan number for readonly. For update it will be visible in popup -->
                                <div *ngIf="activeEmployeeData.employeePersonalDetail.pfNumber != null && !isEditMode">
                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <h4 class="data-key toggle-key">{{ 'pf_number' | translate }} : </h4>
                                        </div>
                                        <ng-container>
                                            <span class="data-value">{{
                                                activeEmployeeData.employeePersonalDetail.pfNumber}}</span>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <h4 class="data-key toggle-key">{{ 'uan' | translate }} : </h4>
                                        </div>
                                        <ng-container>
                                            <span class="data-value">{{
                                                activeEmployeeData.employeePersonalDetail.uan}}</span>
                                        </ng-container>
                                    </div>
                                </div>
                            </mat-card>
                            <!-- basic information end -->

                            <div>
                                <!-- personal information start-->
                                <mat-card class="personal-card">
                                    <div class="employee-basic-details-icon">
                                        <mat-card-subtitle class="basic-information">{{'personal_information' |
                                            translate}}</mat-card-subtitle><br>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">email</mat-icon>
                                            <h4 class="data-key">{{'personal_email'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeePersonalDetail?.email }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label
                                                        class="formLabel">{{'personal_email'|translate}}</mat-label>
                                                    <input matInput type="text" class="input"
                                                        formControlName="personalEmail" required />
                                                    <mat-error
                                                        *ngIf="basicDetails.controls['personalEmail'].hasError('required')">
                                                        {{'personal_email_is_required'|translate}}
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="basicDetails.controls['personalEmail'].hasError('invalidDomain')">
                                                        {{'only_personal_email_allowed'|translate}}
                                                    </mat-error>
                                                </mat-form-field>

                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">today</mat-icon>
                                            <h4 class="data-key">{{'date_of_birth'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                formatDateForDisplay(activeEmployeeData?.employeePersonalDetail?.dob)}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{ 'date_of_birth' | translate }}</mat-label>
                                                    <input matInput formControlName="dateOfBirth"
                                                        [matDatepicker]="picker" [min]="sixtyYearsOldDate"
                                                        [max]="ageOfMaturityDate" (keypress)="$event.preventDefault()"
                                                        required />
                                                    <mat-datepicker-toggle matSuffix
                                                        [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-error>
                                                        {{ 'date_of_birth_is_required' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">credit_card</mat-icon>
                                            <h4 class="data-key">{{'pan'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeePersonalDetail?.panNo }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label class="formLabel">{{'pan'|translate}}</mat-label>
                                                    <input matInput type="text" class="input"
                                                        formControlName="panCardNo"
                                                        (keypress)="alphanumericAllowed($event)" [maxLength]="10"
                                                        required appUppercase />
                                                    <mat-error>
                                                        {{'pan_number_is_required'|translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">phone</mat-icon>
                                            <h4 class="data-key">{{'mobile_number'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeePersonalDetail?.mobileNo }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'mobile_number'|translate}}</mat-label>
                                                    <input matInput (keypress)="validateNumber($event)" minlength="10"
                                                        maxlength="10" formControlName="mobileNo" required />
                                                    <mat-error>{{'mobile_number_required' | translate}}</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">location_on</mat-icon>
                                            <h4 class="data-key">{{'address1'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeePersonalDetail?.addressLine1 }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'address1'|translate}}</mat-label>
                                                    <input matInput type="text" formControlName="addressLine1"
                                                        (keypress)="addressVerification($event)">
                                                    <mat-error>{{'address_is_required' | translate}}</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">location_on</mat-icon>
                                            <h4 class="data-key">{{'address2'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeePersonalDetail?.addressLine2 }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'address2'|translate}}</mat-label>
                                                    <input matInput type="text" formControlName="addressLine2"
                                                        (keypress)="addressVerification($event)">
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon"> public</mat-icon>
                                            <h4 class="data-key">{{'country'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span
                                                class="data-value">{{activeEmployeeData?.employeePersonalDetail?.country}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'country'|translate}}</mat-label>
                                                    <mat-select matInput formControlName="country"
                                                        class="select_mat_input" required="true"
                                                        (selectionChange)="getState($event.value)">
                                                        <mat-option *ngFor="let country of countryList;"
                                                            [value]="country">{{country}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error>{{'country_is_required' | translate}}</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon"> pin_drop</mat-icon>
                                            <h4 class="data-key">{{'state'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span
                                                class="data-value">{{activeEmployeeData?.employeePersonalDetail?.state}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'state'|translate}}</mat-label>
                                                    <mat-select matInput formControlName="state"
                                                        class="select-mat-input" required="true"
                                                        (selectionChange)="getCity($event.value)">
                                                        <mat-option *ngFor="let state of stateList;" [value]="state">
                                                            {{ state }}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error>{{'state_is_required' | translate}}</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon"> location_city </mat-icon>
                                            <h4 class="data-key">{{'city'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span
                                                class="data-value">{{activeEmployeeData?.employeePersonalDetail?.city}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'city'|translate}}</mat-label>
                                                    <mat-select matInput formControlName="city" class="select-mat-input"
                                                        (selectionChange)="selectedCityName($event.value)">
                                                        <mat-option *ngFor="let city of cityList;" [value]="city">{{
                                                            city
                                                            }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">location_on</mat-icon>
                                            <h4 class="data-key">{{'pincode'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeePersonalDetail?.pinCode }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label class="formLabel">{{'pincode'|translate}}</mat-label>
                                                    <input matInput type="text" class="input" minlength="6"
                                                        maxlength="6" (keypress)="validateNumber($event)"
                                                        formControlName="pincode" required />
                                                    <mat-error>
                                                        {{'pincode_is_required'|translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                </mat-card>
                                <!-- personal information end-->

                                <!-- payment inforamtion start -->
                                <mat-card class="personal-card">

                                    <div class="employee-basic-details-icon">
                                        <mat-card-subtitle class="payment-information">{{'payment_information' |
                                            translate}}</mat-card-subtitle>
                                    </div>
                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">account_balance</mat-icon>
                                            <h4 class="data-key">{{'account_number'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeeBankDetail?.bankAccountNo }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'account_number'|translate}}</mat-label>
                                                    <input matInput type="text" formControlName="bankAccountNo"
                                                        (keypress)="alphanumericAllowed($event)" appBlockCopyPaste
                                                        [maxLength]="15">
                                                    <mat-error>{{'Bank_Account_is_required'|translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>


                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">account_circle</mat-icon>
                                            <h4 class="data-key">{{'account_holder_name'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeeBankDetail?.accountHolderName}}</span>
                                        </ng-container>

                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'account_holder_name'| translate}}</mat-label>
                                                    <input matInput type="text" formControlName="accountHolderName"
                                                        [maxLength]="30"
                                                        (keypress)="validateCharacterNumberAndSpace($event)">
                                                    <mat-error>
                                                        {{'account_holder_name_is_required'|translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">confirmation_number</mat-icon>
                                            <h4 class="data-key">{{'ifsc_code'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{ activeEmployeeData?.employeeBankDetail?.ifscCode
                                                }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{ 'ifsc_code' | translate }}</mat-label>
                                                    <input matInput type="text" class="ifscVerify" appUppercase
                                                        [maxLength]="11" (keypress)="alphanumericAllowed($event)"
                                                        formControlName="ifscCode"
                                                        (ngModelChange)="resetIFSCFieldCheck()" required />
                                                    <mat-error
                                                        *ngIf="basicDetails.get('ifscCode')?.hasError('required')">
                                                        {{ 'ifsc_code_is_required' | translate }}
                                                    </mat-error>
                                                    <a *ngIf="!verifiedIFSC && !isLoading" (click)="verifyIFSC()"
                                                        class="verify">{{'verify'|translate}}</a>
                                                    <mat-icon *ngIf="verifiedIFSC" class="verify"
                                                        color="primary">done_mat_icon</mat-icon>
                                                    <p class="error-message" *ngIf="errorMessage">{{ "errorMessage" }}
                                                    </p>
                                                    <app-spinner *ngIf="isLoading" class="spinner"></app-spinner>
                                                    <mat-error
                                                        *ngIf="basicDetails.get('ifscCode')?.hasError('invalidIFSC')">{{'please-provide-valid-ifsc'
                                                        | translate}}</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">account_balance</mat-icon>
                                            <h4 class="data-key">{{'bank_name'| translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{ activeEmployeeData?.employeeBankDetail?.bankName
                                                }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'enter_bank_name'| translate}}</mat-label>
                                                    <input matInput type="text" formControlName="bankName"
                                                        [maxLength]="30" [readonly]="isEditMode"
                                                        (keypress)="validateCharacterNumberAndSpace($event)">
                                                    <mat-error>
                                                        {{'bank_name_is_required'|translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">account_balance_wallet</mat-icon>
                                            <h4 class="data-key">{{'account_type'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeeBankDetail?.accountType }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'account_type'|translate}}</mat-label>
                                                    <mat-select formControlName="accountType">
                                                        <mat-option value="Saving">{{'saving'|translate}}</mat-option>
                                                        <mat-option value="Current">{{'current'|translate}}</mat-option>
                                                        <mat-option value="Salary">{{'salary'|translate}}</mat-option>
                                                    </mat-select>
                                                    <mat-error>
                                                        {{'account_type_is_required'|translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="employee-info">
                                        <div class="heading-icon">
                                            <mat-icon class="icon">swap_horizontal_circle</mat-icon>
                                            <h4 class="data-key">{{'payment_mode'|translate}} : </h4>
                                        </div>
                                        <ng-container *ngIf="!isEditMode">
                                            <span class="data-value">{{
                                                activeEmployeeData?.employeeSalaryDetail?.paymentMode }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditMode">
                                            <div class="input-field">
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <mat-label>{{'account_type'|translate}}</mat-label>
                                                    <mat-select formControlName="paymentMode">
                                                        <mat-option
                                                            value="Bank Transfer">{{'bank_transfer'|translate}}</mat-option>
                                                    </mat-select>
                                                    <mat-error>
                                                        {{'account_type_is_required'|translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                </mat-card>
                            </div>
                            <!-- payment inforamtion end -->
                        </div>
                    </form>
                </div>
            </mat-tab>
            <!-- overview tab end -->

            <!-- salary details tab start -->
            <mat-tab label="{{'salary_details'|translate}}">
                <form [formGroup]="createSalaryDetailsForm">
                    <app-update-employee-salary-details [createSalaryDetailsForm]="createSalaryDetailsForm"
                        [id]="id"></app-update-employee-salary-details>
                </form>
            </mat-tab>
            <!-- salary details tab end -->

            <!-- payslip and form tab start -->
            <mat-tab label="{{'payslip_and_form'|translate}}">
                <app-payslipAndForms></app-payslipAndForms>
            </mat-tab>
            <!-- payslip and form tab end -->

            <!-- document tab start -->
            <mat-tab label="{{'documents'|translate}}">
                <button class="upload-document-button" (click)="uploadDocument()">
                    <div *ngIf="!documentUpload">
                        {{ "upload_document" | translate }}
                    </div>
                    <div *ngIf="documentUpload">
                        {{ "view_document" | translate }}
                    </div>
                </button>
                <ng-container *ngIf="documentUpload; else expansionPanel">
                    <app-documents-upload [id]="id"></app-documents-upload>
                </ng-container>
                <ng-template #expansionPanel>
                    <div class="documents" *ngIf="documentCount > 0">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <h4>{{ 'personal_document' | translate }}</h4>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-expansion-panel-content>
                                <div *ngIf="documents.length > 0">
                                    <mat-chip-list>
                                        <div class="grid-container">
                                            <a class="document-name" *ngFor="let document of documents"
                                                (click)="loadDocument(document)">
                                                {{ document }}
                                            </a>
                                        </div>
                                    </mat-chip-list>
                                </div>
                                <div *ngIf="documents.length === 0">
                                    <h4>{{'no_row_to_show'|translate}}</h4>
                                </div>
                            </mat-expansion-panel-content>
                        </mat-expansion-panel>
                    </div>
                </ng-template>
            </mat-tab>
            <!-- document tab end -->

            <!-- team tab start -->
            <mat-tab label="{{'team'|translate}}">
                <app-org-hierarchy></app-org-hierarchy>
            </mat-tab>
            <!-- team tab end -->

            <!-- team tab start -->
            <mat-tab label="{{'roles_and_responsiblity' | translate}}">
                <app-role-information [id]="id"></app-role-information>
            </mat-tab>
            <!-- team tab start -->

            <!-- emergency contact tab start -->
            <mat-tab label="{{'emergency_contacts' | translate}}">
                <app-family-and-emergency-contact-info [showAddButton]="false" [showActionColumn]="false"
                    [id]="id"></app-family-and-emergency-contact-info>
            </mat-tab>
            <!-- emergency contact tab end -->

        </mat-tab-group>
    </div>
</div>

<div class="overlay" *ngIf="pfPopupView">
    <div class="add-popup">
        <div class="container">
            <div>
                <h3 class="heading">{{ 'pf_details' | translate }}</h3>
            </div>
            <form [formGroup]="pfDetails" id="departmentForm">
                <div class="pf-declaration-div">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'pf_number' | translate }}</mat-label>
                        <input matInput formControlName="pfNumber" type="text" [maxLength]="22" [minlength]="9"
                            (keypress)="alphanumericAllowed($event)" />
                        <mat-error *ngIf="pfDetails.get('pfNumber')?.hasError('required')">{{'pf_number_is_required'
                            | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field id="pfDetails" class="full-width" appearance="outline">
                        <mat-label>{{ 'uan_number' | translate }}</mat-label>
                        <input matInput formControlName="uanNumber" type="text" [maxLength]="12" [minlength]="9"
                            (keypress)="numberOnly($event)" />
                        <mat-error *ngIf="pfDetails.get('uanNumber')?.hasError('required')">
                            {{ 'uan_number_is_required' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!pfDetails.get('uanNumber')?.hasError('required') && pfDetails.get('uanNumber')?.value?.length < 9">
                            {{ 'uan_number_invalid' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="pupup-buttons">
                    <button (click)="closePopup()" class="closeDialog cancel-btn">{{'cancel' | translate}}</button>
                    <button (click)="savePfDetails()">{{'save' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>