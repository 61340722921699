<app-toolbar></app-toolbar>
<div class="buy-subscription">
  <div class="left-section">
    <mat-icon svgIcon="arrow-back-double" (click)="goBack()"></mat-icon>
    <h3>{{ 'subscription_details' | translate }}</h3>
  </div>
  <button class="button" (click)="buySubscription()">
    {{ 'update_subscription' | translate }}
  </button>
</div>


<div class="horizontal-subscription-card">
  <div class="subscription-content">
    <div class="subscription-info">
      <p><strong>{{ 'subscriptionId' | translate }} : </strong> {{ subscriptionCardDetails.id }}</p>
      <p><strong>{{ 'subscription_name' | translate }} : </strong> {{ subscriptionCardDetails.name }}</p>
      <p><strong>{{ 'subscription_type' | translate}} : </strong> {{ subscriptionCardDetails.type }}</p>
    </div>
    <div class="subscription-info-row">
      <p><strong>{{ 'subscription_price' | translate}} : </strong> {{ subscriptionCardDetails.price }}</p>
      <p><strong>{{ 'subscribeDate' | translate}} : </strong> {{ subscriptionCardDetails.subscribeDate }}</p>
      <p><strong>{{ 'subscription_expireDate' | translate}} : </strong>{{ subscriptionCardDetails.expireDate }}</p>
    </div>
  </div>
</div>

<div class="subscription-ag-grid">
  <ag-grid-angular (gridReady)="onGridReady($event)" style="width: 100%; height: 19rem;" class="ag-theme-alpine"
    [rowData]="subscriptionHistoryData" [columnDefs]="columnDefs">
  </ag-grid-angular>
</div>