import { Component } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  IWEXE_LOCATION: string = AppConstant.IWEXE_LOCATION;
  IWEXE_SUPPORT_EMAIL: string = AppConstant.IWEXE_SUPPORT_EMAIL;
  IWEXE_SUPPORT_PHONE: string = AppConstant.IWEXE_SUPPORT_PHONE;
  currentYear: number = new Date().getFullYear();
}
