import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
@Component({
  selector: 'app-view-all-product',
  templateUrl: './view-all-product.component.html',
  styleUrls: ['./view-all-product.component.scss']
})
export class ViewAllProductComponent {
  constructor(private router: Router, private toasterService: ToastrService) { }

  scrollToSection(sectionId: string): void {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  showModuleDetails() {
    const loginEmail = sessionStorage.getItem(AppConstant.LOGIN_PAGE);
    if (!loginEmail) {
      this.toasterService.clear();
      this.toasterService.error(AppConstant.LOGIN_FIRST);
    }
  }
}