<app-toolbar></app-toolbar>
<div class="main-container">
  <div class="header">
    <div class="header-left">
      <mat-icon class="back-arrow-icon" matTooltip="{{'back' | translate}}" (click)="goToBack()"
        svgIcon="arrow-back-double"></mat-icon>
      <div width="24" height="24" svgIcon="arrow-back-double"></div>
      <h3 class="headerName">{{ 'training_details' |translate}}</h3>
    </div>

    <div class="header-right" *ngIf="isAdmin">
      <button (click)="addTraning()">{{ 'add_training' | translate }}</button>
    </div>
  </div>

  <mat-divider class="heading-divider"></mat-divider>

  <mat-tab-group class="mat-tab-content">
    <mat-tab label="{{'training' | translate}}">
      <div class="container">
        <div class="optional">
          <h2 class="trainingTitle"><b>{{ 'compulsory_training' | translate }}</b></h2>
        </div>
        <mat-divider class="divider"></mat-divider>
        <app-image-slider [data]="viewCompulsoryImageSliderDto"></app-image-slider>

        <div class="optional">
          <h2 class="trainingTitle"><b>{{ 'optional_training' | translate }}</b></h2>
        </div>
        <mat-divider class="divider"></mat-divider>
        <app-image-slider [data]="viewOptionalImageSliderDto"></app-image-slider>
      </div>

    </mat-tab>

    <mat-tab label="{{'training_heading' | translate}}" class="summary-tab">
      <div class="training-summary">{{'training_summary' | translate}}</div>
      <mat-divider class="divider"></mat-divider>
      <div class='md-padding' layout="row" flex>
        <div layout="row" layout-xs="column" flex>
          <div class="training-table">
            <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 29rem; width:100%;"
              class="ag-theme-alpine" [rowData]="employeeData" [rowSelection]="'multiple'" [animateRows]="true"
              [columnDefs]="hrTableCol" [defaultColDef]="hrColDef" [enableSorting]="true" [enableFilter]="true"
              [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <app-loader></app-loader>

</div>