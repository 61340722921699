<app-toolbar></app-toolbar>
<div class="horizontal-subscription-card">
  <div class="subscription-header">
    <mat-icon svgIcon="arrow-back-double" (click)="goBack()"></mat-icon>
    <h3>{{ 'transaction_history' | translate }}</h3>
  </div>
</div>

<div class="horizontal-subscription-card">
  <ag-grid-angular (gridReady)="onGridReady($event)" style="width: 100%; height: 27rem;" class="ag-theme-alpine"
    [rowData]="transactionHistory" [columnDefs]="columnDefs" [pagination]="true" [paginationPageSize]="10">
  </ag-grid-angular>
</div>