import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { CountryData } from 'src/app/constants/master-data/country.constant';
import { RegistrationData, VerifyEmail } from 'src/app/model/registration.model';
import { RegistrationService } from 'src/app/services/registration/registrationService';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { SalutationData } from 'src/app/constants/master-data/salutation.constant';
import { EncryptionService } from 'src/app/services/encryption/encryption.service';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {

  public registrationForm !: FormGroup;
  countries: any = CountryData.COUNTRY;
  salutations: any = SalutationData.SALUTATIONS
  adminInfo: any;
  userName: string | undefined;
  registrationLink = AppConstant.LANDING_PAGE;
  isVerifyingEmail: boolean = false;
  isOtpSending: boolean = false;
  hidePassword: boolean = true;
  createRegistrationRequest!: RegistrationData;

  constructor(private router: Router, private fb: FormBuilder, private encryptionService: EncryptionService, private registrationService: RegistrationService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.initregistrationForm();
    this.registrationData();
  }

  initregistrationForm() {
    this.registrationForm = this.fb.group({
      'name': ['', [Validators.required, ValidationUtil.onlySpacesOrNumbersValidator()]],
      'mobileNumber': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10), ValidationUtil.validMobileNumber()]],
      'countryCode': ['(+91)', Validators.required],
      'salutation': ['Mr.', Validators.required],
      'email': ['', [Validators.required, ValidationUtil.emailValidation()]],
      'password': ['', ValidationUtil.getRegistrationPasswordPattern()],
      'confirmPassword': ['', Validators.required],
    }, {
      validators: this.mustMatch('password', 'confirmPassword')
    })
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  registrationData() {
    let email = sessionStorage.getItem(AppConstant.CREATE_REGISTRATION_REQUEST);
    let signUpData: RegistrationData = JSON.parse(email || '{}');
    this.adminInfo = signUpData;
    this.userName = signUpData.name;
  }
  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }


  register() {
    if (this.registrationForm.invalid) {
      this.registrationForm.markAllAsTouched();
      return;
    }
    this.createRegistrationRequest = {
      "name": this.registrationForm.value.name,
      "email": this.registrationForm.value.email,
      "password": this.registrationForm.value.password,
      "mobileNumber": this.registrationForm.value.mobileNumber,
      "countryCode": this.registrationForm.value.countryCode,
      "salutation": this.registrationForm.value.salutation
    };
    this.createRegistrationRequest.password = this.encryptionService.encrypt(this.createRegistrationRequest.password)
    this.registrationService.checkDomainNameExistOrNot(this.registrationForm.value.email).subscribe((res: any) => {
      if (res.status === 200) {
        this.toastrService.clear();
        if (ValidationUtil.validateMobileNumber(this.registrationForm.value.mobileNumber)) {
          this.sendOtpToVerifyEmail();
        }
      } else {
        this.toastrService.clear();
        this.toastrService.error(res.message);
      }
    })

  }

  sendOtpToVerifyEmail() {
    let sendOtp: VerifyEmail = {
      email: this.registrationForm.value.email,
      name: this.registrationForm.value.name,
    };
    this.isOtpSending = true;
    this.registrationService.verifyEmail(sendOtp).subscribe((data: any) => {
      this.isOtpSending = false;
      this.isVerifyingEmail = false;
      if (data.status == 200) {
        this.toastrService.clear();
        this.toastrService.success(data.message);
        sessionStorage.setItem(AppConstant.CREATE_REGISTRATION_REQUEST, JSON.stringify(this.createRegistrationRequest));
        return this.router.navigate([AppConstant.VERIFY_EMAIL]);
      }
      else {
        this.toastrService.clear();
        this.toastrService.error(data.message);
        return this.router.navigate([AppConstant.REGISTRATION]);
      }
    });
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName]
      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true })
      }
      else {
        matchingControl.setErrors(null);
      }
    }
  }
}
