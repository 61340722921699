import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreatePersonalInformationData } from 'src/app/model/employeeDetails';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ToastrService } from 'ngx-toastr';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { MatStepper } from '@angular/material/stepper';
import { AppConstant } from 'src/app/constants/app.constants';
import { FormatUtil } from 'src/app/utils/format.utils';
import { Router } from '@angular/router';
@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit {
  countryList: any;
  stateList: any;
  cityList: any;
  @Input() createPersonalForm!: FormGroup;
  designationList: string[] = undefined as any;
  departmentList: string[] = undefined as any;
  selectedCountry: string = "";
  selectedState: string = "";
  selectedCity: string = "";
  sixtyYearsOldDate: Date = undefined as any;
  ageOfMaturityDate: Date = undefined as any;

  constructor(private router: Router, private stepper: MatStepper, private fb: FormBuilder, private employeeDetailsService: EmployeeDetailsService, private toastrService: ToastrService, private masterDataService: MasterDataService) {
    //finding 60 years old date for DOB from currect date
    this.sixtyYearsOldDate = FormatUtil.sixtyYearsOldDate();

    //18 years old employee
    this.ageOfMaturityDate = FormatUtil.ageOfMaturityDate();
  }

  ngOnInit() {
    this.getCountryList();
    this.personalDetails();
  }


  dobFilter = (date: Date | null): boolean => {
    if (!date) return false;
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setFullYear(today.getFullYear() - 18);
    return date <= maxDate;
  }


  personalDetails() {
    this.createPersonalForm = this.fb.group({
      personalEmail: ['', [Validators.required, Validators.email, ValidationUtil.personalEmailDomainValidator]],
      'mobileNo': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10), ValidationUtil.validMobileNumber()]],
      'dob': ['', [Validators.required, ValidationUtil.ageValidator()]],
      'panNo': ['', [
        Validators.required,
        Validators.pattern("^([A-Z]){5}([0-9]){4}([A-Z]){1}$")
      ]],
      'addressLine1': ['', Validators.required],
      'addressLine2': [''],
      'state': ['', Validators.required],
      'city': [''],
      'country': ['', Validators.required],
      'pinCode': ['', [Validators.required, Validators.pattern(/^[0-9]*$/)
        , Validators.maxLength(6), Validators.minLength(6)]
      ]
    });
  }

  addEmployeePersonalInformation() {
    if (this.createPersonalForm.invalid) {
      return;
    }
    let dob: any;
    if (typeof (this.createPersonalForm.get('dob')?.value) == 'string') {
      dob = FormatUtil.adjustDateForTimeOffset(new Date(this.createPersonalForm.get('dob')?.value));
    } else {
      dob = FormatUtil.adjustDateForTimeOffset(this.createPersonalForm.get('dob')?.value);
    }

    let createPersonalDetails: CreatePersonalInformationData = {
      id: sessionStorage.getItem(AppConstant.BASIC_DETAILS_ID),
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      email: this.createPersonalForm.value.personalEmail,
      mobileNo: this.createPersonalForm.value.mobileNo,
      dob: dob,
      panNo: this.createPersonalForm.value.panNo,
      addressLine1: this.createPersonalForm.value.addressLine1,
      addressLine2: this.createPersonalForm.value.addressLine2,
      country: this.selectedCountry,
      state: this.selectedState,
      city: this.selectedCity,
      pinCode: this.createPersonalForm.value.pinCode,

    }
    this.employeeDetailsService.employeePersonalDetails(createPersonalDetails).subscribe((data: any) => {
      if (data.status === 200) {
        this.toastrService.clear();
        this.toastrService.success(data.message);
        return this.stepper.next();
      }
      else {
        this.toastrService.clear();
        return this.toastrService.error(data.message);
      }
    }
    );
  }

  getCountryList() {
    this.masterDataService.getAllCountries().subscribe(
      (data: any) => {
        if (data.status === 200)
          this.countryList = data.data;
      }
    )
  }

  getState(country: any) {
    this.selectedCountry = country;
    this.masterDataService.getAllState(country).subscribe(
      (data: any) => {
        this.stateList = data.data;
      }
    )
  }

  getCity(state: any) {
    this.selectedState = state;
    this.masterDataService.getAllCity(state).subscribe(
      (data: any) => {
        this.cityList = data.data;
      }
    )
  }

  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event);
  }

  backToHome() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    }

  }
}