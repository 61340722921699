

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { ResignationService } from 'src/app/services/resignation/resignation.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { FormatUtil } from 'src/app/utils/format.utils';
import { SettingService } from 'src/app/services/setting/setting.service';
import { StateService } from 'src/app/services/exit-process/state.service';

@Component({
  selector: 'app-exit-process',
  templateUrl: './exit-process.component.html',
  styleUrls: ['./exit-process.component.scss']
})
export class ExitProcessComponent implements OnInit {
  resignationForm!: FormGroup;
  minDate = new Date();
  minRequiredLWD: Date | null = null;
  isApplyResignation: boolean = false;
  isReadonlyTentaive: boolean = true;
  noticePeriodDays: any;
  selectedEmployeeResignationType: string = 'Employee Resignations';
  employeeResignationData = [];
  empColDef: any;
  managerId = sessionStorage.getItem(AppConstant.MANAGER_ID);
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  logoName: string = '';
  logoError: string | null = null;
  fileInvalid: boolean = true;
  fileInput: any;
  selectedFile: File | any = null;
  selectedFileName: any;
  fileSizeError: boolean = false;
  fileTypeError: boolean = false;

  constructor(
    private stateService: StateService,
    private settingService: SettingService,
    private fb: FormBuilder,
    private router: Router,
    private resignationService: ResignationService,
    private toastrService: ToastrService
  ) {
    this.minDate.setHours(0, 0, 0, 0);
    this.minRequiredLWD = this.minDate;
    this.resignationForm = this.fb.group({
      attachment: [null, Validators.required]
    });
  }

  empTableCol = [
    { field: "id", width: 120, unSortIcon: true, floatingFilter: true },
    { headerName: 'Submission Date', field: 'submissionDate', cellRenderer: ValidationUtil.dateFormatter },
    { field: "reason", floatingFilter: true, unSortIcon: true, width: 350 },
    { headerName: 'HR Status', field: "hrStatus", floatingFilter: true, unSortIcon: true, width: 160 },
    { field: "managerStatus", floatingFilter: true, unSortIcon: true, width: 180 },
  ];

  ngOnInit(): void {
    this.stateService.applyResignation$.subscribe((value: boolean) => {
      this.isApplyResignation = value;
    });

    this.getResignation();
    this.initResignation();
    this.getAllSettingData();
    this.getEmployeeAllResignationHistory();
    this.isReadonlyTentaive = true;
    this.empColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.getResignationStatus();
  }

  initResignation() {
    this.resignationForm = this.fb.group({
      'lastWorkingDay': ['', Validators.required],
      'submissionDate': [new Date(), Validators.required],
      'tentativeLWD': [''],
      'shortfallDays': [''],
      'reason': ['', [Validators.required]],
      'requiredLWD': ['', Validators.required],
      'alternateEmail': ['', [Validators.required, ValidationUtil.alternateEmailIsRequired(), ValidationUtil.alternateEmailValidation]],
      'alternateMobileNumber': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10), ValidationUtil.validMobileNumber()]],
      'remark': [''],
      'attachment': ['']
    });

    this.minRequiredLWD = this.resignationForm.get('submissionDate')?.value || this.minDate;
  }
  onLastWorkingDayChange() {
    const selectedDate: Date = this.resignationForm.get('lastWorkingDay')?.value;
    const tentativeDate: Date = new Date(selectedDate);
    tentativeDate.setDate(selectedDate.getDate() + this.noticePeriodDays - 1); 3
    this.resignationForm.patchValue({
      'tentativeLWD': tentativeDate,
      'shortfallDays': this.noticePeriodDays
    });
  }


  onSubmissionDateChange(event: any) {
    const selectedDate: Date = event.value;
    this.minRequiredLWD = selectedDate;
    this.resignationForm.get('lastWorkingDay')?.updateValueAndValidity();
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  validateCharacter(event: any) {
    return ValidationUtil.characterAndSymbolAllowed(event);
  }

  isFutureDate(date: Date | any): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date && date >= today;
  }

  submitResignation() {
    if (!this.fileSizeError && !this.fileTypeError) {
      const formData = new FormData();
      const formValue = this.resignationForm.value;

      let lastWorkingDay: any;
      if (typeof (formValue.lastWorkingDay) == 'string') {
        lastWorkingDay = FormatUtil.adjustDateForTimeOffset(new Date(formValue.lastWorkingDay));
      } else {
        lastWorkingDay = FormatUtil.adjustDateForTimeOffset(formValue.lastWorkingDay);
      }

      let tentativeLWD: any;
      if (typeof (formValue.tentativeLWD) == 'string') {
        tentativeLWD = FormatUtil.adjustDateForTimeOffset(new Date(formValue.tentativeLWD));
      } else {
        tentativeLWD = FormatUtil.adjustDateForTimeOffset(formValue.tentativeLWD);
      }

      let submissionDate: any;
      if (typeof (formValue.submissionDate) == 'string') {
        submissionDate = FormatUtil.adjustDateForTimeOffset(new Date(formValue.submissionDate));
      } else {
        submissionDate = FormatUtil.adjustDateForTimeOffset(formValue.submissionDate);
      }

      const employeeResignationDto = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        employeeId: sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
        managerId: this.managerId,
        lastWorkingDay: lastWorkingDay,
        tentativeLWD: tentativeLWD,
        shortFallDays: this.noticePeriodDays,
        submissionDate: submissionDate,
        reason: formValue.reason,
        alternateEmail: formValue.alternateEmail,
        alternateMobileNumber: formValue.alternateMobileNumber,
        remark: formValue.remark
      };
      formData.append('employeeResignationDto', JSON.stringify(employeeResignationDto));
      if (this.selectedFile) {
        formData.append('attachment', this.selectedFile);
      }
      this.resignationService.submitResignation(formData).subscribe(
        response => {
          if (response.status == 200) {
            this.toastrService.clear();
            this.toastrService.success(response.message);
            this.isApplyResignation = false;
          } else {
            this.toastrService.clear();
            this.toastrService.error(response.message);
          }
        }
      );
    }
  }

  formatDate(date: Date): string {
    if (!date || isNaN(date.getTime())) {
      return '';
    }
    return date.toISOString();
  }


  onClickFileInputButton(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  onFileSelected(event: any): void {
    this.fileTypeError = false;
    this.fileSizeError = false;
    const file: File | null = event.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'image/svg+xml'];
      if (!allowedTypes.includes(file.type)) {
        this.fileTypeError = true;
        this.clearFileInput();
      } else if (file.name.length > 25) {
        this.fileSizeError = true;
        this.clearFileInput();
      } else {
        this.resignationForm.get('attachment')?.setErrors(null);
        this.resignationForm.patchValue({ attachment: file });
        this.selectedFileName = file.name;
      }
    }
  }

  clearFileInput(): void {
    const fileInput = document.querySelector('#fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.selectedFileName = null;
  }

  isDateValid(date: Date): boolean {
    const currentDate = new Date();
    return !date || date >= currentDate;
  }

  getResignation() {
    this.resignationService.getResignation(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        if (data.data == null) {
          this.isApplyResignation = true;
        } else {
          this.isApplyResignation = false;
        }
      }
    );
  }

  getAllSettingData() {
    this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200 && data.data != null) {
        this.noticePeriodDays = data.data.noticePeriodDays;
      }
    });
  }

  getEmployeeAllResignationHistory() {
    this.resignationService.getEmployeeResignationHistory(this.companyId, this.employeeId).subscribe((data: any) => {
      this.employeeResignationData = data.data;
    });
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  getResignationStatus() {
    this.resignationService.getResignation(this.companyId, this.employeeId).subscribe((data: any) => {
      if (data.data.status === 'Rejected') {
        this.isApplyResignation = true;
      }
    });
  }

  goBack() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

}
