<app-toolbar></app-toolbar>
<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goBack()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{'resignation_details'|translate}}</h3>
    </div>
    <mat-divider></mat-divider>
</div>

<div class="main-container">
    <mat-tab-group class="tab-group">
        <mat-tab label="{{ 'apply_resignation' | translate }}">
            <form [formGroup]="resignationForm" class="container" *ngIf="isApplyResignation">
                <div class="form-field-gap">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'submission_Date' | translate }}</mat-label>
                        <input formControlName="submissionDate" matInput [matDatepicker]="picker" [min]="minDate"
                            (dateChange)="onSubmissionDateChange($event)" (keypress)="$event.preventDefault()" required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="resignationForm.get('submissionDate')?.hasError('required')">
                            {{ 'name_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'requiredLWD' | translate }}</mat-label>
                        <input class="content-width" matInput [matDatepicker]="picker1" formControlName="lastWorkingDay"
                            [min]="minRequiredLWD" (keypress)="$event.preventDefault()" required
                            (dateChange)="onLastWorkingDayChange()">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error *ngIf="resignationForm.get('lastWorkingDay')?.hasError('required')">
                            {{ 'requiredLWD_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-field-gap1">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'tentativeTWD' | translate }}</mat-label>
                        <input class="content-width" matInput [matDatepicker]="picker3" readonly
                            (keypress)="$event.preventDefault()" formControlName="tentativeLWD"
                            [disabled]="isReadonlyTentaive">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3 [disabled]="isReadonlyTentaive"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="content-width">
                        <mat-label>{{ 'shortfallDays' | translate }}</mat-label>
                        <input matInput formControlName="shortfallDays" readonly>
                    </mat-form-field>
                </div>
                <div class="form-field-gap1">
                    <mat-form-field appearance="outline">
                        <mat-label class="formLabel">{{'alternate_email_address'|translate}}</mat-label>
                        <input matInput type="text" formControlName="alternateEmail" required />
                        <mat-error *ngIf="resignationForm.controls['alternateEmail'].hasError('required')">
                            {{ 'alternate_email_is_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="resignationForm.controls['alternateEmail'].hasError('invalidEmail')">
                            {{ 'invalid_email' | translate }}
                        </mat-error>
                        <mat-error *ngIf="resignationForm.controls['alternateEmail'].hasError('invalidDomain')">
                            {{ 'personal_email_only' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'alternate_mobile_number' | translate }}</mat-label>
                        <input matInput class=".content-width " (keypress)="validateNumber($event)" minlength="10"
                            maxlength="10" formControlName="alternateMobileNumber" required />
                        <mat-error *ngIf="resignationForm.controls['alternateMobileNumber'].hasError('required')">
                            {{ 'mobile_number_required' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="resignationForm.controls['alternateMobileNumber'].hasError('invalidMobileNumber')">
                            {{ 'invalid_mobile_number' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="remark">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'remark' | translate }}</mat-label>
                        <input matInput type="text" formControlName="remark" (keypress)="validateCharacter($event)">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'exits_reason' | translate }}</mat-label>
                        <textarea matInput type="text" formControlName="reason" required
                            (keypress)="validateCharacter($event)"></textarea>
                        <mat-error *ngIf="resignationForm.get('reason')?.hasError('required')">
                            {{ 'resignation_reason_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)"
                        accept="image/jpeg, image/png, application/pdf, image/svg+xml">

                    <button type="button" (click)="fileInput.click()">Choose File</button>

                    <div *ngIf="selectedFileName">
                        {{ selectedFileName }}
                    </div>
                    <mat-error *ngIf="fileTypeError">
                        {{'invalid_file_type'|translate}}
                    </mat-error>
                    <mat-error *ngIf="fileSizeError">
                        {{'filename_too_long'|translate}}
                    </mat-error>
                </div>

                <div class="btns">
                    <button class="submit-button" type="button" (click)="submitResignation()">{{ 'submit' | translate
                        }}</button>
                    <button class="cancel-btn cancel-button" (click)="goBack()">{{ 'cancel' |translate }}</button>
                </div>
            </form>

            <div *ngIf="!isApplyResignation">
                <app-view-resignation></app-view-resignation>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'resignation_history' | translate }}">
            <div class="view-history">
                <div class='md-padding' layout="row" flex>
                    <div layout="row" layout-xs="column" flex>
                        <div class="employeeList">
                            <ag-grid-angular (gridReady)="onGridReady($event)" #agGride
                                style="height: 27rem; width:100%;" class="ag-theme-alpine"
                                [rowData]="employeeResignationData" [rowSelection]="'multiple'" [animateRows]="true"
                                [columnDefs]="empTableCol" [defaultColDef]="empColDef" [enableSorting]="true"
                                [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
                            </ag-grid-angular>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<app-loader></app-loader>