<app-toolbar></app-toolbar>
<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{ 'payrun_approval' | translate }}</h3>
    </div>

    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="payrunApprovalData" [tooltipShowDelay]="1" [tooltipHideDelay]="2000" [rowSelection]="'multiple'"
        [animateRows]="true" [columnDefs]="payrunApprovalTableCol" [defaultColDef]="payrunApprovalColDef"
        [enableSorting]="true" [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>