import { Component, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsForDeclaration } from 'src/app/model/employeeDetails';
import { SalaryService } from 'src/app/services/salary-details/salaryService';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TaxDeclarationServiceService } from 'src/app/services/tax-declaration-service/tax-declaration-service.service';


@Component({
  selector: 'app-view-tax-declaration',
  templateUrl: './view-tax-declaration.component.html',
  styleUrls: ['./view-tax-declaration.component.scss']
})
export class ViewTaxDeclarationComponent implements OnInit {

  employeeDetails: EmployeeDetailsForDeclaration | undefined;
  dataFound: boolean = false; 
  finalSubmits: any = null;
  constructor(private taxDeclarationService: TaxDeclarationServiceService, private router: Router, private datePipe: DatePipe, private employeeService: SalaryService) { }

  ngOnInit(): void {
    this.getEmployeeDetails();
    this.showDeclarationDetails();
  }

  getEmployeeDetails() {
    this.employeeService.getEmployeeDetails(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        const dto: EmployeeDetailsForDeclaration = {
          employeeName: data.data.employeeBasicDetail?.firstName + " " + data.data.employeeBasicDetail?.lastName,
          dateOfJoining: this.datePipe.transform(data.data.employeeBasicDetail?.doj, 'MM/dd/yyyy'),
          dateOfBirth: this.datePipe.transform(data.data.employeePersonalDetail?.dob, 'MM/dd/yyyy'),
          gender: data.data.employeeBasicDetail?.gender,
          pan: data.data.employeePersonalDetail?.panNo,
          employementStatus: data.data.employeeBasicDetail?.active
        }
        this.employeeDetails = dto;
      }
    )
  }

  toggleExpansionPanel(item: any): void {
    item.expanded = !item.expanded;
  }

  getInitials(name: string): any {
    if (name) {
      const nameArray = name.split(' ');
      if (nameArray.length >= 2) {
        return nameArray[0].charAt(0).toUpperCase() + nameArray[1].charAt(0).toUpperCase();
      } else if (nameArray.length === 1) {
        return nameArray[0].charAt(0).toUpperCase();
      } else {
        return '';
      }
    }
  }
  goBack() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

  showDeclarationDetails(): void {
    const employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);

    if (!employeeId) {
      console.error('Employee ID is missing from sessionStorage.');
      this.dataFound = false;
      return;
    }

    this.taxDeclarationService.getDeclaredInvestmentDetails(employeeId).subscribe(
      (data: any) => {
        if (data.status === 200 && data.data) {
          this.finalSubmits = data.data;
          this.dataFound = true;
        } else {
          this.finalSubmits = [];
          this.dataFound = false;
        }
      },
      (error) => {
        console.error('Error fetching declared investment details:', error);
        this.finalSubmits = [];
        this.dataFound = false;
      }
    );
  }
}
