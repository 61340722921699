import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { DepartmentData } from 'src/app/model/employeeDetails';
import { BranchService } from 'src/app/services/branch.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-view-all-branch',
  templateUrl: './view-all-branch.component.html',
  styleUrls: ['./view-all-branch.component.scss']
})
export class ViewAllBranchComponent {
  isEmployee: any;
  currentUserId = sessionStorage.getItem('role');
  branchColDef: any;
  BranchData!: DepartmentData[];
  error: any;
  isAddMode: boolean = true;
  companyId: number | null = Number(sessionStorage.getItem(AppConstant.COMPANY_ID));
  branchTableCol = [
    { field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'branchName', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'branchCode', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'addressLine1', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'registrationNo', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },

    { field: 'Action', cellRenderer: this.actionCellRenderer.bind(this) }
  ];

  constructor(
    private readonly renderer: Renderer2,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly toastrService: ToastrService,
    private readonly branchService: BranchService
  ) { }

  ngOnInit() {
    this.branchColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.getAllBranch();
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, colorClass: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      this.renderer.addClass(button, 'mat-icon-button');
      this.renderer.addClass(button, 'ag-grid-custom-button');
      this.renderer.addClass(button, colorClass);
      this.renderer.listen(button, 'click', clickHandler);
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.appendChild(cell, button);
    };
    createButton('edit', 'custom-black', () => this.editBranch(params.data));
    createButton('delete', 'custom-red', () => this.deleteBranch(params.data.id));
    return cell;
  }

  toggleAddPopup(): void {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ADD_BRANCH]);
  }

  editBranch(branch: any) {
    this.router.navigate([`${AppConstant.ADMIN}${AppConstant.SEPERATOR}${AppConstant.ADD_BRANCH}`, branch.id]);
  }


  deleteBranch(branchId: number) {
    this.branchService.deleteBranch(branchId).subscribe(
      (data) => {
        if (data.status === 200) {
          this.toastrService.success(data.message);
          this.BranchData = this.BranchData.filter(branch => branch.id !== branchId);
        } else {
          this.toastrService.error(data.message);
        }
      }
    );
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  getAllBranch() {
    if (this.companyId) {
      this.branchService.getAllBranch(this.companyId).subscribe((data: any) => {
        if (data.status === 200) {
          this.BranchData = data.data.map((branch: any) => ({
            branchName: branch.branchName,
            branchCode: branch.branchCode,
            addressLine1: branch.addressLine1,
            registrationNo: branch.registrationNo,
            id: branch.id
          }));
        }
      });
    }
  }
}
