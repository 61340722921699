<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{ 'list_of_branches' | translate }}</h3>
        <div class="setting__header_button_container">
            <button color="primary" (click)="toggleAddPopup()">{{ 'add_branch' | translate
                }}</button>
        </div>
    </div>
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="BranchData" [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="branchTableCol"
        [defaultColDef]="branchColDef" [enableSorting]="true" [enableFilter]="true" [pagination]="true"
        [paginationPageSize]="10">
    </ag-grid-angular>
</div>
