import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { API_END_POINTS } from '../../config/api-endpoint.config';
import { AddEmployeeInformationData, CreateEmployeeDetails, CreatePersonalInformationData, EmployeeDetails } from '../../model/employeeDetails';
import { AppConstant } from 'src/app/constants/app.constants';
import { PayRunGroupDto, PayRunGroupList } from 'src/app/model/payrun/payrun.model';
import { BankDetails } from 'src/app/model/bankDetails.model';

@Injectable({
  providedIn: 'root'
})

export class EmployeeDetailsService {


  constructor(private http: HttpClient) { }

  createEmployeeDetails(createEmployeeDetailsRequest: CreateEmployeeDetails): Observable<CreateEmployeeDetails> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_EMPLOYEE, createEmployeeDetailsRequest).pipe(
      map((res: any) => {
        return res;
      }));
  }

  addEmployeeDetails(createEmployeeDetailsRequest: AddEmployeeInformationData): Observable<AddEmployeeInformationData> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_EMPLOYEE, createEmployeeDetailsRequest).pipe(
      map((res: any) => {
        return res;
      }));
  }

  createPersonalInformation(createPersonalInformationRequest: CreatePersonalInformationData): Observable<CreatePersonalInformationData> {
    return this.http.post(environment.apiUrl + API_END_POINTS.PERSONAL_INFORMATION, createPersonalInformationRequest).pipe(map((res: any) => {
      return res;
    }));
  }

  getEmployeeById(companyId: number, employeeId: number): Observable<AddEmployeeInformationData> {
    return this.http.get<AddEmployeeInformationData>(environment.apiUrl + API_END_POINTS.PERSONINFORMATION + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => { return res.data })
    )
  }

  updateEmployee(updateEmployee: AddEmployeeInformationData): Observable<Object> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_EMPLOYEE + API_END_POINTS.SEPERATOR + sessionStorage.getItem(AppConstant.COMPANY_ID), updateEmployee).pipe(map((res: any) => {
      return res;
    }))
  }

  getAllBranches(): Observable<Object> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_BRANCH + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((allBranchesResponse: any) => {
        return allBranchesResponse
      })
    )
  }

  updateEmployeeDetails(dto: EmployeeDetails) {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_EMPLOYEE_DETAILS, dto).pipe(map((res: any) => {
      return res;
    }))
  }

  getManagerList(companyId: any): Observable<CreateEmployeeDetails> {
    return this.http.get<CreateEmployeeDetails>(environment.apiUrl + API_END_POINTS.MANAGER_LIST + companyId).pipe(map((res: any) => {
      return res.data;
    }))
  }

  getAllManagerByCompanyId(companyId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_MANAGER_BY_COMPANY_ID + companyId).pipe(map((res: any) => {
      return res;
    }))
  }

  getPayRunGroupList(companyId: any): Observable<Array<string>> {
    return this.http.get(environment.apiUrl + API_END_POINTS.PAY_RUN_GROUP_LIST + companyId).pipe(map((res: any) => {
      return res;
    }))
  }

  getActivePayRunGroupList(companyId: any): Observable<Array<string>> {
    return this.http.get(environment.apiUrl + API_END_POINTS.ACTIVE_PAY_RUN_GROUP_LIST + companyId).pipe(map((res: any) => {
      return res;
    }))
  }

  processToPayrun(dto: PayRunGroupList): Observable<PayRunGroupDto> {
    return this.http.post(environment.apiUrl + API_END_POINTS.PAY_RUN_GROUP_EMPLOYEE_LIST, dto).pipe(map((res: any) => {
      return res;
    }))
  }

  getAllActiveEmployee(isActive: any, companyId: string): Observable<any> {
    return this.http.get<AddEmployeeInformationData>(environment.apiUrl + API_END_POINTS.ALL_ACTIVE_EMPLOYEE + isActive + companyId).pipe(map((res: any) => {
      return res.data;
    }))
  }

  employeeData(companyId: string): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EMPLOYEE_DATA + companyId).pipe(map((res: any) => {
      return res;
    }))
  }


  getEmployeeList(type: String, companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_LIST + type + API_END_POINTS.PARAM_COMAPANY_ID + companyId).pipe(map((res: any) => {
      return res;
    }))
  }

  getAllEmployeeBasicDetailList(companyId: any): Observable<any> {
    return this.http.get<AddEmployeeInformationData>(environment.apiUrl + API_END_POINTS.GET_ALL_EMPLOYEE_LIST + companyId).pipe(map((res: any) => {
      return res;
    }))
  }

  getActiveEmployeeDetails(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_BASED_ON_COMPNAY_ID + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => { return (res.data) })
    );
  }

  getEmployeeSalaryDetails(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_SALARY_DETAILS + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => { return (res.data) })
    )
  }

  public deleteEmployee(companyId: any, id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_EMPLOYEE + companyId + AppConstant.SEPERATOR + id).pipe(
      map((res: any) => {
        return (res);
      }))
  }


  getActiveEmployeeSalaryDetails(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EMPLOYEE_SALARY_DETAILS + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return (res.data)
      }))
  }

  downloadSalarySlip(companyId: any, employeeId: any, year: any, month: any) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(`${environment.apiUrl + API_END_POINTS.DOWNLOAD_SALARY_SLIP + companyId + AppConstant.SEPERATOR + employeeId + AppConstant.SEPERATOR + year + AppConstant.SEPERATOR + month}`, httpOptions).pipe(
      map((res: any) => { return (res) })
    )
  }

  getEmployeeImage(employeeId: any, companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_IMG + employeeId + AppConstant.SEPERATOR + companyId).pipe(
      map((res: any) => { return res.data })
    )
  }

  getEmployeeNotification(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_NOTIFICATION + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => { return res.data })
    )
  }

  getAdminNotification(companyId: any, adminId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ADMIN_NOTIFICATION + companyId + AppConstant.SEPERATOR + adminId).pipe(
      map((res: any) => { return res.data })
    )
  }

  deleteNotification(companyId: any, id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_NOTIFICATION + companyId + API_END_POINTS.SEPERATOR + id).pipe(
      map((res: any) => { return res })
    )
  }

  deleteAllAdminNotification(companyId: any, employeeId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_ALL_ADMIN_NOTIFICATION + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => { return res })
    )
  }

  deleteAllEmployeeNotification(companyId: any, adminId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_ALL_EMPLOYEE_NOTIFICATION + companyId + AppConstant.SEPERATOR + adminId).pipe(
      map((res: any) => { return res })
    )
  }

  putEmployeeImage(companyId: any, employeeId: any, formData: FormData): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.PUT_EMPLOYEE_IMG + companyId + AppConstant.SEPERATOR + employeeId, formData).pipe(
      map((res: any) => { return res })
    );
  }

  getEmployeeProfileImage(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EMPLOYEE_PROFILE_IMAGE + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => { return res })
    );
  }

  addCategoryApi(categoryDto: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.SAVE_ADMIN_CATEGORY, categoryDto).pipe(
      map((res: any) => {
        return res
      }))
  }

  verifyIFSC(ifscCode: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.IFSC_API + ifscCode).pipe(
      (data: any) => {
        return data;
      })
  }

  employeeBasicDetails(employeeBasicDetails: CreateEmployeeDetails): Observable<CreateEmployeeDetails> {
    return this.http.post(environment.apiUrl + API_END_POINTS.EMPLOYEE_BASIC_DETAILS, employeeBasicDetails).pipe(
      map((res: any) => {
        return res;
      }))
  }

  employeePersonalDetails(personalDetails: CreatePersonalInformationData): Observable<CreatePersonalInformationData> {
    return this.http.post(environment.apiUrl + API_END_POINTS.EMPLOYEE_PERSONAL_DETAILS, personalDetails).pipe(
      map((res: any) => {
        return res;
      }))
  }

  employeeBankDetails(employeeBankDetails: BankDetails): Observable<BankDetails> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_BANK_DETAILS, employeeBankDetails).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addEmployeeSalaryDetails(employeeSalaryDetailsDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.EMPLOYEE_SALARY, employeeSalaryDetailsDto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  uploadEmployeeDocument(companyId: any, employeeId: any, formData: FormData, headers: HttpHeaders): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_EMPLOYEE_DOCUMENT + companyId + API_END_POINTS.SEPERATOR + employeeId, formData, { headers: headers })
      .pipe(map((res: any) => {
        return (res);
      }))
  }

  assignManagerApi(dto: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.ASSIGN_MANAGER, dto)
      .pipe(map((res: any) => {
        return (res);
      }))
  }

  getAllDocumentsById(employeeId: any): Observable<string[]> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_DOCUMENTS + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => { return res })
    );
  }

  getdocumentByIdAndFileName(employeeId: any, documentName: any): Observable<HttpResponse<Blob>> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_DOCUMENTS_BY_ID_NAME + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + employeeId + AppConstant.SEPERATOR + documentName, { observe: 'response', responseType: 'blob' as 'json' }).pipe(
      map((res: any) => { return res })
    );
  }

  getEmployeeLastThreeMonthData(companyId: any, empId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_LAST_THREE_MONTH_SALARY_DATA + companyId + AppConstant.SEPERATOR + empId).pipe(
      map((res: any) => { return (res.data) }));
  }

  getUploadBulkEmployee(): Observable<Blob> {
    return this.http.get(environment.apiUrl + API_END_POINTS.UPLOAD_BULK_EMPLOYEE, { responseType: 'blob' });
  }

  uploadBulkEmployeeData(uploadEmployee: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.UPLOAD_EMPLOYEE_BULK_DATA, uploadEmployee, { reportProgress: true, observe: 'events' }).pipe((res: any) => {
      return res;
    })
  }

  savePfDetails(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SAVE_PF_DETAILS, dto)
      .pipe(map((res: any) => {
        return (res);
      }))
  }

  updatePTDetails(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.UPDATE_PT_DETAILS, dto)
      .pipe(map((res: any) => {
        return (res);
      }));
  }

  portalAccessToggle(dto: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.PORTAL_ACCESS_TOGGLE_API, dto).pipe((res: any) => {
      return res;
    })
  }

  //Performance System Api's
  employeeListUnderManager(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EMPLOYEE_LIST_UNDER_MANAGER + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  employeeOrgHierarchy(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EMPLOYEE_ORG_HIERARCHY + companyId + API_END_POINTS.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  changeManager(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.CHNAGEMANAGER, dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addBankDetails(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_BANK_DETAIL, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateBankDetails(bankDetails: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_COMPANY_BANK_DETAILS, bankDetails).pipe(
      map((res: any) => {
        return res;
      })
    )

  }

}


