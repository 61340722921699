import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { LastSummeryData, SubData } from 'src/app/model/tax-declaration/tax-declaration.model';
import { TaxDeclarationServiceService } from 'src/app/services/tax-declaration-service/tax-declaration-service.service';

@Component({
  selector: 'app-view-section-details',
  templateUrl: './view-section-details.component.html',
  styleUrls: ['./view-section-details.component.scss']
})
export class ViewSectionDetailsComponent implements OnInit {
  @Input() data!: LastSummeryData;
  documentSubmit: Boolean = false;
  sampleData: SubData[] = [];
  showPopup: Boolean = false;
  selectedCategories: any = {};
  form!: FormGroup;

  constructor(private fb: FormBuilder, private stepper: MatStepper, private toastrService: ToastrService, private taxDeclarationSerice: TaxDeclarationServiceService) { }

  ngOnInit(): void {
    this.fetchExistingDeclarations();
  }

  fetchExistingDeclarations() {
    this.taxDeclarationSerice.getDeclaredInvestmentDetails(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (response: any) => {
        if (response && response.data && Array.isArray(response.data)) {
          const tempData = response.data.find((res: any) => res.name === this.data.name);
          if (tempData && Array.isArray(tempData.data)) {
            this.sampleData = tempData.data;
          } else {
            this.sampleData = this.data.data;
          }
        } else {
          this.sampleData = this.data.data;
        }
        this.initializeForm();
      },
      (error: any) => {
        this.toastrService.error("Failed to fetch existing declarations");
        this.sampleData = this.data.data;
        this.initializeForm();
      }
    );
  }


  initializeForm() {
    this.sampleData.forEach(category => this.selectedCategories[category.name] = true);
    this.form = this.fb.group({
      amounts: this.fb.array(this.sampleData.map(item => this.createItemFormGroup(item)))
    });
  }

  get amounts(): FormArray {
    return this.form.get('amounts') as FormArray;
  }

  createItemFormGroup(item: SubData): FormGroup {
    return this.fb.group({
      declaredAmount: [item.updatedAmount || null, [this.maxAmountValidator(item.amount)]]
    });
  }

  maxAmountValidator(max: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value !== null && !isNaN(value)) {
        const valueString = value.toString();
        if (valueString.length > 10) {
          return { maxDigitsExceeded: true };
        }
        if (value < 0) {
          return { negativeAmount: true };
        }
      }
      return null;
    };
  }

  preventNegativeInput(event: KeyboardEvent) {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  addCategoryPopup() {
    this.showPopup = true;
  }

  declarationAmount(event: any, declaration: SubData) {
    const inputValue = event?.target?.value;
    const subDataIndex = this.data.data.findIndex(resData => resData.name === declaration.name);
    if (subDataIndex !== -1) {
      this.data.data[subDataIndex].updatedAmount = inputValue;
      const sumUpdatedAmount = this.data.data.reduce((sum, item) => sum + (parseFloat(item.updatedAmount) || 0), 0);
      this.data.updatedAmount = sumUpdatedAmount;
    }
  }

  onFileSelected(event: any, item: any) {
    const file: File = event.target.files[0];
  }

  saveCategoryList() {
    this.sampleData = this.data.data.filter(category => this.selectedCategories[category.name]);
    this.showPopup = false;
    this.updateForm();
  }

  updateForm() {
    this.form.setControl('amounts', this.fb.array(this.sampleData.map(item => this.createItemFormGroup(item))));
  }

  cancel() {
    this.showPopup = false;
  }

  skipSection() {
    this.data.updatedAmount = 0;
    this.data.data.forEach(subData => subData.updatedAmount = 0);
    this.stepper.next();
  }

  saveSection(data: LastSummeryData) {
    if (this.form.valid) {
      const sampleUpdatedData: number = this.sampleData.reduce((total, subData) => total + subData.updatedAmount, 0);
      const updatedAmount = data.updatedAmount ?? 0;
      if (updatedAmount > 0 || sampleUpdatedData > 0) {
        this.taxDeclarationSerice.addSectionBySectionName(this.data).subscribe(
          (response: any) => {
            if (response.status === 200) {
              this.toastrService.clear();
              this.toastrService.success(response.message);
              this.stepper.next();
            } else {
              this.toastrService.clear();
              this.toastrService.error(response.message);
            }
          }
        );
      } else {
        this.toastrService.clear();
        this.toastrService.error("Please provide valid details");
      }
    }
  }

  goBack() {
    this.stepper.previous();
  }

  convertLabel(label: string): string {
    let formattedLabel = label.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedLabel = formattedLabel.replace(/([a-zA-Z\s])([0-9])/g, '$1 $2');
    return formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
  }
}
