<app-toolbar *ngIf="!isToolbarHidden"></app-toolbar>
<div class="main-container">
    <div class="header">
        <div class="back-button">
            <mat-icon matTooltip="{{'back_to_dashboard' | translate}}" class="back-arrow-icon" (click)="goToBack()"
                svgIcon="arrow-back-double" class="rewind"></mat-icon>
            <h3 class="headerName">{{ headingName |translate}}</h3>
        </div>
        <div class="mat-icon-div" *ngIf="updateView && !viewProject">
            <mat-icon class="icon_edit_button" *ngIf="editMode" (click)="editToggle()" matTooltip="Edit">edit</mat-icon>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="editMode">
        <div class="project-summary">
            <div *ngFor="let control of formControlsArray">
                <div class="summary-item"
                    *ngIf="getLabel(control) !== 'Upload Documents' && getLabel(control) !== 'Resource' && getLabel(control) !== 'Approver' ">
                    <label class="label-name">{{ getLabel(control) }} :</label>
                    <span
                        *ngIf="getLabel(control) !== 'Start Date' && getLabel(control) !== 'End Date' && getLabel(control) !== 'Project Budget' && getLabel(control) !== 'Approval' "
                        class="value">{{
                        control.value }}</span>
                    <span *ngIf="getLabel(control) === 'Start Date' || getLabel(control) === 'End Date'" class="value">
                        {{ formatDate(control.value) }}
                    </span>
                    <span *ngIf="getLabel(control) === 'Project Budget'" class="value">
                        {{ control.value | number: '1.2-2'}}
                    </span>
                    <span *ngIf="getLabel(control) === 'Approval'" class="value">
                        {{ getFormattedName(control.value) }}
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="updateView && !viewProject">
            <div class="approval-table" *ngIf="approverSelectedList.length > 0">
                <table>
                    <thead>
                        <tr>
                            <th>{{'id'|translate}}</th>
                            <th>{{'approver_name'|translate}}</th>
                            <th>{{'priority'|translate}}</th>
                            <th>{{'status'|translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let value of approverSelectedList; let i = index">
                            <td>{{ value.id }}</td>
                            <td>{{ value.name }}</td>
                            <td>{{ value.priority }}</td>
                            <td>{{ value.approvalStatus }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="button-div" *ngIf="viewProject">
            <button [disabled]="!showApprovalButton" (click)="reject()">
                {{'reject' |translate}}
            </button>
            <button [disabled]="!showApprovalButton" (click)="approve()">
                {{'approve' | translate}}
            </button>
        </div>
    </div>

    <div class="project-form" *ngIf="!editMode">
        <form [formGroup]="projectForm">

            <div class="project_row">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'project_initials'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" maxlength="3" (keypress)="validateCharacter($event)"
                        [readonly]="!editMode && updateView" formControlName="projectInitial" appUppercase>
                    <mat-error
                        *ngIf="projectForm.get('projectInitial')?.hasError('required') || projectForm.get('projectInitial')?.hasError('noOnlySpaces')">
                        {{ 'project_initials_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'project_name'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" maxlength="30"
                        (keypress)="validateCharacterNumberAndSpace($event)" formControlName="projectName">
                    <mat-error
                        *ngIf="projectForm.get('projectName')?.hasError('required') || projectForm.get('projectName')?.hasError('noOnlySpaces')">
                        {{ 'project_name_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'project_code'|translate}}</mat-label>
                    <input class="Input-field" maxlength="15"
                        (keypress)="characterNumberAndSpaceAndHyphenAllowed($event)" matInput type="text"
                        formControlName="projectCode">
                    <mat-error
                        *ngIf="projectForm.get('projectCode')?.hasError('required') || projectForm.get('projectCode')?.hasError('noOnlySpaces')">
                        {{ 'project_code_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'customer'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" maxlength="30"
                        (keypress)="validateCharacterNumberAndSpace($event)" formControlName="customer">
                    <mat-error
                        *ngIf="projectForm.get('customer')?.hasError('required') || projectForm.get('customer')?.hasError('noOnlySpaces')">
                        {{ 'customer_is_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="project-row">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'project_goal'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" maxlength="100"
                        (keypress)="validateCharacterNumberAndSpace($event)" formControlName="projectGoal">
                    <mat-error
                        *ngIf="projectForm.get('projectGoal')?.hasError('required') || projectForm.get('projectGoal')?.hasError('noOnlySpaces')">
                        {{ 'project_goal_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'objective'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" maxlength="100"
                        (keypress)="validateCharacterNumberAndSpace($event)" formControlName="objective">
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'deliverable'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" maxlength="50"
                        (keypress)="validateCharacterNumberAndSpace($event)" formControlName="deliverables">
                </mat-form-field>
            </div>

            <div class="project-row">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'project_manager' | translate }}</mat-label>
                    <mat-select formControlName="projectManager">
                        <mat-option *ngFor="let manager of projectManagers" [value]="manager.name">{{ manager.name
                            }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="projectForm.get('projectManager')?.hasError('required')">
                        {{ 'project_manager_is_required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'priority' | translate }}</mat-label>
                    <mat-select formControlName="priority">
                        <mat-option value="Low">{{ 'low'|translate }}</mat-option>
                        <mat-option value="Medium">{{ 'medium'|translate }}</mat-option>
                        <mat-option value="High">{{ 'high'|translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="projectForm.get('priority')?.hasError('required')">
                        {{ 'priority_is_required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'budget_type' | translate }}</mat-label>
                    <mat-select formControlName="budgetType">
                        <mat-option value="Monthly">{{ 'monthly'|translate }}</mat-option>
                        <mat-option value="Yearly">{{ 'yearly'|translate }}</mat-option>
                        <mat-option value="Project Duration">{{ 'project_duration_budget'|translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="projectForm.get('priority')?.hasError('required')">
                        {{ 'priority_is_required' | translate }}
                    </mat-error>
                </mat-form-field>
                <!-- Budget Field -->
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'budget' | translate }}</mat-label>
                    <input class="Input-field" matInput type="number" min="0" maxlength="10"
                        formControlName="projectBudget">
                    <mat-error *ngIf="projectForm.get('projectBudget')?.hasError('required')">
                        {{ 'budget_is_required' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!projectForm.get('projectBudget')?.hasError('required') && projectForm.get('projectBudget')?.hasError('notPositive')">
                        {{ 'please_enter_valid_amount' | translate }}
                    </mat-error>
                </mat-form-field>

                <!-- Currency Field -->
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'currency' | translate }}</mat-label>
                    <mat-select formControlName="currency" [disableRipple]="true" class="currency-dropdown">
                        <mat-select-trigger>
                            {{ projectForm.get('currency')?.value || ('select_currency' | translate) }}
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="currencySearchCtrl"
                                placeholderLabel="{{ 'search_currency' | translate }}"
                                noEntriesFoundLabel="{{ 'no_currency_found' | translate }}">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let currency of filteredCurrencies" [value]="currency.code">
                            {{ currency.code }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="project-row">

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'start_date' | translate }}</mat-label>
                    <input matInput [matDatepicker]="startPicker" formControlName="startDate" (dateChange)="onStartDateChange($event)" />
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                    <mat-error *ngIf="projectForm.get('startDate')?.hasError('required')">
                      {{ 'start_date_is_required' | translate }}
                    </mat-error>
                  </mat-form-field>
                  
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'end_date' | translate }}</mat-label>
                    <input matInput [matDatepicker]="endPicker" formControlName="endDate" [min]="minEndDate" />
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                    <mat-error *ngIf="projectForm.get('endDate')?.hasError('required')">
                      {{ 'end_date_is_required' | translate }}
                    </mat-error>
                  </mat-form-field>
                
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'duration' | translate }}</mat-label>
                    <input matInput formControlName="duration" [readonly]="true" />
                </mat-form-field>
            </div>

            <div class="project-row">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'risk' | translate }}</mat-label>
                    <textarea matInput formControlName="potentialRisk" rows="3"></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'communication_channel'|translate}}</mat-label>
                    <textarea matInput formControlName="communicationChannel" rows="3"></textarea>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'technology'|translate}}</mat-label>
                    <textarea matInput formControlName="technology" rows="3"></textarea>
                </mat-form-field>
            </div>

            <div class="project-row">
                <!-- <div class="documentUpload">
                    <label class="label">{{ 'project_requirement_document' | translate }}</label>
                    <input type="file" style="display: none" #fileInput multiple formControlName="uploadDocuments"
                        (change)="onChangeFileInput($event)" accept=".ppt, .pptx, .pdf, .doc, .docx, .xls, .xlsx, .csv"
                        required="*" />
                    <div>
                        <button class="button" (click)="fileInput.click()">
                            <span>{{ 'upload_document' | translate }}</span>
                        </button>
                        <div class="document-upload-messages">
                            <mat-error *ngIf="fileSizeError" class="logo-content">
                                {{ 'file_size_error_25mb' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div> -->

                <div class="documentUpload">
                    <label class="label">{{ 'project_requirement_document' | translate }}</label>
                    <input 
                      type="file" 
                      style="display: none" 
                      #fileInput 
                      multiple 
                      formControlName="uploadDocuments"
                      (change)="onChangeFileInput($event)" 
                      accept=".ppt, .pptx, .pdf, .doc, .docx, .xls, .xlsx, .csv,.zip" 
                    />
                    <div>
                      <button class="button" (click)="fileInput.click()">
                        <span>{{ 'upload_document' | translate }}</span>
                      </button>
                      <div class="document-upload-messages">
                        <mat-error *ngIf="documentRequiredError" class="logo-content">
                          {{ 'please_upload_project_document' | translate }}
                        </mat-error>
                        <mat-error *ngIf="fileSizeError" class="logo-content">
                          {{ 'file_size_error_25mb' | translate }}
                        </mat-error>
                      </div>
                    </div>
                  </div>

                <div class="resource-div">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'resources' | translate }}</mat-label>
                        <mat-select formControlName="resource" (selectionChange)="onResourceClick($event)">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="searchControl" [placeholderLabel]="'Search'"
                                    noEntriesFoundLabel="No matching resources found"
                                    [preventHomeEndKeyPropagation]="true">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let resource of filteredResources" [value]="resource.id">
                                {{ resource.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>

            <div class="project-row">
                <div *ngIf="selectedFiles.length > 0 && !updateView" class="selected-values-wrapper">
                    <div class="selected-values-container">
                        <div *ngFor="let value of selectedFiles" class="selected-value">
                            <span class="selected-value-name">{{ value.name }}</span>
                            <mat-icon class="cancel-icon" (click)="removeFile(value)">cancel</mat-icon>
                        </div>
                    </div>
                </div>

                 <div *ngIf="documentsNameList.length > 0 && updateView" class="selected-values-wrapper">
                    <div class="selected-values-container">
                        <div *ngFor="let value of documentsNameList" class="selected-value">
                            <span class="selected-value-name">{{ value }}</span>
                            <mat-icon class="cancel-icon" (click)="removeDocumentNameList(value)">cancel</mat-icon>
                        </div>
                    </div>
                </div>

                <div id="resource-container" *ngIf="resourceSelectedList.length > 0" class="selected-values-wrapper">
                    <div class="selected-values-container">
                        <div *ngFor="let value of resourceSelectedList" class="selected-value">
                            <span class="selected-value-name">{{ value.name }}</span>
                            <mat-icon class="cancel-icon" (click)="removeResource(value)">cancel</mat-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-row">
                <div>
                    <div class="holidays-list">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'holidays_list' | translate }}</mat-label>
                            <mat-select #select multiple formControlName="holidayList" class="select-mat-input">
                                <div class="select-all">
                                    <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                        (change)="selectAllProjectHolidays()">{{'select_all'|translate}}</mat-checkbox>
                                </div>
                                <mat-option (click)="selectProjectHolidays()" *ngFor="let holidays of projectHolidays"
                                    [value]="holidays"> {{holidays}} </mat-option>
                            </mat-select>
                            <mat-error *ngIf="projectForm.controls['holidayList'].hasError('required')">
                                {{ 'project_holidays_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="radio-button-div">
                    <span>{{'historical_project_code'|translate}}</span>
                    <mat-radio-group formControlName="historicalProjectCode"
                        aria-label="{{'select_an_option' | translate}}">
                        <mat-radio-button value="no" class="checkCircle">{{'no' | translate}}</mat-radio-button>
                        <mat-radio-button value="yes" class="checkCircle">{{'yes' | translate}}</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="projectForm.get('historicalProjectCode')?.hasError('required')">
                        {{ 'historical_project_code_is_required' | translate }}
                    </mat-error>
                </div>

                <div class="radio-button-div">
                    <span>{{'approval' | translate}}</span>
                    <mat-radio-group formControlName="approval" aria-label="{{'select_an_option' | translate}}">
                        <mat-radio-button value="notNeeded" class="checkCircle">{{'not_needed' |
                            translate}}</mat-radio-button>
                        <mat-radio-button value="direct" class="checkCircle">{{'direct' | translate}}</mat-radio-button>
                        <mat-radio-button value="hierarchy" class="checkCircle">{{'hierarchy' |
                            translate}}</mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="projectForm.get('approval')?.value === 'direct'" class="project-row">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'approver' | translate }}</mat-label>
                            <mat-select formControlName="approver" (selectionChange)="selectApprover($event)">
                                <mat-option *ngFor="let approver of approverList" [value]="approver.id">
                                    {{ approver.name }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="projectForm.get('approver')?.hasError('required')">
                                {{ 'approver_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="projectForm.get('approval')?.value === 'hierarchy'" class="project-row">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'approver' | translate }}</mat-label>
                            <mat-select formControlName="approver" (selectionChange)="selectApprover($event)">
                                <mat-option *ngFor="let approver of approverList" [value]="approver.id">
                                    {{ approver.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="projectForm.get('approver')?.hasError('required')">
                                {{ 'approver_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <mat-error *ngIf="projectForm.get('approval')?.hasError('required')">
                        {{ 'approval_is_required' | translate }}
                    </mat-error>
                </div>
            </div>

            <div class="approval-table"
                *ngIf="approverSelectedList.length > 0 && projectForm.get('approval')?.value === 'hierarchy'">
                <table>
                    <thead>
                        <tr>
                            <th>{{'id'|translate}}</th>
                            <th>{{'approver_name'|translate}}</th>
                            <th>{{'priority'|translate}}</th>
                            <th>{{'action'|translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let value of approverSelectedList; let i = index">
                            <td>{{ value.id }}</td>
                            <td>{{ value.name }}</td>
                            <td>{{ value.priority }}</td>
                            <td>
                                <button (click)="removeApproverFromList(i)">Remove</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="buttons">
                <button *ngIf="updateView" (click)="editToggle()" class="cancel-btn">{{'cancel'|translate}}</button>
                <button *ngIf="!updateView" class="cancel-btn" (click)="goBack()">{{'cancel'|translate}}</button>
                <button (click)="addProject()">{{ headingName |translate}}</button>
            </div>
        </form>
    </div>
</div>