import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss']
})
export class SupplierDetailsComponent implements OnInit {
  supplierName = new FormControl('');
  tooltipShowDelay: number = 0;
  tooltipHideDelay: number = 2000;
  pagination = true;
  paginationPageSize = 10;
  paginationPageSizeSelector = [10, 20, 50, 100];
  countryList: any;
  stateList: any;
  cityList: any;
  selectedState: string = "";
  selectedCity: string = "";
  loading: boolean = false;
  ifscCode: any;
  supplierDataList: any[] = [];
  supplierscolumnDefs: any = [
    { field: "supplierId", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "supplierName", filter: true, floatingFilter: true, sortable: true, tooltipField: "supplierName", flex: 1 },
    { field: "businessName", filter: true, floatingFilter: true, sortable: true, tooltipField: "businessName", flex: 1 },
    { field: "businessType", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "assetType", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "city", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "Action", cellRenderer: this.actionCellRenderer.bind(this) },
  ];

  suppliersrowData: any[] = []

  options: string[] = ['Kunal Chaudhari', 'Yashwant Patil', 'Akshay Badgujar'];

  filteredOption!: Observable<string[]>;

  requestAssetsSupplierName: string[] = [
    'Ramesh Ahuja',
    'Bhushan Patil',
    'Aditya Prajapat',
    'Abhishek Patil',
  ];

  requestAssets: string[] = [
    'Laptop',
    'Mouse',
    'Keyboard',
    'Monitor'
  ];

  constructor(private dialog: MatDialog, private employeeDetailsService: EmployeeDetailsService, private companyProfileService: CompanyProfileService, private formBuilder: FormBuilder, private personalInfoService: MasterDataService, private renderer: Renderer2, private router: Router, private assetManagemanetService: AssetManagementDashboardService, private toasterService: ToastrService,) { }

  ngOnInit(): void {
    this.supplierList();

    this.filteredOption = this.supplierName.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value || '')),
    );
  }

  addSupplier() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ADD_SUPPLIER_DETAILS]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ADD_SUPPLIER_DETAILS]);
    }
  }

  goToBack() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ASSET_MANAGEMENT]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ASSET_MANAGEMENT]);
    }
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', 'toolTipText');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const infoClickHandler = () => {
      this.router.navigate([`${AppConstant.VIEW_SUPPLIER_HISTORY}${params.data.supplierId}`], {
        queryParams: { type: "supplier" }
      });
    };

    const visibilityClickHandler = () => {
      this.router.navigate([AppConstant.VIEW_SUPPLIER_INFO + params.data.supplierId]);
    };

    const deleteClickHandler = () => {
      this.supplierDelete(params.data.supplierId);
    };

    createButton('info', '#59595b', infoClickHandler,);
    createButton('visibility', 'green', visibilityClickHandler);
    createButton('delete', 'red', deleteClickHandler);
    return cell;
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }


  supplierList() {
    this.assetManagemanetService.getAllSupplier().subscribe(
      (data: any) => {
        this.suppliersrowData = data.data;
      }
    );
  }

  supplierDelete(id: any) {
    this.assetManagemanetService.getSupplierDeleteById(id).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.supplierList();
          this.toasterService.clear();
          this.toasterService.success(data.message);
        }
        else {
          this.toasterService.clear()
          this.toasterService.error(data.message);
        }
      }
    );
  }
}
