<div class="container">
    
    <div class="main-container">
        <div class="resignation-table">
            <table class="table">
                <div>
                    <tr class="row">
                        <th>{{'applied_employee_name'|translate}}</th>
                        <th>{{'submission_date'| translate}}</th>
                        <th>{{'status'|translate}}</th>
                    </tr>
                    <tr class="row">
                        <td>{{ employeeName }}</td>
                        <td>{{ resignationDetails.submissionDate }}</td>
                        <td
                            [ngStyle]="{'color': (resignationDetails.status === 'Rejected') ? 'red' : (resignationDetails.status=== 'Pending') ? 'orange' : (resignationDetails.status === 'Approved') ? 'green' : ''}">
                            {{resignationDetails.status}}
                        </td>
                    </tr>
                </div>
                <div>
                    <tr class="row">
                        <th>{{'required_lwd'|translate}}</th>
                        <th>{{'tentative_lwd'|translate}}</th>
                        <th>{{'shortfall_days'|translate}}</th>
                    </tr>
                    <tr class="row">
                        <td>{{resignationDetails.lastWorkingDay}}</td>
                        <td>{{resignationDetails.tentativeLWD}}</td>
                        <td>{{resignationDetails.shortFallDays}}</td>
                    </tr>
                </div>
                <div>
                    <tr class="row">
                        <th>{{'alternate_mobile_number'|translate}}</th>
                        <th>{{'applying_to'|translate}}</th>
                        <th>{{'alternate_email_address'|translate}}</th>
                    </tr>

                    <tr class="row">
                        <td>{{resignationDetails.alternateMobileNumber}}</td>
                        <td>{{resignationDetails.managerName}}</td>
                        <td>{{resignationDetails.alternateEmail}}</td>
                    </tr>
                </div>
                <div class="reason">
                    <tr>
                        <th class="key">{{'reason'|translate}}</th>
                    </tr>
                    <tr>
                        <td class="value">{{resignationDetails.reason}}</td>
                    </tr>
                </div>
            </table>
            <div class="timeline">
                <div class="contents">
                    <div class="timeline-title"><b>{{'timeline'|translate}}</b></div>
                    <mat-divider class="timeline-divider"></mat-divider>
                    <div class="event">
                        <p>{{employeeName}}</p>
                        <p>{{resignationDetails.submissionDate}}</p>
                        <p><span class="approved">{{'status'|translate}} </span><span class="timeline-content"
                                [ngStyle]="{'color': (resignationDetails.status === 'Rejected') ? 'red' : (resignationDetails.status=== 'Pending') ? 'orange' : (resignationDetails.status === 'Approved') ? 'green' : ''}">({{resignationDetails.status}})</span>
                        </p>
                        <div class="arrow"><mat-icon>arrow_downward</mat-icon></div>
                    </div>
                    <div class="event">
                        <p>{{resignationDetails.managerName}}</p>
                        <p>{{resignationDetails.submissionDate}}</p>
                        <p><span class="approved">{{'status'|translate}} </span><span class="timeline-content"
                                [ngStyle]="{'color': (resignationDetails.managerStatus === 'Rejected') ? 'red' : (resignationDetails.managerStatus=== 'Pending') ? 'orange' : (resignationDetails.managerStatus === 'Approved') ? 'green' : ''}">({{resignationDetails.managerStatus}})</span>
                        </p>
                        <div class="arrow"><mat-icon>arrow_downward</mat-icon></div>
                    </div>
                    <div class="event">
                        <p>{{resignationDetails.hrName}}</p>
                        <p>{{resignationDetails.submissionDate}}</p>
                        <p><span class="approved">{{'status'|translate}} </span><span class="timeline-content"
                                [ngStyle]="{'color': (resignationDetails.hrStatus === 'Rejected') ? 'red' : (resignationDetails.hrStatus=== 'Pending') ? 'orange' : (resignationDetails.hrStatus === 'Approved') ? 'green' : ''}">({{resignationDetails.hrStatus}})</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isShowWithdraw" class="withdraw-container">
        <div class="withdraw-content">
            <div class="withdraw">
                {{ 'withdraw' | translate }}
            </div>
            <button class="withdraw-button" (click)="openWithdrawConfirmation()">
                {{ 'withdraw_button' | translate }}
            </button>
        </div>
    </div>
    <div *ngIf="showWithdrawConfirmation" class="withdraw-confirmation">
        <div class="confirmation-dialog">
            <p class="withdraw-title">{{ 'withdraw_title' | translate }}</p>
            <button class="yes-button" (click)="confirmWithdraw()">{{ 'yes' | translate }}</button>
            <button (click)="cancelWithdraw()" class="cancel-button">{{ 'cancel' | translate }}</button>
        </div>
    </div>
