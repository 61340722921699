import { FormBuilder } from '@angular/forms';
import { AppConstant } from 'src/app/constants/app.constants';
import { AppraisalServiceService } from 'src/app/services/apprisal-service/appraisal-service.service';
import { AddGoalsComponent } from '../add-goals/add-goals.component';
import { MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-employee-appraisal-view',
  templateUrl: './employee-appraisal-view.component.html',
  styleUrls: ['./employee-appraisal-view.component.scss']
})
export class EmployeeAppraisalViewComponent {
  isPanelOpen = true;
  id: any = "";
  empRating: any;
  isManager: any;
  emplpoyeeGoal: any;
  showAddGoalsButton: boolean = true;
  showPerformanceGoal: boolean = true;
  showBehavioralGoals: boolean = true;
  employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  managerViewEmpAppraisal: boolean = false;
  currentYear: any | undefined;
  reviewPeriods: number[] | undefined;
  category: any;
  behavioral = AppConstant.BEHAVIOUR;
  selectedYear: number;
  hideToolbar: boolean = false;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private formbuilder: FormBuilder,
    private appraisalService: AppraisalServiceService
  ) {
    this.isManager = sessionStorage.getItem(AppConstant.IS_MANAGER);
    const now = new Date();
    this.currentYear = now.getFullYear();
    this.selectedYear = this.currentYear;
    const doj = sessionStorage.getItem('doj');
    if (doj) {
      const dojYear = new Date(doj).getFullYear();
      this.reviewPeriods = this.generateReviewPeriods(dojYear, this.currentYear);
    } else {
      this.reviewPeriods = [this.currentYear - 1, this.currentYear];
    }
  }

  EmpRating: string[] = ['ADVANCED']
  url: any;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const id = params['id'];
      const isAppraisalUrl = this.router.url.startsWith('/approvals/appraisal/') && id;
      this.hideToolbar = isAppraisalUrl;
    });

    this.getEmployeePerformance();
    this.getEmployeeBehaviourGoal();

    this.isManager = sessionStorage.getItem(AppConstant.IS_MANAGER);
    if (this.isManager === 'true') {
      this.managerViewEmpAppraisal = true;
    }
    sessionStorage.setItem('showYearHistory', this.currentYear);
  }
  private generateReviewPeriods(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }
  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.url = event.target?.result;
      };
    }
  }

  private isDynamicIdValid(url: string): boolean {
    const regex = /^approvals\/appraisal\/\d+$/;
    return regex.test(url);
  }

  goToGoalPlan() {
    if (this.isManager == 'true') {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.GOAL_PLAN])
    } else {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.GOAL_PLAN])
    }
  }

  goToPerformanceGoalPlan() {
    if (this.isManager == 'true') {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN])
    } else {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN])
    }
  }

  back() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_APPRISAL])
  }

  downloadGoalsExcel(): void {
    this.appraisalService.downloadApprisalExcel(this.companyId).subscribe((data: any) => {
      const base64String = data.data.file;
      const binaryString = window.atob(base64String);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'goals.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    }, error => {
    });
  }

  addGoals(): void {
    this.category = sessionStorage.setItem(AppConstant.CATEGOERY, AppConstant.PERFORMANCE);
    const dialogRef = this.dialog.open(AddGoalsComponent, {
      width: '680px',
      maxHeight: '90vh',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      this.getEmployeePerformance();
    });
  }

  onYearSelected(event: any) {
    this.selectedYear = event.value;
    this.getEmployeePerformance();
    this.getEmployeeBehaviourGoal();
  }

  getEmployeePerformance() {
    this.appraisalService.getEmployeeGoalsCategory(this.companyId, this.employeeId, AppConstant.PERFORMANCE, this.selectedYear).subscribe(
      (data: any) => {
        this.emplpoyeeGoal = data.data;
        this.showPerformanceGoal = (this.emplpoyeeGoal == null || this.emplpoyeeGoal.length === 0);
      }
    );
  }

  getEmployeeBehaviourGoal() {
    this.appraisalService.getEmployeeGoalsCategory(this.companyId, this.employeeId, AppConstant.BEHAVIOUR, this.selectedYear).subscribe(
      (data: any) => {
        this.emplpoyeeGoal = data.data;
        this.showBehavioralGoals = (this.emplpoyeeGoal == null || this.emplpoyeeGoal.length === 0);
      }
    );
  }

  goToDashboard() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

  viewGoals() {
    sessionStorage.setItem(AppConstant.CATEGOERY, AppConstant.PERFORMANCE);
    if (sessionStorage.getItem(AppConstant.IS_MANAGER) === 'true') {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN])
    }
    else {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN]);
    }
  }

  addBehavioralGoals() {
    this.category = sessionStorage.setItem(AppConstant.CATEGOERY, AppConstant.BEHAVIOUR);
    const dialogRef = this.dialog.open(AddGoalsComponent, {
      width: '680px',
      maxHeight: '90vh',
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      this.getEmployeeBehaviourGoal();
    });
  }

  viewBbhavioralGoals() {
    sessionStorage.setItem(AppConstant.CATEGOERY, AppConstant.BEHAVIOUR);
    if (sessionStorage.getItem(AppConstant.IS_MANAGER) === 'true') {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN])
    }
    else {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN]);
    }
  }
}
