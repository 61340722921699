import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class CompanyStatisticsService {

  constructor(private http: HttpClient) { }

  getCompanyStatistics(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.COMPANY_STATISTICS).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
