import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { AttendanceData } from 'src/app/model/attendance/attendance/attendance.module';
import { AttendanceService } from 'src/app/services/attendance/attendance.service';

@Component({
  selector: 'app-view-employee-attendance',
  templateUrl: './view-employee-attendance.component.html',
  styleUrls: ['./view-employee-attendance.component.scss']
})
export class ViewEmployeeAttendanceComponent {
  public attendanceHistory: any;
  attendanceColDef: any;
  attendanceHistoryData: AttendanceData[] | undefined;
  constructor(private renderer: Renderer2, private router: Router, private attendanceService: AttendanceService, private route: ActivatedRoute) { }

  leaveHistoryTableCol = [
    { field: "id", width: 130, unSortIcon: true },
    { field: "firstName", width: 200, unSortIcon: true },
    { field: "lastName", width: 200, unSortIcon: true },
    { field: "workEmail", width: 300, unSortIcon: true },
    { field: "month", width: 150, unSortIcon: true },

    { field: "year", width: 150, unSortIcon: true },
    {
      field: "Action",
      cellRenderer: this.actionCellRenderer.bind(this),
      width: 350
    }
  ];

  ngOnInit() {
    this.getApplyAttendaceHistory();
  }
  goToDashboard() {
    if (sessionStorage.getItem(AppConstant.MANAGER_SIDENAV) === AppConstant.EMPLOYEE_ROLE) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE])
      sessionStorage.setItem(AppConstant.SHOW_EMPLOYEE_LEAVE, 'false');
      sessionStorage.removeItem(AppConstant.IS_MANAGER);
    }
    else (sessionStorage.getItem('login') === 'admin')
    ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE])
    : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

  getApplyAttendaceHistory() {
    sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION)
    this.attendanceService.getAttendanceHistory(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) == AppConstant.ADMIN ? null : sessionStorage.getItem(AppConstant.EMPLOYEE_ID), null).subscribe((data: any) => {
      this.attendanceHistoryData = data.data.map((row: {
        employeeBasicDetailsModel: any; id: { toString: () => string; }; firstName: string; workEmail: string; lastName: string; month: string; year: string
      }) => ({
        ...row,
        id: (row.employeeBasicDetailsModel.id),
        firstName: (row.employeeBasicDetailsModel.firstName),
        lastName: (row.employeeBasicDetailsModel.lastName),
        workEmail: (row.employeeBasicDetailsModel.workEmail),
        month: (row.month),
        year: (row.year)
      }));
      this.attendanceHistory = this.attendanceHistoryData;
    });
  }


  formatDate(startDate: string) {
    throw new Error('Method not implemented.');
  }


  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', 'toolTipText');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const viewIconClickHandler = () => {
      this.viewEmployeeDetails(params.data);
    };
    createButton('visibility', 'green', viewIconClickHandler);
    return cell;
  }

  viewEmployeeDetails(data: any): void {
    sessionStorage.setItem('managerView', '0');
    sessionStorage.setItem('viewAttendanceButton', 'false');
    const navigationExtras: NavigationExtras = {
      queryParams: {
        employeeId: data.id,
        month: data.month,
        year: data.year
      }
    };
    this.router.navigate([
      AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_SUBMITTED_ATTENDANCE + AppConstant.SEPERATOR + data.id
    ], navigationExtras);
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

}
