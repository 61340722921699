<app-toolbar *ngIf="isSettingSidebar"></app-toolbar>
<div class="main-container">

<div class="setting__main_container" *ngIf="!isSettingSidebar">
    <div class="setting__heading_container">
      <h3>{{ 'setup_appraisal_process' | translate }}</h3>
    </div>
  </div>
  
  <div class="horizontal-subscription-card" *ngIf="isSettingSidebar">
    <div class="subscription-header">
      <mat-icon svgIcon="arrow-back-double" (click)="goBack()"></mat-icon>
      <h3>{{ 'setup_appraisal_process' | translate }}</h3>
    </div>
  </div>
  

<mat-card class="mat-card" *ngIf="setAppraisalWindow">
    <mat-card-content>
        <form [formGroup]="ApprisalSetUpForm">
            <div>
                <div class="upload-container ">
                    <mat-form-field appearance="outline" class="review-period">
                        <mat-label>{{'review_period'|translate}}</mat-label>
                        <mat-select formControlName="year" (selectionChange)="onYearSelect($event.value)">
                            <mat-option [value]="previousYear">{{ previousYear }}
                                {{'review_period'|translate}}</mat-option>
                            <mat-option [value]="currentYear">{{ currentYear }}
                                {{'review_period'|translate}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="ApprisalSetUpForm.get('year')?.hasError('required')">
                            {{ 'year_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>


                    <input type="file" id="fileInput" (change)="selectFile($event)" style="display:none"
                        accept=".xlsx, .xls">
                    <button type="button" class="upload-button" (click)="triggerFileInput()">
                        <i class="material-icons">file_upload</i> {{'upload_excel_content'|translate}}
                    </button>
                    <mat-error *ngIf="fileError" class="select-file">{{'file_is_required'|translate}}</mat-error>
                    <div *ngIf="selectedFileName" class="selected-file-name"><span class="file-name">{{
                            selectedFileName }}</span></div>

                </div>
                <mat-divider></mat-divider>
                <div class="heading">
                    <span>
                        {{'select_window'|translate}}
                    </span>
                    <mat-radio-group [(ngModel)]="selectedOption" formControlName="periodType" class="radionButton">
                        <mat-radio-button value="yearly" (click)="option1()">{{ 'yearly' | translate
                            }}</mat-radio-button>
                        <mat-radio-button value="semiannual" class="Semiannual" (click)="option2()">{{ 'semiannual'
                            | translate }}</mat-radio-button>

                    </mat-radio-group>
                </div>
                <div *ngIf="showDateInput1" class="expansion-panel">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <h2 class="Heading">{{'annual_year'|translate}}</h2>
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="dateInput">
                                <span class="label">{{ 'annual_years' | translate }}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{ 'start_date' | translate }}</mat-label>
                                    <input matInput formControlName="startDate" [readonly]="true">
                                    <mat-error *ngIf="ApprisalSetUpForm.get('startDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{ 'end_date' | translate }}</mat-label>
                                    <input matInput formControlName="endDate" [readonly]="true">
                                    <mat-error *ngIf="ApprisalSetUpForm.get('endDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_setting'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker4" formControlName="goalSettingStartDate"
                                        [min]="minDate" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                    <mat-datepicker #picker4></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('goalSettingStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker5" formControlName="goalSettingEndDate"
                                        [min]="ApprisalSetUpForm.get('goalSettingStartDate')?.value" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                    <mat-datepicker #picker5></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('goalSettingEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_review'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker6" formControlName="goalReviewStartDate"
                                        [min]="ApprisalSetUpForm.get('goalSettingEndDate')?.value" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                    <mat-datepicker #picker6></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('goalReviewStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker7" formControlName="goalReviewEndDate"
                                        [min]="ApprisalSetUpForm.get('goalReviewStartDate')?.value" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
                                    <mat-datepicker #picker7></mat-datepicker>
                                    <mat-error *ngIf="ApprisalSetUpForm.get('goalReviewEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_rating'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker8" formControlName="goalRatingStartDate"
                                        [min]="ApprisalSetUpForm.get('goalReviewEndDate')?.value" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
                                    <mat-datepicker #picker8></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('goalRatingStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker9" formControlName="goalRatingEndDate"
                                        [min]="ApprisalSetUpForm.get('goalRatingStartDate')?.value" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
                                    <mat-datepicker #picker9></mat-datepicker>
                                    <mat-error *ngIf="ApprisalSetUpForm.get('goalRatingEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_rating_review'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker10"
                                        formControlName="goalRatingReviewStartDate"
                                        [min]="ApprisalSetUpForm.get('goalRatingEndDate')?.value" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
                                    <mat-datepicker #picker10></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('goalRatingReviewStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker11" formControlName="goalRatingReviewEndDate"
                                        [min]="ApprisalSetUpForm.get('goalRatingReviewStartDate')?.value"
                                        [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
                                    <mat-datepicker #picker11></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('goalRatingReviewEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'rating_release_date'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker12" formControlName="ratingReleaseStartDate"
                                        [min]="ApprisalSetUpForm.get('goalRatingReviewEndDate')?.value" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker12"></mat-datepicker-toggle>
                                    <mat-datepicker #picker12></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('ratingReleaseStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker13" formControlName="ratingReleaseEndDate"
                                        [min]="ApprisalSetUpForm.get('ratingReleaseStartDate')?.value" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker13"></mat-datepicker-toggle>
                                    <mat-datepicker #picker13></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('ratingReleaseEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div *ngIf="showDateInput" class="expansion-panel">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <h2 class="Heading">{{'first_half_year'|translate}}</h2>
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>


                            <div class="dateInput">
                                <span class="label">{{ 'first_half_of_year' | translate }}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{ 'start_date' | translate }}</mat-label>
                                    <input matInput formControlName="firstHalfStartDate" [readonly]="true"
                                        [min]="firstHalfMinDate" [max]="firstHalfMaxDate">

                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{ 'end_date' | translate }}</mat-label>
                                    <input matInput formControlName="firstHalfEndDate" [readonly]="true"
                                        [min]="firstHalfMinDate" [max]="firstHalfMaxDate">

                                    <mat-error *ngIf="ApprisalSetUpForm.get('firstHalfEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="dateInput">
                                <span class="label">{{'goal_setting'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker4"
                                        formControlName="firstHalfGoalSettingStartDate" [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                    <mat-datepicker #picker4></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfGoalSettingStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker5"
                                        formControlName="firstHalfGoalSettingEndDate"
                                        [min]="ApprisalSetUpForm.get('firstHalfGoalSettingStartDate')?.value"
                                        [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                    <mat-datepicker #picker5></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfGoalSettingEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_review'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker6"
                                        formControlName="firstHalfGoalReviewStartDate"
                                        [min]="ApprisalSetUpForm.get('firstHalfGoalSettingEndDate')?.value"
                                        [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                    <mat-datepicker #picker6></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfGoalReviewStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker7"
                                        formControlName="firstHalfGoalReviewEndDate"
                                        [min]="ApprisalSetUpForm.get('firstHalfGoalReviewStartDate')?.value"
                                        [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
                                    <mat-datepicker #picker7></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfGoalReviewEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_rating'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker8"
                                        formControlName="firstHalfGoalRatingStartDate"
                                        [min]="ApprisalSetUpForm.get('firstHalfGoalReviewEndDate')?.value"
                                        [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
                                    <mat-datepicker #picker8></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfGoalRatingStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker9"
                                        formControlName="firstHalfGoalRatingEndDate"
                                        [min]="ApprisalSetUpForm.get('firstHalfGoalRatingStartDate')?.value"
                                        [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
                                    <mat-datepicker #picker9></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfGoalRatingEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_rating_review'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker10"
                                        formControlName="firstHalfGoalRatingReviewStartDate"
                                        [min]="ApprisalSetUpForm.get('firstHalfGoalRatingEndDate')?.value"
                                        [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
                                    <mat-datepicker #picker10></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfGoalRatingReviewStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker11"
                                        formControlName="firstHalfGoalRatingReviewEndDate"
                                        [min]="ApprisalSetUpForm.get('firstHalfGoalRatingReviewStartDate')?.value"
                                        [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
                                    <mat-datepicker #picker11></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfGoalRatingReviewEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'rating_release_date'|translate}} </span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker12"
                                        formControlName="firstHalfRatingReleaseStartDate"
                                        [min]="ApprisalSetUpForm.get('firstHalfGoalRatingReviewEndDate')?.value"
                                        [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker12"></mat-datepicker-toggle>
                                    <mat-datepicker #picker12></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfRatingReleaseStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker13"
                                        formControlName="firstHalfRatingReleaseEndDate"
                                        [min]="ApprisalSetUpForm.get('firstHalfRatingReleaseStartDate')?.value"
                                        [max]="firstHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker13"></mat-datepicker-toggle>
                                    <mat-datepicker #picker13></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('firstHalfRatingReleaseEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div *ngIf="showDateInput" class="expansion-panel">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <h2 class="Heading">{{'second_half_of_year'|translate}}</h2>
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="dateInput">
                                <span class="label">{{'second_half_of_years'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput formControlName="secondHalfStartDate" readonly="true"
                                        [min]="secondHalfMinDate" [max]="secondHalfMaxDate">
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput formControlName="secondHalfEndDate" readonly="true"
                                        [min]="secondHalfMinDate" [max]="secondHalfMaxDate">
                                    <mat-error *ngIf="ApprisalSetUpForm.get('secondHalfEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_setting'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker16"
                                        formControlName="secondHalfGoalSettingStartDate" [min]="secondHalfMinDate"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker16"></mat-datepicker-toggle>
                                    <mat-datepicker #picker16></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfGoalSettingStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker17"
                                        formControlName="secondHalfGoalSettingEndDate"
                                        [min]="ApprisalSetUpForm.get('secondHalfGoalSettingStartDate')?.value"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker17"></mat-datepicker-toggle>
                                    <mat-datepicker #picker17></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfGoalSettingEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_review'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker18"
                                        formControlName="secondHalfGoalReviewStartDate"
                                        [min]="ApprisalSetUpForm.get('secondHalfGoalSettingEndDate')?.value"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker18"></mat-datepicker-toggle>
                                    <mat-datepicker #picker18></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfGoalReviewStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker19"
                                        formControlName="secondHalfGoalReviewEndDate"
                                        [min]="ApprisalSetUpForm.get('secondHalfGoalReviewStartDate')?.value"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker19"></mat-datepicker-toggle>
                                    <mat-datepicker #picker19></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfGoalReviewEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_rating'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker20"
                                        formControlName="secondHalfGoalRatingStartDate"
                                        [min]="ApprisalSetUpForm.get('secondHalfGoalReviewEndDate')?.value"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker20"></mat-datepicker-toggle>
                                    <mat-datepicker #picker20></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfGoalRatingStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker21"
                                        formControlName="secondHalfGoalRatingEndDate"
                                        [min]="ApprisalSetUpForm.get('secondHalfGoalRatingStartDate')?.value"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker21"></mat-datepicker-toggle>
                                    <mat-datepicker #picker21></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfGoalRatingEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'goal_rating_review'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker22"
                                        formControlName="secondHalfGoalRatingReviewStartDate"
                                        [min]="ApprisalSetUpForm.get('secondHalfGoalRatingEndDate')?.value"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker22"></mat-datepicker-toggle>
                                    <mat-datepicker #picker22></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfGoalRatingReviewStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker23"
                                        formControlName="secondHalfGoalRatingReviewEndDate"
                                        [min]="ApprisalSetUpForm.get('secondHalfGoalRatingReviewStartDate')?.value"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker23"></mat-datepicker-toggle>
                                    <mat-datepicker #picker23></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfGoalRatingReviewEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="dateInput">
                                <span class="label">{{'rating_release_date'|translate}}</span>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'start_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker24"
                                        formControlName="secondHalfRatingReleaseStartDate"
                                        [min]="ApprisalSetUpForm.get('secondHalfGoalRatingReviewEndDate')?.value"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker24"></mat-datepicker-toggle>
                                    <mat-datepicker #picker24></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfRatingReleaseStartDate')?.hasError('required')">
                                        {{ 'start_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label>{{'end_date'|translate}}</mat-label>
                                    <input matInput [matDatepicker]="picker25"
                                        formControlName="secondHalfRatingReleaseEndDate"
                                        [min]="ApprisalSetUpForm.get('secondHalfRatingReleaseStartDate')?.value"
                                        [max]="secondHalfMaxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker25"></mat-datepicker-toggle>
                                    <mat-datepicker #picker25></mat-datepicker>
                                    <mat-error
                                        *ngIf="ApprisalSetUpForm.get('secondHalfRatingReleaseEndDate')?.hasError('required')">
                                        {{ 'end_date_is_required' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <button mat-button class="uploadbutton" type="submit"
                    (click)="onSubmit()">{{'upload'|translate}}</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<mat-card class="mat-card" *ngIf="!setAppraisalWindow">
    <mat-form-field appearance="outline" class="select-period">
        <mat-label>{{ 'review_period' | translate }}</mat-label>
        <mat-select formControlName="year" [(value)]="selectedYear" (selectionChange)="onYearSelect($event.value)">
            <mat-option [value]="previousYear">{{ previousYear }} {{ 'review_period' | translate }}</mat-option>
            <mat-option [value]="currentYear">{{ currentYear }} {{ 'review_period' | translate }}</mat-option>
        </mat-select>
        <mat-error *ngIf="ApprisalSetUpForm.get('year')?.hasError('required')">
            {{ 'year_is_required' | translate }}
        </mat-error>
    </mat-form-field>


    <mat-divider></mat-divider>
    <table mat-table [dataSource]="displayedData" *ngIf="isYearly">

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> <b>{{'type'|translate}}</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef><b>{{'start_date'|translate}}</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef><b>{{'end_date'|translate}}</b></th>
            <td mat-cell *matCellDef="let element"> {{element.endDate}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <table mat-table [dataSource]="displaySemiAnnualData" *ngIf="!isYearly">

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> <b>{{'first_half'|translate}}</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef><b>{{'start_date'|translate}}</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef><b>{{'end_date'|translate}}</b></th>
            <td mat-cell *matCellDef="let element"> {{element.endDate}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-divider></mat-divider>
    <table mat-table [dataSource]="displaySemiAnnualSecondHalfData" *ngIf="!isYearly">

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> <b>{{'second_half'|translate}}</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef><b>{{'start_date'|translate}}</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef><b>{{'end_date'|translate}}</b></th>
            <td mat-cell *matCellDef="let element"> {{element.endDate}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
   <app-loader></app-loader>
</mat-card>


</div>