<app-toolbar></app-toolbar>
<div class="page-heading">
    <mat-icon matTooltip="{{'back_to_dashboard' | translate}}" (click)="goBack()" svgIcon="arrow-back-double"
        class="rewind"></mat-icon>
    <h3>{{'supplier_information'|translate}}</h3>
</div>
<div class="main-container">
    <mat-card class="card">
        <mat-card-title>
            <span class="name">{{'name'|translate}}:- {{supplierdata?.supplierName}}</span>
            <button mat-icon-button (click)="onEditMode()" [matTooltip]="notEditMode ? 'Edit' : 'Save'"
                [matTooltipDisabled]="false">
                <mat-icon id="employeeBasicDetailsIcon">
                    <ng-container *ngIf="notEditMode">edit</ng-container>
                    <ng-container *ngIf="!notEditMode">save</ng-container>
                </mat-icon>
            </button>
        </mat-card-title>

        <mat-divider></mat-divider>
        <mat-card-content>
            <div>
                <form [formGroup]="supplierInfo" class="supplier-info-form">
                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'business_name'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.businessName}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="fileds">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'business_name'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" formControlName="businessName"
                                        (keypress)="validateNumberAndSpaceAndHyphenAllowed($event)">
                                    <mat-error *ngIf="supplierInfo.get('businessName')?.hasError('required')">
                                        {{'business_name_is_required' |translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'asset_type'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.assetType}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="assetField">
                                <mat-form-field appearance="outline" class="input">
                                    <mat-label>{{'asset_type' | translate}}</mat-label>
                                    <mat-select formControlName="assetType">
                                        <mat-option [value]="supplierdata?.assetType">{{supplierdata?.assetType}}</mat-option>
                                        <mat-option *ngFor="let asset of assets" [value]="asset">{{asset}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="supplierInfo.get('assetType')?.hasError('required')">
                                        {{'asset_type_is_required' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'email'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.email}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="email-field">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'email'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" formControlName="email">
                                    <mat-error *ngIf="supplierInfo.get('email')?.hasError('required')">
                                        {{'email_is_required'|translate}}
                                    </mat-error>
                                    <mat-error *ngIf="supplierInfo.get('email')?.hasError('email')">
                                        {{'invalid_email'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'supplier_address_2'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.supplierAddress2}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="supplierAddress2">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'supplier_address_2'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" formControlName="supplierAddress2"
                                        (keypress)="addressVerification($event)">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'state'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.state}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="stateField">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'state'|translate}}</mat-label>
                                    <mat-select matInput formControlName="state" class="select-mat-input"
                                        required="true" (selectionChange)="getCity($event.value)">
                                        <mat-option *ngFor="let state of stateList;" [value]="state">
                                            {{ state }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="supplierInfo.controls['state'].hasError('required')">
                                        {{'state_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'pincode'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.pinCode}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="pincode">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'pincode'|translate}}</mat-label>
                                    <input class="input-field" maxlength="6" matInput type="text"
                                        formControlName="pincode">
                                    <mat-error *ngIf="supplierInfo.get('pincode')?.hasError('required')">
                                        {{'pincode_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'bank_name'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.bankName}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="bankname">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'bank_name'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" formControlName="bankName"
                                        (keypress)="validateNumberAndSpaceAndHyphenAllowed($event)">
                                    <mat-error *ngIf="supplierInfo.get('bankName')?.hasError('required')">
                                        {{'bank_name_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'ifsc_code'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.ifscCode}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="ifsccode">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'ifsc_code'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" formControlName="ifscCode">
                                    <mat-error *ngIf="supplierInfo.get('ifscCode')?.hasError('required')">
                                        {{'ifsc_code_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'supplier_name'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.supplierName}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="supplierName">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'supplier_name'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" formControlName="supplierName"
                                        (keypress)="validateNumberAndSpaceAndHyphenAllowed($event)">
                                    <mat-error *ngIf="supplierInfo.get('supplierName')?.hasError('required')">
                                        {{'supplier_name_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'business_type'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.businessType}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="businessType">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'supplier_name'|translate}}</mat-label>
                                    <mat-select formControlName="businessType">
                                        <mat-option>{{'none'|translate}}</mat-option>
                                        <div *ngFor="let business of businessTypes">
                                            <mat-option [value]="business">{{business}}</mat-option>
                                        </div>
                                    </mat-select>
                                    <mat-error *ngIf="supplierInfo.controls['businessType'].hasError('required')">
                                        {{'supplier_name_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'contact_number'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.contactNo}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="contactNo">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'contact_number'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" (keypress)="numberOnly($event)"
                                        maxlength="10" formControlName="contactNumber" required="*">
                                    <mat-error *ngIf="supplierInfo.get('contactNumber')?.hasError('required')">
                                        {{'contact_number_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'supplier_address'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.supplierAddress1}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="supplierAdd">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'supplier_address'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" formControlName="supplierAddress"
                                        (keypress)="addressVerification($event)">
                                    <mat-error *ngIf="supplierInfo.get('supplierAddress')?.hasError('required')">
                                        {{'supplier_address_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'country'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.country}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="countryField">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'country'|translate}}</mat-label>
                                    <mat-select matInput formControlName="country" class="select-mat-input"
                                        required="true" (selectionChange)="getState($event.value)">
                                        <mat-option *ngFor="let country of countryList;"
                                            [value]="country">{{country}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="supplierInfo.controls['country'].hasError('required')">
                                        {{'country_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'city'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.city}}</h4>
                            </div>
                            <div *ngIf="!notEditMode"class="city">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'city'|translate}}</mat-label>
                                    <mat-select matInput formControlName="city" class="select-mat-input"
                                        (selectionChange)="selectedCityName($event.value)">
                                        <mat-option *ngFor="let city of cityList;" [value]="city">
                                            {{ city }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'gst_number'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.gstNumber}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="gstnumberField">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'gst_number'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" formControlName="gstNumber">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="title">
                            <h4><strong>{{'account_number'|translate}}</strong> :- </h4>
                        </div>
                        <div class="value">
                            <div *ngIf="notEditMode">
                                <h4>{{supplierdata?.accountNo}}</h4>
                            </div>
                            <div *ngIf="!notEditMode" class="accountfield">
                                <mat-form-field class="input" appearance="outline">
                                    <mat-label>{{'account_number'|translate}}</mat-label>
                                    <input class="input-field" matInput type="text" formControlName="accountNumber">
                                    <mat-error *ngIf="supplierInfo.get('accountNumber')?.hasError('required')">
                                        {{'account_number_is_required'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </mat-card-content>
    </mat-card>
</div>