<h1 mat-dialog-title>Select Salary Type</h1>
<div mat-dialog-content>
  <mat-radio-group [(ngModel)]="selectedSalaryType">
    <mat-radio-button value="hourly">Hourly Payrun</mat-radio-button>
    <mat-radio-button value="regular">Regular Payrun</mat-radio-button>
  </mat-radio-group>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Cancel</button>
  <button mat-button [mat-dialog-close]="selectedSalaryType" cdkFocusInitial>Confirm</button>
</div>
