import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { Observable, ReplaySubject, of } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { PayRunService } from 'src/app/services/pay-run/pay-run.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { PayNowList, PayRunGroupDto, PayRunGroupList } from 'src/app/model/payrun/payrun.model';
import { GridApi } from 'ag-grid-community';
import { AppConstant } from 'src/app/constants/app.constants';
import { RegistrationService } from 'src/app/services/registration/registrationService';
import { MatDialog } from '@angular/material/dialog';
import { VerifyEmailComponent } from '../../verify-email/verify-email/verify-email.component';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { Messages } from 'src/app/constants/messages.constant';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Employees } from '../../admin/add-approver/add-approver.component';
import { Router } from '@angular/router';
import { BankAdviceDialogComponent } from 'src/app/shared/bank-advice-dialog/bank-advice-dialog.component';
import { SelectSalaryTypePopupComponent } from '../../select-salary-type-popup/select-salary-type-popup.component';

@Component({
  selector: 'app-pay-run',
  templateUrl: './pay-run.component.html',
  styleUrls: ['./pay-run.component.scss'],
  providers: [DatePipe],
})

export class PayRunComponent implements OnInit {
  selectFormControl = new FormControl();
  selectedValues = [];
  gridApi: GridApi | undefined;
  data: string[] = [];
  employeeData: any[] = [];
  empPaidStatus: any[] = [];
  allEmployeesPaidFlag: boolean = false;
  payRunGroupList!: any[];
  activePayRunGroupList!: any[];
  payRunHistoryData: any[] = [];
  selectedData: any[] = [];
  endDate: any;
  searching: boolean = false;
  payPeriod: any;
  payrun: boolean = true;
  isLoading: boolean = false;
  selectAllUnselectedChecked = false;
  selectAllSelectedChecked = false;
  count: any;
  employeeColDef: any;
  showOptions: boolean = false;
  searchControl: FormControl<any> = new FormControl<any>('');
  searchResults!: Observable<string[]>;
  filteredIssues!: string[];
  filteredResults: any;
  dropDownResults!: Observable<string[]>;
  employeeList: any;
  payRun!: FormGroup;
  employeeDetailsColDef: any;
  unselected!: any[];
  selected: any[] = [];
  pendingSelection: { [key: string]: boolean } = {};
  verifyOtp: boolean = false;
  showSubmitNow: boolean = false;
  showSubmitNowMessage !: string;
  payRundetailsData: any[] = [];
  approvalStatus: boolean = false;
  public filteredServerSide: ReplaySubject<Employees[]> = new ReplaySubject<Employees[]>(1);
  payRunType: string = 'regular';
  submitNowButtonStatus : boolean = true;

  constructor(private loader: LoaderService, private approvalService: ApprovalService, private payService: PayRunService, public dialog: MatDialog, private registrationService: RegistrationService, private employeeService: EmployeeDetailsService, private renderer: Renderer2, private datePipe: DatePipe, private fb: FormBuilder, private payRunService: PayRunService, private toastrService: ToastrService, private router: Router) {
    this.formDataValidate();
  }

  payRunDetailsColDef = [
    { headerName: 'Pay Run Id', field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'payRunGroups', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, },
    { field: 'payRunDate', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, },
    { field: 'status', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, },
    { field: "action", cellRenderer: this.payRunDetails.bind(this) }
  ];

  payrollHistoryDefs = [
    { headerName: 'Pay Run Id', field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'payRunDate', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'payRunGroups', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, tooltipValueGetter: (params: any) => `Pay Run Groups: ${params.value}` },
    { field: 'payrollType', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'details', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'status', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: "action", cellRenderer: this.downloadExcel.bind(this) }
  ];

  columnDefs = [
    { headerName: 'Emp Id', field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 70, valueGetter: (params: any) => 'EMP00' + params.data.id },
    { field: 'name', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 200 },
    { field: 'groupName', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 150 },
    { field: 'paidDays', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 120 },
    { field: 'grossPay', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 150, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    { headerName: 'Total Deduction', field: 'deduction', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 120, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    { field: 'taxes', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 100, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    { field: 'reimbursement', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 150, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    { headerName: 'Net Pay (Per Month)', field: 'netPay', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 130, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    {
      headerName: 'Paid in Current Cycle', field: 'paidInCurrentMonth', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 150
      , valueFormatter: (params: any) => {
        if (params.value) {
          return 'Paid';
        } else if (!params.value) {
          return 'Unpaid';
        }
        return '';
      }
    },
    { field: "action", cellRenderer: this.actionCellRenderer.bind(this), width: 200 }
  ];

  payRunDetails(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void, tooltipText: string) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'matTooltip', tooltipText);
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const viewPayrunEmployeeListClickHandler = () => {
      this.selectedData = [];
      this.selectedData = params.data.payRunGroups.split(',');
      this.getPayRunApprovalStatus(params.data.id);
      this.payrun = false;
      this.approvalStatus = params.data.status === 'Approved'
      this.processToPayrun();
      this.submitNowButtonStatus = params.data.status !== 'Success'
    };
    createButton('visibility', 'green', viewPayrunEmployeeListClickHandler, " ");
    return cell;
  }

  getPayRunApprovalStatus(PayRunId: any) {
    this.payRunService.getPayRunApprovalStatus(PayRunId).subscribe(
      (data: any) => {
        if (data.status === 404) {
          this.showSubmitNow = true;
          this.showSubmitNowMessage = data.message;
        } else {
          this.showSubmitNow = false;
        }
      }
    )
  }

  ngOnInit() {
    this.startAndEndDataOfCurrentMonth();
    this.getPendingPayRunDetailList();
    this.getPayRunGroupList();
    this.getActivePayRunGroupList();
    this.setEmployeeColDef();
    this.getPayrollHistory();
    this.searchControl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      debounceTime(300),
      map(search => search.toLowerCase()),
      takeUntil(this.data)
    ).subscribe(search => {
      this.filteredResults = this.data.filter((item: any) => item?.payRunGroupName.toLowerCase().includes(search));
      this.searching = false;
    });
    this.getAllEmployee();
  }

  getSubmitNow(): Boolean {
    if (this.employeeData.length > 0) {
      return true;
    } else if (this.showSubmitNow) {
      return true
    } else {
      return false;
    }
  }

  downloadExcel(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void, tooltipText: string) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'matTooltip', tooltipText);
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const downloadExcelIconClickHandler = () => {
      this.downloadExcelApi(params.data.id)
      if (this.gridApi) {
        this.gridApi.setRowData(this.payRunHistoryData);
      }
    };
    const viewPayrunEmployeeListClickHandler = () => {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.PAY_RUN_EMPLOYEE_LIST + AppConstant.SEPERATOR + params.data.id]);
    };
    createButton('visibility', 'green', viewPayrunEmployeeListClickHandler, " ");
    createButton('file_download', 'blue', downloadExcelIconClickHandler, " ");
    return cell;
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const deleteIconClickHandler = () => {
      if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
        this.employeeData = this.employeeData.filter((item: any) => item.id !== params.data.id);
      }
    };
    createButton('delete', 'red', deleteIconClickHandler);
    return cell;
  }

  getPayrollHistory() {
    this.payRunService.getPayrollHistory(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.payRunHistoryData = res.data;
        }
      }
    )
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  setEmployeeColDef() {
    this.employeeColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }

  getPayRunGroupList() {
    this.filteredIssues = [];
    this.data = [];
    this.employeeService.getPayRunGroupList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.data = res.data;
          this.payRunGroupList = res.data;
        }
      }
    );
  }


  getActivePayRunGroupList() {
    this.filteredIssues = [];
    this.data = [];
    this.employeeService.getActivePayRunGroupList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.data = res.data;
          this.activePayRunGroupList = res.data;
        }
      }
    );
  }

  startAndEndDataOfCurrentMonth() {
    this.count = 0;
    const today = new Date();
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.endDate = this.datePipe.transform(endDate, 'dd/MM/yyyy');
    const months = Messages.MONTHS;
    const monthIndex = today.getMonth();
    const year = today.getFullYear();
    this.payPeriod = `${months[monthIndex]} ${year}`;
  }

  downloadExcelApi(payRunId: any) {
    this.payRunService.getPayRunExcel(sessionStorage.getItem(AppConstant.COMPANY_ID), payRunId).subscribe(
      (res: any) => {
        if (res.status === 200) {
          const content = res.data.body;
          const byteCharacters = atob(content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const currentDate = new Date();
          const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          const fileDate = `${monthNames[currentDate.getMonth()]}_${currentDate.getFullYear()}`;
          const fileURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = `Payroll_${fileDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    )
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(VerifyEmailComponent, {
      width: '400px',
      data: { name: "" }
    });

    dialogRef.componentInstance.verifiedOTP.subscribe((result: boolean) => {
      if (result === true) {
        this.closePopup();
        this.employeeData = []
        this.payrun = true;
        this.count = 0;
        this.selectedData = [];
      }
    });
  }

  public closePopup(): void {
    this.dialog.closeAll();
  }

  createPayRun() {
    this.isLoading = true;
    this.loader.show();
    if (this.approvalStatus) {
      const employeeList: any[] = this.employeeData.map((employee: any) => {
        return {
          employeeId: employee.id,
          groupId: employee.groupId,
          netPay: employee.netPay
        }
      });
      const dto: PayNowList = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        employeeList: employeeList,
        payRunType : this.payRunType
      }
      sessionStorage.setItem('payRunList', JSON.stringify(dto));
      const dialogRef = this.dialog.open(BankAdviceDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'download') {
          const dto: PayNowList = JSON.parse(sessionStorage.getItem("payRunList") || "{}");
          this.payRunService.payNow(dto).subscribe(
            (data: any) => {
              if (data.status === 200) {
                this.isLoading = false;
                this.employeeData = []
                this.payrun = true;
                this.count = 0;
                this.selectedData = [];
                this.getPendingPayRunDetailList();
                this.toastrService.clear();
                this.toastrService.success(data.message);
              } else {
                this.toastrService.clear();
                this.toastrService.error(data.message);
              }
            }
          );
        }
      });
    }
    else {
      this.approvalService.getPayRunApproval().subscribe(
        (data: any) => {
          if (data.status === 200) {
            if (data.data.payRunApprovalType === 'Direct_Approval') {
              this.payNow();
            }
            else {
              this.sendToApprover();
            }
          }
          else {
            this.loader.hide();
            this.isLoading = false;
            this.toastrService.clear();
            this.toastrService.error(data.message);
          }
        }
      )
    }
  }

  getPendingPayRunDetailList() {
    this.payRunService.getPendingPayRunDetailList().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.payRundetailsData = data.data;
        }
      }
    )
  }

  sendToApprover() {
    const employeeList: any[] = this.employeeData.map((employee: any) => {
      return {
        employeeId: employee.id,
        groupId: employee.groupId
      }
    });
    const dto: PayNowList = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      employeeList: employeeList,
      payRunType: this.payRunType
    }
    this.approvalService.sendToApprover(dto).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.closePopup();
          this.employeeData = []
          this.payrun = true;
          this.count = 0;
          this.selectedData = [];
          this.loader.hide();
          this.isLoading = false;
          this.toastrService.clear();
          this.toastrService.success(data.message);
        } else {
          this.loader.hide();
          this.isLoading = false;
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    )
  }

  payNow() {
    this.registrationService.verifyEmailForPayrun(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200) {
        this.openDialog()
        const employeeList: any[] = this.employeeData.map((employee: any) => {
          return {
            employeeId: employee.id,
            groupId: employee.groupId,
            netPay: employee.netPay
          }
        });
        const dto: PayNowList = {
          companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
          employeeList: employeeList,
          payRunType: this.payRunType
        }
        sessionStorage.setItem('payRunList', JSON.stringify(dto));
        this.loader.hide();
        this.isLoading = false;
      }
    });
  }

  payRunToggle() {
    const dialogRef = this.dialog.open(SelectSalaryTypePopupComponent, {
      width: '300px',
      data: { message: 'You need to add at least one employee!' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.payRunType = result;
        this.processToPayrun();        
        if (this.employeeData && !this.checkAllEmployeePaidInGroup()) {
          this.payrun = false;
        } else {
          this.toastrService.clear();
          this.toastrService.error(AppConstant.ADD_ATLEAST_ONE_EMPLOYEE);
        }  
        if (this.checkAllEmployeePaidInGroup()) {
          this.allEmployeesPaidFlag = true;
        }
      }
    });
  }
  

  checkAllEmployeePaidInGroup() {
    this.employeeData.filter(employee => {
      this.empPaidStatus.push(employee.paidInCurrentMonth);
    });
    if(this.employeeData.length === 0){
      return false;
    }
    return this.empPaidStatus.every(status => {
      status == true
    });
  }

  processToPayrun() {
    this.employeeData = [];
    const dto: any = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      groupNameList: this.selectedData,
      payRunType: this.payRunType
    };
    this.employeeService.processToPayrun(dto).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.count = data.data.length;
          const flattenedData = data.data.flat();
          this.employeeData.push(...flattenedData);
          this.employeeData = this.removeDuplicates(this.employeeData);
          this.showSubmitNow = this.employeeData.every(employee => employee.paidInCurrentMonth);
          if (this.showSubmitNow) {
            this.showSubmitNowMessage = "This Employee salary has already been credited.";
          } else {
            this.showSubmitNowMessage = "";
          }
        }
      }
    );
  }

  removeDuplicates(data: any[]): any[] {
    const uniqueIds = new Set();
    return data.filter(item => {
      const isUnique = !uniqueIds.has(item.id);
      if (isUnique) {
        uniqueIds.add(item.id);
      }
      return isUnique;
    });
  }

  removeSelectedValue(value: string) {
    const index = this.selectedData.indexOf(value);
    if (index !== -1) {
      this.selectedData.splice(index, 1);
      if (this.selectedData.length === 0) {
        this.count = 0;
      } else {
        this.processToPayrun();
      }
    }
  }

  setSelectedValues() {
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((e: never) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  searchGroup(query: string): Observable<string[]> {
    const groupList: string[] = this.filteredIssues;
    const filteredGroupList = groupList.filter(groupList =>
      groupList.toLowerCase().includes(query.toLowerCase())
    );
    if (filteredGroupList.length > 0) {
      this.showOptions = true;
    }
    return of(filteredGroupList.slice(0, 9));
  }

  onOptionClick(group: string) {
    if (!this.selectedData.includes(group)) {
      this.selectedData.push(group);
    }
    this.processToPayrun()
    this.showOptions = false;
    this.searchControl.reset();
  }

  formDataValidate() {
    this.payRun = this.fb.group({
      'payrunName': ['', [Validators.required]],
    });
  }

  characterNumberAndSpaceAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  goBack() {
    this.employeeData = []
    this.payrun = true;
    this.count = 0;
    this.selectedData = [];
  }
  backToHome() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    }
  }
  getAllEmployee() {
    this.employeeService.getAllEmployeeBasicDetailList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.employeeList = data.data;
        this.unselected = this.employeeList;
      }
    )
  }

  createPayRunApi() {
    if (this.payRun.get('payrunName')?.value !== null && this.payRun.get('payrunName')?.value !== '') {
      if (this.selected.length !== 0) {
        const selectedIds = this.selected.map(employee => employee.id);
        const dto: PayRunGroupDto = {
          companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
          employee: selectedIds,
          payRunGroupName: this.payRun.get('payrunName')?.value
        }
        this.payService.groupByPayRun(dto).subscribe(
          (data: any) => {
            if (data.status === 200) {
              this.payRun.reset();
              this.toastrService.clear();
              this.toastrService.success(data.message);
              this.getPayRunGroupList();
              window.location.reload();
            }
            else {
              this.toastrService.clear();
              this.toastrService.error(data.message);
            }
          }
        )
        this.selected = [];
      }
      else {
        this.toastrService.clear();
        this.toastrService.error(AppConstant.ADD_ATLEAST_ONE_EMPLOYEE);
      }
    }
  }

  createPayRunGroup() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.PAY_RUN_GROUP]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.PAY_RUN_GROUP]);
    }
  }
}

