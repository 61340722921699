import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { HelpDeskService } from 'src/app/services/help-desk/help-desk.service';

@Component({
  selector: 'app-help-desk',
  templateUrl: './help-desk.component.html',
  styleUrls: ['./help-desk.component.scss']
})
export class HelpDeskComponent implements OnInit {
  employeeDetails: any;
  headingName: any;
  adminRole: boolean = false;

  constructor(private helpDeskService: HelpDeskService, private router: Router) { }

  ngOnInit(): void {
    this.getRole();
  }

  getRole() {
    this.helpDeskService.getRole().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.employeeDetails = data.data;
          this.headingName = this.employeeDetails.companyDetailsModel.companyName;
          this.adminRole = this.employeeDetails.roleModels.includes('Support');
        }
      }
    )
  }

  incident() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.INCIDENT_REQUEST]);
  }

  viewTicket() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_REQUESTOR_INFORMATION]);
  }

  resolution() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.RESOLUTION]);
  }

  request() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.REQUESTOR_INFORMATION]);
  }

  goToDashboard() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

}