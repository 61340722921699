
<app-navbar *ngIf="!loginEmail || loginEmail !== 'admin@iwexe.com'"></app-navbar>

<div [class.update-subscription]="!displaySubscription">
  <h3 class="heading"  *ngIf="loginEmail !== 'admin@iwexe.com'">{{ 'subscriptions_plan' | translate }}</h3>

  <div class="update-subscription-container" *ngIf="!displayNavbar && !displaySubscription">
    <mat-icon svgIcon="arrow-back-double" (click)="goBack()"></mat-icon>
    <h3 class="update">{{ 'update_subscriptions' | translate }}</h3>
  </div>

  <div *ngIf="displaySubscription" class="add-subscription">
    <h3 class="subscription-title">{{ 'subscriptions' | translate }}</h3>
    
    <button *ngIf="loginEmail === 'admin@iwexe.com'" class="button subscription" color="primary"
      (click)="addSubscription()">
      {{ 'create_subscription' | translate }}
    </button>
  </div>

  <div *ngIf="loginEmail === 'admin@iwexe.com'" class="add-subscription">
    <h3 class="subscription-title">{{ 'subscriptions' | translate }}</h3>
    
    <button  class="button subscription" color="primary"
      (click)="addSubscription()">
      {{ 'create_subscription' | translate }}
    </button>
  </div>

  <div *ngIf="displaySubscription" class="add-subscription">
    <h3 class="subscription-title">{{ 'subscriptions' | translate }}</h3>
  </div>

  <div class="subscription-cards-container">
    <table class="subscription-table">
      <thead class="head">
        <tr>
          <th rowspan="2"><b>{{'features'|translate}}</b></th>
          <!-- <th rowspan="2" *ngIf="freeSubscription">Free</th> -->
          <th [attr.colspan]="getColspan()" *ngIf="subscriptionPlans.length > 0" scope="col">
            <b>{{'annual_plans'|translate}}</b></th>
        </tr>
        <tr>
          <th [class]="'column-' + i" *ngFor="let plan of subscriptionPlans; let i = index">
            {{ plan.subscriptionName }}
          </th>
        </tr>
      </thead>
      <tr>
        <td class="features-list"></td>
        <td *ngFor="let plan of subscriptionPlans">{{'inr'|translate}} {{ plan.price }}/-</td>
      </tr>
      <tr>
        <td class="features-list">{{'period'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">{{ plan.validityDays }} {{'day'|translate}}</td>
      </tr>
      <tr>
        <td class="features-list">{{'number_of_employees'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">{{'upto'|translate}} {{ plan.totalUser }}</td>
      </tr>
      <tr>
        <td class="features-list">{{'employee_attendance'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.attendance" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.attendance" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>
      <tr>
        <td class="features-list">{{'leave_management'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.leaveManagement" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.leaveManagement" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>
      <tr>
        <td class="features-list">{{'payrunapplication'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.payRun" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.payRun" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>

      <tr>
        <td class="features-list">{{'project_management'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.projectManagement" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.projectManagement" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>

      <tr>
        <td class="features-list">{{'employee_onbording'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.employeeOnboarding" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.employeeOnboarding" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>

      <tr>
        <td class="features-list">{{'tax_declaration'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.taxDeclaration" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.taxDeclaration" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>

      <tr>
        <td class="features-list">{{'asset_management'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.assetManagement" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.assetManagement" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>


      <tr>
        <td class="features-list">{{'helpdesk'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.helpDesk" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.helpDesk" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>

      <tr>
        <td class="features-list">{{'training_management'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.trainingManagement" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.trainingManagement" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>

      <tr>
        <td class="features-list">{{'performance_management'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.performanceManagement" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.performanceManagement" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>


      <tr>
        <td class="features-list">{{'it_declaration'|translate}}</td>
        <td *ngFor="let plan of subscriptionPlans">
          <mat-icon *ngIf="plan.itdeclaration" class="tick-icon">check_circle</mat-icon>
          <mat-icon *ngIf="!plan.itdeclaration" class="cancel-icon">cancel</mat-icon>
        </td>
      </tr>

      <tr *ngIf="!loginEmail || loginEmail !== 'admin@iwexe.com'">
        <td></td>
        <td *ngFor="let plan of subscriptionPlans">
          <button class="button" (click)="subscribe(plan)">
            {{ plan.price === 0 ? 'Free' : 'Buy Now' }}
          </button>
        </td>
      </tr>
    </table>
    <mat-chip-set *ngIf="!displaySubscription" class="contact-details">
      <mat-chip>
        <span *ngIf="!displaySubscription" class="text-decorate">
          {{ 'for_more_discount' | translate }}
          <i><b>admin@iwexe.com</b></i>
        </span>
      </mat-chip>
    </mat-chip-set>
  </div>
</div>
<app-loader></app-loader>