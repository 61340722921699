
<div class="coming-soon-container">

  <div class="header">
    <h2>{{'comming_soon'|translate}}</h2>
    <p>{{'comming_soon_content'|translate}}</p>
  </div>

  <div class="applications">
    <div class="application">
      <div class="icon">👩‍💻</div>
      <div class="details">
        <h2>{{'employee_monitoring'|translate}}</h2>
        <p>{{'track_application'|translate}}</p>
      </div>
    </div>
    <div class="application">
      <div class="icon">🌍</div>
      <div class="details">
        <h2>{{'wordwide'|translate}}</h2>
        <p>{{'connect_globally'|translate}}</p>
      </div>
    </div>
    <div class="application">
      <div class="icon">📱</div>
      <div class="details">
        <h2>{{'mobile_apps'|translate}}</h2>
        <p>{{'access_all'|translate}}</p>
      </div>
    </div>
  </div>
</div>