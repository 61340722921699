import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AccountType } from 'src/app/model/bankDetails.model';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.scss']
})
export class AddSupplierComponent implements OnInit {
  supplierForm !: FormGroup;
  isLaptop: Boolean = false;
  isLoadingForVerifyGST: boolean = false;
  isLoadingForVerifyIFSC: boolean = false;
  isEditMode: boolean = false;
  errorMessage: any;
  registrationDate: boolean = false;
  data: any;
  selectedCountry: string = '';
  verifiedIFSC: boolean = false;
  countryList: any;
  stateList: any;
  selectedState: any;
  cityList: any;
  selectedCity: any;
  showInvalidGSTError: boolean = false;
  verifiedGST: boolean = false;
  hidePassword: boolean = true;
  hasGSTNumber: boolean = true;
  ifscCode: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);

  allAssetTypeList: any[] = [];

  assets: string[] = ['Laptop', 'Mouse', 'Keyboard', 'Monitor'];
  accountType: AccountType[] = [
    { accountType: AppConstant.SALARY, selectedAccount: AppConstant.SALARY },
    { accountType: AppConstant.CURRENT, selectedAccount: AppConstant.CURRENT },
    { accountType: AppConstant.SAVING, selectedAccount: AppConstant.SAVING }
  ];
  allSelected: boolean = false;
  businessTypes: string[] = ['Proprietorship', 'Sole proprietorship', 'Partnership', 'Service businesses',
    'Retailer', 'Dealer', 'Distributor'];

  constructor(private addAssetService: AssetManagementDashboardService,private router: Router, private masterData: MasterDataService, private companyProfileService: CompanyProfileService, private employeeDetailsService: EmployeeDetailsService, private assetManagemanetService: AssetManagementDashboardService, private toasterService: ToastrService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getAllAssetType();
    this.supplierForm = this.formBuilder.group({
      gstNumber: [{ value: '', disabled: this.hasGSTNumber }],
      supplierName: ['', [Validators.required]],
      businessName: ['', [Validators.required, Validators.maxLength(30)]],
      businessType: ['', [Validators.required]],
      assetTypes: ['', [Validators.required]],
      contactNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      supplierAddress: ['', [Validators.required]],
      supplierAddress2: ['',],
      branch: ['', [Validators.required]],
      city: ['',],
      state: ['', [Validators.required]],
      pinCode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      bankAccountNo: ['', [Validators.required]],
      confirmBankAccountNo: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      ifscCode: ['', [Validators.required]],
    });

    this.supplierForm.get('assetTypes')?.valueChanges.subscribe
      ((value: any) => {
        if (value === 'Laptop') {
          this.isLaptop = true;
        }
        else {
          this.isLaptop = false;
        }
      });

    this.hasGSTNumber = this.supplierForm.controls['gstNumber'].enabled;
    this.getCountryList();
  }

  submit() {
    const accountNumber = this.supplierForm.value.bankAccountNo;
    const confirmAccountNumber = this.supplierForm.value.confirmBankAccountNo;
    if (accountNumber !== confirmAccountNumber) {
      this.supplierForm.get('confirmBankAccountNo')?.setErrors({ 'mismatch': true });
    } else {
      this.supplierForm.get('confirmBankAccountNo')?.setErrors(null);
    }
    if (this.supplierForm.valid) {
      const dto = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        supplierName: this.supplierForm.value.supplierName,
        businessName: this.supplierForm.value.businessName,
        businessType: this.supplierForm.value.businessType,
        assetTypes: this.supplierForm.value.assetTypes,
        contactNo: this.supplierForm.value.contactNumber,
        emailId: this.supplierForm.value.email,
        supplierAddress: this.supplierForm.value.supplierAddress,
        supplierAddress2: this.supplierForm.value.supplierAddress2,
        city: this.supplierForm.value.city,
        state: this.supplierForm.value.state,
        pinCode: this.supplierForm.value.pinCode,
        country: this.supplierForm.value.country,
        gstNumber: this.supplierForm.value.gstNumber,
        bankName: this.supplierForm.value.bankName,
        accountNo: this.supplierForm.value.confirmBankAccountNo,
        branch: this.supplierForm.value.branch,
        ifscCode: this.supplierForm.value.ifscCode,
      };
      this.assetManagemanetService.addSupplier(dto).subscribe(
        (data: any) => {
          if (data.status == 200) {
            this.goToBack();
            this.toasterService.clear();
            this.toasterService.success(data.message);
          }
          else {
            this.toasterService.clear()
            this.toasterService.error(data.message);
          }
        });
    }
  }

  goToBack() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.SUPPLIER_DETAILS])
  }


  getAllAssetType() {
    this.addAssetService.getActiveAssetTypeList(this.companyId).subscribe((data: any) => {
      if (data.status === 200) {
        const assetTypesSet = new Set<string>(); 
        data.data.forEach((item: any) => {
          if (item.assetType) {
            item.assetType.split(',').forEach((type: string) => {
              assetTypesSet.add(type.trim());
            });
          }
        });
  
        this.allAssetTypeList = Array.from(assetTypesSet); 
      }
    });
  }
  
  toggleAllAssetTypes() {
    if (this.allSelected) {
      // Select all assets
      this.supplierForm.controls['assetTypes'].setValue([...this.assets]);
    } else {
      // Deselect all assets
      this.supplierForm.controls['assetTypes'].setValue([]);
    }
  }

  toggleAssetSelection() {
    const selectedAssets = this.supplierForm.controls['assetTypes'].value || [];
    this.allSelected = selectedAssets.length === this.assets.length;
  }


  changeDisable() {
    if (this.supplierForm.controls['gstNumber'].disabled) {
      this.supplierForm.controls['gstNumber'].enable();
      this.supplierForm.controls['gstNumber'].setValidators(Validators.required);
    } else {
      this.supplierForm.controls['gstNumber'].disable();
      this.supplierForm.controls['gstNumber'].clearValidators();
    }
    this.supplierForm.controls['gstNumber'].updateValueAndValidity();
    this.hasGSTNumber = !this.hasGSTNumber;
  }

  alphanumberOnly(event: any) {
    return ValidationUtil.alphanumberOnly(event);
  }

  gstNumberUpdated(event: any) {
    this.verifiedGST = false;
  }

  verifyGST() {
    this.registrationDate = true
    if (this.supplierForm.value.gstNumber != null && this.supplierForm.value.gstNumber != " " && ValidationUtil.validateGST(this.supplierForm.value.gstNumber)) {
      this.isLoadingForVerifyGST = true;
      sessionStorage.setItem(AppConstant.GST_NUMBER, this.supplierForm.value.gstNumber)
      this.companyProfileService.getGst(this.supplierForm.value.gstNumber).subscribe((data: any) => {
        this.isLoadingForVerifyGST = false;
        if (data.flag == false) {
          this.isLoadingForVerifyGST = false;
        } else {
          this.data = data;
          this.selectedCountry = "India";
          this.supplierForm.get('country')?.setValue(this.selectedCountry);
          this.getState(this.selectedCountry);
          this.selectedState = this.data.data.pradr?.addr?.stcd?.trim();
          this.supplierForm.get('state')?.setValue(data.data.pradr?.addr?.stcd.trim());
          this.getCity(this.selectedState);
          this.selectedCity = this.data.data.pradr?.addr?.dst?.trim();
          this.supplierForm.get('city')?.setValue(data.data.pradr?.addr?.dst.trim());
          if (data.data.gstNumber != null || data.data.gstNumber != '') {
            this.verifiedGST = true;
          }
          this.supplierForm.patchValue({
            businessName: this.data.data.tradeNam.trim(),
            businessType: this.data.data.ctb.trim(),
            country: this.selectedCountry.trim(),
            state: this.selectedState.trim(),
            city: this.selectedCity.trim(),
            supplierAddress: this.data.data.pradr?.adr.trim(),
            supplierAddress2: this.data.data.pradr?.addr?.bnm.trim(),
            gstNumber: this.supplierForm.value.gstNumber,
            pinCode: this.data.data.pradr?.addr?.pncd.trim(),
            supplierName: this.data.data.lgnm.trim()
          });
        }
        if (data.flag) {
          this.showInvalidGSTError = false;
          this.supplierForm.controls['gstNumber'].setErrors(null);
          this.verifiedGST = true;
        } else {
          this.showInvalidGSTError = true;
          this.supplierForm.controls['gstNumber'].setErrors({ 'invalidGST': true });
        }
      });
    } else {
      this.showInvalidGSTError = true;
      this.supplierForm.controls['gstNumber'].setErrors({ 'invalidGST': true });
    }
  }

  getCountryList() {
    this.masterData.getAllCountries().subscribe(
      (data: any) => {
        if (data.status === 200)
          this.countryList = data.data;
      }
    )
  }

  getState(country: any) {
    this.selectedCountry = country;
    this.masterData.getAllState(country).subscribe(
      (data: any) => {
        this.stateList = data.data;
      }
    )
  }

  getCity(state: any) {
    this.selectedState = state;
    this.masterData.getAllCity(state).subscribe(
      (data: any) => {
        this.cityList = data.data;
      }
    )
  }

  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  verifyIFSC() {
    if (this.supplierForm.value.ifscCode != null && this.supplierForm.value.ifscCode != " " && ValidationUtil.validateIFSC(this.supplierForm.value.ifscCode)) {
      sessionStorage.setItem(AppConstant.IFSC_CODE, this.supplierForm.value.ifscCode)
      this.isLoadingForVerifyIFSC = true;
      this.errorMessage = '';
      this.employeeDetailsService.verifyIFSC(this.supplierForm.value.ifscCode).subscribe(
        (response: any) => {
          if (response.status === 200) {
            this.verifiedIFSC = true;
            this.isLoadingForVerifyIFSC = false;
            this.supplierForm.controls['ifscCode'].setErrors(null);
            const data = JSON.parse(response.data);
            const bankName = data.BANK;
            const branchName = data.BRANCH;
            this.supplierForm.patchValue({
              bankName: bankName,
              branch: branchName
            });
          } else {
            this.isLoadingForVerifyIFSC = false;
            this.verifiedIFSC = false;
            this.supplierForm.controls['ifscCode'].setErrors({ 'invalidIFSC': true });
          }
        },
        (error: any) => {
          this.isLoadingForVerifyIFSC = false;
        }
      );
    }
  }

  validateAccountNumberMatch() {
    const accountNumber = this.supplierForm.value.bankAccountNo;
    const confirmAccountNumber = this.supplierForm.value.confirmBankAccountNo;
    if (accountNumber !== confirmAccountNumber) {
      this.supplierForm.get('confirmBankAccountNo')?.setErrors({ 'mismatch': true });
    } else {
      this.supplierForm.get('confirmBankAccountNo')?.setErrors(null);
    }
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  resetIFSCFieldCheck() {
    this.verifiedIFSC = false;
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event)
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumbers(event);
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  goBack() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.SUPPLIER_DETAILS]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.SUPPLIER_DETAILS]);
    }
  }

  validateSpecialCharacters(event: KeyboardEvent) {
    const allowedCharacters = /^[a-zA-Z0-9\s\-@#_!$%^&*()+=~`,.;:]*$/;
    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }
}
