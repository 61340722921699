<app-toolbar></app-toolbar>

<mat-icon svgIcon="arrow-back-double"matTooltip="{{'back_to_dashboard' | translate}}" (click)="backToHome()" class="rewind"></mat-icon>

<mat-tab-group class="group-content">


    <mat-tab label="{{ 'create_pay_payrun' | translate }}">

        <div class="container">
            <div class="createPayRun">
                <!-- <div class="back-icon">
                        <div *ngIf="!payrun" class="back-arrow-icon">
                            <mat-icon svgIcon="arrow-back-double" (click)="goBack()" class="rewind"></mat-icon>
                        </div>
                        <h3>{{'pay_run'| translate}}</h3>
                    </div> -->
                <div class="payrun">
                    <div class="back-icon">
                        <div *ngIf="payrun" class="back-arrow-icon">
                        </div>
                        <h3>{{'pay_run'| translate}}</h3>
                    </div>
                </div>

                <div class="payButton" *ngIf="!payrun && !allEmployeesPaidFlag">
                    <label *ngIf="showSubmitNowMessage != null && showSubmitNow"
                        [ngStyle]="{'color': showSubmitNowMessage === 'This Employee salary has already been credited.' ? 'green' : '#dbdb12'}">
                        {{showSubmitNowMessage}}
                    </label>
                    <div>
                        <button *ngIf="employeeData && submitNowButtonStatus" class="button" id="CreatePayNow"
                            (click)="createPayRun()" [ngClass]="(showSubmitNow) ? 'disabled' : '' "
                            [disabled]="showSubmitNow">
                            {{'submit_now' | translate }}
                        </button>
                    </div>
                </div>
                <span *ngIf="allEmployeesPaidFlag" class="already_paid_msg">
                    {{'all_selected-employees_paid_for_current_month' | translate}}
                </span>
            </div>
            <mat-divider></mat-divider>
            <div class="back-arrow">
                <mat-tab-group class="group-content">
                    <mat-tab label="{{'run_payroll'|translate}}">
                        <div *ngIf="payrun">
                            <mat-card>
                                <div class="heading">
                                    <strong>{{ 'Process_Pay_Run' | translate }}</strong> {{ payPeriod }}
                                    <div class="pay-run-group-selector">
                                        <button class="button" id="CreatePayRun" (click)="payRunToggle()"
                                            [ngClass]="(selectedData.length <= 0) ? 'disabled' : '' "
                                            [disabled]="selectedData.length <= 0"> {{'Process_To_Pay_Run' |
                                            translate
                                            }}</button>
                                    </div>
                                </div>

                                <mat-card-content class="mat-card-content">
                                    <div class="content">
                                        <div class="employeeInfo">
                                            <div class="employeeNetPay">
                                                {{ 'EMPLOYEE_NET_PAY' | translate }}
                                                <div *ngIf="selectedData.length == 0" class="yetToProcess">
                                                    {{ 'Yet_To_Process' | translate }}
                                                </div>
                                                <div *ngIf="selectedData.length > 0" class="readyToPay">
                                                    {{ 'ready_to_pay' | translate }}
                                                </div>
                                            </div>
                                            <div class="hl"></div>
                                            <div class="paymentDate">
                                                {{ 'PAYMENT_DATE' | translate }} <br>
                                                <div class="date"><b>{{ endDate }}</b></div>
                                            </div>
                                            <div class="noOfEmployee">
                                                {{ 'NO_OF_EMPLOYEES' | translate }} <br>
                                                <div class="employee"><b>{{ count }}</b></div>
                                            </div>
                                        </div>
                                        <div class="note">
                                            <div class="info"> <mat-icon class="info-icon">info</mat-icon> </div>
                                            <p class="para">{{'pay_run_info' |translate}}</p>
                                        </div>
                                    </div>

                                    <div class="search-div">
                                        <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                                            <mat-label
                                                class="custom-mat-input">{{'pay_run_group'|translate}}</mat-label>
                                            <mat-select matInput formControlName="roleReportTo"
                                                class="select-mat-input">
                                                <mat-option *ngFor="let payRunGroup of activePayRunGroupList"
                                                    [value]="payRunGroup.payRunGroupName"
                                                    (click)="onOptionClick(payRunGroup.payRunGroupName)">
                                                    {{ payRunGroup.payRunGroupName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                </mat-card-content>
                                <div *ngIf="selectedData.length > 0 " class="selected-values-wrapper">
                                    <div class="selected-values-container">
                                        <div *ngFor="let value of selectedData" class="selected-value">
                                            {{ value }}
                                            <mat-icon class="cancel-icon"
                                                (click)="removeSelectedValue(value)">cancel</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>

                        <div *ngIf="!payrun">
                            <ag-grid-angular (gridReady)="onGridReady($event)"
                                style="width: 100%; margin-top: 20px; height: 25rem" class="ag-theme-alpine"
                                [rowData]="employeeData" [defaultColDef]="employeeColDef" [enableSorting]="true"
                                [enableFilter]="true" [columnDefs]="columnDefs" [pagination]="true"
                                [paginationPageSize]="10">
                            </ag-grid-angular>
                        </div>

                        <div *ngIf="payrun">
                            <ag-grid-angular (gridReady)="onGridReady($event)"
                                style="width: 100%; margin-top: 20px; height: 25rem;" class="ag-theme-alpine"
                                [rowData]="payRundetailsData" [enableSorting]="true" [enableFilter]="true"
                                [columnDefs]="payRunDetailsColDef" [pagination]="true" [paginationPageSize]="10">
                            </ag-grid-angular>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{'payroll_history'|translate}}">
                        <ag-grid-angular (gridReady)="onGridReady($event)"
                            style="width: 100%; margin-top: 20px; height: 25rem;" class="ag-theme-alpine"
                            [rowData]="payRunHistoryData" [enableSorting]="true" [enableFilter]="true"
                            [columnDefs]="payrollHistoryDefs" [pagination]="true" [paginationPageSize]="10">
                        </ag-grid-angular>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div *ngIf="isLoading" class="overlay">
                <mat-progress-spinner class="spinner" mode="indeterminate">
                </mat-progress-spinner>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="{{ 'create_payrun_group' | translate }}">
        <div>
            <app-pay-run-group></app-pay-run-group>
        </div>
    </mat-tab>

</mat-tab-group>
<div *ngIf="isLoading" class="overlay">
    <mat-progress-spinner class="spinner" mode="indeterminate">
    </mat-progress-spinner>
</div>