
<div>
    <mat-horizontal-stepper (selectionChange)="onStepChange($event)">
        <mat-step label="{{'house_rent_details' | translate}}">
            <app-house-rent-details 
                (houseRentDetailsChange)="onHouseRentDetailsChange($event)"></app-house-rent-details>
        </mat-step>
        <ng-container *ngFor="let data of rowData">
            <mat-step [label]="convertLabel(data.name)">
                <app-view-section-details [data]="data"></app-view-section-details>
            </mat-step>
        </ng-container>
        <mat-step [label]="'preview_and_submit' | translate">
            <!-- <div class="regime-selection">
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="selectedRegimeType"
                    (click)="onRegimeSelect()">
                    <mat-button-toggle value="newRegime">{{'new_regime' | translate}}</mat-button-toggle>
                    <mat-button-toggle value="oldRegime">{{'old_regime' | translate}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div> -->
            <div class="last-year-summary-table-row-header">
                <span class="section-name-heading">{{ 'last_financial_year_summary' | translate }}</span>
                <span>{{ 'maximum_amount' | translate }}</span>
                <span>{{ 'declared_amount' | translate }}</span>
            </div>
            <div class="last-year-summary" *ngIf="houseRentDetails">
                <div *ngIf="houseRentDetails.rentPerMonthDeclared > 0" class="last-year-summary-table">
                    <div class="last-year-summary-table-row-sub-header">
                        <span class="section-heading-final-step">{{'house_rent_details' | translate}}</span>
                        <span>{{ houseRentDetails.rentPerMonthDeclared | number: '1.2-2' }}</span>
                        <span>{{ houseRentDetails.rentPerMonthDeclared | number: '1.2-2' }}</span>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="house-rent-details">
                        <div class="detail-row">
                            <strong class="detail-label">{{ 'from_date' | translate }} :</strong>
                            <span class="detail-value">{{ houseRentDetails.fromDate | date }}</span>
                        </div>
                        <div class="detail-row">
                            <strong class="detail-label">{{ 'to_date' | translate }} :</strong>
                            <span class="detail-value">{{ houseRentDetails.toDate | date }}</span>
                        </div>
                        <div class="detail-row">
                            <strong class="detail-label">{{ 'city' | translate }} :</strong>
                            <span class="detail-value">{{ houseRentDetails.city }}</span>
                        </div>
                        <div class="detail-row">
                            <strong class="detail-label">{{ 'landlord_name' | translate }} :</strong>
                            <span class="detail-value">{{ houseRentDetails.landlordName }}</span>
                        </div>
                        <div class="detail-row">
                            <strong class="detail-label">{{ 'landLord_pan' | translate }} :</strong>
                            <span class="detail-value">{{ houseRentDetails.landLordPan }}</span>
                        </div>
                        <div class="detail-row">
                            <strong class="detail-label">{{ 'rented_house_address' | translate }} :</strong>
                            <span class="detail-value">{{ houseRentDetails.rentedHouseAddress }}</span>
                        </div>
                        <div class="detail-row">
                            <strong class="detail-label">{{ 'rent_per_month_declared' | translate }} :</strong>
                            <span class="detail-value">{{ houseRentDetails.rentPerMonthDeclared | number: '1.2-2'
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="last-year-summary" *ngFor="let data of finalSubmit">
                <div *ngIf="data.updatedAmount > 0" class="last-year-summary-table">
                    <div class="last-year-summary-table-row-sub-header">
                        <span class="section-heading-final-step">{{ convertLabel(data.name) }}</span>
                        <span>{{ data.amount | number: '1.2-2' }}</span>
                        <span>{{ data.updatedAmount | number: '1.2-2' }}</span>
                    </div>
                    <mat-divider></mat-divider>
                    <div *ngFor="let item of data.data">
                        <div *ngIf="item.updatedAmount > 0" class="summary-table-row">
                            <span class="section-heading-final-step">{{ convertLabel(item.name) }}</span>
                            <span>{{ item.amount | number: '1.2-2' }}</span>
                            <span>{{ item.updatedAmount | number: '1.2-2' }}</span>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </div>
            <div class="button-div">
                <button matStepperPrevious>{{ 'back' | translate }}</button>
                <button (click)="submitDeclaration()">{{ 'submit' | translate }}</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>
<app-loader></app-loader>