import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-salary-type-popup',
  templateUrl: './select-salary-type-popup.component.html',
  styleUrls: ['./select-salary-type-popup.component.scss']
})
export class SelectSalaryTypePopupComponent {
  selectedSalaryType: string;

  constructor(
    public dialogRef: MatDialogRef<SelectSalaryTypePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedSalaryType = 'regular';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
