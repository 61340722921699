import { ChangeDetectorRef, Component, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppConstant } from "src/app/constants/app.constants";
import { Messages } from "src/app/constants/messages.constant";
import { MonthNamePipe } from "src/app/pipes/month-name.pipe";
import { AddEmployeeInformationData } from "src/app/model/employeeDetails";
import { EmployeeDetailsService } from "src/app/services/employee-details/employee-details.service";
@Component({
  selector: 'app-ag-grid',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.scss']
})
export class AgGridComponent {
  blob: Blob | undefined;

  constructor(private employeeService: EmployeeDetailsService, private renderer: Renderer2, private route: Router, private cdr: ChangeDetectorRef, private monthNamePipe: MonthNamePipe, private toastrService: ToastrService) { }
  employeeColDef: any;
  isEmployee: any;
  currentUserId = sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION);
  hrColDef: any;
  isActive: any;
  hrData: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  empId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  employeeData: any[] = [];
  id: number | undefined;
  employeeId: number | undefined;
  activeEmployeeData: AddEmployeeInformationData[] = [];
  selectedEmployeeType: string = 'allEmployee';
  filteredEmployeeData: any;
  salaryDetails: any;
  selectedRows: any[] = [];
  isExited: boolean = true;
  employeeTableCol = [{ field: "year", floatingFilter: true, width: 160, unSortIcon: true, },
  {
    field: "month", floatingFilter: true, unSortIcon: true, width: 160
    , valueFormatter: (params: any) => {
      return this.monthNamePipe.transform(params.value);
    },
  },
  {
    field: "totalEarning", unSortIcon: true, width: 180, cellRenderer: (params: any) => {
      return this.formatNumber(params.value);
    },
  },
  {
    field: "totalDeduction", unSortIcon: true, width: 180, cellRenderer: (params: any) => {
      return this.formatNumber(params.value);
    },
  },
  {
    field: "netPay", unSortIcon: true, width: 180, cellRenderer: (params: any) => {
      return this.formatNumber(params.value);
    },
  },
  {
    field: "Action",
    cellRenderer: this.actionCellRenderer1.bind(this),
    width: 245
  }
  ];


  formatNumber(value: number | undefined): string {
    if (value !== undefined) {
      return value.toFixed(2);
    }
    return "";
  }

  hrTableCol = [
    { field: 'id', headerName: 'Employee ID', width: 120, unSortIcon: true, floatingFilter: true },
    { field: "departmentName", width: 190, unSortIcon: true, floatingFilter: true },
    { field: "firstName", floatingFilter: true, unSortIcon: true, width: 150 },
    { field: "lastName", floatingFilter: true, unSortIcon: true, width: 140 },
    { field: "workEmail", floatingFilter: true, unSortIcon: true, width: 210 },
    { field: "annualCtc", headerName: 'Annual CTC', floatingFilter: true, unSortIcon: true, width: 150 },
    { field: "status", floatingFilter: true, unSortIcon: true, width: 150, cellStyle: { color: "black" }, cellRenderer: this.statusCellRenderer.bind(this) }, {
      field: "Action",
      cellRenderer: this.actionCellRenderer.bind(this),
      width: 230
    }
  ];

  statusCellRenderer(params: any): string {
    let color = "black";
    if (params.value === "Exited") {
      color = "red";
    }
    return `<span style="color:${color}">${params.value}</span>`;
  }


  getMonthName(monthNumber: number): string {
    const months = Messages.MONTHS;
    return months[monthNumber - 1] || '';
  }

  ngOnInit() {
    this.isActive;
    this.selectedEmployeeType = 'allEmployee';
    this.getAllEmployee();
    this.employeeColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.hrColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.initRole();
    if (AppConstant.ROLE == AppConstant.EMPLOYEE_ROLE) {
      this.getEmployeeSalaryDetails()
    }
  }


  getEmployeeSalaryDetails() {
    this.employeeService.getActiveEmployeeSalaryDetails(this.companyId, this.empId).subscribe((data: any) => {
      this.salaryDetails = data;
      this.salaryDetails.forEach((item: any) => {
        item.monthName = this.getMonthName(item.month);
      });
    });
  }

  getAllEmployee() {
    this.employeeService.getEmployeeList(this.selectedEmployeeType, sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        if (this.selectedEmployeeType === 'exitEmployees') {
          sessionStorage.setItem(AppConstant.EXIT_EMPLOYEE, 'true');
        } else {
          sessionStorage.removeItem(AppConstant.EXIT_EMPLOYEE);
        }
        if (data.status === 200) {
          this.employeeData = data.data.map((item: any) => ({
            id: AppConstant.EMP00 + item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            workEmail: item.workEmail,
            departmentName: item.departmentName,
            panNo: item.panNo || null,
            annualCtc: item.annualCTC || null,
            status: item.status || null,
          }
          ));
        }
      })
  }
  initRole() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) == AppConstant.EMPLOYEE_ROLE) {
      this.isEmployee = sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION);
    }
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');

    const createButton = (icon: string, color: string, clickHandler: () => void, tooltip: string) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');

      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      button.setAttribute('title', tooltip);
      this.renderer.appendChild(cell, button);
    };

    const deleteIconClickHandler = () => {
      if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
        const employeeId = parseInt(params.data.id.split(AppConstant.EMP00)[1], 10);
        this.deleteEmployee(employeeId);
      }
    };

    const viewIconClickHandler = () => {
      const employeeId = parseInt(params.data.id.split(AppConstant.EMP00)[1], 10);
      this.viewEmployeeDetails(employeeId);

      if (params.data.status === 'Exited') {
        sessionStorage.setItem(AppConstant.EXIT_EMPLOYEE, 'true');
      } else {
        sessionStorage.removeItem(AppConstant.EXIT_EMPLOYEE);
      }
      if (params.data.status === AppConstant.INCOMPLETED) {
        sessionStorage.setItem(AppConstant.EMPLOYEE_STATUS, AppConstant.INCOMPLETED);
      } else {
        sessionStorage.removeItem(AppConstant.EMPLOYEE_STATUS);
      }
    };

    createButton('visibility', 'green', viewIconClickHandler, 'View Details');
    createButton('delete', 'red', deleteIconClickHandler, 'Delete Employee');

    return cell;
  }

  viewEmployeeDetails(employeeId: any): void {
    this.route.navigate([`${AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE}/${employeeId}`]);
  }

  deleteEmployee(employeeId: any) {
    this.employeeService.deleteEmployee(sessionStorage.getItem(AppConstant.COMPANY_ID), employeeId).subscribe((data: any) => {
      if (data.status === 200) {
        this.toastrService.success(data.message)
        this.getAllEmployee();
        const index = this.employeeData.findIndex((item: { id: any; }) => item.id === employeeId);
        if (index !== -1) {
          this.employeeData.splice(index, 1);
          this.cdr.detectChanges();
        }
      }
      else {
        this.toastrService.clear();
        this.toastrService.error(data.message);
      }
    });
    this.getAllEmployee();
  }


  getActiveEmployeeDetails(employeeId: any) {
    this.employeeService.getActiveEmployeeDetails(this.id, this.employeeId).subscribe((data: any) => {
      this.activeEmployeeData = data;
      this.route.navigate([AppConstant.VIEW_EMPLOYEE]);

    });
  }

  downloadEmployeeSalaryPdf(row: any) {
    const year = row.year;
    const month = row.month;
    this.employeeService.downloadSalarySlip(this.companyId, this.empId, year, month).subscribe((data: Blob) => {
      this.blob = new Blob([data], { type: 'application/pdf' });
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "salary_slip.pdf";
      link.click();
    });
  }

  viewEmployeeSalaryPdf(row: any) {
    const year = row.year;
    const month = row.month;
    this.employeeService.downloadSalarySlip(this.companyId, this.empId, year, month).subscribe((data: Blob) => {
      this.blob = new Blob([data], { type: 'application/pdf' });
      const pdfUrl = window.URL.createObjectURL(this.blob);
      window.open(pdfUrl, '_blank');
    });
  }

  actionCellRenderer1(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'download') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const viewIconClickHandler = () => {
      this.viewEmployeeSalaryPdf(params.data);
      const downloadIconClickHandler = () => {
        if (params.node.isSelected()) {
          this.selectedRows.push(params.data);
        }
      };
    };
    const downloadIconClickHandler = () => {
      this.downloadEmployeeSalaryPdf(params.data);
      const downloadIconClickHandler = () => {
        if (params.node.isSelected()) {
          this.selectedRows.push(params.data);
        }
      };
    };
    createButton('visibility', 'green', viewIconClickHandler);
    createButton('download', 'gray', downloadIconClickHandler);
    return cell;
  }

  downloadSelectedSalarySlip() {
    for (const row of this.selectedRows) {
      this.downloadEmployeeSalaryPdf(row);
    }
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  addEmployee() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.ADMIN) {
      this.route.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ADD_EMPLOYEE])

    } else {
      this.route.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ADD_EMPLOYEE])
    }
  }

  goToBack() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.ADMIN) {
      this.route.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE])

    } else {
      this.route.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE])
    }
  }

}