import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper'; // Adjusted import
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { LastSummeryData } from 'src/app/model/tax-declaration/tax-declaration.model';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { TaxDeclarationServiceService } from 'src/app/services/tax-declaration-service/tax-declaration-service.service';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-start-fresh',
  templateUrl: './start-fresh.component.html',
  styleUrls: ['./start-fresh.component.scss']
})
export class StartFreshComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper!: MatStepper;
  rowData: LastSummeryData[] = [];
  finalSubmit: LastSummeryData[] = [];
  houseRentDetails: any;
  selectedRegimeType: any;

  constructor(private loader: LoaderService, private dialog: MatDialog, private router: Router, private toastrService: ToastrService, private taxDeclarationService: TaxDeclarationServiceService) { }

  ngOnInit(): void {
    this.getAllSectionList();
    this.selectedRegimeType = 'new_regime';
  }

  ngAfterViewInit(): void {
    if (this.stepper) {
      this.stepper.selectionChange.subscribe((event: StepperSelectionEvent) => {
        this.onStepChange(event);
      });
    }
  }

  onHouseRentDetailsChange(details: any) {
    this.houseRentDetails = details;
  }

  convertLabel(label: string): string {
    let formattedLabel = label.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedLabel = formattedLabel.replace(/([a-zA-Z\s])([0-9])/g, '$1 $2');
    return formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
  }

  getAllSectionList() {
    this.taxDeclarationService.getAllSectionList().subscribe(
      (data: any) => {
        this.rowData = data.data.map((section: any) => {
          return {
            name: section?.sectionName,
            amount: section?.sectionLimit,
            expanded: false,
            data: section.categoryDtoList ? section.categoryDtoList.map((category: any) => {
              return {
                name: category?.categoryName,
                amount: category?.categoryLimit
              };
            }) : []
          };
        });
      }
    );
  }


  onStepChange(event: StepperSelectionEvent) {
    if (event.selectedStep.label === "Preview And Submit") {
      this.taxDeclarationService.getHouseRentDetails(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.houseRentDetails = data.data;
          }
        }
      );
      this.taxDeclarationService.getDeclaredInvestmentDetails(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.finalSubmit = data.data;
          }
        }
      );
      this.taxDeclarationService.getRegimeType(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.selectedRegimeType = data.data;
          }
        }
      );
    }
  }

  submitDeclaration() {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      disableClose: true,
      data: 'Please confirm that you have submitted the IT-Declaration, as after submission, you cannot make any changes to the declaration.'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data) {
        this.loader.show();
        this.taxDeclarationService.submitDeclaration("oldRegime").subscribe(
          (data: any) => {
            if (data.status === 200) {
              this.loader.hide();
              this.toastrService.clear();
              this.toastrService.success(data.message);
              this.router.navigate([AppConstant.EMPLOYEE_ROLE + AppConstant.SEPERATOR + AppConstant.VIEW_TAX_DECLARATION + AppConstant.SEPERATOR + AppConstant.DECLARED_FORM]);
            } else {
              this.loader.hide();
              this.toastrService.clear();
              this.toastrService.error(data.message);
            }
          }
        );
      }
    });
  }
}