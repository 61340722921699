import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Employees } from 'src/app/components/admin/add-approver/add-approver.component';
import { AppConstant } from 'src/app/constants/app.constants';
import { AttendanceService } from 'src/app/services/attendance/attendance.service';
import { TraningService } from 'src/app/services/traning/traning.service';

@Component({
  selector: 'app-assign-training-to-employee',
  templateUrl: './assign-training-to-employee.component.html',
  styleUrls: ['./assign-training-to-employee.component.scss']
})
export class AssignTrainingToEmployeeComponent implements OnInit {
  employeeList: Employees[] = [];
  employeeData: any[] = [];
  empColDef: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  pendingSelection: any;
  employees: any = [];
  trainings: any = [];

  isAllEmployeesSelected = false;
  isEmployeeCheckboxIndeterminate = false;
  isAllTrainingsSelected = false;
  isTrainingCheckboxIndeterminate = false;

  constructor(
    private trainingService: TraningService,
    private router: Router,
    private renderer: Renderer2,
    private employeeService: AttendanceService,
    private toasterService: ToastrService
  ) { }

  hrTableCol = [
    { field: 'id', headerName: 'Employee ID', width: 120, unSortIcon: true, floatingFilter: true },
    { field: "firstName", floatingFilter: true, unSortIcon: true, width: 150 },
    { field: "lastName", floatingFilter: true, unSortIcon: true, width: 140 },
    { field: "workEmail", floatingFilter: true, unSortIcon: true, width: 210 },
    { field: "doj", floatingFilter: true, unSortIcon: true, width: 150 },

    {
      field: "Action",
      cellRenderer: this.actionCellRenderer.bind(this),
      width: 230
    }
  ];

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const viewIconClickHandler = () => {
      const employeeId = parseInt(params.data.id.split(AppConstant.EMP00)[1]);
      this.viewEmployeeDetails(employeeId);
    };

    createButton('visibility', 'green', viewIconClickHandler);

    return cell;
  }

  viewEmployeeDetails(employeeId: any): void {
    this.router.navigate([`${AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_TRANING_DETAILS}/${employeeId}`]);
  }

  goToDashboard() {
    (sessionStorage.getItem('login') === 'admin') 
  ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]) 
  : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

  ngOnInit(): void {
    this.getAllTraningsData();
    this.getAllEmployee();
    this.empColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }

  getAllEmployee() {
    this.employeeService.getEmployeeList(this.companyId, this.employeeId).subscribe(
      (data: any) => {
        this.employees = data.data;
        if (data.status === 200) {
          this.employeeData = data.data.map((item: any) => ({
            id: AppConstant.EMP00 + item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            workEmail: item.workEmail,
            doj: this.formatDate(item.doj),
            selected: false
          }));
          this.updateEmployeeCheckboxState();
        }
      }
    );
  }

  getAllTraningsData() {
    this.trainingService.getAllTrainingName(this.companyId).subscribe((data: any) => {
      this.trainings = data.data.map((item: any) => ({
        id: item.id,
        trainingName: item.trainingName,
        selected: false
      }));
      this.updateTrainingCheckboxState();
    });
  }

  toggleSelectAllEmployees() {
    this.isEmployeeCheckboxIndeterminate = false;
    this.employees.forEach((employee: any) => {
      employee.selected = this.isAllEmployeesSelected;
    });
  }

  toggleEmployeeSelection() {
    const selectedEmployees = this.employees.filter((employee: any) => employee.selected);
    this.isAllEmployeesSelected = selectedEmployees.length === this.employees.length;
    this.isEmployeeCheckboxIndeterminate = selectedEmployees.length > 0 && selectedEmployees.length < this.employees.length;
  }

  toggleSelectAllTrainings() {
    this.isTrainingCheckboxIndeterminate = false;
    this.trainings.forEach((training: any) => {
      training.selected = this.isAllTrainingsSelected;
    });
  }

  toggleTrainingSelection() {
    const selectedTrainings = this.trainings.filter((training: any) => training.selected);
    this.isAllTrainingsSelected = selectedTrainings.length === this.trainings.length;
    this.isTrainingCheckboxIndeterminate = selectedTrainings.length > 0 && selectedTrainings.length < this.trainings.length;
  }

  updateEmployeeCheckboxState() {
    const selectedEmployees = this.employees.filter((employee: any) => employee.selected);
    this.isAllEmployeesSelected = selectedEmployees.length === this.employees.length;
    this.isEmployeeCheckboxIndeterminate = selectedEmployees.length > 0 && selectedEmployees.length < this.employees.length;
  }

  updateTrainingCheckboxState() {
    const selectedTrainings = this.trainings.filter((training: any) => training.selected);
    this.isAllTrainingsSelected = selectedTrainings.length === this.trainings.length;
    this.isTrainingCheckboxIndeterminate = selectedTrainings.length > 0 && selectedTrainings.length < this.trainings.length;
  }


  onSubmit() {
    const selectedEmployeeIds = this.employees
      .filter((employee: any) => employee.selected)
      .map((employee: any) => parseInt(employee.id));

    const selectedTrainingIds = this.trainings
      .filter((training: any) => training.selected)
      .map((training: any) => training.id);

    const payload = {
      companyId: this.companyId,
      managerId: this.employeeId,
      employeeIdList: selectedEmployeeIds,
      trainingIdList: selectedTrainingIds
    };

    this.trainingService.assignTrainings(payload).subscribe(
      data => {
        if (data.status == 200) {
          this.toasterService.success(data.message);
        } else {
          this.toasterService.error(data.message);
        }
      }
    );
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }
  goBack(){
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
  }
}
