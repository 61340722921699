import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/enviroment';
import { API_END_POINTS } from '../../config/api-endpoint.config';
import { OTP, RegistrationData, VerifyEmail } from '../../model/registration.model';
import { AppConstant } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(private http: HttpClient) { }

  createRegistration(createRegistrationRequest: RegistrationData): Observable<RegistrationData> {
    return this.http.post(environment.apiUrl + API_END_POINTS.REGISTARTION, createRegistrationRequest).pipe(map((res: any) => {
      return res;
    }))
  }

  verifyEmail(sendOtp: VerifyEmail): Observable<VerifyEmail> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SEND_OTP, sendOtp).pipe(map((res: any) => {
      return res;
    }))
  }

  verifyEmailForPayrun(conpanyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SEND_OTP_PAY_RUN + conpanyId + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.ADMIN_ID)).pipe(map((res: any) => {
      return res;
    }))
  }

  verifyOtp(sendOtp: OTP): Observable<OTP> {
    return this.http.post(environment.apiUrl + API_END_POINTS.VERIFY_OTP, sendOtp).pipe(map((res: any) => {
      return res;
    }))
  }

  checkDomainNameExistOrNot(email: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.CHECK_DOMAIN_NAME_EXIST_OR_NOT + API_END_POINTS.SEPERATOR + email)
      .pipe(map((res: any) => {
        return (res);
      }));
  }

}



