<footer class="footer">
    <div class="container">
        <div class="contact-info">
            <h3>{{'contact_us' | translate}}</h3>
            <p><strong>{{'address' | translate}} : </strong> {{IWEXE_LOCATION}}</p>
            <p><strong>{{'support_email' | translate}} : </strong> <a
                    href="mailto:{{IWEXE_SUPPORT_EMAIL}}">{{IWEXE_SUPPORT_EMAIL}}</a></p>
            <p><strong>{{'phone' | translate}} : </strong> <a
                    href="tel:{{IWEXE_SUPPORT_PHONE}}">{{IWEXE_SUPPORT_PHONE}}</a></p>
        </div>

        <div class="quick-links">
            <h3>{{'quick_links' | translate}}</h3>
            <ul>
                <li><a href="/privacy-policy" id="privacyPolicyLink">{{'privacy_policy' | translate}}</a></li>
                <li><a href="/terms-condition">{{'terms_conditions' | translate}}</a></li>
                <li>
                    <a href="/cancellation-refund-policy" id="cancellationRefundPolicyLink"
                        class="terms">{{'cancellation_refund_policy' |
                        translate}}</a>
                </li>
                <li> <a href="/shipping-delivery-policy" id="shippingDeliveryLink"
                        class="terms">{{'shipping_delivery_policy' |
                        translate}}</a></li>
            </ul>
        </div>

        <div class="social-links">
            <h3>{{ 'follow_us' | translate }}</h3>
            <div class="social-icons">
                <a href="https://x.com/iwexe_" target="_blank" aria-label="X (Twitter)"
                    matTooltip="X (formerly Twitter)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="24" height="24" fill="#FFFFFF"
                        aria-hidden="true">
                        <path
                            d="M5.99 2h8.44L26 16.35 30.79 9h5.7L26.68 18 35.68 34h-8.46L18 21.65 12.19 34H6.5L15.5 18 6.5 2h-.51z">
                        </path>
                    </svg>
                </a>

                <a href="https://www.linkedin.com/company/iwexe/" target="_blank" aria-label="LinkedIn"
                    matTooltip="LinkedIn">
                    <i class="fab fa-linkedin"></i>
                </a>

                <a href="https://www.instagram.com/iwexe_/?hl=en" target="_blank" aria-label="Instagram"
                    matTooltip="Instagram">
                    <i class="fab fa-instagram"></i>
                </a>
            </div>
        </div>
    </div>
    <div class="copyright-container">
        <p>&copy; {{'footer' | translate}}</p>
    </div>
</footer>