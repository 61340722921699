import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { LastSummeryData, SubData } from 'src/app/model/tax-declaration/tax-declaration.model';
import { TaxDeclarationServiceService } from 'src/app/services/tax-declaration-service/tax-declaration-service.service';

@Component({
  selector: 'app-declaration-approval',
  templateUrl: './declaration-approval.component.html',
  styleUrls: ['./declaration-approval.component.scss']
})
export class DeclarationApprovalComponent implements OnInit {

  rowData: LastSummeryData[] = [];
  employeeId!: any;
  houseRentDetails: any;
  showError: boolean = true;
  houseRentDetailsError: boolean = true;
  houseRentForm!: FormGroup;

  constructor(private formBuilder: FormBuilder, private toastrService: ToastrService, private router: Router, private taxDeclarationService: TaxDeclarationServiceService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.houseRentForm = this.formBuilder.group({
      houseRentFormControlName: [null]
    })
    this.route.params.subscribe((params: any) => {
      this.employeeId = params['employeeId'];
      this.getDeclarationById(this.employeeId);
    });

    this.getHouseRentDetails();
  }

  getHouseRentDetails() {
    this.taxDeclarationService.getHouseRentDetails(this.employeeId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.houseRentDetails = data.data;
        }
      }
    )
  }

  getDeclarationById(employeeId: any) {
    this.taxDeclarationService.getDeclaredInvestmentDetails(employeeId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.rowData = data.data;
        }
      }
    )
  }

  saveHouseRentDetails(event: any, data: LastSummeryData) {
    const inputValue = event?.target?.value;
    const dataIndex = this.rowData.findIndex(resData => resData.name === 'house rent details');
    if (dataIndex !== -1) {
      if (inputValue > this.rowData[dataIndex].amount) {
        this.rowData[dataIndex].approvedAmountError = "amount_exceeds_the_maximum_limit_of"
        this.houseRentDetailsError = false;
      }
      else {
        this.rowData[dataIndex].approvedAmount = inputValue;
        this.rowData[dataIndex].approvedAmountError = null;
        this.houseRentDetailsError = true;
      }
    }
  }

  declarationAmount(event: any, declaration: SubData, data: LastSummeryData) {
    const inputValue = event?.target?.value;
    const dataIndex = this.rowData.findIndex(resData => resData.name === data.name);
    if (dataIndex !== -1) {
      const subDataIndex = this.rowData[dataIndex].data.findIndex(resData => resData.name === declaration.name);
      if (subDataIndex !== -1) {
        if (inputValue > this.rowData[dataIndex].data[subDataIndex].updatedAmount || inputValue < 0) {
          if (inputValue < 0) {
            this.rowData[dataIndex].data[subDataIndex].approvedAmountError = "only_digits_allowed"
            this.showError = false;
          }
          else {
            this.rowData[dataIndex].data[subDataIndex].approvedAmountError = "amount_exceeds_the_maximum_limit_of"
            this.showError = false;
          }
        }
        else {
          this.rowData[dataIndex].data[subDataIndex].approvedAmountError = null;
          this.showError = true;
        }
        this.rowData[dataIndex].data[subDataIndex].approvedAmount = inputValue;
        const sumUpdatedAmount = this.rowData[dataIndex].data.reduce((sum, item) => sum + (parseFloat(item.updatedAmount) || 0), 0);
        this.rowData[dataIndex].approvedAmount = sumUpdatedAmount;
        this.rowData = [...this.rowData];
        if (sumUpdatedAmount > this.rowData[dataIndex].amount) {
          this.toastrService.clear();
          this.toastrService.error("Please do not declare amount is greater than maximum amount.")
        }
      }
    }
  }

  goToDashboard() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

  rejectDeclaration() {
    const dto = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      employeeId: this.employeeId
    }
    this.taxDeclarationService.rejectDeclaration(dto).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.DECLARATION_APPROVAL])
          this.toastrService.clear();
          this.toastrService.success(data.message);
        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    )
  }

  approveDeclaration() {
    if (this.showError && this.houseRentDetailsError) {
      this.taxDeclarationService.approveDeclaration(this.employeeId, this.rowData).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.toastrService.clear();
            this.toastrService.success(data.message);
            this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.DECLARATION_APPROVAL])
          }
          else {
            this.toastrService.clear();
            this.toastrService.error(data.message);
          }
        }
      )
    }
  }

  convertLabel(label: string): string {
    let formattedLabel = label.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedLabel = formattedLabel.replace(/([a-zA-Z\s])([0-9])/g, '$1 $2');
    formattedLabel = formattedLabel.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
    return formattedLabel;
  }

}
