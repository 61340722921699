<mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" position="end">
        <label>{{'iWExe' | translate}}</label>
        <hr />
        <nav class="nav-link" aria-label="nav links">
            <a (click)="openLoginDialog()" routerLinkActive="active">{{'signin' | translate}}</a>
            <a routerLink="/signup" routerLinkActive="active">{{'signup' | translate}}</a>
        </nav>
    </mat-sidenav>

    <mat-sidenav-content>
        <section id="banner">
            <header class="header">
                <a routerLink="/" class="logo-heading">
                    <img src="../../../../../assets/images/landing-page/logo/final-logo.png" alt="" />
                </a>

                <div class="auth-buttons">
                    <button class="sheduled_demo" (click)="sheduleDemo()">{{'schedule_demo'|translate}}</button>
                    <button class="sign-in" (click)="openLoginDialog()">{{'signin' | translate}}</button>
                    <button class="sign-up" (click)="registration()">{{'signup' | translate}}</button>
                </div>

                <mat-icon class="mobile-sidenav" (click)="sidenav.toggle()">menu</mat-icon>
            </header>

            <div class="container">
                <div class="landing-page">
                    <video #backgroundVideo autoplay muted loop playsinline preload="auto" class="background-video">
                        <source src="assets/images/landing-page/banner/hr.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <div class="overlay">
                        <div class="content-container">
                            <div class="text-content">
                                <h1>{{'ai_power'|translate}} <br /><span class="highlight">{{'efortless_hr'|translate}}
                                    </span></h1>
                                <p class="subheading">{{'simple_hr'|translate}}</p>
                                <button class="schedule-demo-button" (click)="sheduleDemo()">{{ 'schedule_a_demo' |
                                    translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="solutions">
            <app-view-all-product></app-view-all-product>
        </section>

        <section id="allproducts">
            <app-all-products></app-all-products>
        </section>


        <section id="whatsupcomming">
            <app-upcomings></app-upcomings>
        </section>

        <section id="footer">
            <app-footer></app-footer>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>