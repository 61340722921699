<app-toolbar></app-toolbar>
<div class="product-view-container">
    <div class="product-content">
        <mat-form-field *ngIf="role" appearance="outline">
            <mat-select [(ngModel)]="selectedBranch" (selectionChange)="onBranchChange($event)">
                <mat-option *ngFor="let branch of branches" [value]="branch">
                    {{ branch.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="header-div">
            <h1 class="product-title">{{ 'everything_you_need' | translate }}</h1>
        </div>

        <div class="search-container">
            <input type="text" placeholder="{{ 'search_module' | translate }}" class="search-input"
                [(ngModel)]="searchQuery" (input)="filterCards()" />
        </div>

        <div class="product-grid">
            <div class="product-card" *ngFor="let card of filteredCards" [ngClass]="card.class" (click)="card.action()">
                <div class="product-card-icon">{{ card.icon }}</div>
                <p class="product-card-title">{{ card.title | translate }}</p>
            </div>
        </div>
    </div>
</div>