import { Component, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEventComponent } from '../add-event/add-event.component';
import { EventManagementService } from 'src/app/services/event-management/event-management.service';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-management',
  templateUrl: './event-management.component.html',
  styleUrls: ['./event-management.component.scss']
})

export class EventManagementComponent {
  private gridApi: any;
  private gridColumnApi: any;
  public columnDefs: any = [];
  public eventData: any = [];
  defaultColDef: any = [];
  currentEditingRow: any = [];
  showEventDeleteConfirmation: boolean = false;

  eventColumnDef = [
    { field: "eventId", filter: true, floatingFilter: true, flex: 1 },
    { field: "eventName", filter: true, floatingFilter: true, flex: 1 },
    { field: "eventType", filter: true, floatingFilter: true, flex: 1 },
    { field: "eventStartDate", filter: true, floatingFilter: true, flex: 1 },
    { field: "eventEndDate", filter: true, floatingFilter: true, flex: 1 },
    { field: "description", filter: true, floatingFilter: true, flex: 1 },
    { field: 'Action', cellRenderer: this.actionCellRenderer.bind(this) },
  ];

  constructor(private router: Router, public dialog: MatDialog, private eventManagementService: EventManagementService, private toasterService: ToastrService, private renderer: Renderer2,) { }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');

    const createButton = (icon: string, colorClass: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      this.renderer.addClass(button, 'mat-icon-button');
      this.renderer.addClass(button, 'ag-grid-custom-button');
      this.renderer.addClass(button, colorClass);
      this.renderer.listen(button, 'click', clickHandler);
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.appendChild(cell, button);
    };
    createButton('edit', 'custom-black', () => this.editEvent(params.data));
    createButton('delete', 'custom-red', () => this.deleteEvent(params.data));
    return cell;
  }

  editEvent(data: any) {
    this.callAddEventPopup("update", data);
  }

  //grid methods
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getApi();
  }

  getApi() {
    this.eventManagementService.getAllEventList().subscribe(
      (data: any) => {
        this.eventData = data.data;
      }
    );
  }

  callAddEventPopup(callingType: string, eventDto?: any) {
    const dialogRef = this.dialog.open(AddEventComponent, {
      disableClose: true,
      width: "auto",
      autoFocus: false,
      data: {
        callingType: callingType,
        eventDto: eventDto
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getApi();
    });
  }

  //add new event
  addEvent() {
    this.callAddEventPopup("add");
  }

  updateEvent(currentEditingRow: any) {
    this.eventManagementService.updateEvent(currentEditingRow).subscribe((responseData: any) => {
      if (responseData.status === 200) {
        this.getApi();
      }
      this.gridApi.seteventData([]);
      this.gridApi.updateeventData({ add: responseData });
      this.getApi();
    });
  }

  cancelUpdate() {
    this.getApi();
  }

  deleteEvent(currentEditingRow: any) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      disableClose: true,
      data: "Are you sure you want to delete this event?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data) { this.confirmEventDelete(currentEditingRow); }
    });
  }

  confirmEventDelete(currentEditingRow: any) {
    this.eventManagementService.eventDelete(currentEditingRow).subscribe((data: any) => {
      if (data.status === 200) {
        this.getApi();
        this.toasterService.clear();
        this.toasterService.success(data.message);
      }
    });
  }

  goToBack() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    }
  }

}
