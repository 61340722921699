<h3 class="headerName">{{'add_asset' | translate}}</h3>

<mat-dialog-content>
    <form [formGroup]="assetForm">
        <div class="assets">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'asset_type' | translate }}</mat-label>

                <mat-select formControlName="assetType" required>
                    <mat-option *ngFor="let asset of allAssetTypeList" [value]="asset.assetType">
                        {{ asset.assetType}}
                    </mat-option>
                    <mat-option>
                        <button class="button" (click)="openAddAssetTypeDialog()">Add Asset Type</button>
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="assetForm.controls['assetType'].hasError('required')">
                    {{ 'asset_type_required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'model_name' | translate }}</mat-label>
                <input matInput maxlength="15" formControlName="modelName" required />

                <mat-error *ngIf="assetForm.get('modelName')?.hasError('required')">
                    {{ 'model_name_is_required' | translate }}
                </mat-error>
            </mat-form-field>

        </div>

        <div class="assets">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'model_no' | translate}}</mat-label>
                <input class="Input-field" matInput type="text"
                    (keypress)="validateNumberAndSpaceAndHyphenAllowed($event)" formControlName="modelNo" required="*">
                <mat-error *ngIf="assetForm.get('modelNo')?.hasError('required')">
                    {{'model_no_is_required' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'quantity' | translate }}</mat-label>
                <input matInput type ="number" minlength="0"formControlName="assetsQuantity" required />
                <mat-error *ngIf="assetForm.get('assetsQuantity')?.hasError('required')">
                    {{ 'quantity_is_required' | translate }}
                </mat-error>
            </mat-form-field>

        </div>

        <div class="assets">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'project_name' | translate }}</mat-label>
                <mat-select formControlName="projectName">
                  <mat-option *ngFor="let project of allProjects" [value]="project.projectName  ">
                    {{ project.projectName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'collected_by' | translate }}</mat-label>
                <input matInput maxlength="15" formControlName="collectedBy" required />
            </mat-form-field>

        </div>

        <div class="client-asset-checkbox">
            <mat-checkbox formControlName="isClientAsset" (change)="onClientAssetChecked($event.checked)">
                {{'client_asset' | translate}}
            </mat-checkbox>
        </div>

        <div *ngIf="isClientAsset" class="assets">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{'client_name' | translate}}</mat-label>
                <input matInput type="text" formControlName="clientName">
            </mat-form-field>
        </div>

        <div class="buttons">
            <button class="cancel-btn" (click)="closeDialog()">{{'cancel' | translate}}</button>
            <button (click)="save()">{{'save' | translate}}</button>
        </div>
    </form>
</mat-dialog-content>

<ng-template #addAssetTypeDialogTemplate class="aset-container">
    <h3 class="assettype">{{'add_asset_type'|translate}}</h3>
    <form [formGroup]="assetTypeForm">
    <mat-form-field appearance="outline" class="full-width">
        <input matInput placeholder="Enter Asset Type" formControlName="assetType" [(ngModel)]="newAssetType">
    </mat-form-field>
    <button class="button asset-button" (click)="saveAssetType()">{{'save'|translate}}</button>
    <button class="cancel-btn cancel">{{'cancel'|translate}}</button>
    </form>
</ng-template>
