<app-toolbar *ngIf="isSettingSidebar"></app-toolbar>
    <div class="setting__main_container" *ngIf="!isSettingSidebar">
        <div class="setting__heading_container">
            <h3>{{ 'company_bank_detail' | translate }}</h3>
        </div>
    </div>

    <div class="horizontal-subscription-card" *ngIf="isSettingSidebar">
        <div class="subscription-header">
            <mat-icon svgIcon="arrow-back-double" (click)="goBack()"></mat-icon>
            <h3>{{ 'company_bank_detail' | translate }}</h3>
        </div>
    </div>

    <mat-divider class="divider"></mat-divider>
    <mat-icon class="edit" (click)="updateDetails()" *ngIf="showBankDetails">edit</mat-icon>
    <div *ngIf="showBankDetails">
        <div class="bank-details">
            <div class="details-row">
                <h4>{{ 'account_holder_name' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{bankDetails.accountHolderName}}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'work_email' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.workEmail }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'mobile_number' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.mobileNumber }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'pan' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.panNumber }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'business_name' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.businessName }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'business_address' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.businessAddress }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'ifsc' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.ifscCode }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'bank_name' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.bankName }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'branch_name' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.branchName }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'account_number' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.accountNumber }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'country' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.country }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'state' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.state }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'city' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.city }}</span>
            </div>
            <div class="details-row">
                <h4>{{ 'pincode' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                <span>{{ bankDetails.pinCode }}</span>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="!showBankDetails">
        <div class="content-left">
            <form name="bankIntegrationForm" [formGroup]="bankIntegrationForm">
                <div class="form-field-gap1">
                    <mat-form-field class="name" appearance="outline">
                        <mat-label class="formLabel">{{'account_holder_name'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="accountHolderName" required
                            (keypress)="validateCharacterNumberAndSpace($event)" maxlength="35" />
                        <mat-error *ngIf="bankIntegrationForm.controls['accountHolderName'].hasError('required')">
                            {{ 'name_is_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="bankIntegrationForm.controls['accountHolderName'].hasError('invalidInput')">
                            {{ 'invalid_name_input' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label class="formLabel">{{'work_email'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="workEmail" required />
                        <mat-error>
                            {{'email_is_required'|translate}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field-gap2">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'mobile_number' | translate }}</mat-label>
                        <input matInput id="mobileNumber" (keypress)="validateNumber($event)"
                            formControlName="mobileNumber" minlength="10" maxlength="10" required />
                        <mat-error *ngIf="bankIntegrationForm.controls['mobileNumber'].hasError('required')">
                            {{ 'mobile_number_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="bankIntegrationForm.controls['mobileNumber'].hasError('invalidMobileNumber')">
                            {{ 'invalid_mobile_number' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label class="formLabel">{{ 'pan' | translate }}</mat-label>
                        <input matInput formControlName="panNumber" class="mat-input"
                            (keypress)="alphanumericAllowed($event)" [maxLength]="10" required appUppercase />
                        <mat-error *ngIf="bankIntegrationForm.get('panNumber')?.hasError('required')">
                            {{ 'pan_number_is_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="bankIntegrationForm.get('panNumber')?.hasError('pattern')">
                            {{ 'please_enter_valid_pan_no' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="name" appearance="outline">
                        <mat-label class="formLabel">{{'address'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="address" required
                            (keypress)="addressVerification($event)" />
                        <mat-error *ngIf="bankIntegrationForm.controls['address'].hasError('required')">
                            {{ 'address_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>


                <div class="form-field-gap3">

                    <mat-form-field class="name" appearance="outline">
                        <mat-label class="formLabel">{{'business_name'|translate}}</mat-label>
                        <input maxlength="35" matInput type="text" class="input" formControlName="businessName" required
                            (keypress)="validateCharacterNumberAndSpace($event)" />
                        <mat-error *ngIf="bankIntegrationForm.controls['businessName'].hasError('required')">
                            {{ 'business_name_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="name" appearance="outline">
                        <mat-label class="formLabel">{{'business_address'|translate}}</mat-label>
                        <input matInput type="text" maxlength="45" class="input" formControlName="businessAddress"
                            required (keypress)="addressVerification($event)" />
                        <mat-error *ngIf="bankIntegrationForm.controls['businessAddress'].hasError('required')">
                            {{ 'business_address_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>

                </div>

                <div class="form-field-gap5">

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'ifsc_code' | translate }}</mat-label>
                        <input matInput type="text" class="ifscVerify" formControlName="ifscCode" appUppercase
                            (keypress)="alphanumericAllowed($event)" [maxLength]="11"
                            (ngModelChange)="resetIFSCFieldCheck()" required />
                        <mat-error *ngIf="bankIntegrationForm.get('ifscCode')?.hasError('required')">
                            {{ 'ifsc_code_is_required' | translate }}
                        </mat-error>
                        <a *ngIf="!verifiedIFSC && !isLoading" (click)="verifyIFSC()" class="verify">{{'verify' |
                            translate}}</a>
                        <mat-icon *ngIf="verifiedIFSC" class="success-icon" color="primary">done</mat-icon>
                        <p class="error-message" *ngIf="errorMessage">{{ "errorMessage" }}</p>
                        <app-spinner *ngIf="isLoading" class="spinner"></app-spinner>
                        <mat-error
                            *ngIf="bankIntegrationForm.get('ifscCode')?.hasError('invalidIFSC')">{{'please-provide-valid-ifsc'
                            | translate}}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="name" appearance="outline">
                        <mat-label class="formLabel">{{'bank_name'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="bankName" [maxLength]="30" required
                            (keypress)="validateCharacterNumberAndSpace($event)" />
                        <mat-error *ngIf="bankIntegrationForm.controls['bankName'].hasError('required')">
                            {{ 'bank_name_is_required' | translate }}
                        </mat-error>

                    </mat-form-field>

                    <mat-form-field class="name" appearance="outline">
                        <mat-label class="formLabel">{{'branch_name'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="branchName" required [maxLength]="30"
                            (keypress)="validateCharacterNumberAndSpace($event)" />
                        <mat-error *ngIf="bankIntegrationForm.controls['branchName'].hasError('required')">
                            {{ 'branch_name_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field-gap5">

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'account_number' | translate}}</mat-label>
                        <input class="input" id="password-field1" type="password" (keypress)="validateNumber($event)"
                            minlength="10" [maxLength]="18" [type]="hidePassword ? 'password' : 'text'"
                            formControlName="accountNumber" appBlockCopyPaste matInput />
                        <mat-error *ngIf="!bankIntegrationForm.controls['accountNumber'].valid">
                            {{'account_number_required'| translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'confirm_account_number' | translate}}</mat-label>
                        <input class="input" id="password-field" (input)="validateAccountNumberMatch()" [maxLength]="18"
                            minlength="10" (keypress)="validateNumber($event)" formControlName="confirmBankAccountNo"
                            [type]="hidePassword ? 'password' : 'text'" compare="accountNumber" matInput
                            appBlockCopyPaste required />
                        <mat-error *ngIf="bankIntegrationForm.hasError('mismatch', 'confirmBankAccountNo')">
                            {{'account_do_not_match'|translate}}
                        </mat-error>
                        <mat-error *ngIf="bankIntegrationForm.get('confirmBankAccountNo')?.hasError('required')">
                            {{'confirm_account_number_required'| translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label class="formLabel">{{'country'|translate}}</mat-label>
                        <mat-select matInput formControlName="country" class="select_mat_input" required="true"
                            (selectionChange)="getState($event.value)">
                            <mat-option *ngFor="let country of countryList;" [value]="country">{{country}}
                            </mat-option>
                        </mat-select>
                        <mat-error>{{'country_is_required'|translate}}</mat-error>
                    </mat-form-field>

                    <button class="visibility" mat-icon-button (click)="hidePassword = !hidePassword"
                        [attr.aria-label]="'Toggle Password Visibility'" [attr.aria-pressed]="!hidePassword">
                        <i class="material-icons">{{ hidePassword ? 'visibility_off' : 'visibility' }}</i>
                        <!-- <span class="password-text">{{(hidePassword ? 'show' : 'hide') | translate}}</span> -->
                    </button>

                </div>


                <div class="form-field-gap5">

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label class="formLabel">{{'state'|translate}}</mat-label>
                        <mat-select matInput formControlName="state" class="select-mat-input" required="true"
                            (selectionChange)="getCity($event.value)">
                            <mat-option *ngFor="let state of stateList;" [value]="state">
                                {{ state }}
                            </mat-option>
                        </mat-select>
                        <mat-error>{{'state_is_required'|translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label class="formLabel">{{'city'|translate}}</mat-label>
                        <mat-select matInput formControlName="city" class="select-mat-input"
                            (selectionChange)="selectedCityName($event.value)">
                            <mat-option *ngFor="let city of cityList;" [value]="city">{{ city }}</mat-option>
                        </mat-select>

                        <mat-error>{{'city_is_required'|translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'pincode' | translate }}</mat-label>
                        <input (keypress)="validateNumber($event)" matInput class="input" formControlName="pinCode"
                            minlength="6" maxlength="6" />
                        <mat-error *ngIf="bankIntegrationForm.get('pinCode')?.hasError('required')">
                            {{ 'pincode_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="bankIntegrationForm.get('pinCode')?.hasError('minlength')">
                            {{ 'pincode_length' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="input-container">
                    <button class="button-right" (click)="submit()">
                        {{ getButtonLabel() | translate }}
                    </button>
                    <button class="button-right cancel-btn" (click)="gotoDashboard()">{{ 'cancel' | translate
                        }}</button>
                </div>
            </form>

        </div>
    </div>


