<app-toolbar *ngIf="!hideToolbar"></app-toolbar>
<div class="toolbar-content">
  <mat-toolbar class="view-toolbar">
    <mat-icon class="back" svgIcon="arrow-back-double" (click)="back()"></mat-icon>
    <h3 *ngIf="isPerformance">{{'performance_goal_plans'|translate}}</h3>
    <h3 *ngIf="!isPerformance">{{'behaviour_goal_plans'|translate}}</h3>
    <span class="toolbar-container"></span>

    <progress *ngIf="!isManager && isGoalRatingButtonVisible" class="progress-bar" [value]="progressBarValue" max="100">
      {{ progressBarValue }}%
    </progress>
    <div *ngIf="!isManager && isGoalRatingButtonVisible" class="progress-details">
      <span class="progress-count">{{ ratedGoals }}/{{ totalGoals }}</span>
    </div>

    <progress *ngIf="isManager && isGoalRatingButtonVisible" class="progress-bar" [value]="managerRatingProgressValue"
      max="100">
      {{ managerRatingProgressValue }}%
    </progress>
    <div *ngIf="isManager && isGoalRatingButtonVisible" class="progress-details">
      <span class="progress-count">{{ ratedGoals }}/{{ totalGoals }}</span>
    </div>

    <button *ngIf="!isManager&& isGoalButtonVisible" class="button" (click)="addGoals()">
      {{'add_goal' | translate}}
    </button>

  </mat-toolbar>
</div>
<mat-divider class="header-divider"></mat-divider>

<div [ngClass]="hideToolbar ? 'container' : 'main-container'">

  <div class="progress-container">
  </div>
  <div class="expansionPanel">
    <mat-card>
      <div *ngFor="let data of employeeGoal">
        <div>
          <div class="goal-detail">
            <div class="goal-detail-item">
              <strong class="goal-name">{{ data.goalName }}</strong>
            </div>
            <div class="goal-detail-item status-weight-container">
              <span class="title-content">{{ 'goal_status' | translate }}:</span>
              <span>{{ data.status }}</span>
            </div>
            <div class="goal-detail-item status-weight-container">
              <span class="title-content"> {{ 'start_date' | translate }}:</span>
              <span>{{ data.startDate | date:'dd-MM-yyyy' }}</span>
              <span class="separator"></span>
              <span class="title-content">{{ 'end_date' | translate }}:</span>
              <span>{{ data.endDate | date:'dd-MM-yyyy' }}</span>
              <span class="separator"></span>
              <span class="title-content"> {{ 'weight' | translate }}:</span>
              <span>{{ data.weights }}</span>
            </div>
            <div class="goal-detail-item">
              <span class="title-content">{{ 'goal_description' | translate }}:</span>
              <span>{{ data.description }}</span>
            </div>
            <div class="goal-detail-item" *ngIf="data.empRating !== null">
              <span class="title-content"> {{'employee_self_rating' | translate}}:</span>
              <span>{{ data.empRating }}</span>
            </div>
            <div class="goal-detail-item" *ngIf="data.empComments !== null">
              <span class="title-content"> {{ 'employee_commnets' | translate }}:</span>
              <span>{{ data.empComments }}</span>
            </div>
            <div class="goal-detail-item" *ngIf=" isManager && data.managerRatting !== null">
              <span class="title-content"> {{ 'manager_ratings' | translate }}:</span>
              <span>{{ data.managerRatting }}</span>
            </div>
            <div class="goal-detail-item" *ngIf="isManager && data.managerRatting !== null">
              <span class="title-content"> {{ 'manager_comments' | translate }}:</span>
              <span>{{ data.managerComments }}</span>
            </div>
          </div>

          <form *ngIf="!isManager" [formGroup]="employeeRatingForm">
            <div *ngIf="data.empRating == null && isGoalRatingButtonVisible">

              <mat-form-field appearance="outline" class="manager-review">
                <mat-label>{{'employee_commnets'|translate}}</mat-label>
                <textarea matInput formControlName="empComments"></textarea>
                <mat-error *ngIf="employeeRatingForm.controls['empComments'].hasError('required')"
                  class="new-rating-error">
                  {{ "employee_comment_is_required"|translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="manager-review-rating">
                <mat-label>{{'emp_rating'|translate}}</mat-label>
                <input matInput type="number" min="0" formControlName="empRating" />
                <mat-error *ngIf="employeeRatingForm.controls['empRating'].hasError('required')"
                  class="new-rating-error">
                  {{ "employee_rating_is_required"|translate }} </mat-error>
                <mat-error *ngIf="employeeRatingForm.controls['empRating'].errors?.['invalidInput']"
                  class="new-rating-error">
                  {{ 'rating_number_in_between' | translate }}
                </mat-error>
              </mat-form-field>

              <button *ngIf="data.empRating == null" type="submit" (click)="employeeRatingSave(data)"
                class="new-manager-rating-button">{{ 'save' | translate }}</button>
            </div>
          </form>

        </div>
        <form *ngIf="isManager" [formGroup]="managerRatingForm">
          <div *ngIf="data.managerRatting == null && isGoalRatingReviewButtonVisible">
            <mat-form-field appearance="outline" class="manager-review">
              <mat-label>{{ 'manager_comments' | translate }}</mat-label>
              <textarea matInput formControlName="comments"></textarea>
              <mat-error *ngIf="managerRatingForm.controls['comments'].hasError('required')" class="new-rating-error">
                {{ "manager_comment_is_required" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="manager-review-rating">
              <mat-label>{{ 'manager_ratings' | translate }}</mat-label>
              <input matInput type="number" min="0" formControlName="rating" />
              <mat-error *ngIf="managerRatingForm.controls['rating'].hasError('required')" class="new-rating-error">
                {{ "rating_is_required" | translate }}
              </mat-error>
              <mat-error *ngIf="managerRatingForm.controls['rating'].errors?.['invalidInput']" class="new-rating-error">
                {{ 'rating_number_in_between' | translate }}
              </mat-error>
            </mat-form-field>
            <button *ngIf="data.managerRatting == null" type="submit" (click)="managerRatingSave(data)"
              class="new-manager-rating-button">{{ 'save' | translate }}</button>
          </div>
          <div class="approved-reject" *ngIf="isGoalReviewButtonVisible">
            <button (click)="goalsApprovedByManager()">{{'approved'|translate}}</button>
            <button class="reject" (click)="goalsRejectedByManager()">{{'rejected'|translate}}</button>
          </div>
        </form>

        <form class="popup" *ngIf="isRejectPopupVisible" [formGroup]="goalReviewByManagerForm">
          <div class="popup-content">
            <h3>{{ 'rejection_reason' | translate }}</h3>
            <div class="popup-actions">
              <button class="cancel-btn cancel" (click)="closePopup()">{{ 'cancel' | translate }}</button>
              <button class="button" (click)="submitRejection()">{{ 'submit' | translate }}</button>
            </div>
          </div>
        </form>
        <mat-divider class="goal-divider" *ngIf="!isGoalReviewButtonVisible"></mat-divider>
      </div>
    </mat-card>
  </div>
  <div class="expansionPanel" *ngIf="!isManager && progressBarValue === 100">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b> {{'summary'|translate}}</b>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <form [formGroup]="summaryForm">
          <div class="labelContainer"
            *ngIf="getSummaryDetail?.avgRatingEmpGoal || getSummaryDetail?.empSummaryComments">
            <span class="calculatedRating"><b>{{'employee_calculated_ratings'|translate}}</b>:</span>
            <span class="emp-rating">
              {{getSummaryDetail?.avgRatingEmpGoal}}
            </span>
            <span><br>
              {{getSummaryDetail?.empSummaryComments}}
            </span>
          </div>
          <div class="emp-summary-comments"
            *ngIf="!getSummaryDetail?.avgRatingEmpGoal && !getSummaryDetail?.empSummaryComments">
            <mat-form-field appearance="outline" class="employee-comments-container">
              <mat-label>{{'employee_commnets'|translate}}</mat-label>
              <textarea matInput formControlName="commonGoalComments"></textarea>
            </mat-form-field>
            <mat-error *ngIf="summaryForm.controls['commonGoalComments'].hasError('required')" class="rating-error">
              {{ "employee_comment_is_required"|translate }}
            </mat-error>
          </div>
        </form>
        <button *ngIf="!getSummaryDetail?.avgRatingEmpGoal && !getSummaryDetail?.empSummaryComments" type="submit"
          class="button final-rating-button" (click)="saveSummary()">{{'save'|translate}}</button>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="expansionPanel" *ngIf="isManager&&managerRatingProgressValue === 100">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b> {{'summary'|translate}}</b>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <form [formGroup]="managerSummaryForm">
          <div
            *ngIf="getSummaryDetail?.managerAvgRatting !== null || getSummaryDetail?.managerSummaryComments !== null">
            <div class="labelContainer">
              <span><b>{{ 'manager_ratings' | translate }} : </b></span>
              <span class="emp-rating">{{ getSummaryDetail?.managerAvgRatting }}</span>
              <span><br>{{ getSummaryDetail?.managerSummaryComments }}</span>
            </div>
          </div>
          <div
            *ngIf="getSummaryDetail?.managerAvgRatting === null && getSummaryDetail?.managerSummaryComments === null">
            <div class="emp-summary-comments">
              <mat-form-field *ngIf="isMangerSummaryComment" appearance="outline" class="employee-comments-container">
                <mat-label>{{ 'manager_comments' | translate }}</mat-label>
                <textarea matInput formControlName="managerComments"></textarea>
                <mat-error *ngIf="managerSummaryForm.controls['managerComments']?.hasError('required')"
                  class="rating-error">
                  {{ 'manager_comment_is_required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <button *ngIf="isMangerSummaryComment" type="submit" class="button final-rating-button"
              (click)="saveManagerSummary()">
              {{ 'save' | translate }}
            </button>
          </div>
        </form>
      </mat-expansion-panel>
    </mat-accordion>




  </div>
</div>