import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']

})
export class ErrorPopupComponent {

  constructor(public dialogRef: MatDialogRef<ErrorPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) { }

  closePopup(): void {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.EMPLOYEE_ROLE) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
    }
    else {
      (sessionStorage.getItem('login') === 'admin') 
      ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]) 
      : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    }
    this.dialogRef.close();
  }
}

