import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { BranchService } from 'src/app/services/branch.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';

@Component({
  selector: 'app-view-modules-rolebase',
  templateUrl: './view-modules-rolebase.component.html',
  styleUrls: ['./view-modules-rolebase.component.scss']
})

export class ViewModulesRolebaseComponent implements OnInit {
  searchQuery: string = '';
  cards: Array<{ title: string; class: string; icon: string; action: () => void }> = [];
  filteredCards: Array<{ title: string; class: string; icon: string; action: () => void }> = [];
  employeeName: any;
  designation: any;
  doj: any;
  selectedBranch: string = 'main';
  role: boolean = false;
  branches: any[] = [];

  constructor(public employeeService: EmployeeDetailsService, private readonly branchService: BranchService, private router: Router, private toasterService: ToastrService) {
    this.initializeCards();
    this.filteredCards = [...this.cards];
    this.role = sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.ADMIN
    if (this.role) {
      this.getAllBranches()
    }
  }

  ngOnInit() {
    this.initializeCards();
    this.getEmployeeInfo();
  }

  getAllBranches() {
    this.employeeService.getAllBranches().subscribe(
      (allBranchesResponse: any) => {
        if (allBranchesResponse.status === 200) {
          this.branches = allBranchesResponse.data.map((branch: any) => ({
            name: branch.branchName,
            value: branch.id,
          }));
          this.selectedBranch = this.branches.find((branch: any) => branch.name === 'Main Branch');
        }
      }
    );
  }

  initializeCards() {
    const roles: string | null = sessionStorage.getItem('role') || sessionStorage.getItem('login');
    const allCards = [
      { title: 'dashboard', class: 'task-management', icon: '🧮', action: () => this.dashboard() },
      { title: 'settings', class: 'settings', icon: '⚙️', action: () => this.setting() },
      { title: 'employee_onboarding', class: 'onboarding', icon: '👥', action: () => this.onboarding() },
      { title: 'payroll_management', class: 'payrun-groups', icon: '💰', action: () => this.payrun() },
      { title: 'task_management', class: 'task-management', icon: '📝', action: () => this.taskManagement() },
      { title: 'project_management', class: 'transaction-history', icon: '📊', action: () => this.addProject() },
      { title: 'Assets Management', class: 'assets-management', icon: '🏢', action: () => this.assetManagement() },
      { title: 'event_management', class: 'event-management', icon: '🎭', action: () => this.eventManagement() },
      { title: 'training_management', class: 'add-training', icon: '📚', action: () => this.trainingManagement() },
      { title: 'approvals', class: 'approvals', icon: '👍', action: () => this.approvals() },
      { title: 'subscription', class: 'subscription', icon: '🛍️', action: () => this.subscription() },
      { title: 'transaction_history', class: 'transaction-history', icon: '🔁', action: () => this.transactionHistory() },
      { title: 'appraisal_process', class: 'apprisal-process', icon: '📈', action: () => this.apprisalProcess() },
      { title: 'bank_details', class: 'onboarding', icon: '🏦', action: () => this.bankDetails() },
      { title: 'salary_details', class: 'onboarding', icon: '💳', action: () => this.salaryDetails() },
      { title: 'salary_breakup', class: 'transaction-history', icon: '🧾', action: () => this.salaryBreakup() },
      { title: 'attendance', class: 'payrun-groups', icon: '📅', action: () => this.attendance() },
      { title: 'apply_leave', class: 'task-management', icon: '🏖️', action: () => this.applyLeave() },
      { title: 'help_desk', class: 'settings', icon: '🛠️', action: () => this.helpDesk() },
      { title: 'Exit Management', class: 'event-management', icon: '🔜', action: () => this.exitManagement() },
      { title: 'it_declaration', class: 'assets-management', icon: '📑 ', action: () => this.itDeclaration() },
      { title: 'it_declaration_approval', class: 'assets-management', icon: '🛍️', action: () => this.itDeclarationApproval() },
      { title: 'view_training', class: 'assets-management', icon: '🛍️', action: () => this.itDeclaration() },
      { title: 'payrun_approval', class: 'add-training', icon: '💲', action: () => this.payrunApproval() },
      { title: 'profile', class: 'onboarding', icon: '🧑‍💻', action: () => this.profile() },
    ];


    const roleCardMap: { [role: string]: string[] } = {
      admin: ['dashboard', 'settings', 'employee_onboarding', 'payroll_management', 'task_management', 'Assets Management', 'event_management', 'training_management', 'approvals', 'subscription', 'transaction_history', 'appraisal_process', 'bank_details'],
      Manager: ['dashboard', 'profile', 'project_management', 'approvals', 'salary_details', 'salary_breakup', 'attendance', 'apply_leave', 'appraisal_process', 'help_desk', 'Exit Management', 'it_declaration', 'task_management'],
      employee: ['dashboard', 'profile', 'salary_details', 'salary_breakup', 'attendance', 'apply_leave', 'help_desk', 'training_management', 'appraisal_process', 'Exit Management', 'task_management', 'it_declaration'],
      HR: ['dashboard', 'profile', 'salary_details', 'salary_breakup', 'attendance', 'apply_leave', 'help_desk', 'approvals', 'employee_onboarding', 'Assets Management', 'event_management', 'appraisal_process', 'Exit Management', 'it_declaration', 'training_management', 'task_management'],
      Accountant: ['dashboard', 'profile', 'salary_details', 'salary_breakup', 'attendance', 'apply_leave', 'help_desk', 'training_management', 'appraisal_process', 'Exit Management', 'payroll_management', 'payrun_approval', 'it_declaration_approval', 'task_management', 'it_declaration'],
      Support: ['dashboard', 'profile', 'salary_details', 'salary_breakup', 'attendance', 'apply_leave', 'help_desk', 'training_management', 'appraisal_process', 'Exit Management', 'task_management', 'it_declaration'],
      User: ['dashboard', 'profile', 'salary_details', 'salary_breakup', 'attendance', 'apply_leave', 'help_desk', 'training_management', 'appraisal_process', 'Exit Management', 'task_management', 'it_declaration'],
    };

    const userRoles: string[] = roles ? roles.split(',').map(role => role.trim()) : [];
    const allowedCards = new Set<string>();
    userRoles.forEach((role: string | number) => {
      (roleCardMap[role] || []).forEach(card => allowedCards.add(card));
    });

    this.cards = allCards.filter(card => allowedCards.has(card.title));
  }

  filterCards() {
    if (!this.searchQuery) {
      this.filteredCards = [...this.cards];
    } else {
      const query = this.searchQuery.toLowerCase();
      this.filteredCards = this.cards
        .filter(card => card.title.toLowerCase().includes(query))
        .sort((a, b) => {
          if (a.title.toLowerCase().startsWith(query) && !b.title.toLowerCase().startsWith(query)) {
            return -1;
          }
          if (!a.title.toLowerCase().startsWith(query) && b.title.toLowerCase().startsWith(query)) {
            return 1;
          }
          return 0;
        });
    }
  }

  onBranchChange(event: any) {
    this.branchService.switchBranch(event.value.value).subscribe(
      (switchBranchResponse: any) => {
        if (switchBranchResponse.status === 200) {
          this.toasterService.clear();
          this.toasterService.success(switchBranchResponse.message);
        } else {
          this.toasterService.clear();
          this.toasterService.error(switchBranchResponse.message);
        }
      });
  }

  salaryDetails() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.SALARY_DETAIL]);
  }

  salaryBreakup() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.SALARY_BREAKUP]);
  }

  attendance() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_ATTENDANCE]);
  }

  applyLeave() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.LEAVE_HISTORY]);
  }

  helpDesk() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.HELPDESK]);
  }

  exitManagement() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.EXIT_PROCESS]);
  }

  payrunApproval() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.PAYRUN_APPROVAL]);
  }

  profile() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PROFILE]);
  }

  itDeclaration() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.IT_DECLARATION + AppConstant.SEPERATOR + AppConstant.START_FRESH]);
  }

  itDeclarationApproval() {
    this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.DECLARATION_APPROVAL]);
  }

  onboarding() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES]);
    }
  }

  payrun() {
    this.router.navigate([AppConstant.PAY_RUN]);
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.PAY_RUN]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.PAY_RUN]);
    }
  }

  setting() {
    sessionStorage.setItem(AppConstant.SETTING_SIDE_BAR, 'true');
    this.toasterService.clear();
    this.router.navigate([`${AppConstant.ADMIN}${AppConstant.SEPERATOR}${AppConstant.DEPARTMENT}`]);
  }

  dashboard() {
    this.toasterService.clear();
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
    }

  }

  taskManagement() {
    this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.LANDING_PAGE]);
  }

  addProject() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.projectManagement) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_MANAGEMENT]);
    }
    else {
      this.toasterService.clear();
      this.toasterService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  eventManagement() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.EVENT_MANAGEMENT]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.EVENT_MANAGEMENT]);
    }
  }

  assetManagement() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ASSET_MANAGEMENT]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ASSET_MANAGEMENT]);
    }
  }

  trainingManagement() {
    (sessionStorage.getItem('login') === 'admin')
      ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_TRAINING])
      : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.VIEW_TRAINING]);
  }

  approvals() {
    this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_LEAVE_DETAILS]);
  }
  subscription() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.USER_SUBSCRIPTION_VIEW]);

  }
  transactionHistory() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.TRANSACTION_HISTORY]);
  }

  apprisalProcess() {
    (sessionStorage.getItem('login') === 'admin')
      ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.UPLOAD_APPRISAL_EXCEL])
      : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_APPRISAL]);
  }

  bankDetails() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.COMPANY_BANK_INFORMATION]);
  }

  getEmployeeInfo() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.HR_ROLE) {
      return;
    }
    this.employeeService.getEmployeeImage(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (res: any) => {

        this.employeeName = res.firstName + " " + res.lastName;
        this.designation = res.designationModel.designation;
        this.doj = res.doj;
        sessionStorage.setItem('doj', this.doj);
        sessionStorage.setItem('employeeName', this.employeeName);
      });
  }
}
