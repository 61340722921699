import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AppraisalServiceService } from 'src/app/services/apprisal-service/appraisal-service.service';
import * as moment from 'moment';
import { FormatUtil } from 'src/app/utils/format.utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-goal-excel',
  templateUrl: './add-goal-excel.component.html',
  styleUrls: ['./add-goal-excel.component.scss']
})
export class AddGoalExcelComponent {
  ApprisalSetUpForm!: FormGroup;
  selectedFiles: FileList | null = null;
  selectedOption: string = 'yearly';
  showDateInput1: boolean = true;
  showDateInput: boolean = false;
  fileToUpload: File | any = null;
  currentYear: number = new Date().getFullYear();
  previousYear: number = this.currentYear - 1;
  companyId: any;
  selectedYear = this.currentYear;
  fileError: boolean = false;
  setAppraisalWindow: boolean = false;
  isYearly = false;
  displayedColumns: string[] = ['type', 'startDate', 'endDate'];
  displayedData: any[] = [];
  displaySemiAnnualData: any[] = [];
  displaySemiAnnualSecondHalfData: any[] = [];
  selectedFileName: any;
  buttonLabel: any = "Upload Apprisal Excel";
  translate: any;
  annualYearSetting: string | undefined;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  firstHalfMinDate: Date | undefined;
  firstHalfMaxDate: Date | undefined;
  secondHalfMinDate: Date | undefined;
  secondHalfMaxDate: Date | undefined;
  isSettingSidebar: boolean = true;
  settingSidebar = sessionStorage.getItem('settingSideBar');

  constructor(private router: Router, private toastrService: ToastrService, private formBuilder: FormBuilder, private toasterService: ToastrService, private apprisalService: AppraisalServiceService) {
    const now = new Date()
    this.currentYear = now.getFullYear();
    this.previousYear = this.currentYear - 1;
    this.setAnnualDates();
  }

  ngOnInit() {

    if (this.settingSidebar === 'true') {
      this.isSettingSidebar = false;
    }
    this.selectedYear = this.currentYear;
    this.companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    this.initAnnualAppraisalSetup();
    this.getAnnualYearSetUp(this.currentYear);
    this.getAnnulaYearSetting();
    this.setAnnualDates();
    this.calculateDateRange();

  }

  onYearSelect(selectedYear: number) {
    this.getAnnualYearSetUp(selectedYear);
  }

  getAnnulaYearSetting() {
    this.apprisalService.getAnnualSetting(this.companyId).subscribe((data: any) => {
      this.annualYearSetting = data.data.appraisalCycle;
      this.setAnnualDates();
    })
  }



  getAnnualYearSetUp(selectedYear: number) {
    this.apprisalService.getAnnualApprisalSetup(this.companyId, selectedYear).subscribe((data: any) => {
      if (data.data === null) {
        this.setAppraisalWindow = true;
      } else {
        this.setAppraisalWindow = false;
        if (data.data.periodType === 'Annual') {
          this.isYearly = true;
          this.formatData(data.data);
        } else {
          this.isYearly = false;
          this.semiAnnualData(data.data);
        }
      }
    });
  }

  calculateDateRange() {
    if (this.annualYearSetting) {
      const annualStartDate = moment(this.annualYearSetting, 'DD-MM-YYYY').toDate();
      const startYear = moment(annualStartDate).year();

      this.minDate = new Date(startYear, 0, 1);
      this.maxDate = new Date(startYear, 11, 31);
    }
  }

  formatData(data: any) {
    this.displayedData = [
      { type: 'Annual Year', startDate: data.startDate, endDate: data.endDate },
      { type: 'Goal Setting', startDate: data.goalSettingStartDate, endDate: data.goalSettingEndDate },
      { type: 'Goal Review', startDate: data.goalReviewStartDate, endDate: data.goalReviewEndDate },
      { type: 'Goal Rating', startDate: data.goalRatingStartDate, endDate: data.goalRatingEndDate },
      { type: 'Goal Review Rating', startDate: data.goalRatingReviewStartDate, endDate: data.goalRatingReviewEndDate },
      { type: 'Rating Released', startDate: data.ratingReleaseStartDate, endDate: data.ratingReleaseEndDate }
    ].filter(row => row.startDate && row.endDate);
  }


  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  semiAnnualData(data: any) {
    this.displaySemiAnnualData = [
      { type: 'First Half Of Year', startDate: this.formatDate(new Date(data.firstHalfStartDate)), endDate: this.formatDate(new Date(data.firstHalfEndDate)) },
      { type: 'Goal Setting', startDate: this.formatDate(new Date(data.firstHalfGoalSettingStartDate)), endDate: this.formatDate(new Date(data.firstHalfGoalSettingEndDate)) },
      { type: 'Goal Review', startDate: this.formatDate(new Date(data.firstHalfGoalReviewStartDate)), endDate: this.formatDate(new Date(data.firstHalfGoalReviewEndDate)) },
      { type: 'Goal Rating', startDate: this.formatDate(new Date(data.firstHalfGoalRatingStartDate)), endDate: this.formatDate(new Date(data.firstHalfGoalRatingEndDate)) },
      { type: 'Goal Review Rating', startDate: this.formatDate(new Date(data.firstHalfGoalRatingReviewStartDate)), endDate: this.formatDate(new Date(data.firstHalfGoalRatingReviewEndDate)) },
      { type: 'Rating Released', startDate: this.formatDate(new Date(data.firstHalfRatingReleaseStartDate)), endDate: this.formatDate(new Date(data.firstHalfRatingReleaseEndDate)) }
    ].filter(row => row.startDate && row.endDate);

    this.displaySemiAnnualSecondHalfData = [
      { type: 'Second Half Of Year', startDate: this.formatDate(new Date(data.secondHalfStartDate)), endDate: this.formatDate(new Date(data.secondHalfStartDate)) },
      { type: 'Goal Setting', startDate: this.formatDate(new Date(data.secondHalfGoalSettingStartDate)), endDate: this.formatDate(new Date(data.secondHalfGoalSettingEndDate)) },
      { type: 'Goal Review', startDate: this.formatDate(new Date(data.secondHalfGoalReviewStartDate)), endDate: this.formatDate(new Date(data.secondHalfGoalReviewEndDate)) },
      { type: 'Goal Rating', startDate: this.formatDate(new Date(data.secondHalfGoalRatingStartDate)), endDate: this.formatDate(new Date(data.secondHalfGoalRatingEndDate)) },
      { type: 'Goal Review Rating', startDate: this.formatDate(new Date(data.secondHalfGoalRatingReviewStartDate)), endDate: this.formatDate(new Date(data.secondHalfGoalRatingReviewEndDate)) },
      { type: 'Rating Released', startDate: this.formatDate(new Date(data.secondHalfRatingReleaseStartDate)), endDate: this.formatDate(new Date(data.secondHalfRatingReleaseEndDate)) }
    ].filter(row => row.startDate && row.endDate);
  }

  initAnnualAppraisalSetup() {
    this.ApprisalSetUpForm = this.formBuilder.group({
      appraisalWindow: ['', Validators.required],
      year: ['', Validators.required],
      startDate: [{ value: '' }],
      endDate: [{ value: '' }],
      goalSettingStartDate: ['', Validators.required],
      goalSettingEndDate: ['', Validators.required],
      goalReviewStartDate: ['', Validators.required],
      goalReviewEndDate: ['', Validators.required],
      goalRatingStartDate: ['', Validators.required],
      goalRatingEndDate: ['', Validators.required],
      goalRatingReviewStartDate: ['', Validators.required],
      goalRatingReviewEndDate: ['', Validators.required],
      ratingReleaseStartDate: ['', Validators.required],
      ratingReleaseEndDate: ['', Validators.required],
      firstHalfStartDate: ['', Validators.required],
      firstHalfEndDate: ['', Validators.required],
      firstHalfGoalSettingStartDate: ['', Validators.required],
      firstHalfGoalSettingEndDate: ['', Validators.required],
      firstHalfGoalReviewStartDate: ['', Validators.required],
      firstHalfGoalReviewEndDate: ['', Validators.required],
      firstHalfGoalRatingStartDate: ['', Validators.required],
      firstHalfGoalRatingEndDate: ['', Validators.required],
      firstHalfGoalRatingReviewStartDate: ['', Validators.required],
      firstHalfGoalRatingReviewEndDate: ['', Validators.required],
      firstHalfRatingReleaseStartDate: ['', Validators.required],
      firstHalfRatingReleaseEndDate: ['', Validators.required],
      secondHalfStartDate: ['', Validators.required],
      secondHalfEndDate: ['', Validators.required],
      secondHalfGoalSettingStartDate: ['', Validators.required],
      secondHalfGoalSettingEndDate: ['', Validators.required],
      secondHalfGoalReviewStartDate: ['', Validators.required],
      secondHalfGoalReviewEndDate: ['', Validators.required],
      secondHalfGoalRatingStartDate: ['', Validators.required],
      secondHalfGoalRatingEndDate: ['', Validators.required],
      secondHalfGoalRatingReviewStartDate: ['', Validators.required],
      secondHalfGoalRatingReviewEndDate: ['', Validators.required],
      secondHalfRatingReleaseStartDate: ['', Validators.required],
      secondHalfRatingReleaseEndDate: ['', Validators.required],
      periodType: ['']
    });
  }

  option1() {
    this.selectedOption = 'yearly';
    this.showDateInput1 = true;
    this.showDateInput = false;
  }

  option2() {
    this.selectedOption = 'semiannual';
    this.showDateInput = true;
    this.showDateInput1 = false;
    this.setSemiAnnualDates();

  }

  triggerFileInput() {
    document.getElementById('fileInput')?.click();
  }

  selectFile(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles) {
      this.fileToUpload = this.selectedFiles.item(0);
      this.fileError = false;
      this.selectedFileName = this.fileToUpload.name;
    }
  }

  onSubmit() {
    if (!this.fileToUpload) {
      this.fileError = true;
      return;
    }
    const formValue = this.ApprisalSetUpForm.value;
    let formData: FormData = new FormData();
    if (this.fileToUpload) {
      formData.append('file', this.fileToUpload, this.fileToUpload.name);
    }

    let startDate: any;
    if (typeof (formValue.startDate) == 'string') {
      startDate = FormatUtil.adjustDateForTimeOffset(new Date(formValue.startDate));
    } else {
      startDate = FormatUtil.adjustDateForTimeOffset(formValue.startDate);
    }

    const appraisalDto = {
      companyId: this.companyId,
      year: formValue.year,
      periodType: AppConstant.ANNUAL,
      startDate: startDate,
      endDate: formValue.endDate,
      goalSettingStartDate: formValue.goalSettingStartDate,
      goalSettingEndDate: formValue.goalSettingEndDate,
      goalReviewStartDate: formValue.goalReviewStartDate,
      goalReviewEndDate: formValue.goalReviewEndDate,
      goalRatingStartDate: formValue.goalRatingStartDate,
      goalRatingEndDate: formValue.goalRatingEndDate,
      goalRatingReviewStartDate: formValue.goalRatingReviewStartDate,
      goalRatingReviewEndDate: formValue.goalRatingReviewEndDate,
      ratingReleaseStartDate: formValue.ratingReleaseStartDate,
      ratingReleaseEndDate: formValue.ratingReleaseEndDate
    };
    const semiAnnualApprisalDto = {
      companyId: this.companyId,
      periodType: AppConstant.SEMIANNUAL,
      year: formValue.year,
      firstHalfStartDate: formValue.firstHalfStartDate,
      firstHalfEndDate: formValue.firstHalfEndDate,
      firstHalfGoalSettingStartDate: formValue.firstHalfGoalSettingStartDate,
      firstHalfGoalSettingEndDate: formValue.firstHalfGoalSettingEndDate,
      firstHalfGoalReviewStartDate: formValue.firstHalfGoalReviewStartDate,
      firstHalfGoalReviewEndDate: formValue.firstHalfGoalReviewEndDate,
      firstHalfGoalRatingStartDate: formValue.firstHalfGoalRatingStartDate,
      firstHalfGoalRatingEndDate: formValue.firstHalfGoalRatingEndDate,
      firstHalfGoalRatingReviewStartDate: formValue.firstHalfGoalRatingReviewStartDate,
      firstHalfGoalRatingReviewEndDate: formValue.firstHalfGoalRatingReviewEndDate,
      firstHalfRatingReleaseStartDate: formValue.firstHalfRatingReleaseStartDate,
      firstHalfRatingReleaseEndDate: formValue.firstHalfRatingReleaseEndDate,
      secondHalfStartDate: formValue.secondHalfStartDate,
      secondHalfEndDate: formValue.secondHalfEndDate,
      secondHalfGoalSettingStartDate: formValue.secondHalfGoalSettingStartDate,
      secondHalfGoalSettingEndDate: formValue.secondHalfGoalSettingEndDate,
      secondHalfGoalReviewStartDate: formValue.secondHalfGoalReviewStartDate,
      secondHalfGoalReviewEndDate: formValue.secondHalfGoalReviewEndDate,
      secondHalfGoalRatingStartDate: formValue.secondHalfGoalRatingStartDate,
      secondHalfGoalRatingEndDate: formValue.secondHalfGoalRatingEndDate,
      secondHalfGoalRatingReviewStartDate: formValue.secondHalfGoalRatingReviewStartDate,
      secondHalfGoalRatingReviewEndDate: formValue.secondHalfGoalRatingReviewEndDate,
      secondHalfRatingReleaseStartDate: formValue.secondHalfRatingReleaseStartDate,
      secondHalfRatingReleaseEndDate: formValue.secondHalfRatingReleaseEndDate,
    };

    if (this.selectedOption === 'yearly') {
      formData.append('appraisalDto', JSON.stringify(appraisalDto));
    } else {
      formData.append('appraisalDto', JSON.stringify(semiAnnualApprisalDto));
    }
    this.apprisalService.uploadAppraisalData(formData).subscribe(data => {

      if (data.status === 200) {
        this.getAnnualYearSetUp(this.currentYear);
        this.toastrService.clear();
        this.toastrService.success(data.message);
        this.ApprisalSetUpForm.reset();
      } else {
        this.toastrService.clear();
        this.toastrService.error(data.message);
      }
    });
  }

  setAnnualDates() {

    let startDate: moment.Moment | null = null;
    let endDate: moment.Moment | null = null;

    const currentYear = moment().year();

    if (this.annualYearSetting?.toLowerCase() === 'january to december') {
      startDate = moment({ year: currentYear, month: 0, day: 1 });
      endDate = moment({ year: currentYear, month: 11, day: 31 });
    } else if (this.annualYearSetting?.toLowerCase() === 'april to march') {
      startDate = moment({ year: currentYear, month: 3, day: 1 });
      endDate = moment({ year: currentYear + 1, month: 2, day: 31 });
    }

    if (startDate && endDate) {
      this.ApprisalSetUpForm.patchValue({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      });
      this.minDate = startDate.toDate();
      this.maxDate = endDate.toDate();
    }
  }

  setSemiAnnualDates() {
    const currentYear = moment().year();
    let firstHalfStartDate: moment.Moment | null = null;
    let firstHalfEndDate: moment.Moment | null = null;
    let secondHalfStartDate: moment.Moment | null = null;
    let secondHalfEndDate: moment.Moment | null = null;

    if (this.annualYearSetting?.toLowerCase() === 'january to december') {
      firstHalfStartDate = moment({ year: currentYear, month: 0, day: 1 });
      firstHalfEndDate = moment({ year: currentYear, month: 5, day: 30 });
      secondHalfStartDate = moment({ year: currentYear, month: 6, day: 1 });
      secondHalfEndDate = moment({ year: currentYear, month: 11, day: 31 });
    } else if (this.annualYearSetting?.toLowerCase() === 'april to march') {
      firstHalfStartDate = moment({ year: currentYear, month: 3, day: 1 });
      firstHalfEndDate = moment({ year: currentYear, month: 8, day: 30 });
      secondHalfStartDate = moment({ year: currentYear, month: 9, day: 1 });
      secondHalfEndDate = moment({ year: currentYear + 1, month: 2, day: 31 });
    }

    if (firstHalfStartDate && firstHalfEndDate && secondHalfStartDate && secondHalfEndDate) {
      this.ApprisalSetUpForm.patchValue({
        firstHalfStartDate: firstHalfStartDate.format('DD-MM-YYYY'),
        firstHalfEndDate: firstHalfEndDate.format('DD-MM-YYYY'),
        secondHalfStartDate: secondHalfStartDate.format('DD-MM-YYYY'),
        secondHalfEndDate: secondHalfEndDate.format('DD-MM-YYYY')
      });

      this.firstHalfMinDate = firstHalfStartDate.toDate();
      this.firstHalfMaxDate = firstHalfEndDate.toDate();

      this.secondHalfMinDate = secondHalfStartDate.toDate();
      this.secondHalfMaxDate = secondHalfEndDate.toDate();
    }
  }

  goBack() {
    this.router.navigate([AppConstant.ADMIN+ AppConstant.SEPERATOR+AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

}


