<app-toolbar></app-toolbar>
<div class="page-heading" *ngIf="isAsset">
    <mat-icon matTooltip="{{'previous_page' | translate}}" routerLink="../../asset-details" svgIcon="arrow-back-double"
        class="rewind"></mat-icon>
    <h3>{{'view_asset_timeline'|translate}}</h3>
</div>
<div class="page-heading" *ngIf="!isAsset">
    <mat-icon matTooltip="{{'previous_page' | translate}}" routerLink="../../supplier-details"
        svgIcon="arrow-back-double" class="rewind"></mat-icon>
    <h3>{{'view_supplier_timeline'|translate}}</h3>
</div>
<mat-divider></mat-divider>
<div class="main-container">
    <div class="timeline" *ngIf="isAsset">
        <div *ngFor="let entry of timeLine.slice().reverse(); let i = index">
            <div class="container" [ngClass]="{ left: i % 2 !== 0, right: i % 2 === 0 }">
                <div class="content">
                    <p><strong>{{ 'asset_id' | translate }}</strong>: {{ entry.assetsId }}</p>
                    <p><strong>{{ 'employee_id' | translate }}</strong> : {{ entry.employeeId }}</p>
                    <p><strong>{{ 'employee_name' | translate }}</strong> : {{ entry.employeeName }}</p>
                    <p><strong>{{ 'assign_date' | translate }}</strong> : {{ entry.assignDate }}</p>
                    <p><strong>{{ 'handover_date' | translate }}</strong>: {{ entry.handoverDate }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="timeline" *ngIf="!isAsset">
        <div *ngFor="let entry of timeLine.slice().reverse(); let i = index">
            <div class="container" [ngClass]="{ left: i % 2 !== 0, right: i % 2 === 0 }">
                <div class="content">
                    <p><strong>{{ 'supplier_id' | translate }}</strong> : {{ entry.supplierId }}</p>
                    <p><strong>{{ 'asset_type' | translate }}</strong> : {{ entry.assetType }}</p>
                    <p><strong>{{ 'quantity' | translate }}</strong> : {{ entry.quantity }}</p>
                    <p><strong>{{ 'order_date' | translate }}</strong> : {{ entry.orderDate }}</p>
                    <p><strong>{{ 'receive_date' | translate }}</strong>: {{ entry.receiveDate }}</p>
                    <p><strong>{{ 'status' | translate }}</strong>: {{ entry.status }}</p>
                </div>
            </div>
        </div>
    </div>
</div>