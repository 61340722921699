<div class="main-container">
    <h3>{{ dashboardId ? ('Update Board' | translate) : ('add_board' | translate) }}</h3>
    <div>
        <mat-divider></mat-divider>
    </div>
    <div>
        <form [formGroup]="boardForm">

            <div class="project-content">
                <mat-form-field id="projectCode" appearance="outline" class="full-width">
                    <mat-label>{{ 'project_code' | translate }}</mat-label>
                    <mat-select formControlName="projectId" (selectionChange)="onProjectSelect($event)">
                        <mat-option *ngFor="let option of projectCodeOptions" [value]="option.id">
                            {{ option.projectCode }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="boardForm.controls['projectId'].hasError('required')">
                        {{ 'project_code_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'board_name'|translate}}</mat-label>
                    <input class="Input-field" matInput type="text" maxlength="30"
                        (keypress)="characterNumberAndSpaceAndHyphenAllowed($event)" formControlName="boardName">
                    <mat-error
                        *ngIf="boardForm.get('boardName')?.hasError('required') || boardForm.get('boardName')?.hasError('noOnlySpaces')">
                        {{ 'board_name_is_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="project-row">

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'start_date' | translate }}</mat-label>
                    <input matInput [matDatepicker]="startPicker" [min]="startDateMin" [max]="endDateMax"
                        formControlName="startDate" />
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                    <mat-error *ngIf="boardForm.controls['startDate'].hasError('required')">
                        {{ 'start_date_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'end_date' | translate }}</mat-label>
                    <input matInput [matDatepicker]="endPicker" [min]="startDateMin" [max]="endDateMax"
                        formControlName="endDate" />
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                    <mat-error *ngIf="boardForm.controls['endDate'].hasError('required')">
                        {{ 'end_date_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

            </div>

            <div class="project-row">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'board_owner' | translate }}</mat-label>
                    <mat-select matInput formControlName="boardOwnerEmpId" class="select-mat-input">
                        <mat-option *ngFor="let manager of managerList" [value]="manager.id">{{ manager.firstName }} {{
                            manager.lastName }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="boardForm.controls['boardOwnerEmpId'].hasError('required')">
                        {{ 'board_owner_required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'collaborators' | translate }}</mat-label>
                    <mat-select #select multiple formControlName="collaborators" class="select-mat-input">
                      <div class="select-all">
                        <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                      (change)="toggleAllSelection()">
                          {{ 'select_all' | translate }}
                        </mat-checkbox>
                      </div>
                      <!-- <mat-option *ngFor="let employee of employeeData" [value]="employee.id"
                                  (click)="optionClicked()">
                        {{ employee.employeeName }}
                      </mat-option> -->

                      <mat-option *ngFor="let employee of employeeData" [value]="employee.id">
                        {{ employee.employeeName }}
                      </mat-option>
                      <mat-divider></mat-divider>
                      <button class="button add-collaborators" (click)="openAddCollaboratorsPopup(select)">
                        {{ 'add_collaborators' | translate }}
                      </button>
                    </mat-select>
                    <mat-error *ngIf="boardForm.controls['collaborators'].hasError('required')">
                      {{ 'collaborators_required' | translate }}
                    </mat-error>
                  </mat-form-field>
                  
                  
            </div>

            <div class="project-row">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'description'|translate}}</mat-label>
                    <textarea matInput formControlName="description" rows="2" maxlength="100"></textarea>
                    <mat-error *ngIf="boardForm.controls['description'].hasError('required')">
                        {{ 'description_is_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="button-div">
        <button (click)="closeDialog()" class="cancel-btn">{{'cancel'|translate}}</button>
        <button (click)="saveBoard()">{{ (dashboardId ? 'update' : 'save') | translate }}</button>
    </div>

    <app-loader></app-loader>

    <div *ngIf="showPopup" class="popup-overlay" (click)="closePopup()">
        <form [formGroup]="addcollaboratorsForm">
            <div class="popup-content" (click)="$event.stopPropagation()">
                <h3>{{ 'add_collaborators' | translate }}</h3>
                <mat-form-field appearance="outline" class="add-colabborator full-width">
                  <mat-label>{{ 'collaborators' | translate }}</mat-label>
                  <mat-select #popupSelect multiple class="select-mat-input" formControlName="addNewcollaborators">
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allSelectedInPopup" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelectionInPopup()">
                        {{ 'select_all' | translate }}
                      </mat-checkbox>
                    </div>
                    <mat-option *ngFor="let employee of employeeList" [value]="employee.id" (click)="selectNewCollaborators()">
                      {{ employee.firstName }} {{ employee.lastName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="popup-actions">
                  <button class="button" (click)="saveCollaborators()">{{ 'save' | translate }}</button>
                  <button class="cancel-btn" (click)="closePopup()">{{ 'cancel' | translate }}</button>
                </div>
              </div>
        </form>
    </div>
</div>