import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { API_END_POINTS } from '../config/api-endpoint.config';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {

  constructor(private http:HttpClient) { }

  getDownloadHoliday(): Observable<Blob> {
    return this.http.get(environment.apiUrl + API_END_POINTS.UPLOAD_BULK_HOLIDAY, { responseType: 'blob' });
  }
  
  uploadBulkHolidayData(uploadHoliday: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.UPLOAD_HOLIDAY_BULK_DATA, uploadHoliday, { reportProgress: true, observe: 'events' }).pipe((res: any) => {
      return res;
    })
  }




}
