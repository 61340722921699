<app-toolbar></app-toolbar>
<div class="main-container">

  <div class="supplierTab">

    <div class="heading">
      <div class="back-button">
        <mat-icon matTooltip="{{'previous_page' | translate}}" svgIcon="arrow-back-double" class="rewind"
          class="back-arrow-icon" (click)="goToBack()">keyboard_arrow_left</mat-icon>
        <h3 class="headerName">{{'order_for_asset'|translate}}</h3>
      </div>
      <div class="button-div">
        <button (click)="orderAsset()">{{'order_asset'|translate}}</button>
      </div>
    </div>

    <div class="viewAllRequest">
      <ag-grid-angular class="ag-theme-alpine" #agGrid style="width: 100%; height:470px" id="myGrid"
        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData" (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClicked($event)" editType="fullRow" [suppressClickEdit]="true"
        (rowEditingStarted)="onRowEditingStarted($event)" (rowEditingStopped)="onRowEditingStopped($event)"
        [gridOptions]="gridOptions">
      </ag-grid-angular>
    </div>
  </div>

</div>