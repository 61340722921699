import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ExitProcessService } from 'src/app/services/exit-process/exit-process.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { employeeExitsDetails } from 'src/app/model/exit-model/exit-model/exit-model.module';
import { ExitsReasonData } from 'src/app/constants/master-data/exit-reason.constant';
import { ResignationService } from 'src/app/services/resignation/resignation.service';
import { FormatUtil } from 'src/app/utils/format.utils';

@Component({
  selector: 'app-exit-details',
  templateUrl: './exit-details.component.html',
  styleUrls: ['./exit-details.component.scss']
})
export class ExitDetailsComponent {
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId = sessionStorage.getItem('employeeId');
  exitsForm !: FormGroup;
  reasonOfExits: any = ExitsReasonData.SALUTATIONS
  EmployeeData: any;
  employeeName: String | undefined;
  doj: any;
  departmentName: string | undefined;
  designationName: string | undefined;
  lastName: string | undefined;
  employeeImage !: string;
  showPopUP: boolean = false;
  isAlreasdyExit: boolean = false;
  employeeEmail: any;
  isReadOnly: boolean = true;
  minDate = new Date();
  minDate2: Date | undefined;
  maxFinalSettlementDate: Date = new Date();
  minFinalSettlementDate: Date = new Date();
  selectedDate: Date | any;

  constructor(private datePipe: DatePipe, private employeeService: EmployeeDetailsService, private route: ActivatedRoute, private employeeExitService: ExitProcessService, private router: Router, private fb: FormBuilder, private toastrService: ToastrService, private resignationService: ResignationService) { }

  ngOnInit(): void {
    this.initExitForm();
    this.getEmployeeExitDetails();
    this.getEmployeeProfileImage();
    this.getExitEmployeeById();
    this.toggleFormReadOnly();
  }

  initExitForm() {
    this.exitsForm = this.fb.group({
      'lastWorkingDay': ['', Validators.required],
      'reason': ['', [Validators.required, Validators.minLength(8)]],
      'alternateEmail': [''],
      'finalSettlementDate': ['', Validators.required],
      'remark': ['']
    });

    this.exitsForm.get('lastWorkingDay')?.valueChanges.subscribe(value => {
      if (value) {
        this.minDate2 = value;
      }
    });
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  getEmployeeExitDetails() {
    this.employeeService.getActiveEmployeeDetails(this.companyId, this.employeeId).subscribe((data: any) => {
      this.employeeEmail = data?.employeePersonalDetail.email;
      this.employeeName = data?.employeeBasicDetail?.firstName;
      this.lastName = data?.employeeBasicDetail?.lastName;
      this.doj = data?.employeeBasicDetail?.doj;
      this.departmentName = data?.employeeBasicDetail?.departmentName;
      this.designationName = data?.employeeBasicDetail?.designationName;
      this.employeeId = data.data.id;
    });
    this.exitsForm.patchValue({
      'alternateEmail': this.employeeEmail
    });
  }
  getEmployeeProfileImage() {
    this.employeeService.getEmployeeImage(this.companyId, this.employeeId).subscribe((data: any) => {
      this.employeeImage = data?.image;
      this.employeeImage = FormatUtil.convertBase64ToDataUrl(data?.image);
    })
  }

  process() {
    if (this.isReadOnly && this.isAlreasdyExit) {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.FINAL_SETTLEMENT]);

    }
    if (this.exitsForm.invalid) {
      this.exitsForm.markAllAsTouched();
      return;
    } else {
      this.showPopUP = true;
    }
  }

  proceed() {
    if (!this.isAlreasdyExit || !this.isReadOnly) {
      const lastWorkingDay = new Date(this.exitsForm.value.lastWorkingDay);
      const finalSettlementDate = new Date(this.exitsForm.value.finalSettlementDate);

      finalSettlementDate.setDate(finalSettlementDate.getDate() + 1);
      lastWorkingDay.setDate(lastWorkingDay.getDate() + 1)
      let exitEmployeeDetails: employeeExitsDetails = {
        lastWorkingDay: lastWorkingDay.toISOString(),
        reason: this.exitsForm.value.reason,
        finalSettlementDate: finalSettlementDate.toISOString(),
        remark: this.exitsForm.value.remark,
        companyId: this.companyId,
        employeeId: this.employeeId
      };

      this.employeeExitService.employExitProcess(exitEmployeeDetails).subscribe((data: any) => {
        if (data.status == 200) {
          this.toastrService.clear();
          this.toastrService.success(data.message);
          this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.FINAL_SETTLEMENT])

        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
          this.showPopUP = false;
          this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.EXIT_DETAILS]);
        }
      });
    } else {
      if (!this.isReadOnly) {
        this.showPopUP = true;
      } else {
        this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.FINAL_SETTLEMENT]);
      }
    }
  }

  convertToUTCString(date: Date): string {
    const offsetMinutes = date.getTimezoneOffset();

    const adjustedDate = new Date(date.getTime() + (offsetMinutes * 60 * 1000));

    return adjustedDate.toISOString();
  }

  cancel() {
    if (this.isReadOnly) {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES])
    }
  }

  closePopup() {
    this.showPopUP = false;
  }

  getExitEmployeeById() {
    this.resignationService.getResignation(this.companyId, this.employeeId).subscribe((data: any) => {
      if (data && data.data) {
        this.isAlreasdyExit = true;
        const resignationData = data.data;
        this.exitsForm.patchValue({
          'lastWorkingDay': resignationData.lastWorkingDay,
          'reason': resignationData.reason,
          'alternateEmail': resignationData.alternateEmail || '',
          'finalSettlementDate': resignationData.finalSettlementDate,
          'remark': resignationData.remark || ''
        });
        this.isReadOnly = true;
      } else {
        this.isAlreasdyExit = false;
        this.isReadOnly = false;
      }
      this.toggleFormReadOnly();
    });
  }

  toggleEdit() {
    this.isAlreasdyExit = false;
    this.isReadOnly = !this.isReadOnly;
    this.toggleFormReadOnly();
  }

  toggleFormReadOnly() {
    const formControls = this.exitsForm.controls;
    Object.keys(formControls).forEach(key => {
      const element = document.querySelector(`[formcontrolname="${key}"]`) as HTMLInputElement;
      if (element && key === 'alternateEmail') {
        element.readOnly = true;
      } else if (element && ['lastWorkingDay', 'finalSettlementDate'].includes(key)) {
        const pickerToggle = element.parentElement?.querySelector('.mat-datepicker-toggle') as HTMLButtonElement;
        if (pickerToggle) {
          pickerToggle.disabled = this.isReadOnly;
        }
      } else if (element) {
        element.readOnly = this.isReadOnly;
      }
    });
  }
  calculateMaxFinalSettlementDate(lastWorkingDate: Date): Date {
    const maxDate = new Date(lastWorkingDate);
    maxDate.setDate(maxDate.getDate() + 45);
    return maxDate;
  }

  onLastWorkingDateChange(event: any) {
    const selectedDate = new Date(event.value);
    this.minFinalSettlementDate = selectedDate;
    this.maxFinalSettlementDate = this.calculateMaxFinalSettlementDate(selectedDate);
  }

  goToBack() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE + AppConstant.SEPERATOR + this.employeeId]);
  }
}
