import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class AssetManagementDashboardService {

  constructor(private http: HttpClient) { }

  getCountOfData(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ASSET_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getYetToReceiveTableData(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.YET_TO_RECEIVE_ASSET + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAssetRequest(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ASSET_REQUEST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAllAssetsList(assetType: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_All_ASSET_DETAILS + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + assetType).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAssetTypeList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_ASSET_TYPE_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getSupplierAssetTypeList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SUPPLIER_ASSET_TYPE_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getModelNameList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_MODEL_NAME_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getSupplierList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SUPPLIER_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAssetAllocationList(assetType: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ASSET_ALLOCATION_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + assetType).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAllAssetsHistory(assetType: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ASSET_HISTORY + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + assetType).pipe(
      map((res: any) => {
        return res;
      })
    );
  }


  assetForm(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_ASSET, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  assignAssetForm(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ASSIGN_ASSET, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAssetHistoryById(paramId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ASSET_HISTORY_BY_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + paramId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getSupplierHistoryById(paramId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_SUPPLIER_HISTORY_BY_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + paramId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAllSupplier(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SUPPLIER + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addSupplier(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_SUPPLIER, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  requestAssetSupplier(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.REQUEST_ASSET_SUPPLER + sessionStorage.getItem(AppConstant.COMPANY_ID), dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getSupplierDeleteById(id: any) {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_SUPPLIER_BY_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  deleteOrderRequest(id: any) {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_ORDER_FOR_ASSET + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAllRequestAsset(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_REQUEST_ASSET + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getSupplierById(id: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_SUPPLIER_INFO + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  editSupplier(id: any, dto: any) {
    return this.http.put(environment.apiUrl + API_END_POINTS.EDIT_SUPPLIER + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + id, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAssetDetailsById(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_ASSET_BY_EMPLOYEE_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  updateRequestForAsset(dto: any) {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_ASSET_ORDER + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + dto.requestId, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  deleteAsset(id: any) {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_ASSET + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAssetListByAssetType(assetType: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ASSET_LIST_BY_ASSET_TYPE + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + assetType).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getActiveAssetTypeList(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ACTIVE_ASSET_TYPE + companyId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addAssettype(assetType: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ASSET_TYPE, assetType).pipe(
      map((res: any) => {
        return res;
      })
    )
  }
}
