<app-toolbar></app-toolbar>
<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToBack()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{'view_request'|translate}}</h3>
    </div>
    <mat-divider></mat-divider>
</div>
<div class="main-container">
    <form [formGroup]="viewRequest">
        <div class="form">
            <div class="form-field">
                <span class="label">{{'number' |translate}} :</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input class="Input-field" matInput type="text" formControlName="number" required="*">
                    <mat-error *ngIf="viewRequest.controls['number'].hasError('required')">
                        {{'number_is_required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-field">
                <span class="label">{{'open_date'|translate}} :</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input matInput [matDatepicker]="datePicker" formControlName="openDate">
                    <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                    <mat-error *ngIf="viewRequest.get('openDate')?.hasError('required')">{{'choose_open_date' |
                        translate}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form">
            <div class="form-field">
                <span class="label">{{'requestfor:'|translate}}</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input class="Input-field" matInput type="text" formControlName="requestFor" required="*">
                    <mat-error
                        *ngIf="viewRequest.controls['requestFor'].hasError('required')">{{'request_for_is_required'|translate}}</mat-error>
                </mat-form-field>
            </div>

            <div class="form-field" *ngIf="resolutionView">
                <span class="label">{{ 'assign_to' | translate }} :</span>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{ 'assign_to' | translate }}</mat-label>
                  <mat-select formControlName="assignTo" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search 
                        [placeholderLabel]="'Search'" 
                        [formControl]="personSearchFilter"
                        noEntriesFoundLabel="No results found" 
                        [searching]="searching"
                        [preventHomeEndKeyPropagation]="true">
                      </ngx-mat-select-search>
                    </mat-option>
                

                    <mat-option *ngFor="let employee of filteredServerSides" [value]="employee.employeeId">
                        {{ employee.employeeName }}
                      </mat-option>
                      
                  </mat-select>
                </mat-form-field>
              </div>
              

            <div class="form-field">
                <span class="label">{{'employee_id:'|translate}}</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input class="Input-field" matInput type="text" formControlName="employeeId" required="*">
                </mat-form-field>
            </div>
        </div>

        <div class="form">
            <div class="form-field">
                <span class="label">{{'priority:'|translate}}</span>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-select formControlName="priority">
                        <div *ngFor="let priority of priority">
                            <mat-option [value]="priority">{{priority}}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-field">
                <span class="label">{{'status:'|translate}}</span>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-select formControlName="status" (selectionChange)="updateStatus()">
                        <div *ngFor="let status of requestStatus">
                            <mat-option [value]="status">{{status}}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form">
            <div class="form-field">
                <span class="label">{{'type'|translate}} :</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input class="Input-field" matInput type="text" formControlName="type" required="*">
                    <mat-error *ngIf="viewRequest.controls['type'].hasError('required')">
                        {{'type_is_required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-field">
                <span class="label">{{'number'|translate}} :</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input class="Input-field" matInput type="text" (keypress)="numberOnly($event)" maxlength="10"
                        formControlName="contactNumber" [disabled]="true" required="*">
                </mat-form-field>
            </div>
        </div>

        <div class="form">
            <div class="form-field">
                <span class="label">{{'service:'|translate}}</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input class="Input-field" matInput type="text" formControlName="service" required="*">

                </mat-form-field>
            </div>
            <div class="form-field">
                <span class="label">{{'location:'|translate}}</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input class="Input-field" matInput type="text" formControlName="location" required="*">

                </mat-form-field>
            </div>
        </div>

        <div class="form" *ngIf="showIncidence">
            <div class="form-field">
                <span class="label">{{'category:'|translate}}</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input class="Input-field" matInput type="text" formControlName="category" required="*">
                </mat-form-field>
            </div>
            <div class="form-field">
                <span class="label">{{'sub_category:'|translate}}</span>
                <mat-form-field class="full-width" appearance="outline">
                    <input class="Input-field" matInput type="text" formControlName="subCategory" required="*">
                </mat-form-field>
            </div>
        </div>

        <div class="form">
            <div class="form-field">
                <span class="label">{{'short_desc:'|translate}}</span>
                <mat-form-field class="full-width" style="height: 100px;" appearance="outline">
                    <textarea matInput placeholder="short Description" formControlName="shortDescription" maxlength="50"
                        required="*"></textarea>

                </mat-form-field>
            </div>
            <div class="form-field">
                <span class="label">{{'long_desc:'|translate}}</span>
                <mat-form-field class="full-width" style="height: 100px;" appearance="outline">
                    <textarea matInput placeholder="Long Description" formControlName="longDescription" maxlength="150"
                        required="*"></textarea>

                </mat-form-field>
            </div>
        </div>


    </form>
</div>