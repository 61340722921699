<app-toolbar></app-toolbar>
<div class="main-container">
  <div class="header">
    <div class="back-button">
      <mat-icon svgIcon="arrow-back-double" class="rewind" (click)="backToEmployeeProfile()"></mat-icon>
      <h3>{{ "family_and_emergency_contact_details" | translate }}</h3>
    </div>
  </div>
  <mat-divider class="header-divider"></mat-divider>
  <div class="container">
    <form [formGroup]="createFamilyAndEmergencyForm">
      <div class="form">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ "salutation" | translate }}</mat-label>
          <mat-select formControlName="salutation" class="select">
            <mat-option *ngFor="let salutation of salutations" [value]="salutation.name">
              {{ salutation.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ "first_name" | translate }}</mat-label>
          <input matInput type="text" class="Input-field" (keypress)="validateCharacterNumberAndSpace($event)"
            maxlength="20" formControlName="firstName" required />
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.controls['firstName'].hasError(
                'required'
              )
            ">
            {{ "first_name_is_required" | translate }}
          </mat-error>
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.controls['firstName'].hasError(
                'invalidInput'
              )
            ">
            {{ "invalid_name_input" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="formLabel">{{
            "middle_name" | translate
            }}</mat-label>
          <input matInput type="text" class="Input-field" formControlName="middleName" maxlength="20"
            (keypress)="validateCharacterNumberAndSpace($event)" />
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.controls['middleName'].hasError(
                'required'
              )
            ">
            {{ "middle_name_is_required" | translate }}
          </mat-error>
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.controls['middleName'].hasError(
                'invalidInput'
              )
            ">
            {{ "invalid_name_input" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="formLabel">{{ "last_name" | translate }}</mat-label>
          <input matInput type="text" class="Input-field" formControlName="lastName" maxlength="20"
            (keypress)="validateCharacterNumberAndSpace($event)" required />
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.controls['lastName'].hasError(
                'required'
              )
            ">
            {{ "last_name_is_required" | translate }}
          </mat-error>
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.controls['lastName'].hasError(
                'invalidInput'
              )
            ">
            {{ "invalid_name_input" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ "gender" | translate }}</mat-label>
          <mat-select class="select-mat-input" formControlName="gender" required>
            <mat-option value="Male">{{ "male" | translate }}</mat-option>
            <mat-option value="Female">{{ "female" | translate }}</mat-option>
            <mat-option value="Other">{{ "other" | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="!createFamilyAndEmergencyForm.controls['gender'].valid">{{ "gender_is_required" |
            translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ "relationship" | translate }}</mat-label>
          <mat-select class="select-mat-input" formControlName="relationship" required>
            <div *ngFor="let relationships of employeeEmergencyContactInfo">
              <mat-option [value]="relationships">{{
                relationships | translate
                }}</mat-option>
            </div>
          </mat-select>
          <mat-error *ngIf="!createFamilyAndEmergencyForm.controls['relationship'].valid">{{
            "relationship_is_required" | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ "relationship_start_date" | translate }}</mat-label>
          <input matInput [matDatepicker]="picker" [min]="employeeDob" formControlName="relationshipStartDate"
            (keypress)="$event.preventDefault()" />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="
              !createFamilyAndEmergencyForm.controls['relationshipStartDate']
                .valid
            ">{{ "relationship_start_date_is_required" | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div class="form">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label class="formLabel">{{
            "family_contact_number" | translate
            }}</mat-label>
          <input class="Input-field" matInput id="contactNo" (keypress)="validateNumber($event)" minlength="10"
            maxlength="10" formControlName="contactNo" required />
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.controls['contactNo'].hasError(
                'required'
              )
            ">
            {{ "contact_number_required" | translate }}
          </mat-error>
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.controls['contactNo'].hasError(
                'invalidContactNumber'
              )
            ">
            {{ "invalid_contact_number" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="formLabel">{{ "email" | translate }}</mat-label>
          <input matInput type="text" class="Input-field" formControlName="emailAddress" required />
          <mat-error> {{ "email_is_required" | translate }} </mat-error>
        </mat-form-field>
      </div>

      <div class="contain">
        <section class="example-section">
          <mat-checkbox formControlName="emergencyContactCheck" class="example-margin">{{
            "this_person_is_an_emergency_contact" | translate
            }}</mat-checkbox>
        </section>
      </div>
      <mat-divider class="field-header"></mat-divider>
      <div class="form-field-gap4">
        <div class="sub-heading">
          <h3 class="sub-heading">{{ "address" | translate }}</h3>
        </div>
        <section class="address-section">
          <mat-radio-group [(ngModel)]="selectedOption" formControlName="addressType">
            <mat-radio-button class="example-margin" value="employeeExistingAddress"
              (click)="employeeExistingAddressChecked()">{{ "use_my_address" | translate
              }}</mat-radio-button>
            <mat-radio-button class="example-margin" value="newAddress" (click)="newAddressChecked()">{{
              "add_a_new_address" | translate }}</mat-radio-button>
          </mat-radio-group>
        </section>
      </div>
      <div class="form" *ngIf="newAddressFlag">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="formLabel">{{ "address1" | translate }}</mat-label>
          <input matInput type="text" class="Input-field" maxlength="40" formControlName="addressLine1" required
            (keypress)="addressVerification($event)" />
          <mat-error>{{ "address_line_is_required" | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="formLabel">{{ "address2" | translate }}</mat-label>
          <input matInput type="text" class="Input-field" maxlength="40" formControlName="addressLine2"
            (keypress)="addressVerification($event)" />
        </mat-form-field>
      </div>

      <div class="form" *ngIf="newAddressFlag">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ "country" | translate }}</mat-label>
          <mat-select matInput formControlName="country" class="select_mat_input" required="true"
            (selectionChange)="getState($event.value)">
            <mat-option *ngFor="let country of countryList" [value]="country">{{ country }}
            </mat-option>
          </mat-select>
          <mat-error>{{ "country_is_required" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ "state" | translate }}</mat-label>
          <mat-select matInput formControlName="state" class="select-mat-input" required="true"
            (selectionChange)="getCity($event.value)">
            <mat-option *ngFor="let state of stateList" [value]="state">
              {{ state }}
            </mat-option>
          </mat-select>
          <mat-error>{{ "state_is_required" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ "city" | translate }}</mat-label>
          <mat-select matInput formControlName="city" class="select-mat-input"
            (selectionChange)="selectedCityName($event.value)">
            <mat-option *ngFor="let city of cityList" [value]="city">{{
              city
              }}</mat-option>
          </mat-select>

          <mat-error>{{ "city_is_required" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ "pincode" | translate }}</mat-label>
          <input (keypress)="validateNumber($event)" matInput class="Input-field" formControlName="pinCode"
            minlength="6" maxlength="6" />
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.get('pinCode')?.hasError('required')
            ">
            {{ "pincode_required" | translate }}
          </mat-error>
          <mat-error *ngIf="
              createFamilyAndEmergencyForm.get('pinCode')?.hasError('minlength')
            ">
            {{ "pincode_length" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <app-loader></app-loader>
      <div class="button-container">
        <button class="cancel-btn" type="reset" (click)="backToEmployeeProfile()">
          {{ "cancel" | translate }}
        </button>
        <button class="save-button" (click)="addUpdateFamilyAndEmergencyContactInformation()">
          {{ (!isEditMode ? "save" : "update") | translate }}
        </button>
      </div>
    </form>
  </div>
</div>