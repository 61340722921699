<app-toolbar></app-toolbar>

<div class="setting__main_container">
    <div class="grid-container-basic">
        <div class="welcome-container">
          <mat-icon svgIcon="arrow-back-double" (click)="goBack()"></mat-icon>
            <h3 class="grid-container-h">{{ 'asset_management' | translate }}</h3>
        </div>
      </div>
    <div class="cardSection">
        <mat-card class="assetsCard" (click)="assetsDetails()">
            <mat-card-content>
                <div class="assets">
                    <div class="numberOfAssets">
                        <div *ngIf="cardData">
                            <h1><b>{{cardData.assets}}</b></h1>
                        </div>
                        <h3 class="heading-name"><b>{{ 'asset' | translate }}</b></h3>
                    </div>
                    <div class="asset-icon-container">
                        <mat-icon class="icon">💻</mat-icon>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="employeeCard"(click)="supplierDetails()">
            <mat-card-content>
                <div class="assets">
                    <div class="numberOfSuppliers">
                        <div *ngIf="cardData">
                            <h1><b>{{cardData.supplier}}</b></h1>
                        </div>
                        <h3 class="heading-name"><b>{{'supplier'|translate}}</b></h3>
                    </div>
                    <div class="asset-icon-container">
                        <mat-icon class="icon">🛻</mat-icon>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="suppliersCard" (click)="orderForAssets()">
            <mat-card-content>
                <div class="assets">
                    <div class="numberOfemployee">
                        <div *ngIf="cardData">
                            <h1><b>{{cardData.assetOrderRequest}}</b></h1>
                        </div>
                        <h3 class="heading-name1"><b>{{'order_for_asset'|translate}}</b></h3>
                    </div>
                    <div class="asset-icon-container">
                        <mat-icon class="icon">🛒</mat-icon>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>



    <div class="secondSection">
        <mat-card class="assets-value">
            <mat-card-title>{{'request_asset'|translate}}</mat-card-title>
            <mat-divider></mat-divider>
            <div class="request-table">
                <table mat-table id="submit-table" [dataSource]="assetRequest" multiTemplateDataRows
                    class="mat-elevation-z8">
                    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToRequestAssets">
                        <mat-header-cell *matHeaderCellDef> {{getTittleCase(column)}} </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element[column]}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                        <mat-cell *matCellDef="let element" [attr.colspan]="columnsToRequestAssets.length">
                            <div class="example-element-detail">
                                <div class="example-element-diagram">
                                    <div class="example-element-position"> {{element.employeeId}} </div>
                                    <div class="example-element-symbol"> {{element.employeeName}} </div>
                                    <div class="example-element-name"> {{element.requestNo}} </div>
                                    <div class="example-element-name"> {{element.assetCode}} </div>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="columnsToRequestAssets"></mat-header-row>
                    <mat-row *matRowDef="let element; columns: columnsToRequestAssets;" class="example-element-row">
                    </mat-row>
                </table>
            </div>
        </mat-card>

        <mat-card class="assets-value">
            <mat-card-title>{{'yet_to_receive'|translate}}</mat-card-title>
            <mat-divider></mat-divider>
            <div class="request-table">
                <table mat-table id="submit-table" [dataSource]="yetToReceiveTable" multiTemplateDataRows
                    class="mat-elevation-z8">
                    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                        <mat-header-cell *matHeaderCellDef> {{getTittleCase(column)}} </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element[column]}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                            <div class="example-element-detail">
                                <div class="example-element-diagram">
                                    <div class="example-element-position"> {{element.assetCode}} </div>
                                    <div class="example-element-symbol"> {{element.assetName}} </div>
                                    <div class="example-element-name"> {{element.submittedDate | date: 'dd/MM/yyyy'}}
                                    </div>
                                    <div class="example-element-name"> {{element.employeeName}} </div>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                    <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                    </mat-row>
                </table>
            </div>
        </mat-card>
    </div>
</div>