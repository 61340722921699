<form [formGroup]="createAttendanceForm">
    <div class="log-time-popup" *ngIf="showAddPopup || logDetails">
        <h3 class="heading" *ngIf="isEditMode">{{ 'update_log_time' | translate }}</h3>
        <h3 class="heading" *ngIf="!isEditMode">{{ 'log-time' | translate }}</h3>
        <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
            <mat-icon class="searchIcon" matPrefix>search</mat-icon>
            <mat-label>{{'project_code' | translate}}</mat-label>
            <input matInput [formControl]="searchControl" class="custom-mat-input" [value]="selectedCategory">
            <mat-icon class="dropDownIcon" matSuffix (click)="toggleOptions()">arrow_drop_down</mat-icon>
        </mat-form-field>
        <div class="overlay-container">
            <div class="overlay" *ngIf="!showOptions">
                <mat-option *ngFor="let category of searchResults | async" [value]="category"
                    class="custom-mat-option custom-mat-option-overlay" (click)="onOptionClick(category)">
                    {{ category }}
                </mat-option>
            </div>
            <div class="overlay" *ngIf="showOptions">
                <mat-option *ngFor="let category of dropDownResults | async" [value]="category"
                    class="custom-mat-option custom-mat-option-overlay" (click)="onOptionClick(category)">
                    {{ category }}
                </mat-option>
            </div>
        </div>
        <div class="form-field-gap1">
            <mat-form-field appearance="outline" class="select-date">
                <mat-label>{{'date' | translate}}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="date" (keypress)="$event.preventDefault()"
                    required [min]="companyRegistrationDate"/>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="!createAttendanceForm.controls['date'].valid">{{'date-is-required' |
                    translate}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="select-date">
                <mat-label>{{'duration' | translate}}</mat-label>
                <input matInput formControlName="logHours" (keypress)="validateNumber($event)" required>
                <mat-error *ngIf="createAttendanceForm?.get('logHours')?.errors?.['maxHoursExceeded']">{{'max_hr' |
                    translate}}</mat-error>
                <mat-error
                    *ngIf="!createAttendanceForm.controls['logHours'].valid && !createAttendanceForm.get('logHours')?.errors?.['maxHoursExceeded']">{{'total-hours-required'
                    | translate}}</mat-error>
            </mat-form-field>
            <a (click)="toggleStartEndTimeFields()" *ngIf="!showStartEndTimeFields" class="set-time">
                {{ 'set-time' | translate }}
            </a>
        </div>
        <div class="form-field-gap1" *ngIf="showStartEndTimeFields">
            <mat-form-field appearance="outline" class="select-date">
                <mat-label>{{ 'start-time' | translate }}</mat-label>
                <mat-select formControlName="startTime" (selectionChange)="handleTimeChange()">
                    <mat-option *ngFor="let time of timeOptions" [value]="time">{{ time }}</mat-option>
                </mat-select>
                <mat-error *ngIf="!createAttendanceForm.controls['startTime'].valid">{{'start_time_is_required' |
                    translate}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="select-date">
                <mat-label>{{ 'end-time' | translate }}</mat-label>
                <mat-select formControlName="endTime" (selectionChange)="handleTimeChange()">
                    <mat-option *ngFor="let time of timeOptions" [value]="time">{{ time }}</mat-option>
                </mat-select>
                <mat-error *ngIf="!createAttendanceForm.controls['endTime'].valid">{{'end_time_is_required' |
                    translate}}</mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="log-description" appearance="outline">
            <mat-label>{{'description' | translate}}</mat-label>
            <input matInput type="text" formControlName="categoryDescription"
                (keypress)="validateCharacterNumberAndSpace($event)" />
        </mat-form-field>
        <div class="button-div">
            <button (click)="closePopup()" type="reset" class="cancel-btn">{{'cancel' | translate}}</button>
            <button (click)="logTime()" *ngIf="isEditMode">
                {{ 'update_log_time' | translate }}
            </button>
            <button (click)="logTime()" *ngIf="!isEditMode">
                {{ 'log-time' | translate }}
            </button>
        </div>
    </div>
</form>