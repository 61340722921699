export class AppConstant {
  public static IWEXE_LOCATION: string = 'Office 220, Suratwala Markplazo, Hinjewadi, Pune, Maharashtra, India - 411057';
  public static IWEXE_SUPPORT_EMAIL: string = 'admin@iwexe.com';
  public static IWEXE_SUPPORT_PHONE: string = '+91 7666775611';
  public static LANDING_PAGE: string = 'home';
  public static PRIVACY_POLICY: string = 'privacy-policy';
  public static TERMS_AND_CONDITION: string = 'terms-condition';
  public static CANCELLATION_AND_REFUND_POLICY: string = 'cancellation-refund-policy';
  public static SHIPPING_AND_DELIVERY_POLICY: string = 'shipping-delivery-policy';
  public static LOGIN_PAGE: string = 'login';
  public static REGISTRATION: string = 'signup';
  public static VERIFY_EMAIL: string = 'verify';
  public static readonly EMPLOYEE_ADDED: string = 'Payment Information Added Successfully.';
  public static COMPANY_BANK_DETAILS: string = 'bank-details';
  public static SUBSCRIPTION_PLAN: string = 'subscription-plan';
  public static GENERATE_PF_EXCEL: string = 'generate-pf-excel';
  public static IMAGE_VIEWER: string = 'image-viewer';
  public static SIDENAV: string = 'admin';
  public static EMPLOYEE_SIDENAV: string = 'employee';
  public static NAVBAR: string = 'navbar';
  public static PAGE_NOT_FOUND = 'page-not-found'
  public static ACTIVE_EMPLOYEES: string = 'active-employees';
  public static ADD_EMPLOYEE: string = 'add-employee';
  public static DASHBOARD: string = 'dashboard-view';
  public static BASIC_DETAILS: string = 'basic-details';
  public static SALARY_DETAILS: string = 'salary-details/:id';
  public static SALARY_DETAIL: string = 'salary-details';
  public static VIEW_EMPLOYEE: string = 'view-employee';
  public static VIEW_REQUEST_DETAILS: string = 'view-service-request-details';
  public static PERSONAL_INFORMATION: string = 'personal-information';
  public static PAYMENT_INFORMATION: string = 'payment-information';
  public static VIEW_COMPANY_PROFILE: string = 'company-profile';
  public static ADD_DEPARTMENT: string = 'add-department';
  public static ADD_DESIGNATION: string = 'add-designation';
  public static VIEW_ALL_DEPARTMENT: string = 'department';
  public static VIEW_ALL_DESIGNATION: string = 'designation';
  public static MANAGERS_EMPLOYEE_LIST: string = 'employee-list';
  public static SALARY_INFO: string = 'salary-details';
  public static EMPLOYEE_LEAVE_DETAILS: string = 'employee-leave-details';
  public static SALARY_BREAKUP: string = 'salary-breakup';
  public static CHANGE_PASSWORD: string = 'change-password';
  public static FORGOT_PASSWORD: string = 'forgot-password';
  public static readonly CREATE_NEW_PASSWORD: string = 'create-new-password';
  public static ADD_COMPANY_PROFILE: string = 'addCompanyInfo'
  public static ADD_BANK_DETAILS: string = 'add-bank-details';
  public static SALARY_STRUCTURE: string = 'salary-structure';
  public static SUPPORT: string = 'support';
  public static DIRECT_DEPOSIT: string = 'direct-deposit';
  public static BANK_INTEGRATION: string = 'bank-integration-setup'
  public static EMAIL_CONFIGURATION = "email-configuration";
  public static TAX_DECLARATION = "tax-declaration";
  public static USER_SUBSCRIPTION_VIEW: string = 'user-subscription-view';
  public static TRANSACTION_HISTORY: string = 'transaction-history';
  public static EARNING: string = 'earning';
  public static VIEW_EMPLOYEE_ATTENDANCE: string = 'view-employee-attendance';
  public static DEDUCTION: string = 'deduction';
  public static REIMBURSEMENT: string = 'reimbursement';
  public static ADD_COMPONENT: string = 'add-components';
  public static LEAVE_STRUCTURE: string = 'leave-structure';
  public static SALARY_SLIP: string = 'salary-slip';
  public static ROLE = sessionStorage.getItem('role');
  public static STATUS: string = '6';
  public static ID = 1;
  public static DEPARTMENT: string = 'department';
  public static EMPLOYEE_ROLE: string = 'employee';
  public static HR_ROLE: string = 'admin';
  public static GSTKEY = "b6b148e8c2478be8e52c580f42cca861";
  public static SALARY: string = 'Salary';
  public static CURRENT: string = 'Current';
  public static SAVING: string = 'Saving';
  public static SEPERATOR: string = '/';
  public static EMPLOYEE_PROFILE: string = 'employee-profile';
  public static COMPANYNAME: string = '&companyId=';
  public static COMPANY_NAME: string = '?companyId=';
  public static MANAGER_ID_REQUEST: string = '?managerId=';
  public static EMPLOYEE_ID_REQUEST: string = '?employeeId=';
  public static EMPLOYEE_LIST: string = '&employeeList=';
  public static EMPLOYEE_DETAILS: string = 'employee-details/:id';
  public static PAY_RUN: string = 'pay-run';
  public static readonly PAY_RUN_GROUP: string = 'pay-run-group';
  public static readonly PAY_RUN_EMPLOYEE_LIST: string = 'pay-run-employee-list';
  public static BASIC_DETAILS_ID: string = 'basicDetailsId';
  public static NETPAY: string = 'Net Pay';
public static LEAVE_HISTORY: string = 'leave-history';
  public static APPLY_LEAVE: string = 'apply-leave';
  public static EMPLOYEE_ATTENDANCE: string = 'attendance';
  public static EMPLOYEE_PROFILE_ASSET_MANAGEMENT: string = 'asset-details';
  public static EMPLOYEE_SUBMITTED_ATTENDANCE: string = 'employee-attendance-details';
  public static LOG_TIME: string = 'log-time';
  public static ATTENDANCE_CONFIGURATION: string = 'attendance-configuration';
  public static EMPLOYEE_ATTENDANCE_DETAILS: string = 'employee-attendance-details';
  public static MANAGER_SIDENAV: string = 'approvals';
  public static TASK_MANAGEMENT_SIDENAV: string = 'task-management-sidenav';
  public static MANAGER: string = 'manager';
  public static ADMIN: string = 'admin';
  public static SUBSCRIPTION_DASHBOARD: string = 'dashboard';
  public static PROFIT_LOSS_REPORT = 'profit-loss';
  public static NEW_USERS_REPORT = 'recently-added-users';
  public static PAYMENT_RECEIVED_REPORT = 'payment-received';
  public static CUSTOMER: string = 'customer';
  public static SUBSCRIPTION: string = 'subscription';
  public static REPORT: string = 'report';
  public static INVOICE: string = 'invoice';
  public static SETTING: string = 'setting';
  public static VIEW_ALL_SUBSCRIPTION: string = 'subscription-report';
  public static SUCCESS: string = 'Success';
  public static PENDING: string = 'Pending';
  public static FAILURE: string = 'Failure';
  public static RECENTLY_ADDED_CUSTOMERS: string = 'Recently Added Customers';
  public static TOTAL_SUBSCRIPTION: string = 'Total Subscription';
  public static ADMIN_SIDEBAR: string = 'superadmin';
  public static DOMAIN: string = 'iwexe.com';
  public static BOOK_DEMO: string = 'book-demo';
  public static ADD_BULK_DATA: string = 'added-bulk-data';
  public static SUBSCRIPTIONID: string = "subscriptionId";
  public static INVALID_ACCESSS = "Invalid Portal Access";
  public static TOKEN: string = 'token';
  public static COMPANY_ID: string = 'companyId';
  public static EMPLOYEE_ID: string = 'employeeId';
  public static SUBSCRIPTION_ID: string = 'subscriptionId';
  public static readonly ROLE_FOR_SESSION: string = 'login';
  public static readonly ASSIGN_ROLES: string = 'role';
  public static ANNUAL_CTC: string = 'annualCTC';
  public static LOGIN_EMAIL: string = 'loginEmail';
  public static LOGIN_FIRST: string = 'Please log in first to access this module';
  public static CREATE_REGISTRATION_REQUEST: string = 'createRegistrationRequest';
  public static SHOW_EMPLOYEE_LEAVE: string = 'showEmployeeLeave';
  public static DELETE_CONFIRMATION_MASSAGE: string = 'Are you sure to delete this record ?';
  public static EMPLOYEE_LOGIN_SUCCESSFULLY: string = 'Employee Login Successfully.';
  public static ADMIN_LOGIN_SUCCESSFULLY: string = 'Admin Login Successfully.';
  public static OTP_SEND_SUCCESSFULLY: string = 'OTP resent successfully.';
  public static ADD_ATLEAST_ONE_EMPLOYEE: string = 'Please add atleast one employee.';
  public static PLEASE_PROVIDE_VALID_DETAILS: string = 'Please provide valid details.';
  public static PLEASE_PROVIDE_EARNING_TOTAL_UPTO_100: string = 'Please provide earnings total upto 100%';
  public static RESEND_OTP_ERROR: string = 'Error occure to resent otp';
  public static YEARLY: string = 'yearly';
  public static BUY_NEW_SUBSCRIPTION: string = 'Please buy new subscription.';
  public static SUBSCRIPTION_MODULE_ACCESS: string = 'subscriptionModuleAccess';
  public static SUBSCRIPTION_UPDATE_SUCCESSFULLY: string = 'Subscription updated successfully.';
  public static LOGOUT: string = "Logout";
  public static SESSION_EXPIRE: string = "Your session is about to expire. Do you want to continue the session ?";
  public static MOUSE_MOVE: string = "mousemove";
  public static KEY_DOWN: string = "keydown";
  public static WHEEL: string = "wheel";
  public static TOUCHSTART: string = "touchstart";
  public static BAREAR: string = "Bearer";
  public static AUTHORIZATION: string = "Authorization";
  public static ERROR: string = "An error occurred";
  public static ADMIN_AND_EMPLOYEE: string = "admin,employee";
  public static LOGIN: string = "/login";
  public static VIEW_INTEGRATED_BANK: string = "view-integrated-bank";
  public static EXCEL: string = "excelFile";
  public static POST: string = "POST";
  public static FILE_SIZE: number = 1024;
  public static ERROR_EXCEL: string = "EmployeeErrorData.xlsx";
  public static HOLIDAY_ERROR_EXCEL: string = "HolidayErrorData.xlsx";

  public static SALARY_COMPONENT: string = 'Salary Component';
  public static CALCULATION_TYPE: string = 'Calculation Type';
  public static MONTHLY_AMOUNT: string = 'Monthly Amount';
  public static ANNUAL_AMOUNT: string = 'Annual Amount';
  public static FILE_NAME: string = 'All_Employees_Upload.xlsx';
  public static HOLIDAY_FILENAME :string ="All_Holiday_Upload.xlsx";
  public static PASTE: string = 'paste';
  public static CUT: string = 'cut';
  public static COPY: string = 'copy';
  public static GST_NUMBER: string = "gstNumber";
  public static IFSC_CODE: string = "ifscCode";
  public static LEAVE_DURATION_MSG: string = "Please leave duration upto ";
  public static LEAVE_COUNT_ERROR_MSG = "Your ";
  public static LEAVE_COUNT_ID = " count is ";
  public static LEAVE_TYPE_ERROR_MSG = ". You cannot apply for leave using this leave type.";
  public static DAYS: string = " days";
  public static PLEASE_SELECT_PAYMENT_METHOD = "Please select payment method.";
  public static CHOOSE_PAYMENT: string = "choose-payment";
  public static PAYMENT_DISCRIPTION: string = "payment-description";
  public static SUBSCRIPTIONNAME: string = "subscriptionName";
  public static SECTION_ID: string = "sectionId";
  public static REGIME_SLAB: string = "regime-slab";
  public static VIEW_TAX_DECLARATION: string = "it-declaration";
  public static DECLARED_FORM: string = "declared-form";
  public static START_FRESH: string = "investment-declaration";
  public static GENERAL_SETTING: string = "setting-configuration";
  public static ADD_ROLES: string = "role-configuration";
  public static COMPANY_REGISTRATION_DATE: string = "companyRegistrationDate";
  public static ASSIGN_MANAGER: string = "assign-manager";
  public static EXIT_PROCESS: string = "exit-process";
  public static VIEW_RESIGNATION: string = "view-resignation";
  public static EMPLOYEE_RESIGNATIONS: string = "employee-resignations";
  public static VIEW_EMPLOYEE_RESIGNATION_DETAILS: string = "employee-resignation-details";
  public static RESIGNATIONS: string = "resignations";
  public static EXIT_DETAILS: string = "exit-details";
  public static FINAL_SETTLEMENT: string = "final-settlement";
  public static SHOW_POI_DETAILS: string = "final-settlement-payroll";
  public static DECLARATION_APPROVAL: string = "declaration-approval";
  public static ASSET_MANAGEMENT: string = "asset-management";
  public static PROJECT_MANAGEMENT: string = "project-management";
  public static EVENT_MANAGEMENT: string = "event-management";
  public static ADD_EVENT: string = "event-management";
  public static VIEW_SUPPLIER_HISTORY: string = "/admin/view-timeline/";
  public static VIEW_SUPPLIER_INFO: string = "/admin/supplier-info/";
  public static EXIT_EMPLOYEE: string = "isExit";
  public static NO_DATA_TO_SAVE: string = "No data to save";
  public static LOGIN_COMPANY_NAME: string = "companyName";
  public static EMP00: string = "EMP00";
  public static HR_VIEW_EMPLOYEE_RESIGNATION_DETAILS: string = "employees-resignation-details";
  public static EVENT_ERROR_EXCEL: string = "AllEventsUpload_Error.xlsx";
  public static EVENT_SUCCESS: string = "All events added successfully";
  public static UPLOAD_EXCEL: string = "upload-goal-excel";
  public static UPLOAD_APPRISAL_EXCEL:string="upload-apprisal-excel";
  public static VIEW_EMPLOYEE_GOALS: string = "employee-goal-plan";
  public static EMPLOYEE_LIST_UNDER_MANAGER: string = "employees-list";
  public static EMPLOYEE_BASIC_GOAL = 'employee-basic-goal';
  public static VIEW_EMPLOYEE_APPRISAL: string = "appraisal";
  public static EMPLOYEE_EVOLUTION: string = "evolution";
  public static GOAL_PLAN: string = "goal-plans";
  public static ADD_GOALS: string = "add-goals";
  public static EMPLOYEE_UNDER_MANAGER: string = "viewEmpGoalById";
  public static ANNUAL: string = "Annual";
  public static SEMIANNUAL: string = "Semiannual";
  public static EMPLOYEE_PERFORMANCE_GOAL_PLAN: string = "performance-goal-plan";
  public static PAYRUN_APPROVAL: string = 'payrun_approval';
  public static IS_MANAGER: string = "isManager";
  public static YEAR_HISTORY: string = "showYearHistory";
  public static ACCEPT: string = 'Accept';
  public static REJECT: string = 'Reject';
  public static REJECTED: string = 'reject';

  public static SUPPLIER_DETAILS: string = 'supplier-details';
  public static ADD_SUPPLIER_DETAILS: string = 'add-supplier-details';
  public static OREDR_FOR_ASSET: string = 'order-for-asset';
  public static ASSET_DETAILS: string = 'asset-details';
  public static PERFORMANCE: string = 'Performance';
  public static BEHAVIOUR: string = 'Behavioural';
  public static CATEGOERY: string = 'goals-category';
  public static VOUCHER: string = 'create-voucher';
  public static VIEW_VOUCHER: string = 'view-vouchers';
  public static USE_VOUCHER: string = 'vouchers-details';
  public static AND: string = '&voucherCode=';
  public static SUBSCRIPTION_PRICE: string = 'subscription-price';
  public static INCIDENT_REQUEST: string = 'incident-request';
  public static REQUESTOR_INFORMATION: string = 'requestor-information';
  public static VIEW_REQUESTOR_INFORMATION: string = 'view-request';
  public static RESOLUTION: string = 'resolution';
  public static HELPDESK: string = 'help-desk';
  public static ADD_PROJRCT: string = 'add-project';
  public static UPDATE_PROJECT: string = 'update-project';
  public static PROJECT_COSTING: string = 'project-costing'
  public static PROJECT_START_DATE: string = '?startDate=';
  public static PROJECT_END_DATE: string = '&endDate=';
  public static ADD_APPROVALS: string = "payrun-approvals";
  public static PROJECT_APPROVAL: string = "project-approval";
  public static ADD_HIRARCHICAL_APPROVALS: string = 'payrun-approvals-hirarchy';
  public static VIEW_PROJECT_DETAILS: string = 'view-project-details';
  public static DIRECT_APPROVAL_CONFIRMATION_MASSAGE: string = 'Are you sure to change approval type as Direct Approval ?';
  public static HIERARCHICAL_APPROVAL: string = 'Hierarchical_Approval';
  public static STANDARD_APPROVAL: string = 'Standard_Approval';
  public static DIRECT_APPROVAL: string = 'Direct_Approval';
  public static PAYRUN_APPROVAL_DETAILS: string = 'payrun-approval-details'
  public static VIEW_TRAINING: string = 'view-training';
  public static ADD_TRANING: string = "add-training";
  public static COURSES: string = 'courses_details';
  public static LEARNCOURSES: string = 'learn_courses';
  public static REQ: string = 'REQ00'
  public static VIEW_EMPLOYEE_TRAINING: string = 'view-employee-training/'
  public static BIRTHDAY_LIST: string = 'birthday_list'
  public static REQUEST_ID: string = ':id';
  public static VIEW_DETAILS_OF_TRAINING: string = 'deatils-of-training';
  public static VIEW_EMPLOYEE_TRANING_DETAILS: string = 'employee-assign-training-details';
  public static ASSIGN_TRAINING_TO_THE_EMPLOYEE: string = 'assign-training-to-the-employee';
  public static EMPLOYEE_STATUS: string = 'employeeStatus';
  public static INCOMPLETED: string = 'Incomplete';
  public static SELECT_SUBSCRIPTION: string = 'selectedSubscription';
  public static MANAGER_ID: string = "managerId";
  public static ADMIN_ID: string = "adminId";;
  public static VIEW_REPORT: string = "view-report";
  public static VIEW_TASK: string = "view-task";
  public static VIEW_BACKLOG: string = "view-backlog";
  public static PROJECTS: string = "projects";
  public static DASHBOARD_ID: string = "dashboardId";
  public static TASK_ID: string = "taskId";
  public static PARAMS_DASHBOARD_ID: string = "/:dashboardId";
  public static PARAMS_SPRINT_ID: string = "/:sprintId";
  public static VIEW_TIMELINE: string = "view-timeline";
  public static SUPPLIER_INFO: string = "supplier-info";
  public static MAX_FILE_SIZE = 25 * 1024 * 1024;
  public static SPACE: string = " ";
  public static PRIORITY: string = "priority";
  public static readonly CREATE_TASK = "createTaskDto";
  public static readonly ATTACHMNETS = "attachments";
  public static readonly COMPANY_BANK_DETAIL: string = "company-bank-details";
  public static readonly COMPANY_BANK_INFORMATION:string ="company-bank-information";
  public static readonly EMAIL_QUERY_PARAM_APPENDER: string = "&email=";
  public static readonly IT_DECLARATION: string = "it-declaration";
  public static readonly TASK_MANAGEMENT: string = "task-management";
  public static readonly BOARD_TRACKING: string = "board-tracking";
  public static readonly PROJECT_DASHBOARD: string = "project-dashboard";
  public static readonly VIEW_SPRINT: string = "view-sprint";
  public static readonly ACTIVE_EMPLOYEE: string = "activeEmployee";
  public static readonly PROJECT_ID: string = "projectId";
  public static readonly ISMANAGER: string = "Manager";
  public static readonly PARAMS_ID: string = "/:id";
  public static readonly DELETE_DASHBOARD_CONFIRMATION_MESSAGE: string = "Are you sure to delete this board? Sprints and tasks associate with this board will also be deleted."
  public static readonly OR: string = "&email=";
  public static readonly APPROVE: string = "approve";
  public static readonly CONTACT_ID: string = "contactId";
  public static readonly DOB: string = "dob";
  public static readonly FAMILY_AND_EMERGENCY_CONTACT_INFO: string = "family-and-emergency-contact-information";
  public static readonly VIEW_FAMILY_AND_EMERGENCY_DETAILS: string = "view-family-and-emergency-details";
  public static readonly ADD_FAMILY_AND_EMERGENCY_CONTACTS: string = "add-family-and-emergency-contacts";
  public static readonly VIEW_EMPLOYEE_FAMILY_AND_EMERGENCY_CONTACT_INFO: string = "view-employee-family-and-emergency-info";
  public static readonly SETTING_SIDE_BAR: string = "settingSideBar";
  public static readonly SHOWSIDEBAR: string = "showSidebar";
  public static readonly EMPLOYEE_MANAGEMENT_OVERVIEW: string = "employee-management-overview";
  public static readonly ACESS_MODULES_BASED_ON_ROLE: string = "all-modules";
  public static readonly HELP :string ="help";
  public static readonly EMPLOYEE:string="employee";
  public static readonly MAINTENANCE:string ="maintenance";
  public static readonly VIEW_BRANCH :string ="view-branch";
  public static readonly ADD_BRANCH :string ="add-branch";

  public static getMenuName(name: string) {
    switch (name) {
      case 'Home': {
        return AppConstant.SUBSCRIPTION_DASHBOARD;
      }
      case 'Customer': {
        return AppConstant.CUSTOMER;
      }
      case 'Subscription': {
        return AppConstant.SUBSCRIPTION_PLAN;
      }
      case 'Report': {
        return AppConstant.REPORT;
      }
      case 'Invoice': {
        return AppConstant.INVOICE;
      }
      case 'Setting': {
        return AppConstant.EMAIL_CONFIGURATION;
      }
      case 'Tax Declaration': {
        return AppConstant.TAX_DECLARATION;
      }
      case 'Regime': {
        return AppConstant.REGIME_SLAB;
      }
      case 'Voucher': {
        return AppConstant.VIEW_VOUCHER;
      }

      default: {
        return '';
      }
    }
  }

}