<mat-toolbar color="primary">
  <button (click)="isExpanded = !isExpanded" *ngIf="!isEmployee">
    <mat-icon>menu</mat-icon>
  </button>
  <img class="logo" [src]="companyLogo" alt="">
  <h1>{{ companyName | translate}}</h1>

  <span class="profile-menu"></span>

  <button (click)="iwexeSupport()" *ngIf="!isEmployee">
    <mat-icon (click)="iwexeSupport()" matTooltip="IWEXE Support">help</mat-icon>
  </button>

  <button (click)="toggleNotifications()" matTooltip="Notifications">
    <div class="notification-icons" *ngIf="isEmployee">
      <mat-icon>notifications_none</mat-icon>
    </div>
    <div *ngIf="!isEmployee">
      <mat-icon>notifications_none</mat-icon>
    </div>
    <span class="employee-badge" *ngIf="badgeValue > 0 && isEmployee">{{ badgeValue }}</span>
    <span class="custom-badge" *ngIf="badgeValue > 0 && !isEmployee">{{ badgeValue }}</span>
  </button>

  <button [matMenuTriggerFor]="menu" matTooltip="Profile">
    <span class="material-icons">account_circle</span>
  </button>


  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onMenuItemSelected()" routerLink="company-profile" *ngIf="!isEmployee">
      <mat-icon>business</mat-icon>
      <span class="profile-menu">{{ 'company_profile' | translate }}</span>
    </button>
    <button mat-menu-item routerLink="/home" (click)="logout()">
      <mat-icon class="logoutIcon">logout</mat-icon>
      <span class="profile-menu">{{ 'logout' | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="container" autosize>
  <mat-sidenav #sidenav class="sidenav" mode="side" opened="true">

    <mat-nav-list *ngIf="isEmployee">
      <div>
        <div>
          <img [src]="selectedImage" class="selected-image" alt="" />
          <form [formGroup]="myFormGroup">
            <input type="file" style="display: none" #fileInput formControlName="logo"
              (change)="onChangeFileInput($event)" accept="image/*" />
          </form>
          <mat-icon class="file-select-button" (click)="onClickFileInputButton()">camera_alt</mat-icon>
          <h4 class="name">{{employeeName}}</h4>
          <p class="designation">{{designation}}</p>
          <mat-list-item routerLink="employee-profile" (click)="onMenuItemSelected()" class="view_profile">
            <span class="profile" routerLink="employee-profile" (click)="onMenuItemSelected()">{{'view_profile' |
              translate}} </span>
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="dashboard-view">
            <mat-icon class="icon">home</mat-icon>
            <span class="full-width" (click)="onMenuItemSelected()" routerLink="dashboard-view">{{'home'|
              translate}}</span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="salary-details">
            <mat-icon class="icon">monetization_on</mat-icon>
            <span (click)="onMenuItemSelected()" class="full-width" routerLink="salary-details">{{'salary_details'|
              translate}}</span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="salary-breakup">
            <mat-icon class="icon">receipt</mat-icon>
            <span class="full-width" (click)="onMenuItemSelected()" routerLink="salary-breakup">{{'salary_breakup'|
              translate}}</span>
          </mat-list-item>
          <mat-list-item [routerLinkActive]="['is-active']" (click)="approveAttendanceButton()">
            <mat-icon class="icon">calendar_month</mat-icon>
            <span class="full-width">{{'attendance'| translate}}</span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" (click)="goToLeaveHistory()">
            <mat-icon class="icon">assignment</mat-icon>
            <span class="full-width">
              {{'apply_leave'| translate}}</span>
          </mat-list-item>

          <mat-list-item *ngIf="hrRole" [routerLinkActive]="['is-active']" routerLink="/employee/active-employees">
            <mat-icon class="icon">person</mat-icon>
            <span class="full-width">{{'add_employee'| translate}}</span>
          </mat-list-item>

          <mat-list-item *ngIf="accountantRole" [routerLinkActive]="['is-active']" (click)="payrun()">
            <mat-icon class="icon" (click)="payrun()">account_balance_wallet</mat-icon>
            <span [routerLinkActive]="['is-active']" (click)="payrun()" class="full-width">
              {{ 'pay_run' | translate }}
            </span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" *ngIf="isManager || hrRole"
            (click)="navigateToManagerTab()">
            <mat-icon class="icon">approval</mat-icon>
            <span class="full-width">{{'manager_tab'| translate}}</span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" (click)="itDeclaration()">
            <mat-icon class="icon" (click)="itDeclaration()">archive</mat-icon>
            <span class="full-width" (click)="itDeclaration()">{{'it_declaration'| translate}}</span>
          </mat-list-item>

          <mat-list-item *ngIf="declarationApprovalWindow && accountantRole" [routerLinkActive]="['is-active']"
            routerLink="declaration-approval">
            <mat-icon class="icon">queue_play_next</mat-icon>
            <span class="full-width">{{'it_declaration_approval'| translate}}</span>
          </mat-list-item>

          <mat-list-item *ngIf="isManager" [routerLinkActive]="['is-active']" (click)="project()">
            <mat-icon class="icon" (click)="project()">work</mat-icon>
            <span class="full-width" (click)="project()">{{ 'project_management' | translate }}</span>
          </mat-list-item>

          <mat-list-item *ngIf="accountantRole" [routerLinkActive]="['is-active']" routerLink="payrun_approval">
            <mat-icon class="icon" routerLink="payrun_approval">playlist_add_check</mat-icon>
            <span class="full-width" routerLink="payrun_approval">{{'payrun_approval'|
              translate}}</span>
          </mat-list-item>

          <mat-list-item *ngIf="hrRole" [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()"
            routerLink="event-management" matTooltip="{{ isExpanded ? '' : 'event_management' | translate }}"
            matTooltipPosition="right">
            <mat-icon routerLink="event-management" class="icon" (click)="showAssignManagerPopup()"
              matTooltipPosition="right">event_available</mat-icon>
            <span routerLink="event-management" class="full-width" *ngIf="isExpanded || isShowing"
              (click)="onMenuItemSelected()">{{'event_management' | translate}}</span>
          </mat-list-item>

          <mat-list-item *ngIf="supportRole || hrRole" [routerLinkActive]="['is-active']" (click)="asset()">
            <mat-icon (click)="asset()" class="icon">laptop</mat-icon>
            <span class="full-width" (click)="asset()">{{'asset_management'| translate}}</span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" (click)="appraisal()">
            <mat-icon class="icon" (click)="appraisal()">star</mat-icon>
            <span class="full-width" (click)="appraisal()">{{'appraisal'| translate}}</span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" (click)="viewEmpTraining()">
            <mat-icon (click)="viewEmpTraining()" class="icon">laptop</mat-icon>
            <span (click)="viewEmpTraining()" class="full-width">{{'view_training' |translate}}</span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" (click)="helpDesk()">
            <mat-icon (click)="helpDesk()" class="icon">support_agent</mat-icon>
            <span (click)="helpDesk()" class="full-width">{{'helpdesk'| translate}}</span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="exit-process">
            <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="exit-process">exit_to_app</mat-icon>
            <span (click)="onMenuItemSelected()" routerLink="exit-process" class="full-width">{{'exits_management'|
              translate}}</span>
          </mat-list-item>

          <mat-list-item [routerLinkActive]="['is-active']" (click)="employeeSideTaskManagement()">
            <mat-icon class="icon">view_list</mat-icon>
            <span class="full-width">{{'task_management'| translate}}</span>
          </mat-list-item>
        </div>
      </div>
    </mat-nav-list>

    <!-- <mat-nav-list *ngIf="showDefaultMenu && !isEmployee">
      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="dashboard-view"
        [matTooltip]="isExpanded ? '' : 'Home'" mat-raised-button matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="dashboard-view">home</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="dashboard-view">{{'home' | translate}}</span>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="active-employees"
        [matTooltip]="isExpanded ? '' : 'Employee Details'" matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="active-employees">person</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="active-employees">{{'employee_details' | translate}}</span>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()"
        [matTooltip]="isExpanded ? '' : 'Approvals'" mat-raised-button matTooltipPosition="right"
        (click)="navigateToManagerTab()">
        <div>
          <mat-icon class="icon" (click)="navigateToManagerTab()">approval</mat-icon>
          <span *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()" class="full-width"
            (click)="navigateToManagerTab()">{{'manager_tab'|translate}}</span>
        </div>
      </mat-list-item>



      <mat-list-item [routerLinkActive]="['is-active']" routerLink="user-subscription-view"
        (click)="onMenuItemSelected()" [matTooltip]="isExpanded ? '' : 'Subscription'" matTooltipPosition="right">
        <div routerLink="user-subscription-view">
          <mat-icon class="icon" (click)="onMenuItemSelected()">subscriptions</mat-icon>
          <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()">{{ 'subscription' |
            translate }}</span>
        </div>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" routerLink="transaction-history" (click)="onMenuItemSelected()"
        [matTooltip]="isExpanded ? '' : 'Transaction History'" matTooltipPosition="right">
        <div routerLink="transaction-history">
          <mat-icon class="icon" (click)="onMenuItemSelected()">history</mat-icon>
          <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
            routerLink="transaction-history">{{ 'transaction_history' | translate }}</span>
        </div>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" routerLink="/admin/pay-run-group"
        [matTooltip]="isExpanded ? ' ' : 'pay_run_group' | translate" matTooltipPosition="right">
        <div>
          <mat-icon class="icon">group</mat-icon>
          <span [routerLinkActive]="['is-active']" class="full-width" *ngIf="isExpanded || isShowing">
            {{ 'pay_run_group' | translate }}
          </span>
        </div>
      </mat-list-item>


      <mat-list-item [routerLinkActive]="['is-active']" (click)="goToPayRun()"
        [matTooltip]="isExpanded ? '' : 'Pay Run'" matTooltipPosition="right">
        <div>
          <mat-icon class="icon" (click)="goToPayRun()">account_balance_wallet</mat-icon>
          <span [routerLinkActive]="['is-active']" class="full-width" *ngIf="isExpanded || isShowing"
            (click)="goToPayRun()">{{ 'pay_run' | translate
            }}</span>
        </div>
      </mat-list-item>

]      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()"
        routerLink="../task-management-sidenav/home" [matTooltip]="isExpanded ? '' : ('task_management' | translate)"
        matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()"
          routerLink="../task-management-sidenav/home">view_list</mat-icon>
        <span (click)="onMenuItemSelected()" routerLink="../task-management-sidenav/home" class="full-width"
          *ngIf="isExpanded || isShowing">
          {{'task_management'| translate}}
        </span>
      </mat-list-item>

    </mat-nav-list> -->

    <mat-nav-list *ngIf="!showDefaultMenu && !isEmployee">
      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" (click)="back()"
        matTooltip="{{ isExpanded ? '' : 'home' | translate }}" mat-raised-button matTooltipPosition="right">
        <mat-icon (click)="back()" class="icon" (click)="onMenuItemSelected()"
          routerLink="department">arrow_back</mat-icon>
        <span (click)="back()" class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="home">{{'home' |
          translate}}</span>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="department"
        matTooltip="{{ isExpanded ? '' : 'department' | translate }}" mat-raised-button matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="department">domain</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="department">{{'add-department' |
          translate}}</span>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="designation"
        matTooltip="{{ isExpanded ? '' : 'designation' | translate }}" matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="designation">work</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="designation">{{'add-designation' |
          translate}}</span>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="leave-structure"
        matTooltip="{{ isExpanded ? '' : 'leave-structure' | translate }}" matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="leave-structure">description</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="leave-structure">{{'leave-structure' |
          translate}}</span>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="salary-structure"
        matTooltip="{{ isExpanded ? '' : 'salary_structure' | translate }}" matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="salary-structure">money</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="salary-structure">{{'salary_structure' |
          translate}}</span>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()"
        routerLink="setting-configuration" matTooltip="{{ isExpanded ? '' : 'setting_configuration' | translate }}"
        mat-raised-button matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="setting-configuration">settings</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="setting-configuration">{{'setting_configuration' |
          translate}}</span>
      </mat-list-item>

      <!-- <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="add-employee"
        matTooltip="{{ isExpanded ? '' : 'add_employee' | translate }}" matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="add-employee">group_add</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="add-employee">{{'add_employee' |
          translate}}</span>
      </mat-list-item> -->

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="assign-manager"
        matTooltip="{{ isExpanded ? '' : 'assign_manager' | translate }}" matTooltipPosition="right">
        <mat-icon routerLink="assign-manager" class="icon" (click)="showAssignManagerPopup()"
          matTooltipPosition="right">assignment_ind</mat-icon>
        <span routerLink="assign-manager" class="full-width" *ngIf="isExpanded || isShowing"
          (click)="onMenuItemSelected()">{{'assign_manager' | translate}}</span>
      </mat-list-item>

      <mat-list-item (click)="goToApprisalProcess()" [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()"
        matTooltip="{{ isExpanded ? '' : 'appraisal_process' | translate }}" matTooltipPosition="right">
        <mat-icon (click)="goToApprisalProcess()" class="icon" (click)="onMenuItemSelected()">star</mat-icon>
        <span (click)="goToApprisalProcess()" class="full-width" *ngIf="isExpanded || isShowing"
          (click)="onMenuItemSelected()">{{'appraisal_process' |
          translate}}</span>
      </mat-list-item>

      <!-- <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="event-management"
        matTooltip="{{ isExpanded ? '' : 'event_management' | translate }}" matTooltipPosition="right">
        <mat-icon routerLink="event-management" class="icon" (click)="showAssignManagerPopup()"
          matTooltipPosition="right">event_available</mat-icon>
        <span routerLink="event-management" class="full-width" *ngIf="isExpanded || isShowing"
          (click)="onMenuItemSelected()">{{'event_management' | translate}}</span>
      </mat-list-item> -->

      <!-- <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="direct-deposit"
        matTooltip="{{ isExpanded ? '' : 'direct_deposits' | translate }}" matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="direct-deposit">account_balance</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="direct-deposit">{{'direct_deposits' |
          translate}}</span>
      </mat-list-item> -->
      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="company-bank-details"
        matTooltip="{{ isExpanded ? '' : 'company_bank_details' | translate }}" matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()"
          routerLink="company-bank-details">account_balance</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="company-bank-details">{{'company_bank_details' |
          translate}}</span>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="payrun-approvals"
        matTooltip="{{ isExpanded ? '' : 'payrun-approvals' | translate }}" matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()"
          routerLink="payrun-approvals">assignment_turned_in</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="payrun-approvals">{{'payrun-approvals' |translate}}</span>
      </mat-list-item>

      <mat-list-item [routerLinkActive]="['is-active']" (click)="onMenuItemSelected()" routerLink="view-branch"
        matTooltip="{{ isExpanded ? '' : 'view_branches' | translate }}" matTooltipPosition="right">
        <mat-icon class="icon" (click)="onMenuItemSelected()" routerLink="payrun-approvals">device_hub</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing" (click)="onMenuItemSelected()"
          routerLink="view-branch">{{'view_branches' |translate}}</span>
      </mat-list-item>


    </mat-nav-list>

    <mat-nav-list class="superAdminLogoContainer" *ngIf="isExpanded">
      <img class="superAdminLogo" src="../../../../assets/images/landing-page/logo/iwexe-logo.png" alt="IWEXE Logo">
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav #notificationsSidenav class="notification-menu" position="end" mode="over" [opened]="showNotifications">
    <div class="notification-menu-content">
      <ng-container *ngIf="showNotifications; else noNotificationsMessage">
        <div class="notification-list">
          <div *ngFor="let notification of notifications" class="notification">
            <mat-icon>email</mat-icon>
            <div class="notification-message">{{ notification.message }}</div>
            <span class="close-button" (click)="closeNotification(notification)">
              <mat-icon>cancel</mat-icon>
            </span>
          </div>
        </div>
        <span class="closeAll" (click)="closeAllNotification()">Clear All</span>
      </ng-container>
      <ng-template #noNotificationsMessage>
        <div class="no-notifications-message">
          No notifications available
        </div>
      </ng-template>
    </div>
  </mat-sidenav>
  <router-outlet></router-outlet>
</mat-sidenav-container>
<div class="overlay" *ngIf="assignManagerPopup" (click)="hideAssignManagerPopup()"></div>