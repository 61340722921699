import { Component, ViewChild, Inject, Optional, Self } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, filter, map, Subject, takeUntil, tap } from 'rxjs';
import { AppConstant } from 'src/app/constants/app.constants';
import { Messages } from 'src/app/constants/messages.constant';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent {
  @ViewChild('select') select: MatSelect | any;
  addTaskForm!: FormGroup;
  projectData: any[] = [];
  taskStatus = Messages.TASK_STATUS;
  search!: string;
  employeeList: any[] = [];
  issueList: any[] = [];
  sprintList: any[] = [];
  projectId!: number;
  filteredServerSide: Subject<any[]> = new Subject<any[]>();
  searching: boolean = false;
  private _onDestroy = new Subject<void>();
  personSearchFilter = this.fb.control('');
  selectedEmployeeType: string = 'allEmployee';
  noRecordsFound: boolean = false;
  documentUrls: any;
  documentsNameList: any[] = [];
  fileSizeError: boolean = false;
  showDocumentError: boolean = false;
  selectedFiles: File[] = [];
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);

  constructor(
    private formBuilder: FormBuilder, private router: Router, private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddTaskComponent>,
    private toastrService: ToastrService, private employeeService: EmployeeDetailsService,
    private projectService: ProjectManagementService,
    private approvalService: ApprovalService
  ) { }

  ngOnInit(): void {
    this.initTaskDetails();
    this.getProjectList();
    this.searchEmployee();
    this.getAllIssueList();
  }

  initTaskDetails() {
    this.addTaskForm = this.formBuilder.group({
      projectId: ['', [Validators.required]],
      status: [' toDo', [Validators.required]],
      summary: ['', [Validators.required, ValidationUtil.noOnlySpacesValidator()]],
      description: [''],
      taskPointEstimate: ['', ValidationUtil.greaterThanZeroValidator(),Validators.max(99),Validators.maxLength(2)],
      assigneeTo: [],
      parentTaskId: ['',],
      sprintId: [''],
      reporterId: ['', [Validators.required]],
      linkedTasks: [''],
      parentId: ['']
    });
  }

  getProjectList() {
    this.projectService.getProjectList().subscribe(
      (projectList: any) => {
        if (projectList.status === 200) {
          this.projectData = projectList.data;
        }
      }
    );
  }

  searchEmployee() {
    this.getEmployeeList();
    this.personSearchFilter.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => {
          this.searching = true;
          this.noRecordsFound = false;
        }),
        takeUntil(this._onDestroy),
        debounceTime(500),
        map((search: any) => {
          this.search = search.toLowerCase();
          if (!this.employeeList) {
            return [];
          }
          return this.employeeList.filter(employee =>
            (employee.firstName.toLowerCase() + AppConstant.SPACE + employee.lastName.toLowerCase()).indexOf(this.search) > -1
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredEmployees => {
        if (filteredEmployees.length !== 0) {
          this.searching = false;
          this.noRecordsFound = false;
          this.filteredServerSide.next(filteredEmployees);
        } else {
          this.approvalService.searchEmployee(this.companyId, this.search).subscribe((employeeDetails: any) => {
            if (employeeDetails.status === 200 && employeeDetails.data && employeeDetails.data.length > 0) {
              this.searching = false;
              this.noRecordsFound = false;
              this.filteredServerSide.next(employeeDetails.data);
            } else {
              this.searching = false;
              this.noRecordsFound = true;
              this.filteredServerSide.next([]);
            }
          });
        }
      }, error => {
        this.searching = false;
        this.noRecordsFound = true;
        this.filteredServerSide.next([]);
      });
  }

  getEmployeeList() {
    this.employeeService.getEmployeeList(this.selectedEmployeeType, this.companyId).subscribe(
      (employeeData: any) => {
        this.employeeList = employeeData.data;
        this.filteredServerSide.next(this.employeeList);
      }
    );
  }

  onProjectChange(event: any): void {
    this.projectId = event.value;
    this.getAllIssueList();
    this.getAllSprintList();
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }


  getAllIssueList(): void {
    if (this.projectId) {
      this.projectService.getIssueList(this.projectId).subscribe(
        (taskData: any) => {
          if (taskData.status === 200) {
            this.issueList = taskData.data;
          }
        });
    }
  }

  getAllSprintList() {
    this.projectService.getAllSprintList(this.projectId).subscribe((sprintList: any) => {
      this.sprintList = sprintList.data;
      if (sprintList.data.status === 200) {
        this.sprintList = sprintList.data;
      }
    })
  }

  onChangeFileInput(event: Event): void {
    this.showDocumentError = true;
    this.fileSizeError = false;
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];
        if (file.size > Messages.MAX_FILE_SIZE) {
          this.fileSizeError = true;
        } else if (!this.isFileSelected(file)) {
          this.selectedFiles.push(file);
        }
      }
    }
  }

  isFileSelected(file: File): boolean {
    return this.selectedFiles.some(selectedFile => selectedFile.name === file.name);
  }

  removeFile(value: File) {
    const index = this.selectedFiles.findIndex((files: any) => files.name === value.name);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }

  createTask() {
    // if (this.addTaskForm.valid) {
      const addTaskFormData = new FormData();
      const formValue = this.addTaskForm.value;
      const createTaskDto = {
        companyId: this.companyId,
        projectId: formValue.projectId,
        status: formValue.status,
        summary: formValue.summary,
        description: formValue.description,
        assigneeTo: formValue.assigneeTo,
        parentTaskId: formValue.parentId,
        sprintId: formValue.sprintId,
        taskPointEstimate: formValue.taskPointEstimate,
        reporterId: formValue.reporterId,
        linkedTasks: formValue.linkedTasks ? formValue.linkedTasks.split(',').map((id: string) => parseInt(id, 10)) : []
      };
      addTaskFormData.append(AppConstant.CREATE_TASK, JSON.stringify(createTaskDto));
      this.selectedFiles.forEach((file, index) => {
        addTaskFormData.append(AppConstant.ATTACHMNETS, file);
      });

      this.projectService.addTask(addTaskFormData).subscribe(
        (addTask: any) => {
          if (addTask.status == 200) {
            this.toastrService.clear();
            this.toastrService.success(addTask.message);
            this.dialogRef.close();
          } else {
            this.toastrService.clear();
            this.toastrService.error(addTask.message);
            this.dialogRef.close();
            this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.LANDING_PAGE]);
          }
        },
      );
    // }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  restrictToTwoDigits() {
    const control = this.addTaskForm.get('taskPointEstimate');
    if (control?.value && control.value.toString().length > 2) {
      control.setValue(control.value.toString().slice(0, 2));
    }
  }

  characterNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event)
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}


