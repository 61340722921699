<div class="issue-detail">
    <div *ngIf="showClosePopup" class="close-icon" (click)="closePopup()"><mat-icon>close</mat-icon></div>
    <div *ngIf="!isEditMode">
        <mat-chip class="issue-title">
            <h3 class="issueName">{{ taskDetails?.summary }}</h3>
        </mat-chip>

        <div class="issue-metadata">
            <mat-icon matTooltip="{{'edit'|translate}}" (click)="editTaskDetails()">edit</mat-icon>
            <div>
            </div>
            <div class="metadata-item">
                <strong>{{'project_name'|translate}} - </strong> <span class="show-task-details">{{
                    taskDetails?.projectName }}</span>
            </div>
            <div class="metadata-item">
                <strong>{{'summary'|translate}} - </strong> <span class="show-task-details">{{
                    taskDetails?.summary }}</span>
            </div>
            <div class="metadata-item">
                <strong>{{'description'|translate}} - </strong> <span class="show-task-details">{{
                    taskDetails?.description }}</span>
            </div>
            <div class="metadata-item">
                <strong>{{'status'|translate}} - </strong> <span class="show-task-details">{{
                    getCamelCase(taskDetails?.status) }}</span>
            </div>
            <div class="metadata-item">
                <strong>{{'assignee'|translate}} - </strong><span class="show-task-details"> {{
                    taskDetails?.assigneeName }}</span>
            </div>
            <div class="metadata-item">
                <strong>{{'reporter'|translate}} - </strong> <span class="show-task-details">{{
                    taskDetails?.reporterName}}</span> <strong class="story-point">{{'story_point'|translate}} -
                </strong><span class="show-task-details">{{
                    taskDetails?.taskPointEstimate }}</span>
            </div>
            <div class="metadata-item">
                <strong>{{'parent_name'|translate}} - </strong><span class="show-task-details">{{
                    taskDetails?.parentTaskName }}</span>
            </div>
            <div class="metadata-item">
                <strong>{{'sprint_name'|translate}} - </strong><span class="show-task-details">{{
                    taskDetails?.sprintName}}</span>
            </div>

            <div class="metadata-item" *ngIf="attachments.length > 0">
                <strong>{{ 'attachments' | translate }} - </strong>
                <ul>
                    <li *ngFor="let attachment of attachments">
                        <a (click)="viewDocument(attachment)" style="cursor: pointer;">{{ attachment?.fileName }}</a>
                    </li>
                </ul>
            </div>
            
            <div class="metadata-item">
                <span class="created-time">{{'created_by'|translate}} - </span>
                <span class="created-time">{{taskDetails?.createdBy}}</span> <span
                    class="updated-time">{{'created_date'|translate}} -
                </span><span class="created-time">{{taskDetails?.createdDate | date: 'yyyy-MM-dd'}}</span>
            </div>
        </div>
    </div>
    <div class="update-task" [ngClass]="{'update-task': showUpdateWindow }" *ngIf="isEditMode">
        <app-view-task-details (taskUpdated)="handleTaskUpdated($event)"></app-view-task-details>
    </div>
    <div class="activity-content">
        <span class="activity">{{ 'activity' | translate }}</span>
        <mat-chip-set class="show">
            <span>
                <mat-chip cdkDrag class="comment" (click)="showCommets()">{{ 'comments' | translate
                    }}</mat-chip>
            </span>
        </mat-chip-set>
    </div>

    <div class="comment-section" *ngIf="showComments">
        <div class="initials-circle">{{ initials }}</div>
        <form [formGroup]="commentsForm">
            <input type="text" class="add-comment" formControlName="comment"
                placeholder="{{ 'add_a_comment' | translate }}" />
        </form>
    </div>
    <div class="save-comment" *ngIf="showComments">
        <button class="button save" (click)="saveComment()">{{ 'save' | translate }}</button>
        <button class="cancel-btn cancel" (click)="hideComments()">{{ 'cancel' | translate }}</button>
        <div *ngFor="let comment of comments" class="comment-container">
            <span class="initials-circle">{{ comment?.createdBy | slice:0:1 }}</span>
            <div class="comment-details">
                <div class="header">
                    <span class="createdBy">{{ comment.createdBy }}</span>
                    <span class="timestamp">{{'created'|translate}} {{ getRelativeTime(comment?.createdDate) }}</span>
                </div>
                <span class="comments">{{ comment?.comments }}</span>
                <a (click)="openDeleteDialog(comment)">{{'delete'|translate}}</a>
            </div>
        </div>
    </div>

    <div *ngIf="showDeleteDialog" class="dialog-overlay">
        <div class="dialog-content">
          <p>{{'delete_comment_confirmation'|translate}}</p>
          <button (click)="confirmDelete()" class="confirmation-button button">{{'ok'|translate}}</button>
          <button (click)="cancelDelete()" class="delete-comment ">{{'cancel'|translate}}</button>
        </div>
      </div>
</div>