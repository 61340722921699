<app-toolbar></app-toolbar>
<div class="container">
    <div class="employeeList_header">
        <div class="employeeList_heading">
            <mat-icon svgIcon="arrow-back-double" class="back-icon" routerLink="../../pay-run"></mat-icon>
            <h3 class="heading">{{'pay-run-employee-list' | translate}}</h3>
        </div>
        <div>
            <button [disabled]="showSendSalarySlip" (click)="sendSalarySlip()"
                [ngClass]="{ 'disabled-button': showSendSalarySlip, 'enabled-button': !showSendSalarySlip }">
                {{ 'send_salary_slip' | translate }}
            </button>
        </div>
    </div>
    <ag-grid-angular (gridReady)="onGridReady($event)" style="width: 100%; margin-top: 20px; height: 30rem"
        class="ag-theme-alpine" [rowData]="employeeData" [defaultColDef]="employeeColDef" [enableSorting]="true"
        [enableFilter]="true" [columnDefs]="columnDefs" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>