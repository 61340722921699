import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';

@Component({
  selector: 'app-view-all-project',
  templateUrl: './view-all-project.component.html',
  styleUrls: ['./view-all-project.component.scss']
})
export class ViewAllProjectComponent {
  empProfilecolumnDefs: any[] = []
  empProfilerowData: any[] = [];

  constructor(private renderer: Renderer2, private toasterService: ToastrService, private projectService: ProjectManagementService, private router: Router) {
    this.empProfilecolumnDefs = [
      { field: "projectName", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "projectCode", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "customer", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "budget", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "manager", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "action", cellRenderer: this.actionCellRenderer.bind(this), }
    ];
  }

  ngOnInit(): void {
    this.getAllProject();
  }

  addProject() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ADD_PROJRCT])
  }

  getAllProject() {
    this.projectService.getAllProject().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.empProfilerowData = data.data.map(
            (res: any) => {
              return {
                id: res.id,
                projectName: res.projectName,
                projectCode: res.projectCode,
                customer: res.customer,
                manager: res.projectManager,
                budget: res.projectBudget,
              }
            })
        }
      }
    )
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, tooltip: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'matTooltip', tooltip);
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const viewIconClickHandler = () => {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.UPDATE_PROJECT + AppConstant.SEPERATOR + params.data.id])

    };

    const viewCostingDetailsClickHandler = () => {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_COSTING + AppConstant.SEPERATOR + params.data.id])
    };

    const deleteIconClickHandler = () => {
      this.projectService.deleteProject(params.data.id).subscribe(
        (projectDeleteResponse: any) => {
          if (projectDeleteResponse.status === 200) {
            this.getAllProject();
            this.toasterService.clear();
            this.toasterService.success(projectDeleteResponse.message);
          } else {
            this.toasterService.clear();
            this.toasterService.error(projectDeleteResponse.message);
          }
        }
      )
    };

    createButton('visibility', 'green', 'View Details', viewIconClickHandler);
    createButton('attach_money', 'green', 'View Costing Details', viewCostingDetailsClickHandler);
    createButton('delete', 'red', 'Delete Details', deleteIconClickHandler);
    return cell;
  }

  goToBack() {
    (sessionStorage.getItem('login') === 'admin')
      ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE])
      : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }
}


