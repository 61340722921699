import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PayRunService } from 'src/app/services/pay-run/pay-run.service';

@Component({
  selector: 'app-pay-run-employee-list',
  templateUrl: './pay-run-employee-list.component.html',
  styleUrls: ['./pay-run-employee-list.component.scss']
})
export class PayRunEmployeeListComponent implements OnInit {
  employeeData: any[] = [];
  employeeColDef: any;
  payRunId!: any;
  showSendSalarySlip: boolean = true;

  constructor(private toasterService: ToastrService, private payRunService: PayRunService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.payRunId = this.activeRoute.snapshot.params['id'];
    this.getSendSalarySlipStatus(this.payRunId);
    this.getPayRollEmployee(this.payRunId);
  }

  columnDefs = [
    { headerName: 'Emp Id', field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 70, valueGetter: (params: any) => 'EMP00' + params.data.id },
    { field: 'name', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 200 },
    { field: 'paidDays', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 120 },
    { field: 'earning', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 150, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    { field: 'deduction', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 120, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    { field: 'taxes', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 100, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    { field: 'reimbursement', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 150, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    { field: 'netPay', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 130, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
  ];

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  getSendSalarySlipStatus(payRunId: any) {
    this.payRunService.getSendSalarySlipStatus(payRunId).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.showSendSalarySlip = res.data !== 'Pending'
        }
      })
  }

  getPayRollEmployee(payRunId: any) {
    this.payRunService.getPayRollEmployee(payRunId).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.employeeData = res.data.map(
            (data: any) => {
              return {
                id: data.employeeBasicDetailsModel.id,
                name: data.employeeBasicDetailsModel.firstName + data.employeeBasicDetailsModel.lastName,
                paidDays: data.paidDays,
                earning: data.totalEarning,
                deduction: data.totalDeduction,
                taxes: data.taxes,
                reimbursement: data.reimbursement,
                netPay: data.netPay
              }
            });
        }
      }
    )
  }

  sendSalarySlip() {
    this.payRunService.sendSalarySlip(this.payRunId).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.getSendSalarySlipStatus(this.payRunId)
          this.toasterService.clear();
          this.toasterService.success(res.message);
        } else {
          this.toasterService.clear();
          this.toasterService.error(res.message);
        }
      })
  }

}
