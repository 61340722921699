
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { ToastrService } from 'ngx-toastr';
import { CompanyBankDetailsService } from 'src/app/services/company-bank-details.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-bank-info',
  templateUrl: './company-bank-info.component.html',
  styleUrls: ['./company-bank-info.component.scss']
})
export class CompanyBankInfoComponent implements OnInit {
  public bankIntegrationForm!: FormGroup;
  hidePassword: boolean = true;
  countryList: any;
  stateList: any;
  cityList: any;
  selectedCountry: string = "";
  selectedState: string = "";
  selectedCity: string = "";
  verifiedIFSC: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  bankName: any;
  bankLogo: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  isEditMode: boolean = false;
  isSettingSidebar: boolean = true;
  settingSidebar = sessionStorage.getItem('settingSideBar');

  constructor(
    private companyBankDetails: CompanyBankDetailsService,
    private employeeDetailsService: EmployeeDetailsService,
    private fb: FormBuilder,
    private masterDataService: MasterDataService,
    private toasterService: ToastrService,
    private router: Router
  ) { }
  bankDetails!: any;
  showBankDetails: boolean = false;
  country: any

  ngOnInit() {
    if (this.settingSidebar === 'true') {
      this.isSettingSidebar = false;
    }
    this.bankName = JSON.stringify(sessionStorage.getItem("bankName"));
    this.bankLogo = sessionStorage.getItem("bankLogo");
    this.initBankIntegration();
    this.getCountryList();
    this.getCompanyBankDetails();
  }

  initBankIntegration() {
    this.bankIntegrationForm = this.fb.group({
      'accountHolderName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'workEmail': ['', [Validators.email, Validators.required]],
      'mobileNumber': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10), ValidationUtil.validMobileNumber()]],
      'businessName': ['', Validators.required],
      'state': ['', Validators.required],
      'businessAddress': ['', Validators.required],
      'bankName': ['', [Validators.required]],
      'branchName': ['', [Validators.required]],
      'accountNumber': ['', [Validators.required, Validators.minLength(8), Validators.maxLength(18)]],
      'confirmBankAccountNo': ['', [Validators.required, Validators.minLength(8), Validators.maxLength(18)]],
      'ifscCode': ['', Validators.required],
      'panNumber': ['', [
        Validators.required,
        Validators.pattern("^([A-Z]){5}([0-9]){4}([A-Z]){1}$")
      ]],
      'address': ['', Validators.required],
      'city': [''],
      'pinCode': ['', Validators.required],
      'country': ['']
    })
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  getCountryList() {
    this.masterDataService.getAllCountries().subscribe(
      (data: any) => {
        if (data.status === 200)
          this.countryList = data.data;
      }
    )
  }

  getState(country: any) {
    this.selectedCountry = country;
    this.masterDataService.getAllState(country).subscribe(
      (data: any) => {
        this.stateList = data.data;
      }
    )
  }

  getCity(state: any) {
    this.selectedState = state;
    this.masterDataService.getAllCity(state).subscribe(
      (data: any) => {
        this.cityList = data.data;
      }
    )
  }

  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  verifyIFSC() {
    this.isLoading = true;
    this.errorMessage = '';
    this.employeeDetailsService.verifyIFSC(this.bankIntegrationForm.value.ifscCode).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.verifiedIFSC = true;
          this.isLoading = false;
          this.bankIntegrationForm.controls['ifscCode'].setErrors(null);
          const data = JSON.parse(response.data);
          const bankName = data.BANK;
          const branchName = data.BRANCH;
          this.bankIntegrationForm.patchValue({
            bankName: bankName,
            branchName: branchName
          });
        } else {
          this.isLoading = false;
          this.verifiedIFSC = false;
          this.bankIntegrationForm.controls['ifscCode'].setErrors({ 'invalidIFSC': true });
        }
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  resetIFSCFieldCheck() {
    this.verifiedIFSC = false;
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  saveBankDetails() { }

  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event);
  }

  validateAccountNumberMatch() {
    const accountNumber = this.bankIntegrationForm.value.accountNumber;
    const confirmAccountNumber = this.bankIntegrationForm.value.confirmBankAccountNo;
    if (accountNumber !== confirmAccountNumber) {
      this.bankIntegrationForm.get('confirmBankAccountNo')?.setErrors({ 'mismatch': true });
    } else {
      this.bankIntegrationForm.get('confirmBankAccountNo')?.setErrors(null);
    }
  }

  submit() {
    const accountNumber = this.bankIntegrationForm.value.accountNumber;
    const confirmAccountNumber = this.bankIntegrationForm.value.confirmBankAccountNo;
    if (accountNumber !== confirmAccountNumber) {
      this.bankIntegrationForm.get('confirmBankAccountNo')?.setErrors({ 'mismatch': true });
    } else {
      this.bankIntegrationForm.get('confirmBankAccountNo')?.setErrors(null);
    }
    if (this.bankIntegrationForm.valid) {
      const dto = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        accountHolderName: this.bankIntegrationForm.value.accountHolderName,
        workEmail: this.bankIntegrationForm.value.workEmail,
        mobileNumber: this.bankIntegrationForm.value.mobileNumber,
        panNumber: this.bankIntegrationForm.value.panNumber,
        address: this.bankIntegrationForm.value.address,
        businessName: this.bankIntegrationForm.value.businessName,
        businessAddress: this.bankIntegrationForm.value.businessAddress,
        ifscCode: this.bankIntegrationForm.value.ifscCode,
        bankName: this.bankIntegrationForm.value.bankName,
        branchName: this.bankIntegrationForm.value.branchName,
        accountNumber: this.bankIntegrationForm.value.accountNumber,
        country: this.bankIntegrationForm.value.country,
        state: this.bankIntegrationForm.value.state,
        city: this.bankIntegrationForm.value.city,
        pinCode: this.bankIntegrationForm.value.pinCode,
      };

      if (this.isEditMode) {
        this.updateBankDetails(dto);
      } else {
        this.addBankDetails(dto);
      }

    }
  }

  updateBankDetails(updatedDto: any) {
    this.employeeDetailsService.updateBankDetails(updatedDto).subscribe(
      (data: any) => {
        this.showBankDetails = true;
        if (data.status === 200) {
          this.showBankDetails = true;
          this.toasterService.clear();
          this.toasterService.success(data.message);
        } else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      });
  }

  addBankDetails(dto: any) {
    this.employeeDetailsService.addBankDetails(dto).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.getCompanyBankDetails();
          this.toasterService.clear();
          this.toasterService.success(data.data);
        } else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      });
  }

  getCompanyBankDetails() {
    this.companyBankDetails.getCompanyBankDetails(this.companyId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          if (data.data != null) {
            this.showBankDetails = true;
            this.isEditMode = true;
            const bankDetails = data.data;
            this.bankDetails = bankDetails;
            this.bankIntegrationForm.patchValue({
              ...bankDetails,
              confirmBankAccountNo: bankDetails.accountNumber,
              country: bankDetails.country
            });
            this.getState(bankDetails.country);
          }
        }
      })
  }

  gotoDashboard() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

  getButtonLabel() {
    return this.isEditMode ? 'Update Bank Details' : 'Save';
  }

  updateDetails() {
    this.showBankDetails = false;
    this.companyBankDetails.getCompanyBankDetails(this.companyId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          if (data.data != null) {
            this.isEditMode = true;
            const bankDetails = data.data;
            this.bankDetails = bankDetails;
            this.bankIntegrationForm.patchValue({
              ...bankDetails,
              confirmBankAccountNo: bankDetails.accountNumber,
              country: bankDetails.country
            });
            this.getState(bankDetails.country);
          }
        }
      })
  }

  goBack() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    }
  }

}
