import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { SalaryDataAndPaymentData } from 'src/app/model/employeeDetails';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss']
})

export class PaymentInformationComponent implements OnInit {
  @Input() createPaymentInformationForm!: FormGroup;
  constructor(private fb: FormBuilder, private router: Router, private toastrService: ToastrService, private employeeDetailsService: EmployeeDetailsService) { }

  ngOnInit(): void {
    this.createPaymentInformationForm = this.fb.group({
      paymentMode: ['Bank Transfer']
    });
  }
  companyName = sessionStorage.getItem(AppConstant.LOGIN_COMPANY_NAME);

  addPaymentInformation() {
    if (this.createPaymentInformationForm.value.paymentMode === null || this.createPaymentInformationForm.value.paymentMode.trim() === '') {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.PLEASE_SELECT_PAYMENT_METHOD);
    }
    else {
      let salaryDataAndPaymentData: SalaryDataAndPaymentData = {
        id: sessionStorage.getItem(AppConstant.BASIC_DETAILS_ID),
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        annualCtc: sessionStorage.getItem(AppConstant.ANNUAL_CTC),
        paymentMode: this.createPaymentInformationForm.value.paymentMode,
      }
      this.employeeDetailsService.addEmployeeSalaryDetails(salaryDataAndPaymentData).subscribe((data: any) => {
        if (data.status === 200) {
          this.toastrService.clear();
          this.toastrService.success(AppConstant.EMPLOYEE_ADDED);
          sessionStorage.removeItem('settingSideBar');
          this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES]);
        }
        else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      });
    }
  }
  backToHome() {
    if (sessionStorage.getItem('login') === 'admin') {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    }
  }
}
