import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaterialModule } from './shared/material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgGridModule } from 'ag-grid-angular';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { RegistrationComponent } from './components/admin/registration/registration.component';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found.component';
import { BasicDetailsComponent } from './components/admin/add-employee/basic-details/basic-details.component';
import { PersonalInformationComponent } from './components/admin/add-employee/personal-information/personal-information.component';
import { PaymentInformationComponent } from './components/admin/add-employee/payment-information/payment-information.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { AgGridComponent } from './components/ag-grid/ag-grid.component';
import { SalaryDetailsComponent } from './components/admin/add-employee/salary-details/salary-details.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpErrorResponseInterceptor } from './services/common-http/http-error-response.interceptor';
import { AddBankDetailsComponent } from './components/admin/add-bank-details/add-bank-details.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/forgot-password/change-password/change-password.component';
import { DashboardPageComponent } from './components/admin/dashboard-page/dashboard-page.component';
import { LayoutModule } from '@angular/cdk/layout';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/admin/dashboard-page/bar-chart/bar-chart.component';
import { CompanyProfileComponent } from './components/company-profile/company-profile.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddEmployeeComponent } from './components/admin/add-employee/add-employee/add-employee.component';
import { NgChartsModule } from 'ng2-charts';
import { ViewEmployeeProfileComponent } from './components/view-employee-profile/view-employee-profile.component';
import { ViewDepartmentComponent } from './components/admin/view-department/view-department.component';
import { ViewDesignationComponent } from './components/admin/view-designation/view-designation.component';
import { EmployeeLeaveDetailsComponent } from './components/leave-application/hr-side/employee-leave-details/employee-leave-details.component';
import { SalaryBreakupComponent } from './components/salary-breakup/salary-breakup.component';
import { CompanyLeaveStuctureComponent } from './components/leave-application/hr-side/company-leave-stucture/company-leave-stucture.component';
import { SalaryStructureComponent } from './components/admin/salary-structure/salary-structure.component';
import { ApplyLeaveComponent } from './components/leave-application/employee-side/apply-leave/apply-leave.component';
import { LeaveHistoryComponent } from './components/leave-application/employee-side/leave-history/leave-history.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email/verify-email.component';
import { CompanyBankDetailsComponent } from './components/admin/company-bank-details/company-bank-details.component';
import { EmployeeDetailsComponent } from './components/admin/add-employee/employee-details/employee-details.component';
import { PayRunComponent } from './components/pay-run/pay-run/pay-run.component';
import { EmployeeBankDetailsComponent } from './components/admin/employee-bank-details/employee-bank-details.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { LoaderComponent } from './components/loader/loader/loader.component';
import { MonthNamePipe } from './pipes/month-name.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { InstructionsDialogComponent } from './components/admin/instructions-dialog-component/instructions-dialog.component';
import { ErrorPopupComponent } from './shared/error-popup/error-popup.component';
import { MaskingDirective } from './shared/masking/masking.directive';
import { AttendanceComponent } from './components/attendance/employee/attendance/attendance.component';
import { LogTimeComponent } from './components/attendance/employee/log-time/log-time.component';
import { UppercaseDirective } from './shared/uppercase/uppercase-directive';
import { DocumentsUploadComponent } from './components/admin/add-employee/documents-upload/documents-upload.component';
import { ManagersEmployeeListComponent } from './components/attendance/ag-grid/managers-employee-list/managers-employee-list.component';
import { AttendanceConfigurationComponent } from './components/attendance/attendance-configuration/attendance-configuration.component';
import { ManagerSidenavComponent } from './components/shared/manager-approvals/manager-sidenav/manager-sidenav.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ViewEmployeeAttendanceComponent } from './components/shared/manager-approvals/view-employee-attendance/view-employee-attendance.component';
import { SupportComponent } from './components/admin/support/support/support.component';
import { UserSubscriptionViewComponent } from './components/admin/user-subscription-view/user-subscription-view/user-subscription-view.component';
import { TransactionHistoryComponent } from './components/admin/transactionHistory/transaction-history/transaction-history.component';
import { SubscriptionPlanComponent } from './components/admin/subscription-plan/subscription-plan/subscription-plan.component';
import { CreateSubscriptionsComponent } from './components/shared/subscriptions/components/create-subscriptions/create-subscriptions.component';
import { PaymentReceivedGraphComponent } from './components/shared/subscriptions/components/dashboard/components/payment-received-graph/payment-received-graph.component';
import { ProfitLossChartComponent } from './components/shared/subscriptions/components/dashboard/components/profit-loss-chart/profit-loss-chart.component';
import { RecentlyAddedCustomersComponent } from './components/shared/subscriptions/components/dashboard/components/recently-added-customers/recently-added-customers.component';
import { SubscriptionsViewComponent } from './components/shared/subscriptions/components/dashboard/components/subscriptions-view/subscriptions-view.component';
import { PaymentReceivedViewComponent } from './components/shared/subscriptions/components/dashboard/components/payment-received-view/payment-received-view.component';
import { CustomerComponent } from './components/shared/subscriptions/components/dashboard/containers/customer/customer.component';
import { AdminDashboardComponent } from './components/shared/subscriptions/components/dashboard/containers/admin-dashboard/admin-dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TrendModule } from 'ngx-trend';
import { CommonModule, DatePipe } from '@angular/common';
import { ReportComponent } from './components/shared/subscriptions/components/dashboard/containers/report/report.component';
import { AdminModuleSidebarComponent } from './components/shared/admin-module-sidebar/admin-module-sidebar.component';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenInterceptor } from './services/common-http/Token/jwt.interceptor';
import { LandingPageComponent } from './components/shared/landing-page/landing-page/landing-page.component';
import { BookDemoComponent } from './components/shared/landing-page/book-demo/book-demo.component';
import { ImageViewerComponent } from './components/admin/add-employee/image-viewer/image-viewer/image-viewer.component';
import { FeaturesComponent } from './components/shared/landing-page/features/features.component';
import { UpcomingsComponent } from './components/shared/landing-page/upcomings/upcomings.component';
import { MasterTeamComponent } from './components/shared/landing-page/master-team/master-team.component';
import { PrivacyPolicyComponent } from './components/shared/landing-page/policy/privacy-policy/privacy-policy.component';
import { SolutionsComponent } from './components/shared/landing-page/solutions/solutions.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FooterComponent } from './components/shared/landing-page/footer/footer.component';
import { RefreshTokenComponent } from './components/shared/refresh-token/refresh-token.component';
import { EmailConfigurationComponent } from './components/admin/email-configuration/email-configuration.component';
import { DirectDepositComponent } from './components/direct-deposit/direct-deposit.component';
import { BankIntegrtaionSetupComponent } from './components/bank-integrations/bank-integrtaion-setup/bank-integrtaion-setup.component';
import { ViewIntegratedBankComponent } from './components/bank-integrations/view-integrated-bank/view-integrated-bank.component';
import { AddBulkEmployeeComponent } from './components/admin/add-employee/add-bulk-employee/add-bulk-employee.component';
import { PfConfigurationComponent } from './components/admin/pf-configuration/pf-configuration.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { BlockCopyPasteDirective } from './shared/block-copy-paste.directive';
import { ChoosePaymentComponent } from './components/admin/subscription-plan/choose-payment/choose-payment.component';
import { PaymentDiscriptionComponent } from './components/admin/subscription-plan/payment-discription/payment-discription.component';
import { GodaddySslComponent } from './components/shared/godaddy-ssl/godaddy-ssl.component';
import { TermsAndConditionsComponent } from './components/shared/landing-page/policy/terms-and-conditions/terms-and-conditions.component';
import { CancellationRefundPolicyComponent } from './components/shared/landing-page/policy/cancellation-refund-policy/cancellation-refund-policy.component';
import { ShippingDeliveryPolicyComponent } from './components/shared/landing-page/policy/shipping-delivery-policy/shipping-delivery-policy.component';
import { AssignManagerComponent } from './components/admin/assign-manager/assign-manager.component';
import { TaxDeclarationConfigurationComponent } from './components/shared/tax-declaration-configuration/tax-declaration-configuration.component';
import { AddSectionPopupComponent } from './components/shared/add-section-popup/add-section-popup.component';
import { ViewCategoryBySectionIdComponent } from './components/shared/view-category-by-section-id/view-category-by-section-id.component';
import { AddCategoryComponent } from './components/shared/add-category/add-category.component';
import { RegimeSlabComponent } from './components/shared/regime-slab/regime-slab.component';
import { AddRegimeSlabPopupComponent } from './components/shared/add-regime-slab-popup/add-regime-slab-popup.component';
import { ViewTaxDeclarationComponent } from './components/tax-declaration/view-tax-declaration/view-tax-declaration/view-tax-declaration.component';
import { DeclaredFormComponent } from './components/tax-declaration/declared-form/declared-form.component';
import { StartFreshComponent } from './components/tax-declaration/start-fresh/start-fresh.component';
import { SettingConfigurationComponent } from './components/admin/setting-configuration/setting-configuration.component';
import { AddRolesComponent } from './components/admin/add-roles/add-roles.component';
import { ExitProcessComponent } from './components/exit-process/employee-side/exit-process.component';
import { ViewResignationComponent } from './components/exit-process/employee-side/view-resignation/view-resignation.component';
import { EmployeeResignationsComponent } from './components/shared/manager-approvals/employee-resignations/employee-resignations.component';
import { ViewEmployeeResignationComponent } from './components/shared/manager-approvals/view-employee-resignation/view-employee-resignation.component';
import { ExitDetailsComponent } from './components/admin/exit-process/exit-details/exit-details.component';
import { FinalSettlementComponent } from './components/admin/exit-process/final-settlement/final-settlement.component';
import { FinalSettlementPayrunDetailsComponent } from './components/admin/exit-process/final-settlement-payrun-details/final-settlement-payrun-details.component';
import { DeclarationApprovalComponent } from './components/tax-declaration/declaration-approval/declaration-approval.component';
import { ViewDeclaredListComponent } from './components/tax-declaration/view-declared-list/view-declared-list.component';
import { MatStepperModule } from '@angular/material/stepper';
import { AssetManagementDashboardComponent } from './components/asset-management/asset-management-dashboard/asset-management-dashboard/asset-management-dashboard.component';
import { AddAssetPopupComponent } from './components/asset-management/add-asset-popup/add-asset-popup.component';
import { AssignAssetsPopupComponent } from './components/asset-management/assign-assets-popup/assign-assets-popup.component';
import { SupplierDetailsComponent } from './components/asset-management/supplier-details/supplier-details.component';
import { ExitHistoryComponent } from './components/admin/exit-process/exit-history/exit-history.component';
import { HouseRentDetailsComponent } from './components/tax-declaration/house-rent-details/house-rent-details.component';
import { ViewSectionDetailsComponent } from './components/tax-declaration/view-section-details/view-section-details.component';
import { RevertExitProcessComponent } from './components/admin/exit-process/revert-exit-process/revert-exit-process.component';
import { HrViewEmployeeResignationComponent } from './components/shared/hr-resignation-approval/hr-view-employee-resignation/hr-view-employee-resignation.component';
import { AddGoalExcelComponent } from './components/appraisal/components/hr/add-goal-excel/add-goal-excel.component';
import { EmployeeListUnderManagerComponent } from './components/appraisal/components/manager-side/employee-list-under-manager/employee-list-under-manager.component';
import { EmployeeBasicGoalsComponent } from './components/appraisal/components/employee-side/employee-basic-goals/employee-basic-goals.component';
import { EmployeeAppraisalViewComponent } from './components/appraisal/components/employee-side/employee-appraisal-view/employee-appraisal-view.component';
import { EmployeeAppraisalEvolutionComponent } from './components/appraisal/components/employee-side/employee-appraisal-evolution/employee-appraisal-evolution.component';
import { EmployeePerformanceGoalComponent } from './components/appraisal/components/employee-side/employee-performance-goal/employee-performance-goal.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AddGoalsComponent } from './components/appraisal/components/employee-side/add-goals/add-goals.component';
import { EmployeeAnnualPerformanceComponent } from './components/appraisal/components/employee-side/employee-annual-performance/employee-annual-performance.component';
import { EmployeeGoalsPlanComponent } from './components/appraisal/components/manager-side/employee-goals-plan/employee-goals-plan.component';
import { AssetDetailsComponent } from './components/asset-management/asset-details/asset-details.component';
import { SupplierInfoComponent } from './components/asset-management/supplier-info/supplier-info.component';
import { UpdateEmployeeSalaryDetailsComponent } from './components/admin/add-employee/update-employee-salary-details/update-employee-salary-details.component';
import { EventManagementComponent } from './components/event-management/event-management/event-management.component';
import { AddEventComponent } from './components/event-management/add-event/add-event.component';
import { GridCellComponent } from './components/event-management/grid-cell/grid-cell.component';
import { ViewTimelineComponent } from './components/asset-management/view-timeline/view-timeline.component';
import { EventsComponent } from './components/admin/dashboard-page/events/events.component';
import { ConfirmationPopupComponent } from './shared/confirmation-popup/confirmation-popup.component';
import { EmployeeAssetDetailsComponent } from './components/asset-management/employee-asset-details/employee-asset-details.component';
import { AddSupplierComponent } from './components/asset-management/add-supplier/add-supplier.component';
import { OrderForAssetComponent } from './components/asset-management/order-for-asset/order-for-asset.component';
import { OrderForAssetPopupComponent } from './components/asset-management/order-for-asset-popup/order-for-asset-popup.component';
import { AddApproverComponent } from './components/admin/add-approver/add-approver.component';
import { ViewAllProjectComponent } from './components/project-management/view-all-project/view-all-project.component';
import { AddProjectPopupComponent } from './components/project-management/add-project-popup/add-project-popup.component';
import { ViewCostingDetailsComponent } from './components/project-management/view-costing-details/view-costing-details.component';
import { CreateVoucherComponent } from './components/shared/create-voucher/create-voucher.component';
import { ViewVoucherComponent } from './components/shared/view-voucher/view-voucher.component';
import { ChooseVoucherComponent } from './components/shared/choose-voucher/choose-voucher.component';
import { IncidentRequestComponent } from './components/helpdesk/incident-request/incident-request.component';
import { RequestorInformationComponent } from './components/helpdesk/requestor-information/requestor-information.component';
import { ViewIncidentRequestComponent } from './components/helpdesk/view-incident-request/view-incident-request.component';
import { HelpDeskComponent } from './components/helpdesk/help-desk/help-desk.component';
import { ProjectApprovalComponent } from './components/project-management/project-approval/project-approval.component';
import { PayrunApprovalComponent } from './components/admin/payrun-approval/payrun-approval.component';
import { PayrunApprovalDetailsComponent } from './components/admin/payrun-approval-details/payrun-approval-details.component';
import { AddTraningComponent } from './components/admin/traning-module/add-traning/add-traning.component';
import { ViewTraningComponent } from './components/admin/traning-module/view-traning/view-traning.component';
import { ResolutionComponent } from './components/helpdesk/resolution/resolution.component';
import { ViewServiceRequestComponent } from './components/helpdesk/view-service-request/view-service-request.component';
import { ViewTrainingDetailsComponent } from './components/admin/traning-module/view-training-details/view-training-details.component';
import { ViewTrainingSummaryComponent } from './components/admin/traning-module/view-training-summary/view-training-summary.component';
import { ImageSliderComponent } from './components/admin/traning-module/image-slider/image-slider.component';
import { EmployeeAssignTrainingDetailsComponent } from './components/admin/traning-module/employee-assign-training-details/employee-assign-training-details.component';
import { AssignTrainingToEmployeeComponent } from './components/shared/manager-approvals/assign-training-to-employee/assign-training-to-employee.component';
import { OrgHierarchyComponent } from './components/admin/org-hierarchy/org-hierarchy.component';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { RatingComponent } from './components/admin/traning-module/rating/rating.component';
import { PayslipAndFormsComponent } from './components/admin/payslipandforms/payslipandforms.component';
import { AddEditLeavePopupComponent } from './components/leave-application/hr-side/add-edit-leave-popup/add-edit-leave-popup.component';
import { CommonSearchInputComponent } from './components/shared/common-search-input/common-search-input.component';
import { BirthdayListComponent } from './components/helpdesk/birthday-list/birthday-list.component';
import { PdfViewerComponent } from './shared/pdf-viewer/pdf-viewer.component';
import { RoleInformationComponent } from './components/admin/add-employee/role-information/role-information.component';
import { RoleInfoStepperComponent } from './components/admin/add-employee/role-info-stepper/role-info-stepper.component';
import { PayRunGroupComponent } from './components/pay-run/pay-run-group/pay-run-group.component';
import { SalaryAgGridComponent } from './components/salary-ag-grid/salary-ag-grid.component';
import { SelectRegimeDialogComponent } from './shared/select-regime-dialog/select-regime-dialog.component';
import { PayRunEmployeeListComponent } from './components/pay-run-employee-list/pay-run-employee-list.component';
import { BankAdviceDialogComponent } from './shared/bank-advice-dialog/bank-advice-dialog.component';
import { FamilyAndEmergencyContactInfoComponent } from './components/family-and-emergency-contact-info/family-and-emergency-contact-info/family-and-emergency-contact-info.component';
import { ViewFamilyAndEmergencyDetailsComponent } from './components/family-and-emergency-contact-info/view-family-and-emergency-details/view-family-and-emergency-details.component';
import { AddFamilyAndEmergencyContactsComponent } from './components/family-and-emergency-contact-info/add-family-and-emergency-contacts/add-family-and-emergency-contacts.component';
import { CompanyBankInfoComponent } from './components/company-bank-details/company-bank-info.component';
import { CreateNewPasswordComponent } from './components/forgot-password/create-new-password/create-new-password.component';
import { TaskManagementComponent } from './components/task-management/task-management/task-management.component';
import { BoardTrackingComponent } from './components/task-management/board-tracking/board-tracking.component';
import { BoardPopupComponent } from './shared/board-popup/board-popup.component';
import { CreateSprintComponent } from './components/task-management/create-sprint/create-sprint.component';
import { ViewSprintComponent } from './components/task-management/view-sprint/view-sprint.component';
import { ViewProjectDashboardComponent, CollaboratorsRendererComponent } from './components/task-management/view-project-dashboard/view-project-dashboard.component';
import { AddTaskComponent } from './components/task-management/add-task/add-task/add-task.component';
import { BacklogComponent } from './components/task-management/backlog/backlog.component';
import { TaskManagementSidenavComponent } from './components/task-management/task-management-sidenav/task-management-sidenav.component';
import { ViewTaskDetailsComponent } from './components/task-management/add-task/view-task-details/view-task-details.component';
import { ViewReportComponent } from './components/task-management/view-report/view-report.component';
import { TaskComponent } from './components/task-management/task/task.component';
import { LeaveDetailsPopupComponent } from './shared/leave-details-popup/leave-details-popup.component';
import { ViewAllProductComponent } from './components/shared/landing-page/view-all-product/view-all-product.component';
import { MainLandingPageComponent } from './components/shared/landing-page/main-landing-page/main-landing-page.component';
import { AllProductsComponent } from './components/shared/landing-page/all-products/all-products.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ViewModulesRolebaseComponent } from './components/acess-all-modules/view-modules-rolebase/view-modules-rolebase.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { SelectSalaryTypePopupComponent } from './components/select-salary-type-popup/select-salary-type-popup.component';
import { AddBulkHolidayComponent } from './components/admin/add-bulk-holiday/add-bulk-holiday.component';
import { ViewAllBranchComponent } from './components/view-all-branch/view-all-branch.component';
import { AddBranchComponent } from './components/add-branch/add-branch.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    RegistrationComponent,
    PageNotFoundComponent,
    AddEmployeeComponent,
    BasicDetailsComponent,
    SalaryDetailsComponent,
    PersonalInformationComponent,
    PaymentInformationComponent,
    LoginPageComponent,
    SideNavComponent,
    AgGridComponent,
    CompanyProfileComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    AddBankDetailsComponent,
    SalaryStructureComponent,
    ViewEmployeeProfileComponent,
    AddBankDetailsComponent,
    ViewDepartmentComponent,
    ViewDesignationComponent,
    DashboardPageComponent,
    BarChartComponent,
    PieChartComponent,
    EmployeeLeaveDetailsComponent,
    SalaryBreakupComponent,
    CompanyLeaveStuctureComponent,
    LeaveHistoryComponent,
    ApplyLeaveComponent,
    VerifyEmailComponent,
    CompanyBankDetailsComponent,
    EmployeeDetailsComponent,
    PayRunComponent,
    EmployeeBankDetailsComponent,
    LoaderComponent,
    MonthNamePipe,
    SafePipe,
    InstructionsDialogComponent,
    ErrorPopupComponent,
    MaskingDirective,
    AttendanceComponent,
    LogTimeComponent,
    UppercaseDirective,
    DocumentsUploadComponent,
    ManagersEmployeeListComponent,
    AttendanceConfigurationComponent,
    ManagerSidenavComponent,
    ViewEmployeeAttendanceComponent,
    SupportComponent,
    UserSubscriptionViewComponent,
    TransactionHistoryComponent,
    SubscriptionPlanComponent,
    CreateSubscriptionsComponent,
    PaymentReceivedGraphComponent,
    ProfitLossChartComponent,
    RecentlyAddedCustomersComponent,
    SubscriptionsViewComponent,
    PaymentReceivedViewComponent,
    CustomerComponent,
    AdminDashboardComponent,
    ReportComponent,
    AdminModuleSidebarComponent,
    LandingPageComponent,
    PrivacyPolicyComponent,
    BookDemoComponent,
    ImageViewerComponent,
    FeaturesComponent,
    UpcomingsComponent,
    MasterTeamComponent,
    SolutionsComponent,
    LandingPageComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    FooterComponent,
    RefreshTokenComponent,
    EmailConfigurationComponent,
    DirectDepositComponent,
    BankIntegrtaionSetupComponent,
    ViewIntegratedBankComponent,
    AddBulkEmployeeComponent,
    PfConfigurationComponent,
    BlockCopyPasteDirective,
    ChoosePaymentComponent,
    PaymentDiscriptionComponent,
    GodaddySslComponent,
    TermsAndConditionsComponent,
    CancellationRefundPolicyComponent,
    ShippingDeliveryPolicyComponent,
    AssignManagerComponent,
    TaxDeclarationConfigurationComponent,
    AddSectionPopupComponent,
    ViewCategoryBySectionIdComponent,
    AddCategoryComponent,
    RegimeSlabComponent,
    AddRegimeSlabPopupComponent,
    ViewTaxDeclarationComponent,
    DeclaredFormComponent,
    StartFreshComponent,
    SettingConfigurationComponent,
    AddRolesComponent,
    ExitProcessComponent,
    ViewResignationComponent,
    EmployeeResignationsComponent,
    ViewEmployeeResignationComponent,
    ExitDetailsComponent,
    FinalSettlementComponent,
    FinalSettlementPayrunDetailsComponent,
    DeclarationApprovalComponent,
    ViewDeclaredListComponent,
    AssetManagementDashboardComponent,
    AssetDetailsComponent,
    AddAssetPopupComponent,
    AssignAssetsPopupComponent,
    SupplierDetailsComponent,
    SupplierInfoComponent,
    ExitHistoryComponent,
    HouseRentDetailsComponent,
    ViewSectionDetailsComponent,
    RevertExitProcessComponent,
    HrViewEmployeeResignationComponent,
    AddGoalExcelComponent,
    EmployeeListUnderManagerComponent,
    EmployeeBasicGoalsComponent,
    EmployeeAppraisalViewComponent,
    EmployeeAppraisalEvolutionComponent,
    EmployeePerformanceGoalComponent,
    AddGoalsComponent,
    EmployeeAnnualPerformanceComponent,
    EmployeeGoalsPlanComponent,
    UpdateEmployeeSalaryDetailsComponent,
    EventManagementComponent,
    AddEventComponent,
    GridCellComponent,
    ViewTimelineComponent,
    EventsComponent,
    ConfirmationPopupComponent,
    EmployeeAssetDetailsComponent,
    AddSupplierComponent,
    OrderForAssetComponent,
    OrderForAssetPopupComponent,
    AddApproverComponent,
    ViewAllProjectComponent,
    AddProjectPopupComponent,
    ViewCostingDetailsComponent,
    CreateVoucherComponent,
    ViewVoucherComponent,
    ChooseVoucherComponent,
    AddApproverComponent,
    IncidentRequestComponent,
    RequestorInformationComponent,
    ViewIncidentRequestComponent,
    HelpDeskComponent,
    AddApproverComponent,
    ProjectApprovalComponent,
    ChooseVoucherComponent,
    PayrunApprovalComponent,
    PayrunApprovalDetailsComponent,
    AddTraningComponent,
    ViewTraningComponent,
    ResolutionComponent,
    ViewServiceRequestComponent,
    ViewTrainingDetailsComponent,
    ViewTrainingSummaryComponent,
    ImageSliderComponent,
    EmployeeAssignTrainingDetailsComponent,
    AssignTrainingToEmployeeComponent,
    OrgHierarchyComponent,
    RatingComponent,
    PayslipAndFormsComponent,
    AddEditLeavePopupComponent,
    CommonSearchInputComponent,
    BirthdayListComponent,
    PdfViewerComponent,
    PayRunEmployeeListComponent,
    RoleInformationComponent,
    RoleInfoStepperComponent,
    PayRunGroupComponent,
    SalaryAgGridComponent,
    SelectRegimeDialogComponent,
    BankAdviceDialogComponent,
    FamilyAndEmergencyContactInfoComponent,
    AddFamilyAndEmergencyContactsComponent,
    ViewFamilyAndEmergencyDetailsComponent,
    CompanyBankInfoComponent,
    CreateNewPasswordComponent,
    TaskManagementComponent,
    BoardTrackingComponent,
    BoardPopupComponent,
    CreateSprintComponent,
    ViewProjectDashboardComponent,
    ViewSprintComponent,
    CollaboratorsRendererComponent,
    AddTaskComponent,
    BacklogComponent,
    TaskManagementSidenavComponent,
    ViewTaskDetailsComponent,
    ViewReportComponent,
    TaskComponent,
    LeaveDetailsPopupComponent,
    ViewAllProductComponent,
    MainLandingPageComponent,
    AllProductsComponent,
    ToolbarComponent,
    ViewModulesRolebaseComponent,
    MaintenanceComponent,
    SelectSalaryTypePopupComponent,
    AddBulkHolidayComponent,
    ViewAllBranchComponent,
    AddBranchComponent
  ],
  imports: [
    BrowserModule,
    MatToolbarModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    CommonModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgChartsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatInputModule,
    RouterModule,
    AgGridModule,
    NgOtpInputModule,
    HttpClientModule,
    LayoutModule,
    NgApexchartsModule,
    CdkStepperModule,
    MatStepperModule,
    TrendModule,
    CommonModule,
    NgxOrgChartModule,
    NgxMatSelectSearchModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      closeButton: true
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [NgOtpInputModule, HttpClientModule, MonthNamePipe, SafePipe, DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorResponseInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-IN' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule { }