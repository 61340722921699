import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';

@Component({
  selector: 'app-project-approval',
  templateUrl: './project-approval.component.html',
  styleUrls: ['./project-approval.component.scss']
})
export class ProjectApprovalComponent {
  id: any;
  projectList: any;
  projectListCol: any = [
    { field: "projectName", width: 120, unSortIcon: true, floatingFilter: true },
    { field: "projectCode", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "projectBudget", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "projectManager", floatingFilter: true, unSortIcon: true, width: 250 },
    { field: "Action", cellRenderer: this.actionCellRenderer.bind(this), width: 230 }
  ];
  projectListColDef: any;

  constructor(private route: ActivatedRoute, private router: Router, private renderer: Renderer2, private projectService: ProjectManagementService) {
    this.projectListColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getAllProjectDetailsByEmployeeId();
  }

  getAllProjectDetailsByEmployeeId() {
    this.projectService.getAllProjectDetailsByEmployeeId().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.projectList = data.data;
        }
      }
    )
  }

  goToDashboard() {
    (sessionStorage.getItem('login') === 'admin') 
    ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]) 
    : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', 'toolTipText');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const viewProjectDetails = () => {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_PROJECT_DETAILS + AppConstant.SEPERATOR + params.data.id], {
        queryParams: { id: sessionStorage.getItem(AppConstant.EMPLOYEE_ID) }
      });
    };

    createButton('visibility', 'green', viewProjectDetails);
    return cell;
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

}