<div class="product-view-container">
  <div class="product-nav">
    <a class="product-link active" (click)="scrollToSection('solutions')">{{ 'features' | translate }}</a>
    <a class="product-link" (click)="scrollToSection('allproducts')">{{'our_products'|translate}}</a>
    <a class="product-link" (click)="scrollToSection('whatsupcomming')">{{'whats_upcoming'|translate}}</a>
  </div>
  
  <div class="product-content">
    <h1 class="product-title">{{'explore'|translate}}</h1>
    <div class="product-grid">
      <div class="product-card onboarding" (click)="showModuleDetails()">
        <div class="product-card-icon">👥</div>
        <p class="product-card-title">{{'employee_onboarding'|translate}}</p>
      </div>
      <div class="product-card payrun-groups" (click)="showModuleDetails()">
        <div class="product-card-icon">💰</div>
        <p class="product-card-title">{{'payroll_management'|translate}}</p>
      </div>
      <div class="product-card task-management" (click)="showModuleDetails()">
        <div class="product-card-icon">📝</div>
        <p class="product-card-title">{{'task_management'|translate}}</p>
      </div>

      <div class="product-card transaction-history" (click)="showModuleDetails()">
        <div class="product-card-icon">📊</div>
        <p class="product-card-title">{{'project_management'|translate}}</p>
      </div>

      <div class="product-card apprisal-process" (click)="showModuleDetails()">
        <div class="product-card-icon">📈</div>
        <p class="product-card-title">{{'apprisal_process'|translate}}</p>
      </div>
      <div class="product-card assets-management" (click)="showModuleDetails()">
        <div class="product-card-icon">🏢</div>
        <p class="product-card-title">{{'asstes_management'|translate}}</p>
      </div>
      <div class="product-card event-management" (click)="showModuleDetails()">
        <div class="product-card-icon">🎉</div>
        <p class="product-card-title">{{'event_management'|translate}}</p>
      </div>
      <div class="product-card add-training" (click)="showModuleDetails()">
        <div class="product-card-icon">📚</div>
        <p class="product-card-title">{{'training_management'|translate}}</p>
      </div>
      <!-- <div class="product-card assign-manager">
        <div class="product-card-icon">👔</div>
        <p class="product-card-title">{{'assign_manager'|translate}}</p>
      </div> -->
      <div class="product-card attendance" (click)="showModuleDetails()">
        <div class="product-card-icon">📅</div>
        <p class="product-card-title">{{'attendance'|translate}}</p>
      </div>
      <div class="product-card leave" (click)="showModuleDetails()">
        <div class="product-card-icon">🏖️</div>
        <p class="product-card-title">{{'leave_management'|translate}}</p>
      </div>
      <div class="product-card approvals" (click)="showModuleDetails()">
        <div class="product-card-icon">👍</div>
        <p class="product-card-title">{{'approvals'|translate}}</p>
      </div>
      <div class="product-card onboarding" (click)="showModuleDetails()">
        <div class="product-card-icon">🛟</div>
        <p class="product-card-title">{{'help_desk'|translate}}</p>
      </div>
      <div class="product-card apprisal-process" (click)="showModuleDetails()">
        <div class="product-card-icon">📄</div>
        <p class="product-card-title">{{'it_declaration'|translate}}</p>
      </div>
      <div class="product-card settings" (click)="showModuleDetails()">
        <div class="product-card-icon">💵</div>
        <p class="product-card-title">{{'salary_details'|translate}}</p>
      </div>
      <div class="product-card roles" (click)="showModuleDetails()">
        <div class="product-card-icon">⚖️</div>
        <p class="product-card-title">{{'roles_and_responsibilityes'|translate}}</p>
      </div>
    </div>
  </div>
</div>