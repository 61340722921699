
<div class="hero">
    <h2 class="product-title">{{'welcome_to_product'|translate}}</h2>
    <p>Discover innovative solutions for your needs.</p>
</div>
<div class="interview-portal-container">
    <div class="image-container">
        <img src="../../../..//../assets/images/landing-page/banner/interviewportal.jpg" alt="Interview Portal Image"
            class="fade-left">
    </div>
    <div class="description-container">
        <h1 class="title fade-right">{{'interview_portal' | translate}}</h1>
        <p class="interview-description fade-right">
            {{'interview_portal_description' | translate}}
        </p>
        <div>
            <button class="interview-button" (click)="openInterviewPortal()">{{'goto_interview_portal'|translate}}</button>
        </div>
    </div>
</div>

<div class="interview-portal-container">
    <div class="description-container">
        <h1 class="title fade-right">{{'verify_hiring' | translate}}</h1>
        <p class="interview-description fade-right">
            {{'verify_hiring_description' | translate}}
        </p>
     
    </div>
    <div class="image-container">
        <img src="../../../..//../assets/images/landing-page/banner/verified-hiring.jpg" alt="Interview Portal Image"
            class="fade-left">
    </div>

</div>