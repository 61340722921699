import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { ApprovalService } from 'src/app/services/approval/approval.service';

@Component({
  selector: 'app-payrun-approval',
  templateUrl: './payrun-approval.component.html',
  styleUrls: ['./payrun-approval.component.scss']
})
export class PayrunApprovalComponent implements OnInit {
  payrunApprovalColDef: any;
  payrunApprovalData: PayrunApprovalData[] = [];
  payrunApprovalTableCol = [
    { headerName: 'Pay Run Id', field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { headerName: 'Pay Run Month', field: 'payrunMonth', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'amount', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, valueFormatter: (params: any) => params.value ? params.value.toFixed(2) : '0.00' },
    { field: 'groups', floatingFilter: true, unSortIcon: true, sortable: true, tooltipField: 'groups', filter: true, resizable: true },
    { field: 'status', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { headerName: 'No. of Employees', field: 'noofemployees', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'Action', cellRenderer: this.actionCellRenderer.bind(this) },
  ];

  constructor(private approvalService: ApprovalService, private renderer: Renderer2, private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.getPayrunApprovalHistory();
  }

  getPayrunApprovalHistory() {
    this.approvalService.getPayrunApprovalHistory().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.payrunApprovalData = data.data;
        }
      })
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');

    const createButton = (icon: string, colorClass: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      this.renderer.addClass(button, 'mat-icon-button');
      this.renderer.addClass(button, 'ag-grid-custom-button');
      this.renderer.addClass(button, colorClass);
      this.renderer.listen(button, 'click', clickHandler);
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.appendChild(cell, button);
    };
    createButton('visibility', 'custom-black', () => this.payrunApprovalDetailView(params.data));
    return cell;
  }

  payrunApprovalDetailView(data: any) {
    this.router.navigate([`${AppConstant.MANAGER_SIDENAV}/${AppConstant.PAYRUN_APPROVAL_DETAILS}`, data.id], { state: { data } });
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  goToDashboard() {
    if (sessionStorage.getItem(AppConstant.MANAGER_SIDENAV) === AppConstant.EMPLOYEE_ROLE) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
      sessionStorage.setItem(AppConstant.SHOW_EMPLOYEE_LEAVE, 'false');
      sessionStorage.removeItem(AppConstant.IS_MANAGER);
    }
    else {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    }
  }
}

export interface PayrunApprovalData {
  id: string,
  payrunMonth: string,
  amount: number,
  status: string,
  groups: string,
  noofemployees: number
}