<app-toolbar></app-toolbar>
<div class="main-container">
    <div class="header">
        <div class="header-left">
            <!-- SVG Icon for All Projects -->
            <mat-icon class="back-arrow-icon" matTooltip="{{'back' | translate}}" (click)="goToBack()"
                svgIcon="arrow-back-double"></mat-icon>
            <div width="24" height="24" svgIcon="arrow-back-double"></div>
            <h3 class="headerName">{{'all_projects' | translate}}</h3>
        </div>
        <div class="header-right">
            <button (click)="addProject()">{{'add_project' | translate}}</button>
        </div>
    </div>
    <div class="employeeAssetDetail">
        <ag-grid-angular class="ag-theme-alpine" style="height:480px" [columnDefs]="empProfilecolumnDefs"
            [rowData]="empProfilerowData" [tooltipShowDelay]="0" [tooltipHideDelay]="2000" [floatingFiltersHeight]="40"
            [sideBar]="true" [pagination]="true" [paginationPageSize]="10"
            [paginationPageSizeSelector]="[10, 20, 50, 100]">
        </ag-grid-angular>
    </div>
</div>