<app-toolbar></app-toolbar>
<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{ 'salary_details' | translate }}</h3>
    </div>

    <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 29rem; width:100%;"
        class="ag-theme-alpine" [rowData]="salaryDetails" [rowSelection]="'multiple'" [animateRows]="true"
        [columnDefs]="employeeTableCol" [defaultColDef]="employeeColDef" [enableSorting]="true" [enableFilter]="true"
        [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>