import { Component } from '@angular/core';
import { ActivityMonitorService } from './services/activity-monitor/activity-monitor.service';
import { Icons } from './constants/icons.costants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.dev';
import { Router } from '@angular/router';
import { AppConstant } from './constants/app.constants';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string = "iWExe";
  constructor(private activityMonitorService: ActivityMonitorService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,private router:Router) {
    let svgIocns = Icons.SVGICONS;
    svgIocns.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }

  ngOnInit(): void {
    this.activityMonitorService.startMonitoring();
    if (environment.maintenanceMode) {
      this.router.navigate([AppConstant.MAINTENANCE]);
    }
  }
}
